import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, role, requiredPermission }) => {
  const  token  = localStorage.getItem('token');
  const storedPermissions = localStorage.getItem('module_permission');
  let modulePermissions = [];

  if (storedPermissions) {
    modulePermissions = JSON.parse(storedPermissions);
  } else {
    console.log('No permissions found');
  }
  const hasPermission =  modulePermissions.includes(requiredPermission);
  return (hasPermission && token) ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
