import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { removeErrorMsg,AddAssetMaintaince } from "../../../../redux/assetSlice";
import { useDispatch, useSelector } from 'react-redux';
import Alert from "../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { customStyles } from "../../../../helpers/Constants";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { format, parseISO } from 'date-fns';
import { getEmployee } from '../../../../redux/commonSlice';

const validationSchema = Yup.object().shape({

    maintenance_type: Yup.string().required("Type is required"),

    maintenance_date: Yup.string().required("Date of Maintenance is required"),

    assigned_Employee: Yup.string().required("Employee is required"),

    maintenance_cost: Yup.string().required("Maintenance cost is required"),
});



function AssetMaintenance({ show, handleClose, asset }) {
    const dispatch = useDispatch();
    const {
        loading = false,
        error = "",
    } = useSelector((state) => state.asset || {});

    useEffect(() => {
        dispatch(getEmployee());

      }, [dispatch]);

    const { employee } = useSelector((state) => state.common);

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            maintenance_type: "",
            maintenance_date: "",
            assigned_Employee: "",
            maintenance_cost: "",
            description: "",
        },
    });

    const maintenanceTypeOptions = [{
        value: 'upgrade',
        label: 'Upgrade',
    }, {
        value: 'repair',
        label: 'Repair',
    },{
        value: 'maintainance',
        label: 'Maintainance',
    }, {
        value: 'lost',
        label: 'Lost',
    },{
        value: 'damaged',
        label: 'Damaged',
    }];

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const onSubmit = async (values) => {
        try {
            if (asset) {
                values.asset_id = asset.id ? asset.id : '';
                await dispatch(AddAssetMaintaince(values));
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (asset) {
            reset({
                maintenance_type: "",
                maintenance_date: "",
                assigned_Employee: "",
                maintenance_cost: "",
                description: "",
            });
        } else {
            reset({
                maintenance_type: "",
            maintenance_date: "",
            assigned_Employee: "",
            maintenance_cost: "",
            description: "",
            });
        }

    }, [asset, reset]);


    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{asset ? 'Asset Maintainance Details' : 'Asset Maintainance Details'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Type :
                                </label>
                                <Controller
                                    name="maintenance_type"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={maintenanceTypeOptions} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={maintenanceTypeOptions?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.maintenance_type ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.maintenance_type && (
                                    <span className="text-danger">
                                        {errors.maintenance_type.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Date of Repair :
                                </label>
                                <Controller
                                    name='maintenance_date'
                                    control={control}
                                    render={({ field }) => (
                                        <div className="cal-icon">
                                            <DatePicker
                                                className={`form-control w-100 ${field.value ? '' : 'error-input'}`}
                                                selected={field.value ? parseISO(field.value) : null}
                                                onChange={(date) => field.onChange(date ? format(date, "yyyy-MM-dd") : "")}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select a date"
                                            />
                                        </div>
                                    )}
                                />
                                {errors.maintenance_date && (
                                    <span className="text-danger">
                                        {errors.maintenance_date.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Employee Assigned :
                                </label>
                                <Controller
                                    name="assigned_Employee"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={employee} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={employee?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.assigned_Employee ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.assigned_Employee && (
                                    <span className="text-danger">
                                        {errors.assigned_Employee.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                Maintenance Cost :
                                </label>
                                <Controller
                                    name="maintenance_cost"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.maintenance_cost ? "error-input" : ""}`}
                                            type="number"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.maintenance_cost && (
                                    <span className="text-danger">
                                        {errors.maintenance_cost.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="input-block mb-3">
                            <label className="col-form-label">
                                    Description :
                                </label>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                        className={`form-control ${errors.description ? "error-input" : ""}`}
                        rows="2" // Set an initial height with rows
                        style={{ resize: "vertical" }} // Allow vertical resizing
                        {...field}
                    />
                                    )}
                                />
                                {errors.description && (
                                    <span className="text-danger">{errors.description.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Save Changes
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AssetMaintenance;
