import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const initialState = {
  allData: null,
  country:null,
  search:'',
  fields: ['name', 'designation'],
  pagination:null,
  loading: false,
  error: null,
  success:null,
  message:null
};

const reimbursementSlice = createSlice({
  name: 'reimbursement',
  initialState,
  reducers: {
    designationRequestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    designationUpdatePagination: (state) => {
      // state.pagination = true;
      // state.error = null;
    },
    designationRequestSuccess: (state, action) => {
      state.allData = action.payload.data;
      state.pagination = action.payload.pagination;
      state.loading = false;
    },
    designationRequestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    designationAdded:(state, action) => {
      state.error = null;
      state.success = action.payload.message;
      state.message = action.payload.message;
      state.loading = false;
    },
    removeErrorMsg:(state)=>{
      state.error = null;
      state.success = null;
      state.message = null;

    },
    setCountry:(state, action)=>{
      state.country = action.payload.data;
       state.loading = false;

    },
  },
});

export const { 
  designationRequestStart, 
  designationRequestSuccess, 
  designationRequestFailure,
  designationAdded ,
  removeErrorMsg,
  setCountry
} = reimbursementSlice.actions;

export default reimbursementSlice.reducer;

export const Getreimbursement = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(designationRequestStart());
  try {
    const response = await axios.get(`${apiUrl}/get-reimbursement?page=${page}&per_page=${per_page}&search=${search}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'code': 'super',
      },
    });
    
    // Extract data and meta from the response
    const { data, meta } = response.data; // Assuming response.data contains both data and meta
    
    if (data) {
      dispatch(designationRequestSuccess({ data, pagination: meta }));
    } else {
      dispatch(designationRequestFailure('No data found'));
    }
  } catch (error) {
    dispatch(designationRequestFailure(error.message || 'Failed to fetch data'));
  }
};
export const GetLastReimbursementId = () => async (dispatch) => {
  dispatch(designationRequestStart());
  try {
    const response = await axios.get(`${apiUrl}/reimbursement-request/last-id`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'code': 'super',
      },
    });

    // Extract the last ID from the response
    const { data } = response.data; // Assuming response.data contains the data
    
    if (data !== null) {
      dispatch(designationRequestSuccess({ data }));
    } else {
      dispatch(designationRequestFailure('No data found'));
    }
  } catch (error) {
    dispatch(designationRequestFailure(error.message || 'Failed to fetch data'));
  }
};

export const viewReimbursement = (id) => async (dispatch) => {
  console.log(id,'ffffff')
  dispatch(designationRequestStart());
  
  // Ensure id is a primitive type (string or number)
  // if (typeof id !== 'string' && typeof id !== 'number') {
  //   return dispatch(designationRequestFailure('Invalid ID type.'));
  // }

  try {
    const response = await axios.get(`${apiUrl}/reimbursement/view/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'code': 'super',
      },
    });

    // Assuming response.data contains the reimbursement details
    const reimbursementData = response.data;

    if (reimbursementData) {
      dispatch(designationRequestSuccess(reimbursementData));
    } else {
      dispatch(designationRequestFailure('No data found'));
    }
  } catch (error) {
    dispatch(designationRequestFailure(error.message || 'Failed to fetch data'));
  }
};

export const deleteReimbursement = (id) => async (dispatch) => {
  console.log(id,'sssssssss')
  dispatch(designationRequestStart());
  try {
    await axios.delete(`${apiUrl}/delete-reimbursement/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'code': 'super',
      },
    });
    dispatch(designationRequestSuccess({ message: 'Reimbursement deleted successfully' }));
    // Optionally, refetch the list after deletion
    dispatch(Getreimbursement());
  } catch (error) {
    dispatch(designationRequestFailure(error.message || 'Failed to delete reimbursement'));
  }
};

export const addReimbursement = (designationData) => async (dispatch) => {
  dispatch(designationRequestStart());

  try {
      const response = await axios.post(`${apiUrl}/add-reimbursement`, designationData, {
          headers: {
              'Content-Type': 'multipart/form-data', // FormData requires this header
              'code': 'super',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
      });

      const { status, message } = response.data;

      if (status) {
          dispatch(designationAdded({ message }));
          return response.data; // Return the response data for use in onSubmit
      } else {
          dispatch(designationRequestFailure(message || 'Failed to add Reimbursement'));
          return { status: false, message }; // Return an error response
      }
  } catch (error) {
      let errorMessage = 'Failed to add Reimbursement';

      if (error.response) {
          if (error.response.data && error.response.data.errors) {
              const validationErrors = error.response.data.errors;
              errorMessage = Object.values(validationErrors).flat().join(', ') || errorMessage;
          } else {
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error.request) {
          errorMessage = 'No response received from the server.';
      } else {
          errorMessage = error.message || errorMessage;
      }

      dispatch(designationRequestFailure(errorMessage));
      return { status: false, message: errorMessage }; // Return error message
  }
};

export const GetreimbursementHR = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(designationRequestStart());
  try {
    const response = await axios.get(`${apiUrl}/get-reimbursement-hr?page=${page}&per_page=${per_page}&search=${search}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'code': 'super',
      },
    });
    
    // Extract data and meta from the response
    const { data, meta } = response.data; // Assuming response.data contains both data and meta
    
    if (data) {
      dispatch(designationRequestSuccess({ data, pagination: meta }));
    } else {
      dispatch(designationRequestFailure('No data found'));
    }
  } catch (error) {
    dispatch(designationRequestFailure(error.message || 'Failed to fetch data'));
  }
};
export const updateFile = (formData) => async (dispatch) => {
  dispatch(designationRequestStart()); // Indicate a request has started

  try {
    const response = await axios.post(`${apiUrl}/reimbursement/file`, formData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data', // Correct header for file uploads
        'code': 'super',
      },
    });

    const { data, message } = response.data;

    if (response.data.status) {
      dispatch(designationAdded({ message, data })); // Update Redux state
      return { status: true, message }; // Return success response
    } else {
      dispatch(designationRequestFailure(message || 'Failed to upload file'));
      return { status: false, message }; // Return error response
    }
  } catch (error) {
    let errorMessage = 'Failed to upload file';

    if (error.response) {
      errorMessage = error.response.data.message || errorMessage;
    } else if (error.request) {
      errorMessage = 'No response received from the server.';
    } else {
      errorMessage = error.message || errorMessage;
    }

    dispatch(designationRequestFailure(errorMessage)); // Dispatch failure action
    return { status: false, message: errorMessage }; // Return error response
  }
};

export const updateStatus = (id, status) => async (dispatch) => {
  dispatch(designationRequestStart()); // Indicating a request has started

  try {
    const response = await axios.patch(`${apiUrl}/reimbursement/status/${id}`, { status }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`, // Ensure proper authentication
        'Content-Type': 'application/json',
        'code': 'super',
      },
    });

    const { data, message } = response.data;

    if (response.data.status) {
      dispatch(designationAdded({ message, data })); // Update Redux state
      return { status: true, message }; // Return success response
    } else {
      dispatch(designationRequestFailure(message || 'Failed to update status'));
      return { status: false, message }; // Return error response
    }
  } catch (error) {
    let errorMessage = 'Failed to update status';

    if (error.response) {
      // Handle server errors
      errorMessage = error.response.data.message || errorMessage;
    } else if (error.request) {
      // Handle network errors
      errorMessage = 'No response received from the server.';
    } else {
      // Handle other errors
      errorMessage = error.message || errorMessage;
    }

    dispatch(designationRequestFailure(errorMessage)); // Dispatch failure action
    return { status: false, message: errorMessage }; // Return error response
  }
};
export const updateSingleDetailStatus = (id) => async (dispatch) => {
 
  dispatch(designationRequestStart()); // Indicating a request has started

  try {
    const response = await axios.patch(`${apiUrl}/reimbursement/status-update-single/${id.id}`, { status:id.newStatus , amount:id.amount , reject:id.rejectReason}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`, // Ensure proper authentication
        'Content-Type': 'application/json',
        'code': 'super',
      },
    });

    const { data, message } = response.data;

    if (response.data.status) {
      dispatch(designationAdded({ message, data })); // Update Redux state
      return { status: true, message }; // Return success response
    } else {
      dispatch(designationRequestFailure(message || 'Failed to update status'));
      return { status: false, message }; // Return error response
    }
  } catch (error) {
    let errorMessage = 'Failed to update status';

    if (error.response) {
      // Handle server errors
      errorMessage = error.response.data.message || errorMessage;
    } else if (error.request) {
      // Handle network errors
      errorMessage = 'No response received from the server.';
    } else {
      // Handle other errors
      errorMessage = error.message || errorMessage;
    }

    dispatch(designationRequestFailure(errorMessage)); // Dispatch failure action
    return { status: false, message: errorMessage }; // Return error response
  }
};

export const StoreDocument = (designationData) => async (dispatch) => {
  dispatch(designationRequestStart());

  try {
      const response = await axios.post(`${apiUrl}/store-document`, designationData, {
          headers: {
              'Content-Type': 'multipart/form-data', // FormData requires this header
              'code': 'super',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
      });

      const { status, message } = response.data;

      if (status) {
          dispatch(designationAdded({ message }));
          return response.data; // Return the response data for use in onSubmit
      } else {
          dispatch(designationRequestFailure(message || 'Failed to add designation'));
          return { status: false, message }; // Return an error response
      }
  } catch (error) {
      let errorMessage = 'Failed to add designation';

      if (error.response) {
          if (error.response.data && error.response.data.errors) {
              const validationErrors = error.response.data.errors;
              errorMessage = Object.values(validationErrors).flat().join(', ') || errorMessage;
          } else {
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error.request) {
          errorMessage = 'No response received from the server.';
      } else {
          errorMessage = error.message || errorMessage;
      }

      dispatch(designationRequestFailure(errorMessage));
      return { status: false, message: errorMessage }; // Return error message
  }
};

export const GetDocumentRequest = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(designationRequestStart());
  try {
    const response = await axios.get(`${apiUrl}/get-document-request?page=${page}&per_page=${per_page}&search=${search}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'code': 'super',
      },
    });
    
    // Extract data and meta from the response
    const { data, meta } = response.data; // Assuming response.data contains both data and meta
    
    if (data) {
      dispatch(designationRequestSuccess({ data, pagination: meta }));
    } else {
      dispatch(designationRequestFailure('No data found'));
    }
  } catch (error) {
    dispatch(designationRequestFailure(error.message || 'Failed to fetch data'));
  }
};

export const GetDocumentRequestHR = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(designationRequestStart());
  try {
    const response = await axios.get(`${apiUrl}/get-document-request-hr?page=${page}&per_page=${per_page}&search=${search}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'code': 'super',
      },
    });
    
    // Extract data and meta from the response
    const { data, meta } = response.data; // Assuming response.data contains both data and meta
    
    if (data) {
      dispatch(designationRequestSuccess({ data, pagination: meta }));
    } else {
      dispatch(designationRequestFailure('No data found'));
    }
  } catch (error) {
    dispatch(designationRequestFailure(error.message || 'Failed to fetch data'));
  }
};



export const updateDocumentRequest = (designationData) => async (dispatch) => {
  dispatch(designationRequestStart());
  
  try {
    const response = await axios.put(`${apiUrl}/update-document-request/${designationData.id}`, designationData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`, // Use Bearer token format
        'code': 'super',
      },
    });

    const { data, status, message } = response.data;
    console.log(data); // Log the received data for debugging

    if (status) {
      dispatch(designationAdded({ message, data })); // Include data if needed
      // dispatch(getAllDesignations({ page: 1, per_page: 10 }));
    } else {
      dispatch(designationRequestFailure(message || 'Failed to update designation'));
    }
  } catch (error) {
    dispatch(designationRequestFailure(error.message || 'Failed to update designation'));
  }
};

export const deleteDocumentRequest = (id) => async (dispatch) => {
  dispatch(designationRequestStart());
  try {
    const response = await axios.delete(`${apiUrl}/delete-document-request/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'code': 'super',
      },
    });

    const { data, status, message } = response.data;
    if (status) {
      dispatch(designationAdded({ message, data }));
    } else {
      dispatch(designationRequestFailure(message || 'Failed to delete designation'));
    }
  } catch (error) {
    dispatch(designationRequestFailure(error.message || 'Failed to delete data'));
  }
};

export const updateDocumentComment = (designationData) => async (dispatch) => {
  dispatch(designationRequestStart());
  
  try {
    const response = await axios.put(`${apiUrl}/update-document-comment/${designationData.id}`, designationData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`, // Use Bearer token format
        'code': 'super',
      },
    });

    const { data, status, message } = response.data;
    console.log(data); // Log the received data for debugging

    if (status) {
      dispatch(designationAdded({ message, data })); // Include data if needed
      // dispatch(getAllDesignations({ page: 1, per_page: 10 }));
    } else {
      dispatch(designationRequestFailure(message || 'Failed to update designation'));
    }
  } catch (error) {
    dispatch(designationRequestFailure(error.message || 'Failed to update designation'));
  }
};

