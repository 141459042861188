import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
  projectTasks: null,
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null,
  projectTaskDetails:null,
  slno:null,
};

const projectTaskSlice = createSlice({
  name: 'projectTask',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    requestSuccess: (state, action) => {
      if(action.payload.type === 'dependents'){
          state.dependentList = action.payload.data;
      }else if(action.payload.type === 'details'){
        state.projectTaskDetails = action.payload.data;
      }else if(action.payload.type === 'updated'){
        state.success = action.payload.message;
      }else{
          state.projectTasks = action.payload.data;
          state.pagination = action.payload.pagination;
      }

      state.loading = false;

    },
    requestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    removeErrorMsg: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;

    },
    setProjectTaskSlNo:(state, action)=>{
        state.slno = action.payload.data;
        state.loading = false;
    }
  },
});

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  removeErrorMsg,
  setProjectCode,
  setProjectTaskSlNo
} = projectTaskSlice.actions;

export default projectTaskSlice.reducer;

export const getDependent = () => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.get(`/get-projectTask-dependent-data`);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, type:'dependents' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};
export const getProjectTasks = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(requestStart());
  try {
    let fields=[];
    if(search)
    {
     fields = ['projects.project_name', 'projects.project_code','users.name', 'project_activities.activity_code','project_activities.activity'];
    }
    const response = await axiosInstance.get(`/project-tasks?page=${page}&per_page=${per_page}&search=${search}&${fields.map(field => `fields[]=${field}`).join('&')}`);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, pagination,type:'projectTasks' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};

export const getProjectTaskSlNo = (id) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/get-slno/`+id);
    const { status, message, data } = response.data;
    if (status) {
      dispatch(setProjectTaskSlNo({ message, data }));
    } else {
      dispatch(requestFailure(message || 'Failed to get slno request'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to get slno';
    dispatch(requestFailure(errorMessage));
  }
};

export const addEditProjectTask = (newRequest) => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.post(`/project-task`, newRequest);
    const { status, message, data } = response.data;
    if (status) {
      dispatch(requestSuccess({ message, data, type:"updated" }));
    } else {
      dispatch(requestFailure(message || 'Failed to add request'));
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(', ');
      dispatch(requestFailure(errorMessage || 'Failed to add request'));
    } else {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to add grade';
      dispatch(requestFailure(errorMessage));
    }
  }
};

export const getProjectTaskDetails = (id) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/project-task/`+id);
    const { data, message,status} = response.data;
    if (status) {
      dispatch(requestSuccess({ data, type:'details' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const deleteProjectTask = (id) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.delete(`/project-task/${id}`);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(requestSuccess({ message, data, type:"updated" }));
      dispatch(getProjectTasks());
    } else {
      dispatch(requestFailure(message || 'Failed to delete user'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to delete data'));
  }
};




