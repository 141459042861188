import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';
import { currentMonthDateRange } from "../helpers/common";

const initialState = {
  myProjects: null,
  dateRange:null,
  projectHaveTask:null,
  projectTask:null,

  allTask:null,
  pagination:null,

  loading: false,
  error: null,
  success: null,
  message: null,
  searchFilter:{
    start_date: currentMonthDateRange().startDate,
    end_date: currentMonthDateRange().endDate,
    status: "all",
    employee_id: "",
  }
};

const timeSheetSlice = createSlice({
  name: 'timeSheet',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    requestSuccess: (state, action) => {        
        state.myProjects = action.payload.data.projects;
        state.dateRange = action.payload.data.dateRange;
        state.projectHaveTask = action.payload.data.doHaveProjectTask;
        state.projectTask = action.payload.data.projects_task;      
        state.loading = false;
      
    },
    requestCompleted: (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = null;
        state.success = action.payload.message;    
      },
    requestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    removeErrorMsg: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;

    },    
    requestSuccessAllTask: (state, action) => {        
      state.allTask = action.payload.data;  
      state.pagination = action.payload.pagination;    
      state.loading = false;
  },
  setFilter: (state, action) => {
    const { key, value } = action.payload; 
    console.log(key, value)
    state.searchFilter[key] = value; 
  },
  resetFilters: (state) => {
    state.searchFilter = {
      startDate: currentMonthDateRange().startDate,
      endDate: currentMonthDateRange().endDate,
      status: "all",
      name: "",
    };
  },
  },
});

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  removeErrorMsg,
  requestCompleted,
  requestSuccessAllTask,
  setFilter,
  resetFilters
} = timeSheetSlice.actions;

export default timeSheetSlice.reducer;



export const getMyTimeSheet = (startDate, endDate) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/my-time-sheet/`+startDate+'/'+endDate);
    const { data, message,status} = response.data;
    if (status) {
      dispatch(requestSuccess({ data}));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const saveTimeSheet = (newRequest) => async (dispatch) => {
    dispatch(requestStart());
    try {  
      const response = await axiosInstance.post(`/store-time-sheet`, newRequest);
      const { status, message } = response.data;
      if (status) {
        dispatch(requestCompleted({ message}));
      } else {
        dispatch(requestFailure(message || 'Failed to add request'));
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const validationErrors = error.response.data.errors;
        const errorMessage = Object.values(validationErrors)
          .flat()
          .join(', ');
        dispatch(requestFailure(errorMessage || 'Failed to add request'));
      } else {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to add grade';
        dispatch(requestFailure(errorMessage));
      }
    }
  };


export const getAllTask = ({ page, per_page, pageType,requestType, projectId }) => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().timeSheet;
  try {
    const response = await axiosInstance.post(`/get-all-task?page=${page}&per_page=${per_page}&pageType=${pageType}&projectId=${projectId}&requestType=${requestType}`, searchFilter);
    const { data, status, message, pagination } = response.data;
    
    if (status) {
      dispatch(requestSuccessAllTask({ data, pagination }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const updateTaskStatus = (updateData) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.post(`/update-task-status`, updateData);
    const { data, status, message, pagination } = response.data;
    
    if (status) {
      dispatch(requestCompleted({message }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const exportTask = ({ page, per_page,pageType, projectId }) => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().timeSheet;
  
  try {
    const response = await axiosInstance.get(`/export-all-task`, {
      params: { page, per_page, search:searchFilter, pageType, projectId }, // Pass search data as query params
      responseType: 'blob', // This is crucial for file download
    });

    // Create a link to download the file
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'users.xlsx'); // Set the file name
    document.body.appendChild(link);
    link.click();
    link.remove(); // Clean up
    dispatch(requestCompleted());

  

  } catch (error) {
    // Handle errors here
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(' ');
      dispatch(requestFailure(errorMessage || 'Failed to export user'));
    } else {
      dispatch(requestFailure(error.message || 'Failed to export user'));
    }
  }
};

export const deleteTask = (ids) => async (dispatch) => {
  dispatch(requestStart());
  
  try {
    const response = await axiosInstance.post(`/delete-task`, {id:ids});
    const {status, message } = response.data;
    if (status) {
      dispatch(requestCompleted());
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};





