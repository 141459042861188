import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addProjectBusinessArea, removeErrorMsg, updateprojectBusinessArea } from "../../../../../redux/projectBusinessAreaSlice";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alert from "../../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const validationSchema = Yup.object().shape({
    code: Yup.string().required("Code is required"),
    name: Yup.string().required("Business Area Name is required"),
});

function AddEditprojectBusinessArea({ show, handleClose, projectBusinessArea }) {
    //console.log(projectBusinessArea,'projectBusinessArea')
    const { loading, error, success } = useSelector((state) => state.projectBusinessArea);
    const dispatch = useDispatch();
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            id:"",
            code: "",
            name: "",
        },
    });

    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    // Handle form submission
    const onSubmit = async (values) => {
        try {
            if (projectBusinessArea) {
                // If editing an existing projectBusinessArea, add the projectBusinessArea ID to the values
                values.id = projectBusinessArea.id;
                await dispatch(updateprojectBusinessArea(values)); // Dispatch update
            } else {
                // If adding a new projectBusinessArea, dispatch addprojectBusinessArea
                await dispatch(addProjectBusinessArea(values));
            }
        } catch (error) {
            console.error(error);
        }
    };

     // Reset the form based on projectBusinessArea data
     useEffect(() => {
        if (projectBusinessArea) {
            reset({
                code: projectBusinessArea.code || "",
                name: projectBusinessArea.name || "",
            });
        } else {
            reset({
                code: "",
                name: "",
            });
        }
    }, [projectBusinessArea, reset]);

    // Handle modal close
    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{projectBusinessArea ? 'Edit Project Business Area' : 'Add Project Business Area'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-block mb-3">
                    <Controller
                            name="id"
                            control={control}
                            render={({ field }) => (
                                <input name="id"
                                value={projectBusinessArea?.id}
                                    className={`form-control ${errors.name ? "error-input" : ""}`}
                                    type="hidden"
                                    {...field}
                                />
                            )}
                        />


                        <label className="col-form-label">
                            Code <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="code"
                            control={control}
                            render={({ field }) => (
                                <input name="code"
                                    className={`form-control ${errors.code ? "error-input" : ""}`}
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        {errors.code && <span className="text-danger">{errors.code.message}</span>}
                    </div>
                    <div className="input-block mb-3">
                        <label className="col-form-label">
                            Name <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${errors.name ? "error-input" : ""}`}
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        {errors.code && <span className="text-danger">{errors.name.message}</span>}
                    </div>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Save Changes
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );

}

export default AddEditprojectBusinessArea;
