import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { useFormContext } from 'react-hook-form';
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { getAll, deleteData, deleteDataQuestion } from '../../../../../redux/pemQuestionSlice';
import { updateCategoriesDispatch } from '../../../../../redux/pemTemplateSlice';
// Get register from form context

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';



const CustomFormComponent = () => {
    const { register } = useFormContext();
    const dispatch = useDispatch();
    const [openModel, setOpenModel] = useState(false)
    const [dataDetails, setdataDetails] = useState(null);
    const [dataType, setdataType] = useState(null);
    const [categories, setCategories] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const { allData, loading, pagination, success, search } = useSelector((state) => state.pemquestion);
    const { data } = useSelector((state) => state.pemtemplates);
    const [paginationState, setPaginationState] = useState({
        current: 1,
        pageSize: 10,
    });
    useEffect(() => {
        dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
    }, [dispatch, paginationState.current, paginationState.pageSize]);
    //console.log(allData, '0099')




    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };




    const columns = [
        {
            title: "",
            dataIndex: "id",
            key: "id",
            width: '150px',
            className: 'text-start-td',
            render: (text, record) => {
                if (record.rowType === 'category') {
                    return {
                        children: <span className="font-weight-bold text-left">{record.category_name} [{record.category_code}]</span>,
                        props: {
                            colSpan: columns.length - 2, // Span across all columns
                        },
                    };
                }
                return {
                    children: <span className="font-weight-bold">{record.pem_question_code} </span>,

                };
            },
        },
        {
            title: "Parameter",
            dataIndex: "parameter",
            key: "parameter",
            width: '150px',
            render: (text, record) => (record.rowType === 'category' ? { props: { colSpan: 0 } } : text),
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            width: '300px',
            className: 'text-wrap',
            render: (text, record) => (record.rowType === 'category' ? { props: { colSpan: 0 } } : text),
        },

        {
            title: "Weightage",
            key: "action",
            width: '50px',
            className: 'text-end-td',
            render: (text, record) => {
                if (record.rowType === 'category') {
                    return {
                        children: <div style={{
                            position: 'absolute',
                            left: '62px',
                            top: '9px'
                        }}
                            key={record.id}
                            data-check={record.isChecked}
                        >



                            <input
                                {...register(`category_weightage[${record.id}]`)}
                                type="number"
                                className="category-weightage-input"
                                value={record.weightage || ''}
                                disabled={!record.isCheckeds}  // Disable if false
                                readOnly={record.readonly}
                                onChange={(e) => {
                                    handleCategoryWeightageChange(record.id, e.target.value);

                                }}


                                style={{ width: '50px' }}
                            />
                            {/* <button className="btn btn-edit" onClick={() => addModal(record, 'category')}>
                                <i className="fa fa-edit" />
                            </button>
                            <button className="btn btn-delete" onClick={() => deleteModal(record, 'category')}>
                                <i className="fa fa-trash" />
                            </button> */}
                        </div>,

                    };
                }
                return {
                    children: <div

                        key={record.id}


                    >


                        <input
                            {...register(`question_weightage[${record.id}]`)}
                            type="number"
                            className="question-weightage-input"
                            value={record.weightages || ''}
                            disabled={!record.isChecked}
                            readOnly={record.readonly}
                            onChange={(e) => {
                                handleQuestionWeightageChange(record.pem_question_category_id, record.id, e.target.value);

                            }}
                            style={{ width: '50px' }}
                        />


                        {/* <button className="btn btn-edit" onClick={() => addModal(record, 'question')}>
                            <i className="fa fa-edit" />
                        </button>
                        <button className="btn btn-delete" onClick={() => deleteModal(record, 'question')}>
                            <i className="fa fa-trash" />
                        </button> */}
                    </div>,

                };
            },

        },

        {
            title: "Target",
            key: "action",
            width: '50px',
            className: 'text-end-td',
            render: (text, record) => {
                if (record.rowType === 'category') {
                    return {
                        children: <div

                            style={{
                                position: 'absolute',
                                top: '14px',
                                left: '35px'
                            }}


                        >



                            <input
                                {...register(`category_ids[${record.id}]`)}
                                type="checkbox"
                                className="question-category_ids-input"
                                checked={record.isCheckeds || false}
                                onChange={(e) => {
                                    handleCategoryCheck(record.id, e.target.checked);

                                }}
                                style={{ width: '20px', height: '20px' }}  // Adjust size if needed
                            />

                            {/* <button className="btn btn-edit" onClick={() => addModal(record, 'category')}>
                                <i className="fa fa-edit" />
                            </button>
                            <button className="btn btn-delete" onClick={() => deleteModal(record, 'category')}>
                                <i className="fa fa-trash" />
                            </button> */}
                        </div>,

                    };
                }
                return {
                    children: <div>

                        <input
                            {...register(`question_ids[${record.id}]`)}
                            type="checkbox"
                            checked={record.isChecked || false}
                            onChange={(e) => {
                                handleQuestionCheck(record.pem_question_category_id, record.id, e.target.checked);

                            }}
                            className="question-category_ids-input"
                            style={{ width: '20px', height: '20px' }}  // Adjust size if needed
                        />
                        {/* <button className="btn btn-edit" onClick={() => addModal(record, 'question')}>
                            <i className="fa fa-edit" />
                        </button>
                        <button className="btn btn-delete" onClick={() => deleteModal(record, 'question')}>
                            <i className="fa fa-trash" />
                        </button> */}
                    </div>,

                };
            },

        },


    ];







    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded
            ? [...expandedRowKeys, record.key]
            : expandedRowKeys.filter((key) => key !== record.key);
        setExpandedRowKeys(newExpandedRowKeys);
    };

    const expandable = {
        expandedRowRender: (record) => {
            // Nested rc-table for the expanded row
            return (
                <div>
                    {/* <h4>Nested Table for {record.key}</h4> */}
                    <Table
                        columns={columns}
                        dataSource={record.nestedData}
                        pagination={false}
                        showHeader={false}
                        // Hide header in nested table
                        size="small"
                    />
                </div>
            );
        },
        expandedRowKeys: expandedRowKeys,
        onExpand: handleExpand,
    };

    // useEffect(() => {
    //     // console.log('Het');
    //     if (success) {
    //         showAlert({
    //             title: 'PEM Question',
    //             text: success,
    //             confirmButtonColor: '#FF902F',
    //             confirmButtonText: 'OK',
    //         });
    //         dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
    //         handleClose();
    //         handleDeleteClose();
    //     }
    // }, [success, showAlert]);

    useEffect(() => {
        if (allData) {
            setCategories(allData.map((data) => ({ ...data, weightage: data.weightage || 0 })));


            setExpandedRowKeys(allData.map((item, index) => index + 1));






        }
    }, [allData]);

    useEffect(() => {


        if (data?.all_questions && categories) {

            let updatedcates = categories;
            let cate2 = data.all_questions;

            const updatedCategories = categories.map((category) => {
                const matchingItem2 = cate2.find(item2 => item2.id === category.id);
                if (matchingItem2) {

                    const updatedQuestions = category.my_question.map((question) => {
                        const matchingQuestion2 = matchingItem2.teams_question.find(
                            question2 => question2.pem_question_id === question.id
                        );
                        if (matchingQuestion2) {
                            const numericValue = parseFloat(matchingQuestion2.weightage);
                            return { ...question, readonly: true, weightages: numericValue, isChecked: true };
                        }
                        return question;
                    });
                    return {
                        ...category,
                        isCheckeds: matchingItem2?.teams_question.length > 0 ? true : false,
                        readonly: true,
                        weightage: matchingItem2?.teams_question.length > 0 ? matchingItem2?.teams_question[0].category_weightage : '', my_question: updatedQuestions
                    };
                }
                return category;
            });
            // updatedcates.forEach(item1 => {
            //     // Find the corresponding item in cate2 with the same id
            //     const matchingItem2 = cate2.find(item2 => item2.id === item1.id);

            //     if (matchingItem2) {
            //         // Update weitage of the main item if match is found

            //         if (matchingItem2?.teams_question?.length > 0)
            //             item1.weightage = 100;

            //         // Update weitage of nested question array
            //         item1.my_question.forEach(question1 => {
            //             const matchingQuestion2 = matchingItem2.teams_question.find(
            //                 question2 => question2.pem_question_id === question1.id
            //             );

            //             if (matchingQuestion2) {
            //                 const numericValue = parseFloat(matchingQuestion2.weightage);
            //                 question1.weightages = numericValue;

            //             }
            //         });

            //     }
            // });

            setCategories(updatedCategories);
            // setCategories(allData.map((data) => ({ ...data, weightage: data.weightage || 0 })));
        }


    }, [data, categories]);

    useEffect(() => {
        dispatch(updateCategoriesDispatch({ categories }));
        console.log('final')
        console.log(categories)
    }, [categories]);

    const onSearch = (search) => {
        dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
        console.log(search, 'pp');
    }



    const handleCategoryWeightageChange = (categoryId, value) => {
        const numericValue = parseFloat(value);
        if (numericValue < 0) {
            MySwal.fire({
                title: 'Invalid Input',
                text: 'Weightage cannot be negative.',
                icon: 'error',
            });
            return;
        }


        const updatedCategories = categories.map((category) => {
            if (category.id === categoryId) {
                return { ...category, weightage: parseFloat(value) || 0 };
            }
            return category;
        });

        // Calculate the latest total weightage excluding the current category
        const otherCategoriesTotal = updatedCategories
            .filter((category) => category.id !== categoryId)
            .reduce((total, category) => total + (category.weightage || 0), 0);

        const maxAllowedWeightage = 100 - otherCategoriesTotal;
        const newWeightage = Math.min(parseFloat(value) || 0, maxAllowedWeightage);

        // Update the specific category's weightage if it exceeds the maximum allowed
        const finalCategories = updatedCategories.map((category) => {
            if (category.id === categoryId) {
                return { ...category, weightage: newWeightage };
            }
            return category;
        });

        setCategories(finalCategories);

        if (parseFloat(value) > maxAllowedWeightage) {
            MySwal.fire({
                title: 'Error',
                text: `The maximum allowable weightage for this category is ${maxAllowedWeightage}%. Adjusted automatically.`,
                icon: 'warning',
            });
        }
    };


    const handleCategoryCheck = (categoryId, isChecked) => {
        //alert(isChecked)
        const updatedCategories = categories.map((category) => {
            if (category.id === categoryId) {
                const updatedQuestions = category.my_question.map((question) => {

                    return { ...question, isChecked: isChecked ? true : false };

                    return question;
                });
                return { ...category, isCheckeds: isChecked ? true : false, my_question: updatedQuestions };
            }
            return category;
        });
        console.log(updatedCategories);
        setCategories(updatedCategories);



    }

    const handleQuestionCheck = (categoryId, questionId, isChecked) => {
        //alert(isChecked)
        const updatedCategories = categories.map((category) => {
            if (category.id === categoryId) {
                const updatedQuestions = category.my_question.map((question) => {


                    if (question.id === questionId) {
                        return { ...question, isChecked: isChecked ? true : false };

                    }

                    return question;
                });
                return { ...category, isCheckeds: isChecked ? true : true, my_question: updatedQuestions };
            }
            return category;
        });

        setCategories(updatedCategories);



    }




    const handleQuestionWeightageChange = (cateid, questionId, value) => {


        const numericValue = parseFloat(value);
        if (numericValue < 0) {
            MySwal.fire({
                title: 'Invalid Input',
                text: 'Weightage cannot be negative.',
                icon: 'error',
            });
            return;
        }

        // Update the question weightage within its respective category
        const updatedCategories = categories.map((category) => {
            if (category.id === cateid) {
                const updatedQuestions = category.my_question.map((question) => {
                    if (question.id === questionId) {
                        return { ...question, weightages: numericValue };
                    }
                    return question;
                });
                return { ...category, my_question: updatedQuestions };
            }
            return category;
        });

        setCategories(updatedCategories);

        // Calculate the total weightage across all questions after the change
        // const totalWeightage = updatedCategories
        //     .reduce((total, category) => total + category.my_question.reduce((subTotal, question) => subTotal + (question.weightages || 0), 0), 0);


        const totalWeightage = updatedCategories.reduce((total, category) => {
            // Only sum weightages if the category ID matches cateid
            if (category.id === cateid) {
                return total + category.my_question.reduce(
                    (subTotal, question) => subTotal + (question.weightages || 0),
                    0
                );
            }
            return total; // If the ID doesn't match, just return the current total
        }, 0);
        // Calculate the remaining weightage for the other questions
        const remainingWeightage = 100 - totalWeightage + numericValue; // Add the updated value back to adjust the remaining weightage

        if (totalWeightage > 100) {
            // If total weightage exceeds 100, show error message and adjust accordingly
            MySwal.fire({
                title: 'Error',
                text: `The total weightage of all questions cannot exceed 100%. The weightage for this question was adjusted automatically to fit within the limit.`,
                icon: 'warning',
            });

            // Adjust the weightage of the current question to fit within the remaining limit
            const adjustedValue = Math.min(numericValue, remainingWeightage);
            const adjustedCategories = categories.map((category) => {
                if (category.id === cateid) {
                    const updatedQuestions = category.my_question.map((question) => {
                        if (question.id === questionId) {
                            return { ...question, weightages: adjustedValue };
                        }
                        return question;
                    });
                    return { ...category, my_question: updatedQuestions };
                }
                return category;
            });

            setCategories(adjustedCategories);
        }
    };




    const validateCategoryWeightage = (totalWeightage) => {
        if (totalWeightage > 100) {
            MySwal.fire({
                title: 'Error',
                text: "The total weightage of all categories cannot exceed 100%.",
                icon: 'error',
            });
            return false;
        }
        return true;
    };



    const userElements = categories?.map((category, index) => ({
        key: index,
        id: category.id,
        rowType: 'category',
        category_name: category.category_name,
        category_code: category.category_code,
        weightage: category.weightage,
        isCheckeds: category.isCheckeds,
        readonly: category.readonly || false,

        nestedData: category.my_question?.map((question, questionIndex) => ({
            ...question,
            index: questionIndex,
        }))
    }));

    return (
        <>

            <table className="table">
                <thead>
                    <tr>
                        <th className="table-icon"></th>
                        <th>Parameter</th>
                        <th>Description</th>
                        <th>Weightage</th>
                        <th>Target</th>
                    </tr>
                </thead>
                <tbody>
                    {userElements?.length > 0 &&
                        userElements.map((eachCategory, key) => (
                            <>


                                <tr className="table-category table-active">
                                    <td colSpan="3">{eachCategory.category_name}[{eachCategory.category_code}]</td>
                                    <td><input readOnly={eachCategory.readonly} disabled={!eachCategory.isCheckeds} name="category_weightage[1]" type="number" className="category-weightage-input" value={eachCategory.weightage || ''} style={{ width: "50px" }}


                                        onChange={(e) => {
                                            handleCategoryWeightageChange(eachCategory.id, e.target.value);

                                        }}
                                    /></td>
                                    <td><input

                                        onChange={(e) => {
                                            handleCategoryCheck(eachCategory.id, e.target.checked);

                                        }}

                                        checked={eachCategory.isCheckeds || false}
                                        name={`category_ids[${eachCategory?.id}[]`}

                                        type="checkbox" className="question-category_ids-input" style={{ width: "20px", height: "20px" }} /></td>
                                </tr>





                                {eachCategory?.nestedData?.length > 0 &&
                                    eachCategory?.nestedData.map((eachQuestion, keys) => (
                                        <>
                                            <tr>
                                                <td>{eachQuestion?.pem_question_code}</td>
                                                <td>{eachQuestion?.parameter}</td>
                                                <td>{eachQuestion?.description}</td>
                                                <td><input name="question_weightage[1]"
                                                    type="number"
                                                    onChange={(e) => {
                                                        handleQuestionWeightageChange(eachQuestion.pem_question_category_id, eachQuestion.id, e.target.value);

                                                    }}
                                                    class="question-weightage-input"
                                                    value={eachQuestion?.weightages || ''}

                                                    disabled={!eachQuestion.isChecked}
                                                    readOnly={eachQuestion.readonly}
                                                    style={{ width: "50px" }} /></td>
                                                <td><input name="question_ids[1]"
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        handleQuestionCheck(eachQuestion.pem_question_category_id, eachQuestion.id, e.target.checked);

                                                    }}
                                                    checked={eachQuestion.isChecked || false}
                                                    class="question-category_ids-input"
                                                    style={{ width: "20px", height: "20px" }} /></td>
                                            </tr>

                                        </>
                                    ))}




                            </>
                        ))}


                </tbody>
            </table>

            {/* <div className="table-responsive">
                <Table
                    columns={columns}

                    dataSource={
                        userElements?.length > 0
                            ? userElements?.map((designation) => ({
                                ...designation,
                                key: designation.id,
                            }))
                            : []
                    }
                    expandable={expandable}
                    rowClassName={(record) =>
                        record.rowType === 'category' ? 'highlight-row' : ''
                    }
                    pagination={false}
                    // Hide header in nested table
                    // pagination={{
                    //     current: paginationState.current,
                    //     pageSize: paginationState.pageSize,
                    //     total: pagination?.total,
                    //     onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                    // }}
                    loading={loading}
                />
            </div>
             */}









        </>
    );
};

export default CustomFormComponent;