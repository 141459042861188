// src/axiosInstance.js
import axios from 'axios';
const baseURL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://127.0.0.1:8000/api";


const axiosInstance = axios.create({
    baseURL: baseURL, // Replace with your API base URL
    headers: { 'Content-Type': 'application/json', 'content-type': 'multipart/form-data' }

});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        // Do something before the request is sent
        config.headers.code = `super`;//super
        const token = localStorage.getItem('token'); // Retrieve auth token from localStorage
        const code = localStorage.getItem('code'); // Retrieve auth token from localStorage
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        if (code) {
            config.headers.code = `${code}`;
        }
        else {
            config.headers.code = `super`;

        }

        if (config.data && config.data instanceof FormData) {

            config.headers['Content-Type'] = 'multipart/form-data';
        }
        return config;
    },
    function (error) {
        // Handle the error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    function (response) {
        // Do something with the response data

        return response;
    },
    function (error) {
        // Handle the response error
        if (error.response && error.response.status === 401) {
            // Handle unauthorized error
            console.error('Unauthorized, logging out...');
            // Perform any logout actions or redirect to login page
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;

