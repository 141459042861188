import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
  allData: null,
  search: '',
  fields: ['name', 'designation'],
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null
};

const leaveTypeSlice = createSlice({
  name: 'holiday',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    requestSuccess: (state, action) => {
      state.allData = action.payload.data;
      state.pagination = action.payload.pagination;
      state.loading = false;
    },
    requestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    added: (state, action) => {
      state.error = null;
      state.success = action.payload.message;
      state.message = action.payload.message;
      state.loading = false;
    },
    removeErrorMsg: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;

    },
  },
});

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  added,
  removeErrorMsg
} = leaveTypeSlice.actions;

export default leaveTypeSlice.reducer;

export const getAll = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.get(`/leave-type?page=${page}&per_page=${per_page}&search=${search}&fields[]=name`);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, pagination }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};

export const add = (holidayData) => async (dispatch) => {
  dispatch(requestStart());
  try {

    console.log(holidayData);

    const response = await axiosInstance.post(`/leave-type`, holidayData);
    const { status, message } = response.data;
    if (status) {
      dispatch(added({ message }));
    } else {
      dispatch(requestFailure(message || 'Failed to add designation'));
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors; // Get the validation errors
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(', ');

      dispatch(requestFailure(errorMessage || 'Failed to add designation'));
    } else {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to add grade';
      dispatch(requestFailure(errorMessage));
    }
  }
};

export const update = (holidayData, id) => async (dispatch) => {
  dispatch(requestStart());

  try {
    const response = await axiosInstance.post(`/leave-type/${id}`, holidayData);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(added({ message, data }));
    } else {
      dispatch(requestFailure(message || 'Failed to update designation'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to update grade';
    dispatch(requestFailure(errorMessage));
  }
};

export const deleteLeaveType = (id) => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.delete(`/leave-type/${id}}`);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(added({ message, data }));
    } else {
      dispatch(requestFailure(message || 'Failed to delete grade'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to delete data';
    dispatch(requestFailure(errorMessage));
  }
};



