import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addEditSoftware, removeErrorMsg } from "../../../../redux/subscriptionSlice";
import { useDispatch, useSelector } from 'react-redux';
import Alert from "../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { customStyles } from "../../../../helpers/Constants";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { format, parseISO } from 'date-fns';
import { getEmployee, getTeams,getSoftwares } from '../../../../redux/commonSlice';


const validationSchema = Yup.object().shape({
    software_name: Yup.string().required("Software Name is required"),
    team: Yup.string().required("Team is required"),
    owner: Yup.string().required("Owner is required"),
    billing_type: Yup.string().required("Billing Type Name is required"),
    cost: Yup.string().required("Cost is required"),
    currency: Yup.string().required("Currency is required"),
    end_date: Yup.string().required("End date is required"),
    start_date: Yup.string().required("Start date is required"),
});

function AddEditSoftware({ show, handleClose, software }) {
    const {
        loading = false,
        error = "",
    } = useSelector((state) => state.subscription || {});
    const dispatch = useDispatch();

    const { employee, teams } = useSelector((state) => state.common);

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            software_name: "",
            team: "",
            owner: "",
            billing_type: "",
            cost: "",
            currency: "",
            start_date: "",
            end_date: "",
        },
    });

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const onSubmit = async (values) => {
        try {
            if (software) {
                values.software_id = software.id;
                console.log(values);
                await dispatch(addEditSoftware(values));
            } else {
                await dispatch(addEditSoftware(values));
            }
            dispatch(getSoftwares());
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        if (software) {
            reset({
                software_name: software?.software_name || "",
                team: software?.team_id || "",
                owner: software?.owner_id || "",
                billing_type: software?.billing_type || "",
                cost: software?.cost || "",
                currency: software?.currency || "",
                start_date: software?.start_date || "",
                end_date: software?.end_date || "",
            });
        } else {
            reset({
                software_name: "",
                team: "",
                owner: "",
                billing_type: "",
                cost: "",
                currency: "",
                start_date: "",
                end_date: "",
            });
        }

    }, [software, reset]);

    useEffect(() => {
        dispatch(getEmployee());
        dispatch(getTeams());
    }, [dispatch]);

    const teamOptions = teams?.map(g => ({
        value: g.id,
        label: g.team + " " +g.description,
    }));

    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    const billingTypeOptions = [
        {
            value: 'Yearly',
            label: 'Yearly',
        }, {
            value: 'Halfyearly',
            label: 'Halfyearly',
        },
        {
            value: 'Quarterly',
            label: 'Quarterly',
        }, {
            value: 'Monthly',
            label: 'Monthly',
        },
        {
            value: 'Usage Basis',
            label: 'Usage Basis',
        }];

    const currencyOptions = [
        {
            value: 'INR',
            label: 'INR',
        }, {
            value: 'USD',
            label: 'USD',
        }
    ];

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{software ? 'Edit Software' : 'Add Software'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>

                    {/* team and software name */}

                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Departement:
                                </label>
                                <Controller
                                    name="team"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={teamOptions} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={teamOptions?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.team ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.team && (
                                    <span className="text-danger">
                                        {errors.team.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Software Name <span className="text-danger">*</span>
                                </label>
                                <Controller
                                    name="software_name"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.software_name ? "error-input" : ""}`}
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.software_name && <span className="text-danger">{errors.software_name.message}</span>}
                            </div>
                        </div>
                    </div>

                    {/* owner and billing type */}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Owner :
                                </label>
                                <Controller
                                    name="owner"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={employee} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={employee?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.owner ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.owner && (
                                    <span className="text-danger">
                                        {errors.owner.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Billing Type:
                                </label>

                                <Controller
                                    name="billing_type"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={billingTypeOptions} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={billingTypeOptions?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.billing_type ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.billing_type && (
                                    <span className="text-danger">
                                        {errors.billing_type.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* cost and currency */}
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Cost <span className="text-danger">*</span>
                                </label>
                                <Controller
                                    name="cost"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.cost ? "error-input" : ""}`}
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.cost && <span className="text-danger">{errors.cost.message}</span>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Currency:
                                </label>
                                <Controller
                                    name="currency"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={currencyOptions} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={currencyOptions?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.currency ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.currency && (
                                    <span className="text-danger">
                                        {errors.currency.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* start date end date  */}
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Start Date
                                </label>
                                <div className="cal-icon">

                                    <Controller
                                        name={`start_date`}
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                isClearable
                                                className={`form-control w-100 ${field.value ? '' : 'error-input'}`}
                                                selected={field.value ? parseISO(field.value) : null}
                                                onChange={(date) => field.onChange(date ? format(date, "yyyy-MM-dd") : "")}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select a date"
                                                // readOnly={hasId && hasId > 0 ? true : false}
                                            />
                                        )}
                                    />
                                    {errors.start_date && (
                                        <span className="text-danger">{errors.start_date.message}</span>
                                    )}


                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    End Date
                                </label>
                                <div className="cal-icon">
                                    <Controller
                                        name={`end_date`}
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                isClearable
                                                className={`form-control w-100 ${field.value ? '' : 'error-input'}`}
                                                selected={field.value ? parseISO(field.value) : null}
                                                onChange={(date) => field.onChange(date ? format(date, "yyyy-MM-dd") : "")}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select a date"
                                                // readOnly={hasId && hasId > 0 ? true : false}
                                            />
                                        )}
                                    />
                                    {errors.end_date && (
                                        <span className="text-danger">{errors.end_date.message}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Save Changes
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AddEditSoftware;
