import { useSelector } from 'react-redux';

export const SidebarData = () => {
  const { is_super_admin } = useSelector((state) => state.auth);

  return [
    {
      tittle: 'Dashboard',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: 'Dashboard',
          moduleValue: 'Dashboard',
          hasSubRoute: false,
          showSubRoute: false,
          route: "/admin-dashboard",
          icon: "la la-dashcube",
        },
      ],
    },
    {
      tittle: 'EMPLOYEE',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: 'Employee',
          moduleValue: 'Employee',
          hasSubRoute: true,
          showSubRoute: true,
          route: "#",
          icon: "la la-user",
          subMenus: [
            {
              menuValue: 'All Employee',
              moduleValue: 'All Employee',
              route: "/all-employees",
            },
            {
              menuValue: 'Attendance',
              moduleValue: 'Attendance',
              route: "/my-attendance",
            },
            {
              menuValue: 'Attendance Request',
              moduleValue: 'Attendance Request',
              route: "/employee-attendance-request",
            },
            {
              menuValue: 'Leave/WFH',
              moduleValue: 'Leave/WFH',
              route: "/leave-wfh",
            },
            {
              menuValue: 'Leave/WFH Request',
              moduleValue: 'Leave/WFH Request',
              route: "/leave-wfh-request",
            },
            {
              menuValue: 'Additional Employee Access',
              moduleValue: 'Additional Employee Access',
              route: "addtional-employee-request",
            },
            // {
            //   menuValue: 'All Employee List',
            //   moduleValue: 'All Employee List',
            //   route: "all-employee-list",
            // },
          ],
        },
      ],
    },

    {
      tittle: 'PEM',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: 'PEM',
          moduleValue: 'PEM',
          hasSubRoute: true,
          showSubRoute: false,
          route: "#",
          icon: "la la-chart-bar",
          subMenus: [
            {
              menuValue: "My Goals/Prefs",
              moduleValue: "My Goals/Prefs",
              route: "/pem-questions",
            },
            {
              menuValue: "All Templates",
              moduleValue: "All Templates",
              route: "/templates",
            },
            {
              menuValue: "All PEM",
              moduleValue: "All PEM",
              route: "/all-pem",

            },
            {
              menuValue: "My Goals",
              moduleValue: "My Goals",
              route: "/pem/mygoals",
              excludeRoleAdmin: is_super_admin || false
            },
            {
              menuValue: "PEM Request",
              moduleValue: "PEM Request",
              route: "/pem/request",
              excludeRoleAdmin: is_super_admin
            },
            {
              menuValue: "Employee Request",
              moduleValue: "Employee Request",
              route: "/pem/employeerequest",
              excludeRoleAdmin: is_super_admin
            },
            {
              menuValue: "Employee Rating",
              moduleValue: "Employee Rating",
              route: "/pem-rating",
              excludeRoleAdmin: is_super_admin
            },
          ],
        },
      ],
    },



    {
      tittle: 'Subscriptions',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: "Subscription",
          hasSubRoute: true,
          moduleValue: "Subscription",
          showSubRoute: false,
          icon: "las la-calendar-check",
          subMenus: [
            {
              menuValue: "All Subscriptions",
              moduleValue: "All Subscriptions",
              route: "/subscriptions/all-subscriptions"

            },
          ]
        },
      ],
    },
    {
      tittle: 'Assets',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: "Assets",
          moduleValue: "Assets",
          hasSubRoute: true,
          showSubRoute: false,
          icon: "la la-laptop",
          subMenus: [
            {
              menuValue: "Asset Categories",
              moduleValue: "Asset Categories",
              route: "/admin/assetcategories",
            },
            {
              menuValue: "All Assets",
              moduleValue: "All Assets",
              route: "/admin/assets",
            },
            {
              menuValue: "Asset Stats",
              moduleValue: "Asset Stats",
              route: "/admin/asset-stats",
            },
          ],
        },
      ],
    },
    {
      tittle: 'Request',
      showAsTab: false,
      excludeRoleAdmin: is_super_admin,
      separateRoute: false,
      menu: [
        {
          menuValue: "Request",
          moduleValue: "Request",
          hasSubRoute: true,
          showSubRoute: false,
          icon: "las la-comments",

          subMenus: [
            {
              menuValue: "Reimbursement",
              moduleValue: "Reimbursement",
              route: "/reimbursement",
            },
            {
              menuValue: "Document",
              moduleValue: "Document",
              route: "/documents",
            },
            {
              menuValue: "Request Asset",
              moduleValue: "Request Asset",
              route: "/asset-request",
            },
            {
              menuValue: "Reimbursement HR",
              moduleValue: "Reimbursement HR",
              route: "/reimbursement/hr",
            },
            {
              menuValue: "Document HR",
              moduleValue: "Document HR",
              route: "/document/hr",
            },
          ],
        },
      ],
    },
    {
      tittle: 'Contractor',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: "Contractor",
          moduleValue: "Contractor",
          hasSubRoute: true,
          showSubRoute: false,
          icon: "las la-file-signature",
          subMenus: [
            {
              menuValue: "Contractor Salary",
              moduleValue: "Contractor Salary",
              route: "/contract-employee-salary",
            },
            {
                menuValue: "Contractor Employee",
                moduleValue: "Contractor Employee",
                route: "/contract-employee",
            },
          ],
        },
      ],
    },
    {
      tittle: 'Project',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: "Project",
          moduleValue: "Project",
          hasSubRoute: true,
          showSubRoute: false,
          icon: "las la-tasks",
          subMenus: [
            {
              menuValue: "All Projects",
              moduleValue: "All Projects",
              route: "/project/list",
            },
            {
              menuValue: "Project Task",
              moduleValue: "Project Task",
              route: "/project/tasks",
            },
            {
              menuValue: "My Projects",
              moduleValue: "My Projects",
              route: "/project/my-projects",
            },
          ],
        },
      ],
    },
    {
      tittle: 'Master',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: "Master",
          moduleValue: "Master",
          hasSubRoute: true,
          showSubRoute: false,
          icon: "la la-user-plus",
          subMenus: [
            {
              menuValue: "Designation",
              moduleValue: "Designation",
              route: "/master/designation",
            },
            {
              menuValue: "Team",
              moduleValue: "Team",
              route: "/master/team",
            },
            {
              menuValue: "Grade",
              moduleValue: "Grade",
              route: "/master/grade",
            },
            {
              menuValue: "Holiday",
              moduleValue: "Holiday",
              route: "/master/holiday",
            },
            {
              menuValue: "Leave",
              moduleValue: "Leave",
              route: "/master/leave",
            },
            {
              menuValue: "Country Diem",
              moduleValue: "Country Diem",

              route: "/master/diem",
            },
            {
              menuValue: "Customer",
              moduleValue: "Customer",
              route: "/master/customer"
            },

            {
              menuValue: "Technology",
              moduleValue: "Project Technology",
              route: "/master/project_technology"
            },

            {
              menuValue: "Business Area",
              moduleValue: "Project Business Area",
              route: "/master/projectBusinessArea"
            },

            {
              menuValue: "Billability",
              moduleValue: "Project Billability",
              route: "/master/projectBillability"
            },

            {
              menuValue: "Payment Type",
              moduleValue: "Project Payment Type",
              route: "/master/projectPaymentType"
            },

            {
              menuValue: "Partner",
              moduleValue: "Project Partner",
              route: "/master/projectPartner"
            },

            {
              menuValue: "Type",
              moduleValue: "Project Type",
              route: "/master/projectType"
            },
          ]
        },
        {
          tittle: 'Client',
          menuValue: 'Client',
          moduleValue: 'Client',
          hasSubRoute: false,
          showSubRoute: false,
          route: "/clients",
          icon: "la la-users",
        },
        {
          tittle: 'Company',
          menuValue: 'Company',
          moduleValue: 'Company',
          hasSubRoute: false,
          showSubRoute: false,
          route: "/companies",
          icon: "la la-la la-building",
        },
        {
          tittle: 'Roles & Permissions',
          menuValue: 'Roles & Permissions',
          moduleValue: 'Role Permission',
          hasSubRoute: false,
          showSubRoute: false,
          route: "/rolespermission",
          icon: "la la-key",
        },
      ],
    },
    {
      tittle: 'Contract',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          tittle: 'Contractor Salary',
          menuValue: "Contractor Salary",
          moduleValue: "Contractor Salary",
          hasSubRoute: false,
          showSubRoute: false,
          icon: "la la-graduation-cap",
          route: "/contract-employee-salary",
        },
      ],
    },
    {
      tittle: 'Reports',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: "Reports",
          moduleValue: "Reports",
          hasSubRoute: true,
          showSubRoute: false,
          icon: "la la-graduation-cap",
          subMenus: [
            {
              menuValue: "Project Time Sheet",
              moduleValue: "Project Time Sheet",
              route: "/reports/project-time-sheet",
            },
            {
              menuValue: "Hourly Time Sheet",
              moduleValue: "Hourly Time Sheet",
              route: "/reports/hourly-time-sheet",
            },
            {
              menuValue: "Attendance Team Wise",
              moduleValue: "Attendance report",
              route: "/reports/attendance-report",
            },
            {
              menuValue: "Employee Availability",
              moduleValue: "Attendance report",
              route: "/reports/employee-attendance-report",
            },
            {
              menuValue: "Team Availability",
              moduleValue: "Attendance report",
              route: "/reports/team-availability",
            },
          ],
        },
      ],
    },
  ];
}
