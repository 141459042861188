import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';
import { currentMonthDateRange } from "../helpers/common";

const initialState = {
  allData: null,
  employeeRequest: null,
  allHoliday: null,
  allLeave: null,
  todayMarked: false,
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null,
  searchFilter: {
    startDate: currentMonthDateRange().startDate,
    endDate: currentMonthDateRange().endDate,
    status: "all",
    name: "",
  }
};

const attendanceSlice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    requestSuccess: (state, action) => {
      state.allData = action.payload.data.attendances;
      state.allHoliday = action.payload.data.holiday;
      state.allLeave = action.payload.data.leave;
      state.todayMarked = action.payload.data.todayMarked;
      state.wfh = action.payload.data.wfh;
      state.loading = false;
    },
    requestSuccessManager: (state, action) => {
      state.employeeRequest = action.payload.data;
      state.pagination = action.payload.pagination;
      state.loading = false;

    },
    requestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    added: (state, action) => {
      state.error = null;
      state.success = action.payload.message;
      state.message = action.payload.message;
      state.loading = false;
    },
    removeErrorMsg: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;

    },
    setFilter: (state, action) => {
      const { key, value } = action.payload;
      console.log(key, value)
      state.searchFilter[key] = value;
    },
    resetFilters: (state) => {
      state.searchFilter = {
        startDate: currentMonthDateRange().startDate,
        endDate: currentMonthDateRange().endDate,
        status: "all",
        name: "",
      };
    },
  },
});

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  added,
  removeErrorMsg,
  requestSuccessManager,
  setFilter,
  resetFilters
} = attendanceSlice.actions;

export default attendanceSlice.reducer;

export const getAllAttendance = () => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.get(`/attendance/get-attendance`);
    const { data, status, message } = response.data;
    if (status) {

      dispatch(requestSuccess({ data }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};
export const getMyUserAttendance = ({ page, per_page }) => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().attendance;
  try {
    const response = await axiosInstance.post(`/my-employee-attendance?page=${page}&per_page=${per_page}`, searchFilter);
    const { data, status, message, pagination } = response.data;

    if (status) {
      dispatch(requestSuccessManager({ data, pagination }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const markAttendance = (designationData) => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.post(`/attendance/mark-attendance`, designationData);
    const { status, message } = response.data;
    if (status) {
      dispatch(added({ message }));
    } else {
      console.log('error', message);
      dispatch(requestFailure(message || 'Failed to add designation'));
    }
  } catch (error) {

    console.log(error)
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors; // Get the validation errors
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(', '); // Flatten the error messages

      dispatch(requestFailure(errorMessage || 'Failed to add designation'));
    } else {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to add grade';
      dispatch(requestFailure(errorMessage));
    }
  }
};

export const markPastAttendance = (designationData) => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.post(`/attendance/mark-past-attendance`, designationData);
    const { status, message } = response.data;
    if (status) {
      dispatch(added({ message }));
    } else {
      console.log('error', message);
      dispatch(requestFailure(message || 'Failed to add designation'));
    }
  } catch (error) {

    console.log(error)
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors; // Get the validation errors
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(', '); // Flatten the error messages

      dispatch(requestFailure(errorMessage || 'Failed to add designation'));
    } else {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to add grade';
      dispatch(requestFailure(errorMessage));
    }
  }
};

export const updateRequestStatus = (newRequest) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.post(`/update-employee-attendance`, newRequest);
    const { status, message } = response.data;
    if (status) {
      dispatch(added({ message }));
    } else {
      console.log('error', message);
      dispatch(requestFailure(message || 'Failed to add request'));
    }
  } catch (error) {

    console.log(error)
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors; // Get the validation errors
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(', '); // Flatten the error messages

      dispatch(requestFailure(errorMessage || 'Failed to add request'));
    } else {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to add grade';
      dispatch(requestFailure(errorMessage));
    }
  }
};




