import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
  allProjects: null,
  projectDetails:null,
  dependentList:null,
  projectCode:null,
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null,
  searchFilter:{
    project :"",
    user_name:"",
    manager:""
  }
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    requestSuccess: (state, action) => {
      if(action.payload.type === 'dependents'){
          state.dependentList = action.payload.data;
      }else if(action.payload.type === 'details'){        
        state.projectDetails = action.payload.data;        
      }else if(action.payload.type === 'updated'){        
        state.success = action.payload.message;       
      }else{
          state.allProjects = action.payload.data;
          state.pagination = action.payload.pagination;
          
      }     
      
      state.loading = false;
      
    },
    requestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    removeErrorMsg: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;

    },
    setProjectCode:(state, action)=>{
        state.projectCode = action.payload.data;
        state.loading = false;
    },
    setFilter: (state, action) => {
      const { key, value } = action.payload; 
      console.log(key, value)
      state.searchFilter[key] = value; 
    },
    resetFilters: (state) => {
      state.searchFilter = {
        project :"",
        user_name:"",
        manager:""
      };
    },
    requestCompleted:(state, action)=>{
      state.loading = false;
  },
  },
});

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  removeErrorMsg,
  setProjectCode,
  setFilter,
  resetFilters,
  requestCompleted
} = projectSlice.actions;

export default projectSlice.reducer;

export const getDependent = () => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.get(`/get-project-dependent-data`);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, type:'dependents' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};
export const getProjects = ({ page, per_page }) => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().project;
  try {
    const response = await axiosInstance.post(`/get-projects?page=${page}&per_page=${per_page}&search=${searchFilter ? searchFilter.project : ''}&fields[]=project_name&fields[]=project_code`, searchFilter);
    const { data, status, message, pagination} = response.data;
    if (status) {
      dispatch(requestSuccess({ data,pagination, type:'projects' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};
export const getMyProjects = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(requestStart());
  
  try {
    const response = await axiosInstance.post(`/my-projects?page=${page}&per_page=${per_page}&search=${search ? search.projectCodeOrName : ''}&fields[]=project_name&fields[]=project_code`, search?search:{});
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data,pagination, type:'projects' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};


export const getProjectCode = (newRequest) => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.post(`/get-project-code`, newRequest);
    const { status, message, data } = response.data;
    if (status) {
      dispatch(setProjectCode({ message, data }));
    } else {
      dispatch(requestFailure(message || 'Failed to add request'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to get company code';
    dispatch(requestFailure(errorMessage));
  }
};

export const addEditProject = (newRequest) => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.post(`/store-project`, newRequest);
    const { status, message, data } = response.data;
    if (status) {
      dispatch(requestSuccess({ message, data, type:"updated" }));
    } else {
      dispatch(requestFailure(message || 'Failed to add request'));
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(', ');
      dispatch(requestFailure(errorMessage || 'Failed to add request'));
    } else {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to add grade';
      dispatch(requestFailure(errorMessage));
    }
  }
};

export const getProjectDetails = (id) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/project-details/`+id);
    const { data, message,status} = response.data;
    if (status) {
      dispatch(requestSuccess({ data, type:'details' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const deleteProject = (id) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.delete(`/project-delete/${id}`);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(requestSuccess({ message, data, type:"updated" }));
      dispatch(getProjects());
    } else {
      dispatch(requestFailure(message || 'Failed to delete user'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to delete data'));
  }
};







