import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { removeErrorMsg, getAllAssetCategories,getAllAssets,allotAssets } from "../../../../redux/assetSlice";
import { useDispatch, useSelector } from 'react-redux';
import Alert from "../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { customStyles } from "../../../../helpers/Constants";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { format, parseISO } from 'date-fns';
import AllotAssetTable from "./AssignAssetTable";
import { getEmployee } from '../../../../redux/commonSlice';

const validationSchema = Yup.object().shape({

    user_id: Yup.string().required("User is required"),

    date_of_allotment: Yup.string().required("Date of allotement is required"),

    status: Yup.string().required("Status is required"),
});



function AllotAsset({ show, handleClose, asset, isBulk }) {
    const dispatch = useDispatch();
    const {
        loading = false,
        error = "",
    } = useSelector((state) => state.asset || {});

    const [paginationState, setPaginationState] = useState({
        current: 1,
        pageSize: 2000,
    });

    useEffect(() => {
        dispatch(getAllAssetCategories({ page: paginationState.current, per_page: paginationState.pageSize, search: '' }));
    }, [dispatch, paginationState.current, paginationState.pageSize]);

    useEffect(() => {
        dispatch(getAllAssets({ page: paginationState.current, per_page: paginationState.pageSize, search:''
         }));
    }, [dispatch, paginationState.current, paginationState.pageSize]);

    useEffect(() => {
        dispatch(getEmployee());

      }, [dispatch]);

    const { assetCategories=[],assets = [] } = useSelector((state) => state.asset);
    const { employee } = useSelector((state) => state.common);

    const [selectedAssets, setSelectedAssets] = useState([]);
    const [selectedAssetId, setSelectedAssetId] = useState("");
    const [selectedAssetTypeId, setSelectedAssetTypeId] = useState("");
    const assetInitialOptions = assets?.map(g => ({
        value: g.id,
        label: g.model_serial_number,
    }));

    const [assetOptions, setAssetOptions] = useState(assetInitialOptions);

    const assetCategoriesOptions = assetCategories?.map(g => ({
        value: g.id,
        label: g.name,
    }));

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            user_id: "",
            date_of_allotment: "",
            asset_type_e: "",
            asset_e: "",
            comments: "",
            status: "",
        },
    });

    const statusOptions = [{
        value: 'alloted',
        label: 'Assign',
    }, {
        value: 'intransit',
        label: 'In Transit',
    },
    {
        value: '',
        label: 'all',
    }, {
        value: 'available',
        label: 'Available',
    },
    {
        value: 'maintainance',
        label: 'Maintainance',
    }];

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const onSubmit = async (values) => {
        try {
            if (asset) {
                values.asset_e = asset.id ? [asset.id] : [];
                await dispatch(allotAssets(values));
            } else {
                values.asset_e = selectedAssets.map(g => g.id);
                await dispatch(allotAssets(values));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onAddButtonClicked = async () => {
        try {
            const assetCategory = assetCategories.find(x => x.id === selectedAssetTypeId);
            const asset = assets?.find(x => x.id === selectedAssetId);

            if (assetCategory && asset) {
                // Check if the asset is already in selectedAssets
                const isAssetAlreadySelected = selectedAssets.some(selectedAsset => selectedAsset.id === asset.id);

                if (!isAssetAlreadySelected) {
                    setSelectedAssets([
                        ...selectedAssets,
                        { id: asset.id, category: assetCategory.name, assetName: asset.model_serial_number }
                    ]);
                } else {
                    console.warn("Asset is already selected");
                }
            } else {
                console.warn("Asset or Asset Category not found");
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (asset) {
            reset({
                user_id: '',
                date_of_allotment: '',
                asset_e: asset.id ? [asset.id] : [],
                status: '',
                comments: '',
            });
        } else {
            reset({
                user_id: '',
                date_of_allotment: '',
                asset_e: [],
                status: '',
                comments: '',
            });
        }

    }, [asset, reset]);


    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal custom-modal-three">
            <Modal.Header>
                <Modal.Title>{asset ? 'Assign Asset' : 'Assign Assets'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-12">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Assign To User :
                                </label>
                                <Controller
                                    name="user_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={employee} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={employee?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.user_id ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.user_id && (
                                    <span className="text-danger">
                                        {errors.user_id.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    {isBulk === true &&(
                    <>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Selected Assets :
                                </label>
                              <AllotAssetTable assets={selectedAssets} setAssets={setSelectedAssets}></AllotAssetTable>
                           </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center w-100 mb-3">
                            <div className="flex-grow-1 me-2">
                                <Controller
                                    name="asset_type_id"
                                    control={control}
                                    defaultValue={selectedAssetTypeId}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={assetCategoriesOptions}
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={assetCategoriesOptions?.find(option => option.value === selectedAssetTypeId) || null}
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null);
                                                const filteredOptions = assets
                                                ?.filter(asset => asset.asset_type_id === selectedOption?.value)
                                                .map(g => ({
                                                  value: g.id,
                                                  label: g.model_serial_number,
                                                }));

                                                setAssetOptions(filteredOptions || []); // Update asset options for the dependent dropdown

                                                setSelectedAssetTypeId(selectedOption ? selectedOption.value : "");
                                            }}

                                        />
                                    )}
                                /></div>
                                <div className="flex-grow-1 me-2">
                                <Controller
                                    name="asset_id"
                                    control={control}
                                    defaultValue={selectedAssetId}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={assetOptions}
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={assetOptions?.find(option => option.value === selectedAssetId) || null}
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null);
                                                setSelectedAssetId(selectedOption ? selectedOption.value : "");
                                            }}
                                        />
                                    )}
                                />
                                </div>
                                <button type="button" className="btn btn-primary ms-2" on fdprocessedid="as7mtr" onClick={onAddButtonClicked}>   <i className="fa fa-plus" /></button>
                            </div>
                        </div>

                    </div>
                    </> )}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Status :
                                </label>
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={statusOptions} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={statusOptions?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.status ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.status && (
                                    <span className="text-danger">
                                        {errors.status.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Date of Allotement :
                                </label>
                                <Controller
                                    name='date_of_allotment'
                                    control={control}
                                    render={({ field }) => (
                                        <div className="cal-icon">
                                            <DatePicker
                                                className={`form-control w-100 ${field.value ? '' : 'error-input'}`}
                                                selected={field.value ? parseISO(field.value) : null}
                                                onChange={(date) => field.onChange(date ? format(date, "yyyy-MM-dd") : "")}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select a date"
                                            />
                                        </div>
                                    )}
                                />
                                {errors.date_of_allotment && (
                                    <span className="text-danger">
                                        {errors.date_of_allotment.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="input-block mb-3">
                            <label className="col-form-label">
                                    Comments :
                                </label>
                                <Controller
                                    name="comments"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                        className={`form-control ${errors.comments ? "error-input" : ""}`}
                        rows="2" // Set an initial height with rows
                        style={{ resize: "vertical" }} // Allow vertical resizing
                        {...field}
                    />
                                    )}
                                />
                                {errors.comments && (
                                    <span className="text-danger">{errors.comments.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Save Changes
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AllotAsset;
