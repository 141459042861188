import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

const ContractEmployeeDateFilter = ({ onSearch, options, SetSelectedSalaryMonth, SelectedSalaryMonths, contractEmployees,setSelectedUser }) => {

    const [inputValue, setInputValue] = useState(SelectedSalaryMonths);
    const [selectedEmployee, setSelectedEmployee] = useState("all");

    const dateOptions = [
        { value: '', label: 'All' }, // "All" option at index 0
        ...(Array.isArray(options)
            ? options.map(g => ({
                value: g,
                label: g,
            }))
            : []),
    ];

    const employeesOptions = [
        { value: 'all', label: 'All' }, // "All" option at index 0
        ...(Array.isArray(contractEmployees)
            ? contractEmployees.map(g => ({
                value: g.id,
                label: g.name ? g.name : (g.first_name ? `${g.first_name} ${g.last_name}` : ""),
            }))
            : []),
    ];

    const search = () => {
        onSearch(inputValue,selectedEmployee);
    };
    useEffect(() => {
        SetSelectedSalaryMonth(inputValue);
    }, [inputValue, SetSelectedSalaryMonth]);

    return (
        <div className="row filter-row justify-content-start text-start mb-3">
            <div className="col-sm-3 col-md-3">
                <Select
                    options={dateOptions} // This should be an array of objects with label and value
                    placeholder="Select Month"
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} 
                    value={dateOptions?.find(option => option.value === inputValue) || "all"} // Set the selected option
                    onChange={(selectedOption) => {
                        setInputValue(selectedOption.value) // Store only the value
                    }}
                />
            </div>

            <div className="col-sm-3 col-md-3">
                <Select
                    options={employeesOptions} // This should be an array of objects with label and value
                    placeholder="Select Employee"
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} 
                    value={employeesOptions?.find(option => option.value === selectedEmployee) || 0} // Set the selected option
                    onChange={(selectedOption) => {
                        setSelectedEmployee(selectedOption.value) // Store only the value
                        setSelectedUser(selectedOption.value);
                    }}
                />
            </div>

            <div className="col-sm-6 col-md-4">
                <Link to="#" className="btn btn-success" onClick={search}>
                    <i className="fa fa-search" /> Search
                </Link>
            </div>
        </div>
    );
};

export default ContractEmployeeDateFilter;
