import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
  contractEmployeeSalary: null,
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null,
  salaryMonths:null,
  salarydocuments:null,
  salarypayments:null,
};

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    //   state.message="";
    },
    requestSuccess: (state, action) => {
      state.contractEmployeeSalary=action.payload.data;
      state.pagination = action.payload.pagination;
      state.loading = false;
      state.success = null;
    },
    requestGetDistinctSalaryMonthsSuccess: (state,action) => {
        state.salaryMonths=action.payload.data.distinctSalaryMonths;
        state.message = action.payload.message;
        state.loading = false;
        state.success = null;
      },

      added:(state, action) => {
        state.error = null;
        state.success = action.payload.success;
        state.message = action.payload.message;
        state.loading = false;
      },

      requestSalaryDocumentsSuccess:(state, action) => {
        state.error = null;
        state.salarydocuments = action.payload.data;
        state.success = null;
        state.message = action.payload.message;
        state.loading = false;
      },
      requestSalaryPaymentsSuccess:(state, action) => {
        state.error = null;
        state.salarypayments = action.payload.data;
        state.success = null;
        state.message = action.payload.message;
        state.loading = false;
      },
    requestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    removeErrorMsg: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;

    }
  },
});

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  removeErrorMsg,
  requestGetDistinctSalaryMonthsSuccess,
  added,
  requestSalaryDocumentsSuccess,
  requestSalaryPaymentsSuccess,
} = contractSlice.actions;

export default contractSlice.reducer;

export const getContractEmployeeSalaries = ({ page, per_page, salary_month="",selectedEmployee="all" }) => async (dispatch) => {
 dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/contract-employee-salary?page=${page}&salary_month=${salary_month}&employee=${selectedEmployee}&per_page=${per_page}`);;
    const { data, status, message,pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data,pagination }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};

export const generateContractEmployeeSalaries = () => async (dispatch) => {
    dispatch(requestStart());
    try {
      const response = await axiosInstance.post(`/generate-salary`);;
      const { data, status, message } = response.data;
    //   console.log(status);
    //   console.log(message);
      if (status) {
        // dispatch(getContractEmployeeSalaries());
        dispatch(added({success:status, message: message }));
      } else {
        dispatch(requestFailure(message || 'Failed to fetch data'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
      dispatch(requestFailure(errorMessage));
    }
  };

  export const getDistinctSalaryMonths = () => async (dispatch) => {
    dispatch(requestStart());
    try {
      const response = await axiosInstance.get(`/distinct-salary-months`);;
      const { data, status, message } = response.data;
      if (status) {
        // dispatch(getContractEmployeeSalaries());
        dispatch(requestGetDistinctSalaryMonthsSuccess({data,message}));
      } else {
        dispatch(requestFailure(message || 'Failed to fetch data'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
      dispatch(requestFailure(errorMessage));
    }
  };

  export const exportSalary = (salary_month="",user_id="") => async (dispatch) => {
    dispatch(requestStart());
    try {
      const response = await axiosInstance.get(`/export-contract-employee-salary?salary_month=${salary_month}&user_id=${user_id}`, {
        responseType: 'blob', // This is crucial for file download
      });

      // Create a link to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'contractorsalary.xlsx'); // Set the file name
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up

      // Dispatch success action if needed
      dispatch(added({ message: 'Export successful!' }));

    } catch (error) {
      // Handle errors here
      if (error.response && error.response.data && error.response.data.errors) {
        const validationErrors = error.response.data.errors;
        const errorMessage = Object.values(validationErrors)
          .flat()
          .join(' ');
        dispatch(requestFailure(errorMessage || 'Failed to export salary'));
      } else {
        dispatch(requestFailure(error.message || 'Failed to export salary'));
      }
    }
  };

  export const uploadSalaryDocuments = (formDataToSend) => async (dispatch) => {
    // dispatch(requestStart());
    try {
      const response = await axiosInstance.post(`/salary-doc`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for FormData
        },
      });

      const { status, message } = response.data;

      if (status) {
        dispatch(added({ message }));
      } else {
        dispatch(requestFailure(message || 'Failed to upload doc'));
      }

      return response; // Ensure the action returns the response
    } catch (error) {
      dispatch(requestFailure(error.message || 'Failed to upload doc'));
      return error.response;
    }
  };

  export const getSalaryDocuments = (id) => async (dispatch) => {
    // dispatch(requestStart());
    try {
      const response = await axiosInstance.get(`/salary-documents/${id}`);

      const { status, message, data } = response.data;

      if (status) {
        dispatch(requestSalaryDocumentsSuccess({ message,data }));
      } else {
        dispatch(requestFailure(message || 'Failed to get docs'));
      }

      return response; // Ensure the action returns the response
    } catch (error) {
      dispatch(requestFailure(error.message || 'Failed to get docs'));
      return error.response;
    }
  };

  export const deleteSalaryDocument = (id) => async (dispatch) => {
    // dispatch(requestStart());
    try {

      const response = await axiosInstance.delete(`/salary-document/${id}`);

      const { status, message } = response.data;

      if (status) {
        dispatch(added({ message }));
      } else {
        dispatch(requestFailure(message || 'Failed to delete doc'));
      }

      return response; // Ensure the action returns the response
    } catch (error) {
      dispatch(requestFailure(error.message || 'Failed to delete doc'));
      return error.response;
    }
  };

  export const updateContractEmployeeSalary = ({ data,page, per_page, salary_month="",selectedEmployee="all" }) => async (dispatch) => {
    // dispatch(requestStart());
    try {

      console.log(data);

      const response = await axiosInstance.post(`/update-salary`, data);

      const { status, message } = response.data;

      if (status) {
        dispatch(added({ message }));
        dispatch(getContractEmployeeSalaries({ page, per_page,salary_month, selectedEmployee }));
      } else {
        dispatch(requestFailure(message || 'Failed to save salaries'));
      }

      return response; // Ensure the action returns the response
    } catch (error) {
      dispatch(requestFailure(error.message || 'Failed to save salaries'));
      return error.response;
    }
  };


  export const addSalaryPayment = ({ data,page, per_page, salary_month="",selectedEmployee="all" }) => async (dispatch) => {
    // dispatch(requestStart());
    try {
      const response = await axiosInstance.post(`/salary-payment`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for FormData
        },
      });

      const { status, message } = response.data;

      if (status) {
        dispatch(added({ message }));
        dispatch(getSalaryPayments(data.salary_id));
        dispatch(getContractEmployeeSalaries({ page, per_page,salary_month, selectedEmployee }));
      } else {
        dispatch(requestFailure(message || 'Failed to add salary payments'));
      }

      return response; // Ensure the action returns the response
    } catch (error) {
      dispatch(requestFailure(error.message || 'Failed to add salary payment'));
      return error.response;
    }
  };

  export const getSalaryPayments = (id) => async (dispatch) => {
    // dispatch(requestStart());
    try {
      const response = await axiosInstance.get(`/salary-payments/${id}`);

      const { status, message, data } = response.data;

      if (status) {
        dispatch(requestSalaryPaymentsSuccess({ message,data }));
      } else {
        dispatch(requestFailure(message || 'Failed to get salary payments'));
      }

      return response; // Ensure the action returns the response
    } catch (error) {
      dispatch(requestFailure(error.message || 'Failed to get salary payments'));
      return error.response;
    }
  };

  export const deleteSalaryPayment = (id) => async (dispatch) => {
    // dispatch(requestStart());
    try {

      const response = await axiosInstance.delete(`/salary-payments/${id}`);

      const { status, message } = response.data;

      if (status) {
        dispatch(added({ message }));
      } else {
        dispatch(requestFailure(message || 'Failed to delete salary payment'));
      }

      return response; // Ensure the action returns the response
    } catch (error) {
      dispatch(requestFailure(error.message || 'Failed to delete salary payment'));
      return error.response;
    }
  };
