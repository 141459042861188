import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import Breadcrumbs from "../../../../Breadcrumbs";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // Import your FormModal component
import FormModal from '../../../../Common/FormModal';
import { add, update, removeErrorMsg, removeData, showData } from "../../../../../redux/pemRequestSlice";
import { getTemplates, getEmployee, } from '../../../../../redux/commonSlice';

import { useForm, FormProvider } from "react-hook-form";
import { Loader } from "react-feather";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const TemplateForm = () => {
    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    const navigate = useNavigate(); // For navigation after submission
    const dispatch = useDispatch();
    const [postdata, setpostData] = useState(null);
    const { categories, data, loading, loadingsingle, error, success, notfound } = useSelector((state) => state.pemrequest);
    const { id } = useParams(); // Get the client ID from the URL
    useEffect(() => {

        if (id) {
            // dispatch(removeData());
            // If there's an ID, fetch the existing client data for editing
            dispatch(showData({ id }));
        }
    }, [id]);

    useEffect(() => {
        dispatch(removeData());

    }, []);
    const { manager, employee, templates } = useSelector((state) => state.common);
    const teamOptions = manager ? manager : [];

    const employeeOptions = employee ? employee : [];
    const templateOptions = templates?.map(g => ({
        value: g.id,
        label: g.name,
    }));

    // const fieldConfig = [/* Your field configurations here */];

    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    let fieldConfig = [


        {
            name: "teams", label: "Select Manager",
            type: "select",
            options: teamOptions,
            onChange: (value) => {
                // console.log("Team selected:", value);
                dispatch(getEmployee('all', value));
                // Perform any action based on selected value
            },
            dependent: "employee",


        },


        {
            name: "employee", label: "Select Employee",
            type: "selectmulitple",
            options: employeeOptions,
            validation: Yup.array()
                .min(1, 'At least one employee must be selected')
                .required('Employee selection is required')


        },
        {
            name: "template_id", label: "Select Template",
            type: "select",
            options: templateOptions,
            validation: Yup.string().required("Template is required")


        },


        { block: true, name: "evaluation_from", label: "Select evaluation Start", type: "date", validation: Yup.string().required("Evaluation start date is required") },
        { block: true, name: "evaluation_to", label: "Select evaluation End", type: "date", validation: Yup.string().required("Evaluation end  date is required") },

    ];
    // if (postdata && id) {




    //     fieldConfig = [


    //         {
    //             name: "sub_domain", placeholder: "Enter subdomain'", label: "Subdomain", type: "text", validation: Yup.string()
    //                 .required("Subdomain is required")
    //                 .max(255, "Subdomain cannot be more than 255 characters"),
    //         },

    //     ];
    // }


    const handleSubmit = async (values) => {
        try {
            // Create JSON object for non-file fields
            const jsonData = {};




            Object.entries(values).forEach(([key, value]) => {

                // Append non-file data to jsonData
                jsonData[key] = value !== undefined ? value : '';

            });

            const employees = jsonData.employee.map(team => team.value);

            // Prepare the JSON data for submission
            const jsonDatas = {
                ...jsonData, // Copy other form fields
                employees // Now teams is an array of values, e.g., [1, 2]
            };

            if (data) {
                jsonDatas._method = 'put';
                jsonDatas.id = data.id;
                await dispatch(update(jsonDatas, data.id));
            } else {
                await dispatch(add(jsonDatas));
            }
        } catch (error) {
            console.error(error);
        }
    };



    useEffect(() => {
        dispatch(getEmployee());
        dispatch(getTemplates());

        dispatch(getEmployee('Manager'));



    }, [dispatch]);
    useEffect(() => {

        if (data) {

            setpostData(data);
        }


    }, [data]);

    useEffect(() => {
        if (notfound) {
            navigate('/all-pem');
        }
        if (success) {
            navigate('/all-pem');
        }
    }, [success, notfound]);
    const methods = useForm();
    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row align-items-center">
                        <Breadcrumbs
                            maintitle="Create New Performance Evaluation Metrics Process For Employee"
                            title="Dashboard"
                            subtitle=" Add PEM"
                        />
                        <div className="col-auto float-end ms-auto">
                            <Link to="/all-pem" class="btn btn-primary" on fdprocessedid="as7mtr">   <i className="fa fa-arrow-left" /> Go Back</Link>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-0">
                                            <div className="card-body">
                                                {loadingsingle || (!postdata && id) ? (
                                                    <Loader
                                                        className="feather-loader"
                                                        data-bs-toggle="tooltip"
                                                        title="Loading..."
                                                    />
                                                ) : fieldConfig ? (
                                                    <FormProvider {...methods}>  {/* Wrap with FormProvider */}
                                                        <FormModal
                                                            error={error}
                                                            disabled={(postdata && id) ? true : false}
                                                            loading={loading}
                                                            removeErrorMsg={removeErrorMsg}
                                                            data={postdata?.template}
                                                            fieldConfig={fieldConfig}

                                                            onSubmit={handleSubmit}
                                                        />   </FormProvider>
                                                ) : (
                                                    <div>No content available</div> // Fallback message or component
                                                )}


                                            </div> </div> </div> </div> </div> </div></div>

                </div> </div>


        </>
    );
};

export default TemplateForm;
