import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../Breadcrumbs";
import DeleteModal from "./model/DeleteModel";
import { useDispatch, useSelector } from 'react-redux';
import { getAll,deleteGrade } from '../../../../redux/gradeSlice';
import AddEdit from './model/AddEdit';
import Filter from "./Filter";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Grade = () => {
  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false)
  const [designationDetails, setDesignationDetails] = useState(null);
  const { allData, loading, pagination, success, search } = useSelector((state) => state.grade); 
  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10,
  });
  useEffect(() => {
    dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize,search  }));
  }, [dispatch, paginationState.current, paginationState.pageSize]);
console.log(allData, '0099')
  const userElements = allData?.map((designation, index) => ({
    key: index,
    id: designation.id,
    name: designation.name,
    description: designation.description,
  }));

  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);

  const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      sorter: (a, b) => a.key.length - b.key.length,
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.id.length - b.id.length,
      width: "20%",
    },
    {
      title: "Action",
      className: "text-end",
      render: (text, designation) => (
        <div className="dropdown dropdown-action text-end me-30">          
            <Link
              className="dropdown-item1 m-r-10"
              to="#"
              onClick={() => addModal(designation)}
            >
              <i className="fa fa-pencil m-r-5" />
            </Link>
            <Link
              className="dropdown-item1 m-r-10"
              to="#"
              onClick={() => deleteModal(designation)}
            >
              <i className="fa fa-trash m-r-5" /> 
            </Link>
        </div>
      ),
      sorter: (a, b) => a.length - b.length,
      width: "10%",
    },
  ];

  const haldleModel = () => {
    setOpenModel(!openModel)
  }
  const addModal = (data)=> {
    setDesignationDetails(data);
    handleShow()
  }

  const deleteModal = (data)=> {
    setDesignationDetails(data);
    handleDeleteShow()
  }

  const deleteDesignationData = (data)=> {
    handleDeleteClose();
    dispatch(deleteGrade(data.id));
  }

  useEffect(() => {
    console.log('Het');
    if (success) {
        showAlert({
            title: 'Grade',
            text: success,
            confirmButtonColor: '#FF902F',
            confirmButtonText: 'OK',
        });
        dispatch(getAll({ page: paginationState.current, per_page:  paginationState.pageSize ,search}));
        handleClose();
        handleDeleteClose();
    }
}, [success,  showAlert]);


const onSearch = (search)=>{
  dispatch(getAll({ page: paginationState.current, per_page:  paginationState.pageSize ,search}));
  console.log(search, 'pp');
}
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
        <div className="page-header">
        <div className="row align-items-center">
          <Breadcrumbs
            maintitle="Grade"
            title="Dashboard"
            subtitle="Grade"
          />
          <div className="col-auto float-end ms-auto"> 
          <button type="button" class="btn btn-primary" on fdprocessedid="as7mtr"   onClick={()=>addModal(null)}>   <i className="fa fa-plus" /> Add Grade</button>
           
        </div>
          </div>
          </div>
          <Filter onSearch={onSearch}/>
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0 data-table-card">
                
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-stripped"
                      columns={columns}
                      dataSource={
                        userElements?.length > 0
                          ? userElements?.map((designation) => ({
                              ...designation,
                              key: designation.id,
                            }))
                          : []
                      }
                      pagination={{
                        current: paginationState.current,
                        pageSize: paginationState.pageSize,
                        total: pagination?.total,
                        onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
       <AddEdit show={showModal} handleClose={handleClose}  data={designationDetails} />
       <DeleteModal show={showDeleteModal} handleClose={handleDeleteClose}  data={designationDetails} onDelete={deleteDesignationData}  name="Delete Grade" />
    </div>
  );
};

export default Grade;
