import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { AddEmployeeAssetRequest, removeErrorMsg} from "../../../../../redux/assetSlice";
import { useDispatch, useSelector } from 'react-redux';
import Alert from "../../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const validationSchema = Yup.object().shape({
    comment: Yup.string().required("Comment is required"),
});

function EmployeeAssetRequest({ show, handleClose }) {
    const {
        loading = false,
        error = "",
      } = useSelector((state) => state.asset || {});
    const dispatch = useDispatch();
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            comment: "",
        },
    });

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const onSubmit = async (values) => {
        try {
            await dispatch(AddEmployeeAssetRequest(values));
            reset({
                comment: "",
            });
        } catch (error) {
            console.error(error);
        }
    };


    const handleModalClose = () => {
        reset({
            comment: "",
        });
        resetMsg();
        handleClose();

    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{'Asset Request'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-block mb-3">
                        <label className="col-form-label">
                            Comment <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="comment"
                            control={control}
                            render={({ field }) => (
                                <textarea
                                className={`form-control ${errors.comment ? "error-input" : ""}`}
                                rows="2" // Set an initial height with rows
                                style={{ resize: "vertical" }} // Allow vertical resizing
                                {...field}
                            />
                            )}
                        />
                        {errors.comment && <span className="text-danger">{errors.comment.message}</span>}
                    </div>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Submit Request
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default EmployeeAssetRequest;
