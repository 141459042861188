import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({dataa}) => {

  console.log(dataa, 'a')
  function getRandomColor() {
    const r = Math.floor(Math.random() * 256); // Random red value
    const g = Math.floor(Math.random() * 256); // Random green value
    const b = Math.floor(Math.random() * 256); // Random blue value
    const alpha = 0.6; // Transparency
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  
  // Chart data setup
  const data = {
    labels: dataa?.map((item) => item.label), // Extract 'label' values
    datasets: [
      {
        label: "Gender", // Example label for the dataset
        data: dataa?.map((item) => item.y), // Extract 'y' values
        backgroundColor: dataa?.map(() => getRandomColor()), // Generate random colors for each data point
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
  };

  return (
    <div >
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Gender</h5>
        </div>
        <div className="card-body">
          <div style={{height:'300px'}}>
            <Pie data={data} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
