import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { uploadSalaryDocuments, removeErrorMsg, getSalaryDocuments } from "../../../../redux/contractSlice";
import Modal from 'react-bootstrap/Modal';
import Alert from "../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import { message, Table } from "antd";
import { Link } from "react-router-dom";
import { number } from "yup";

// SaveEmployeeSalaryDocument, DocumentList, DeleteDocument
const AddEditemployeeSalaryDocument = ({ employeeSalaryId, show, handleClose, onDocDeleteClick }) => {

    const dispatch = useDispatch();

    const { salarydocuments, loading, error } = useSelector((state) => state.contract);

    const [inputError, setInputError] = useState("");

    useEffect(() => {
        if (employeeSalaryId && Number(employeeSalaryId) > 0) {
            dispatch(getSalaryDocuments(employeeSalaryId));
            setFormData({
                id: employeeSalaryId || 0,
                doc: null,
            })
        }
    }, [employeeSalaryId, getSalaryDocuments]);

    const MySwal = withReactContent(Swal);

    const [formData, setFormData] = useState({
        id: employeeSalaryId || 0,
        doc: null,
    });

    const getUrl = (path) => {
        return path.includes('/api') ? path.replace('/api', '/storage') : path;
    };

    const handleDownload = (docUrl, fileName) => {

        const fileUrl = getUrl(`${process.env.REACT_APP_API_URL}/${docUrl}`);

        console.log(fileUrl);

        // Create an anchor element
        const link = document.createElement("a");

        // Set the href to the file URL
        link.href = fileUrl;

        // Set the download attribute to suggest the file name
        link.setAttribute("download", fileName || "download");

        // Append the link to the DOM (this step is required for Firefox compatibility)
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Remove the link after triggering the download
        document.body.removeChild(link);
    };

    // const handleDownload = (docUrl, fileName) => {
    //     const fileUrl = getUrl(`${process.env.REACT_APP_API_URL}/${docUrl}`);

    //     // Fetch the file as a blob to ensure proper download handling
    //     fetch(fileUrl)
    //         .then(response => {
    //             if (!response.ok) {
    //                 throw new Error("Network response was not ok");
    //             }
    //             return response.blob(); // Convert response to blob
    //         })
    //         .then(blob => {
    //             const url = window.URL.createObjectURL(blob);

    //             // Create an anchor element
    //             const link = document.createElement("a");
    //             link.href = url;

    //             // Set the download attribute to suggest the file name
    //             link.setAttribute("download", fileName || "download");

    //             // Append the link to the DOM
    //             document.body.appendChild(link);

    //             // Trigger the download
    //             link.click();

    //             // Cleanup: Remove the link and revoke the blob URL
    //             document.body.removeChild(link);
    //             window.URL.revokeObjectURL(url);
    //         })
    //         .catch(error => {
    //             console.error("Error downloading the file:", error);
    //         });
    // };

    const userElements = salarydocuments?.map((doc, index) => ({
        key: index,
        id: doc.id,
        name: doc.document_name,
        file_url: doc.document_path
    }));

    const columns = [
        {
            title: "S.No",
            width: "10%",
            render: (text, record, index) => index + 1, // Calculate serial number based on row index
        },
        {
            title: "File Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: "40%",
            render: (text, record) => (
                <Link
                    to="#"
                    onClick={() => handleDownload(record.file_url, record.name)}
                >
                    {text}
                </Link>
            ),
        },
        {
            title: "Action",
            className: "text-end",
            render: (text, record) => (
                <div className="dropdown dropdown-action text-end me-30">
                    <Link
                        className="dropdown-item1 m-r-10"
                        to="#"
                        onClick={() => handleDownload(record.file_url, record.name)}
                    >
                        <i className="fa fa-download m-r-5" />
                    </Link>

                    <Link
                        className="dropdown-item1 m-r-10"
                        to="#"
                        // data-bs-toggle="modal"
                        // data-bs-target="#delete"
                        onClick={() => onDocDeleteClick(record.id)}
                    >
                        <i className="fa fa-trash m-r-5" />
                    </Link>
                </div>
            ),
            sorter: (a, b) => a.length - b.length,
            width: "40%",
        },
    ];

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0]; // Only select the first file
        if (!file) {
            setInputError("Please select a file.");
            setFormData({
                id: employeeSalaryId || 0,
                doc: null,
            })
            return;
        }

        // Check for valid file types
        const allowedTypes = ["application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "image/jpeg", "image/png", "image/gif"];
        if (!allowedTypes.includes(file.type)) {
            setInputError("Invalid file type. Only PDF, images, and XLSX are allowed.");
            setFormData({
                id: employeeSalaryId || 0,
                doc: null,
            })
            // setSelectedFile(null);
            return;
        }

        // Clear the error and set the selected file
        setInputError("");

        setFormData({ ...formData, [name]: file });  // Store the first file
    };

    const validateForm = () => {
        const newErrors = {};
        // setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {

        e.preventDefault();

        if (validateForm()) {
            // Create a FormData object to send both form data and files
            const formDataToSend = new FormData();

            // Append text data to FormData
            formDataToSend.append('id', formData.id);

            // Append files to FormData
            if (formData.doc) {
                formDataToSend.append('doc', formData.doc);
            }

            // console.log(formData);
            console.log(formDataToSend);

            // Dispatch the update action with FormData

            dispatch(uploadSalaryDocuments(formDataToSend))
                .then(() => {
                    MySwal.fire("Success", "file upload successfully!", "success");

                    setFormData({
                        id: employeeSalaryId || 0,
                        doc: null,
                    });

                    // Clear the file input field
                    const fileInput = document.querySelector('input[name="doc"]');
                    if (fileInput) {
                        fileInput.value = ""; // Clear the file input field
                    }

                    dispatch(getSalaryDocuments(employeeSalaryId));
                    // setModalVisible(false); // Close modal after success
                })
                .catch((err) => {

                    MySwal.fire("Error", "Failed to upload file", "error");
                });
        }
    };

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{"Documents"}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <form onSubmit={handleSubmit}>
                    <input type="hidden" name="id" value={employeeSalaryId} />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                {/* <label className="col-form-label">Document<span className="text-danger">*</span></label> */}
                                <input type="file" className="form-control" name="doc" onChange={handleFileChange} />
                                {inputError && <p className="text-danger mt-2">{inputError}</p>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <button className="btn btn-primary" type="submit" disabled={!!inputError }>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0 data-table-card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <Table
                                            className="table-stripped"
                                            columns={columns}
                                            dataSource={
                                                userElements?.length > 0
                                                    ? userElements?.map((assetcategory) => ({
                                                        ...assetcategory,
                                                        key: assetcategory.id,
                                                    }))
                                                    : []
                                            }
                                            pagination={{}}
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ?
                            "" : (
                                <button type="button" className="btn btn-primary" disabled>
                                    <i className="fas fa-spinner fa-spin me-2" />
                                    Processing...
                                </button>
                            )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default AddEditemployeeSalaryDocument;
