

import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { add, removeErrorMsg, update } from "../../../../../redux/rolesSlice";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alert from "../../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormModal from '../../../../Common/FormModal'; // Import your FormModal component
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Role name is required"),

});
const RolesPermissionsModal = ({ show, handleClose, data }) => {

  const { loading, error, success } = useSelector((state) => state.roles);


  const dispatch = useDispatch();
  const handleModalClose = () => {
    resetMsg();
    handleClose();
  }; const resetMsg = () => {
    dispatch(removeErrorMsg());
  };



  const handleSubmit = async (values) => {
    try {
      // Handle the submission logic
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {

        if (key === 'logo') {
          if (value) {
            formData.append(key, value);
          }
        } else {
          formData.append(key, value);
        }
      });

      if (data) {
        formData.append('_method', 'put');
        formData.append('id', data.id);
        await dispatch(update(formData, data.id));
        // handleModalClose();
      } else {
        await dispatch(add(formData));
        //  handleModalClose();
      }
    } catch (error) {
      console.error(error);
      handleModalClose();
    }
  };

  let fieldConfig = [

    { name: "name", full: true, placeholder: "Employe ", label: "Role name", type: "text", validation: Yup.string().required("Company Name is required") },



  ];

  return (
    <>
      <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
        <Modal.Header>
          <Modal.Title>{data ? 'Edit Role' : 'Add Role'}</Modal.Title>
          <button type="button" className="btn-close" onClick={handleModalClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Alert
              type="danger"
              message={error}
              onClose={resetMsg}
            />
          )}
          <FormModal
            error={error}
            loading={loading}
            removeErrorMsg={removeErrorMsg}
            data={data}
            fieldConfig={fieldConfig}
            onSubmit={handleSubmit}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RolesPermissionsModal;
