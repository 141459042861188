
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../Breadcrumbs";
import DeleteModal from "./model/DeleteModel";
import { useDispatch, useSelector } from 'react-redux';
import { getAllprojectBusinessArea, deleteprojectBusinessArea, updateSearch } from '../../../../redux/projectBusinessAreaSlice';
import AddEditprojectBusinessArea from './model/AddEditProjectBusinessArea';
import ProjectBusinessAreaFilter from "./ProjectBusinessAreaFilter";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const ProjectBusinessArea = () => {
    const dispatch = useDispatch();
    const [openModel, setOpenModel] = useState(false)
    const [projectBusinessAreaDetails, setprojectBusinessAreaDetails] = useState(null);
    const { allData, loading, pagination, success, search } = useSelector((state) => state.projectBusinessArea);
    const [paginationState, setPaginationState] = useState({
      current: 1,
      pageSize: 10,
    });
    useEffect(() => {
      dispatch(getAllprojectBusinessArea({ page: paginationState.current, per_page: paginationState.pageSize, search: ''  }));
    }, [dispatch, paginationState.current, paginationState.pageSize]);

    const userElements = allData?.map((projectBusinessArea, index) => ({
        key: index,
        serialNo: index + 1 + (paginationState.current - 1) * paginationState.pageSize,
        id: projectBusinessArea.id,
        code: projectBusinessArea.code,
        name: projectBusinessArea.name,
      }));


    const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);

  const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

  const columns = [
    {
      title: "S. No.",
      dataIndex: "serialNo",
      sorter: (a, b) => a.id.length - b.id.length,
      width: "10%",
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: (a, b) => a.projectBusinessArea.length - b.projectBusinessArea.length,
      width: "20%",
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.projectBusinessArea.length - b.projectBusinessArea.length,
      width: "20%",
    },
    {
      title: "Action",
      className: "text-end",
      render: (text, projectBusinessArea) => (
        <div className="dropdown dropdown-action text-end me-30">
          {/* <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link> */}
          {/* <div className="dropdown-menu dropdown-menu-right"> */}
            <Link
              className="dropdown-item1 m-r-10"
              to="#"
              onClick={() => addModal(projectBusinessArea)}
            >
              <i className="fa fa-pencil m-r-5" />
            </Link>
            <Link
              className="dropdown-item1 m-r-10"
              to="#"
            //   data-bs-toggle="modal"
            //   data-bs-target="#delete"
              onClick={() => deleteModal(projectBusinessArea)}
            >
              <i className="fa fa-trash m-r-5" />
            </Link>
          {/* </div> */}
        </div>
      ),
      sorter: (a, b) => a.length - b.length,
      width: "10%",
    },
  ];

  const handleModel = () => {
    setOpenModel(!openModel)
  }

  const addModal = (data)=> {
    setprojectBusinessAreaDetails(data);
    handleShow()
  }

  const deleteModal = (data)=> {
    setprojectBusinessAreaDetails(data);
    handleDeleteShow()
  }

  const deleteprojectBusinessAreaData = (data)=> {
    handleDeleteClose();
    dispatch(deleteprojectBusinessArea(data.id));
  }

  useEffect(() => {
    if (success) {
        showAlert({
            title: 'Project Business Area',
            text: success,
            confirmButtonColor: '#FF902F',
            confirmButtonText: 'OK',
        });
        dispatch(getAllprojectBusinessArea({ page: paginationState.current, per_page:  paginationState.pageSize ,search}));
         handleClose();
         handleDeleteClose();
    }
}, [success,  showAlert]);

const onSearch = (search)=>{
    dispatch(getAllprojectBusinessArea({ page: paginationState.current, per_page:  paginationState.pageSize ,search}));
    handleDeleteClose();
    //console.log(search, 'pp');
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
        <div className="page-header">
        <div className="row align-items-center">
          <Breadcrumbs
            maintitle="Project Business Area"
            title="Dashboard"
            subtitle="Project Business Area"
            modal="#add_projectBusinessArea"
            name="Add  projectBusinessArea"
          />
          <div className="col-auto float-end ms-auto">
          <button type="button" class="btn btn-primary" on fdprocessedid="as7mtr"  onClick={()=>addModal(null)} >   <i className="fa fa-plus" /> Add Project Area</button>

        </div>
          </div>
          </div>
          <ProjectBusinessAreaFilter onSearch={onSearch}/>
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0 data-table-card">

                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-stripped"
                      columns={columns}
                      dataSource={
                        userElements?.length > 0
                          ? userElements?.map((projectBusinessArea) => ({
                              ...projectBusinessArea,
                              key: projectBusinessArea.id,
                            }))
                          : []
                      }
                      pagination={{
                        current: paginationState.current,
                        pageSize: paginationState.pageSize,
                        total: pagination?.total,
                        onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddEditprojectBusinessArea show={showModal} handleClose={handleClose}  projectBusinessArea={projectBusinessAreaDetails} />
      <DeleteModal show={showDeleteModal} handleClose={handleDeleteClose}  projectBusinessArea={projectBusinessAreaDetails} onDelete={deleteprojectBusinessAreaData}  name="Delete Project Business Area" />

    </div>
  );
};

    export default ProjectBusinessArea;
