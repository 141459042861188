import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addProjectTech, removeErrorMsg, updateProjectTech } from "../../../../../redux/projectTechSlice";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alert from "../../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const validationSchema = Yup.object().shape({
    technology: Yup.string().required("Technology is required"),
    business_area: Yup.string().required("Business Area is required"),
});

function AddEditProjectTech({ show, handleClose, projectTech }) {
    //console.log(projectTech,'projectTech')
    const { loading, error, success } = useSelector((state) => state.projectTech);
    const dispatch = useDispatch();
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            id:"",
            technology: "",
            business_area: "",
        },
    });

    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    // Handle form submission
    const onSubmit = async (values) => {
        try {
            if (projectTech) {
                // If editing an existing projectTech, add the projectTech ID to the values
                values.id = projectTech.id;
                await dispatch(updateProjectTech(values)); // Dispatch update
            } else {
                // If adding a new projectTech, dispatch addprojectTech
                await dispatch(addProjectTech(values));
            }
        } catch (error) {
            console.error(error);
        }
    };

     // Reset the form based on projectTech data
     useEffect(() => {
        if (projectTech) {
            reset({
                technology: projectTech.technology || "",
                business_area: projectTech.business_area || "",
            });
        } else {
            reset({
                technology: "",
                business_area: "",
            });
        }
    }, [projectTech, reset]);

    // Handle modal close
    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{projectTech ? 'Edit Project Technology' : 'Add Project Technology'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-block mb-3">
                    <Controller
                            name="id"
                            control={control}
                            render={({ field }) => (
                                <input name="id"
                                value={projectTech?.id}
                                    className={`form-control ${errors.name ? "error-input" : ""}`}
                                    type="hidden"
                                    {...field}
                                />
                            )}
                        />


                        <label className="col-form-label">
                            Technology <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="technology"
                            control={control}
                            render={({ field }) => (
                                <input name="technology"
                                    className={`form-control ${errors.technology ? "error-input" : ""}`}
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        {errors.technology && <span className="text-danger">{errors.technology.message}</span>}
                    </div>
                    <div className="input-block mb-3">
                        <label className="col-form-label">
                            Business Area <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="business_area"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${errors.business_area ? "error-input" : ""}`}
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        {errors.code && <span className="text-danger">{errors.business_area.message}</span>}
                    </div>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Save Changes
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );

}

export default AddEditProjectTech;
