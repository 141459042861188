import React, { useState } from "react";
import { Link } from "react-router-dom";

const Filter = ({ onSearch }) => {
  const [inputValue, setInputValue] = useState("");
  const [focused, setFocused] = useState(false);

  const handleLabelClick = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    if (inputValue === "") {
      setFocused(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value !== "" && !focused) {
      setFocused(true);
    }
  };

  const search = () => {
    onSearch(inputValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      search();
    }
  };

  return (
    <div>
      <div className="row filter-row justify-content-end text-end">
        <div className="col-sm-6 col-md-10">
          <div
            className={
              focused || inputValue !== ""
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={inputValue}
              onFocus={handleLabelClick}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown} // Add the onKeyDown event
            />
            <label className="focus-label" onClick={handleLabelClick}>
              Search by company name ,email, phone number
            </label>
          </div>
        </div>

        <div className="col-sm-6 col-md-2">
          <Link to="#" className="btn btn-success w-100" onClick={search}>
            <i className="fa fa-search" /> Search
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Filter;
