import React from "react";
import { removeErrorMsg } from "../../../../redux/assetSlice";
import { useDispatch, useSelector } from 'react-redux';
import Alert from "../../../../helpers/AlertMessage";
import Modal from 'react-bootstrap/Modal';
import { Table } from "antd";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';



function AssetHistory({ show, handleClose, AssetHistory, MaintenanceHistory }) {
    const dispatch = useDispatch();
    const {
        loading = false,
        error = "",
    } = useSelector((state) => state.asset || {});


    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const columns = [
        {
            title: "S.No",
            width: "10%",
            render: (text, record, index) => index + 1, // Calculate serial number based on row index
        },
        {
            title: "Employee Name",
            dataIndex: "user",
            width: "40%",
            render: (user) => {
                return user.firstname && user.lastname
                    ? `${user.firstname} ${user.lastname}`
                    : user.name;
            },
        },
        {
            title: "Date of Allotement",
            dataIndex: "date_of_allotment",
            width: "40%",
        },
        {
            title: "Date of Submission",
            dataIndex: "date_of_submission",
            width: "40%",
        },
        // {
        //     title: "Action",
        //     className: "text-end",
        //     render: (text, asset) => (
        //         <Link
        //             className="dropdown-item1"
        //             to="#"
        //             onClick={() => handleDelete(asset.id)}
        //         >
        //             <i className="fa fa-trash m-r-5" />
        //         </Link>
        //     ),
        //     width: "10%",
        // },
    ];

    const maintenaceTableColumns = [
        {
            title: "S.No",
            width: "10%",
            render: (text, record, index) => index + 1, // Calculate serial number based on row index
        },
        {
            title: "Type",
            dataIndex: "maintenance_type",
            width: "40%",
        },
        {
            title: "Date",
            dataIndex: "maintenance_date",
            width: "40%",
        },
        {
            title: "Cost",
            dataIndex: "maintenance_cost",
            width: "40%",
        },
        {
            title: "Remark",
            dataIndex: "description",
            width: "40%",
        },
    ];


    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal custom-modal-three">
            <Modal.Header>
                <Modal.Title>{'Asset History'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <div className="row">
                    <div className="col-12">
                        <div className="card bg-white">
                            <div className="card-body">
                                <ul className="nav nav-tabs nav-tabs-top">
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link active"
                                            to="#top-tab1"
                                            data-bs-toggle="tab"
                                        >
                                            Asset
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="#top-tab2" data-bs-toggle="tab">
                                            Mainteance
                                        </Link>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane show active" id="top-tab1">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card data-table-card">
                                                    <div className="card-body">
                                                        <div className="table-responsive">
                                                            {AssetHistory?.length === 0 && (
                                                                <div className="no-data-message">
                                                                    No data available
                                                                </div>
                                                            )}
                                                            <Table
                                                                className="table-stripped"
                                                                columns={columns}
                                                                dataSource={
                                                                    AssetHistory?.length > 0
                                                                        ? AssetHistory?.map((asset) => ({
                                                                            ...asset,
                                                                            key: asset.id,
                                                                        }))
                                                                        : []
                                                                }
                                                                pagination={false}
                                                                // style={{
                                                                //     height: AssetHistory?.length > 0 ? 'auto' : '0px',
                                                                //     visibility: AssetHistory?.length > 0 ? 'visible' : 'hidden', // Adjust width based on data
                                                                //     transition: 'width 0.3s ease', // Optional transition for smooth effect
                                                                // }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="top-tab2">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card data-table-card">
                                                    <div className="card-body">
                                                        <div className="table-responsive">
                                                            {MaintenanceHistory?.length === 0 && (
                                                                <div className="no-data-message">
                                                                    No data available
                                                                </div>
                                                            )}
                                                            <Table
                                                                className="table-stripped"
                                                                columns={maintenaceTableColumns}
                                                                dataSource={
                                                                    MaintenanceHistory?.length > 0
                                                                        ? MaintenanceHistory?.map((asset) => ({
                                                                            ...asset,
                                                                            key: asset.id,
                                                                        }))
                                                                        : []
                                                                }
                                                                pagination={false}
                                                                // style={{
                                                                //     height: MaintenanceHistory?.length > 0 ? 'auto' : '0px',
                                                                //     visibility: MaintenanceHistory?.length > 0 ? 'visible' : 'hidden', // Adjust width based on data
                                                                //     transition: 'width 0.3s ease', // Optional transition for smooth effect
                                                                // }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                    </Modal.Footer>
        </Modal>
    );
}

export default AssetHistory;
