import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { removeErrorMsg,SubmitAsset } from "../../../../redux/assetSlice";
import { useDispatch, useSelector } from 'react-redux';
import Alert from "../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { customStyles } from "../../../../helpers/Constants";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { format, parseISO } from 'date-fns';
import { getEmployee } from '../../../../redux/commonSlice';

const validationSchema = Yup.object().shape({

    user_id: Yup.string().required("User is required"),

    date_of_allotment: Yup.string().required("Date of allotement is required"),
});



function SubmitAssets({ show, handleClose, assetDisbursment,assetId }) {
    const dispatch = useDispatch();
    const {
        loading = false,
        error = "",
    } = useSelector((state) => state.asset || {});


    useEffect(() => {
        dispatch(getEmployee());

      }, [dispatch]);

    const { employee } = useSelector((state) => state.common);

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            user_id: "",
            date_of_allotment: "",
            date_of_submission:"",
            comments:"",
        },
    });

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const onSubmit = async (values) => {
        try {
                values.asset_id = assetId ? assetId : '';
                values.disbursment_id = assetDisbursment?.id ? assetDisbursment.id : '';
                await dispatch(SubmitAsset(values));

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (assetDisbursment) {
            reset({
                user_id: assetDisbursment?.user_id ? assetDisbursment?.user_id:"",
                date_of_allotment: assetDisbursment?.date_of_allotment ?assetDisbursment?.date_of_allotment:"",
            });
        } else {
            reset({
            user_id: "",
            date_of_allotment: "",
            date_of_submission:"",
            comments:"",
            });
        }

    }, [assetDisbursment, reset]);


    const handleModalClose = () => {
        resetMsg();
        reset({
            user_id: "",
            date_of_allotment: "",
            date_of_submission:"",
            comments:"",
            });
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{'Submit Asset'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                     <div className="col-12">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Assign To User :
                                </label>
                                <Controller
                                    name="user_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={employee} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={employee?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.user_id ? "error-input" : ""}`}
                                            isDisabled={true}
                                        />
                                    )}
                                />
                                {errors.user_id && (
                                    <span className="text-danger">
                                        {errors.user_id.message}
                                    </span>
                                )}
                            </div>
                     </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Date of Allotement :
                                </label>
                                <Controller
                                    name='date_of_allotment'
                                    control={control}
                                    render={({ field }) => (
                                        <div className="cal-icon">
                                            <DatePicker
                                                className={`form-control w-100 ${field.value ? '' : 'error-input'}`}
                                                selected={field.value ? parseISO(field.value) : null}
                                                onChange={(date) => field.onChange(date ? format(date, "yyyy-MM-dd") : "")}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select a date"
                                                disabled
                                            />
                                        </div>
                                    )}
                                />
                                {errors.date_of_allotment && (
                                    <span className="text-danger">
                                        {errors.date_of_allotment.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Date of Submission :
                                </label>
                                <Controller
                                    name='date_of_submission'
                                    control={control}
                                    render={({ field }) => (
                                        <div className="cal-icon">
                                            <DatePicker
                                                className={`form-control w-100 ${field.value ? '' : 'error-input'}`}
                                                selected={field.value ? parseISO(field.value) : null}
                                                onChange={(date) => field.onChange(date ? format(date, "yyyy-MM-dd") : "")}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select a date"
                                            />
                                        </div>
                                    )}
                                />
                                {errors.date_of_submission && (
                                    <span className="text-danger">
                                        {errors.date_of_submission.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="input-block mb-3">
                            <label className="col-form-label">
                                    Comments :
                                </label>
                                <Controller
                                    name="comments"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                        className={`form-control ${errors.comments ? "error-input" : ""}`}
                        rows="2" // Set an initial height with rows
                        style={{ resize: "vertical" }} // Allow vertical resizing
                        {...field}
                    />
                                    )}
                                />
                                {errors.comments && (
                                    <span className="text-danger">{errors.comments.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Save Changes
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default SubmitAssets;
