import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addEditSubscription, removeErrorMsg } from "../../../../redux/subscriptionSlice";
import { useDispatch, useSelector } from 'react-redux';
import Alert from "../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { customStyles } from "../../../../helpers/Constants";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { format, parseISO } from 'date-fns';
import { getEmployee, getSoftwares } from '../../../../redux/commonSlice';


const validationSchema = Yup.object().shape({
    owned_by: Yup.string().required("Employee is required"),
    software: Yup.string().required("Software is required"),
    user_id: Yup.string().required("User Id is required"),
    password: Yup.string().required("Password is required"),
    subscription_charges: Yup.string().required("Subscription is required"),
    date_of_renewal: Yup.string().required("Date is required"),
});

function AddEditSubscription({ show, handleClose, subscription }) {
    const {
        loading = false,
        error = "",
    } = useSelector((state) => state.subscription || {});

    const dispatch = useDispatch();

    const { employee, softwares } = useSelector((state) => state.common);

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            owned_by: "",
            software: "",
            user_id: "",
            password: "",
            subscription_charges: "",
            date_of_renewal: "",
        },
    });

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const onSubmit = async (values) => {
        try {
            if (subscription) {
                values.subscription_id = subscription.id;
                await dispatch(addEditSubscription(values));

            } else {
                await dispatch(addEditSubscription(values));
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        if (subscription) {
            reset({
                owned_by: subscription?.owned_id || "",
                software: subscription?.software_id || "",
                user_id: subscription?.login_id || "",
                password: subscription?.password || "",
                subscription_charges: subscription?.subscription_charges || "",
                date_of_renewal: subscription?.date_of_renewal || ""
            });
        } else {
            reset({
                owned_by: "",
                software: "",
                user_id: "",
                password: "",
                subscription_charges: "",
                date_of_renewal: "",
            });
        }

    }, [subscription, reset]);

    useEffect(() => {
        dispatch(getEmployee());
        dispatch(getSoftwares());
    }, [dispatch]);

    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    const softwareOptions = softwares?.map(g => ({
        value: g.id,
        label: g.software_name,
    }));

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{subscription ? 'Edit Subscription' : 'Add Subscription'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>

                    {/* employee and software  */}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Select Employee :
                                </label>
                                <Controller
                                    name="owned_by"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={employee} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={employee?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.owned_by ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.owned_by && (
                                    <span className="text-danger">
                                        {errors.owned_by.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Select Software:
                                </label>
                                <Controller
                                    name="software"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={softwareOptions} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={softwareOptions?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.software ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.software && (
                                    <span className="text-danger">
                                        {errors.software.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* userid and password */}
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    User Id <span className="text-danger">*</span>
                                </label>
                                <Controller
                                    name="user_id"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.user_id ? "error-input" : ""}`}
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.user_id && <span className="text-danger">{errors.user_id.message}</span>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Password :
                                </label>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.password ? "error-input" : ""}`}
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.password && (
                                    <span className="text-danger">
                                        {errors.password.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* subscription charges end date of renewal  */}
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Subscription Charges <span className="text-danger">*</span>
                                </label>
                                <Controller
                                    name="subscription_charges"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.subscription_charges ? "error-input" : ""}`}
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.subscription_charges && <span className="text-danger">{errors.subscription_charges.message}</span>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Date of Renewal
                                </label>
                                <div className="cal-icon">
                                    <Controller
                                        name={`date_of_renewal`}
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                isClearable
                                                className={`form-control w-100 ${field.value ? '' : 'error-input'}`}
                                                selected={field.value ? parseISO(field.value) : null}
                                                onChange={(date) => field.onChange(date ? format(date, "yyyy-MM-dd") : "")}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select a date"
                                            // readOnly={hasId && hasId > 0 ? true : false}
                                            />
                                        )}
                                    />
                                    {errors.date_of_renewal && (
                                        <span className="text-danger">{errors.date_of_renewal.message}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Save Changes
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AddEditSubscription;
