import React from "react";

const AchievementTable = ({ achievements, viewonly }) => {
    return (
        <div className="table-responsive">
            <table className="table rating-table project-table">
                <tbody>
                    <tr className="table-active">
                        <th>S. No.</th>
                        <th>Description</th>

                        <th>Appraiser Rating</th>
                        <th>Appraiser Comments</th>
                    </tr>

                    {achievements?.map((eachA, key) => (
                        <React.Fragment key={eachA.id}>
                            <input type="hidden" value={eachA.id} name="achievement_answer_id[]" />
                            <tr>
                                <td style={{ borderBottom: "1px solid lightgrey" }}>1</td>
                                <td style={{ borderBottom: "1px solid lightgrey" }}>
                                    <textarea
                                        className="disable-inputs"
                                        placeholder="Description"
                                        style={{ width: "100%", boxSizing: "border-box" }}
                                        rows="1"
                                        defaultValue={eachA.description}
                                        disabled
                                    />
                                </td>
                                {/* <td style={{ borderBottom: "1px solid lightgrey" }}>
                                <input
                                    className="disable-inputs"
                                    type="number"
                                    placeholder="Self Rating"
                                    style={{ width: "100%", boxSizing: "border-box" }}
                                    defaultValue={eachA.self_rating}
                                    min="1"
                                    max="10"
                                    readOnly
                                />
                            </td>
                            <td style={{ borderBottom: "1px solid lightgrey" }}>
                                <textarea
                                    className="disable-inputs"
                                    placeholder="Self Comments"
                                    style={{ width: "100%", boxSizing: "border-box" }}
                                    rows="1"
                                    disabled={viewonly}
                                    defaultValue={eachA.self_comments}
                                    readOnly
                                />
                            </td> */}
                                <td style={{ borderBottom: "1px solid lightgrey" }}>
                                    <input
                                        className="disable-inputs"
                                        type="number"
                                        placeholder="Appraiser Rating"
                                        name={`achievement_appraiser_rating[]`}
                                        style={{ width: "100%", boxSizing: "border-box" }}
                                        defaultValue={eachA.appraiser_rating}
                                        min="1"
                                        disabled={viewonly}
                                        max="10"

                                    />
                                </td>
                                <td style={{ borderBottom: "1px solid lightgrey" }}>
                                    <textarea
                                        className="disable-inputs"
                                        placeholder="Appraiser Comments"
                                        name={`achievement_appraiser_comment[]`}
                                        style={{ width: "100%", boxSizing: "border-box" }}
                                        rows="1"
                                        disabled={viewonly}
                                        defaultValue={eachA.appraiser_comments}

                                    />
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>  </div>
    );
};

export default AchievementTable;