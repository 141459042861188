import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addCustomer, removeErrorMsg, updateCustomer } from "../../../../../redux/customerSlice";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alert from "../../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// Validation Schema
const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    code: Yup.string().required("Code is required"),
    region: Yup.string().required("Region is required"),
    industry: Yup.string().required("Industry is required"),
});

function AddEditCustomer({ show, handleClose, customer }) {
    //console.log(customer,'customer')
    const { loading, error, success } = useSelector((state) => state.customer);
    const dispatch = useDispatch();
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            id:"",
            name: "",
            code: "",
            region: "",
            industry: "",
        },
    });

    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    // Handle form submission
    const onSubmit = async (values) => {
        try {
            if (customer) {
                // If editing an existing customer, add the customer ID to the values
                values.id = customer.id;
                await dispatch(updateCustomer(values)); // Dispatch update
            } else {
                // If adding a new customer, dispatch addCustomer
                await dispatch(addCustomer(values));
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Reset the form based on customer data
    useEffect(() => {
        if (customer) {
            reset({
                code: customer.code || "",
                name: customer.name || "",
                region: customer.region || "",
                industry: customer.industry || "",
            });
        } else {
            reset({
                code: "",
                name: "",
                region: "",
                industry: "",
            });
        }
    }, [customer, reset]);

    // Handle modal close
    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{customer ? 'Edit Customer' : 'Add Customer'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-block mb-3">
                    <Controller
                            name="id"
                            control={control}
                            render={({ field }) => (
                                <input name="id"
                                value={customer?.id}
                                    className={`form-control ${errors.name ? "error-input" : ""}`}
                                    type="hidden"
                                    {...field}
                                />
                            )}
                        />


                        <label className="col-form-label">
                            Name <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <input name="name"
                                    className={`form-control ${errors.name ? "error-input" : ""}`}
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        {errors.name && <span className="text-danger">{errors.name.message}</span>}
                    </div>
                    <div className="input-block mb-3">
                        <label className="col-form-label">
                            Client Code <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="code"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${errors.code ? "error-input" : ""}`}
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        {errors.code && <span className="text-danger">{errors.code.message}</span>}
                    </div>

                    <div className="input-block mb-3">
                        <label className="col-form-label">
                            Region <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="region"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${errors.region ? "error-input" : ""}`}
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        {errors.region && <span className="text-danger">{errors.region.message}</span>}
                    </div>

                    <div className="input-block mb-3">
                        <label className="col-form-label">
                            Industry <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="industry"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${errors.industry ? "error-input" : ""}`}
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        {errors.industry && <span className="text-danger">{errors.industry.message}</span>}
                    </div>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Save Changes
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AddEditCustomer;
