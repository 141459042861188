import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProjectsFilter from "./ProjectFilter";
import Breadcrumbs from "../../../Breadcrumbs";
import { Table } from "antd";
import DeleteModal from "./DeleteModal";
import { getProjectTasks, deleteProjectTask } from "../../../../redux/projectTaskSlice";
import { useDispatch, useSelector } from 'react-redux';


const ProjectTask = () => {

  const dispatch = useDispatch();
  const { loading, success, error, projectTasks, pagination } = useSelector((state) => state.projectTask);
  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10,
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);
  const [projectTaskDetails, setProjectTaskDetails] = useState(null);

  useEffect(() => {
    dispatch(getProjectTasks({ page: paginationState.current, per_page: paginationState.pageSize,search:null}));
  }, [dispatch, paginationState]);

  const projectTasklist = projectTasks?.map((row, index) => ({
    key: index,
    id: row.id,
    project_task: row.project? row.project.project_code + ' ' + row.project.project_name :'N/A',
    activity_code: row.activity_code ? row.activity_code : 'N/A',
    task: row.activity ? row.activity_code + ' ' + row.activity:'N/A' ,
    assigned_to: row.user.name ? row.user.name : '' ,
    submitted: row.submitted ? row.submitted:'',
    shortlisted :row.shortlisted ? row.shortlisted:"",
    closed: row.closed ? row.closed : "",
    status: row.status ? (row.status == 1 ? "Active":"Inactive"):"",
  }));

  const columns = [
    {
      title: "Project",
      dataIndex: "project_task",
      sorter: (a, b) => a.project_task.length - b.project_task.length,
    },
    {
      title: "Sl. No",
      dataIndex: "activity_code",
      sorter: (a, b) => a.activity_code.length - b.activity_code.length,
    },
    {
      title: "Task",
      dataIndex: "task",
      sorter: (a, b) => a.task.length - b.task.length,
    },
    {
      title: "Assigned To",
      dataIndex: "assigned_to",
      sorter: (a, b) => a.assigned_to.length - b.assigned_to.length,
    },
    // {
    //   title: "Submitted",
    //   dataIndex: "submitted",
    //   sorter: (a, b) => a.submitted.length - b.submitted.length,
    // },
    // {
    //   title: "Shortlisted",
    //   dataIndex: "shortlisted",
    //   sorter: (a, b) => a.shortlisted.length - b.shortlisted.length,
    // },
    // {
    //   title: "Closed",
    //   dataIndex: "closed",
    //   sorter: (a, b) => a.closed.length - b.closed.length,
    // },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      className: "text-end",
      render: (row) => (
        <div className="dropdown dropdown-action text-end">
          <div className="dropdown dropdown-action text-end me-30">
            {/* <Link
                className="dropdown-item1 m-r-10"
                to={"/project/time-sheet-task/manager-view/"+row.id}
              >
                <i className="fa fa-eye m-r-5" />
            </Link> */}
            <Link
              className="dropdown-item1 m-r-10"
              to={'/project/editTask/'+row.id}
            >
              <i className="fa fa-pencil m-r-5" />
            </Link>
            <Link
              className="dropdown-item1 m-r-10"
              to="#"
              onClick={() => deleteModal(row)}
            >
              <i className="fa fa-trash m-r-5" />
            </Link>
        </div>
        </div>
      ),
      sorter: (a, b) => a.length - b.length,
      width: "10%",
    },
  ];

  const deleteModal = (data)=> {
    setProjectTaskDetails(data);
    handleDeleteShow()
  }

  const deleteUserData = ()=> {
    handleDeleteClose();
    dispatch(deleteProjectTask(projectTaskDetails.id));
    dispatch(getProjectTasks({ page: paginationState.current, per_page: paginationState.pageSize,search:null}));
  }

  const onSearch = (search)=>{
    // console.log(search, 'Search')
    dispatch(getProjectTasks({ page: paginationState.current, per_page:  paginationState.pageSize ,search}));
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <Breadcrumbs
                maintitle="Project Task"
                title="Dashboard"
                subtitle="Project Task"
              />

              <div className="col-auto float-end ms-auto">

                <Link
                  to="/project/addTask"
                  className="btn btn-primary  me-3"
                  on
                  fdprocessedid="as7mtr"
                >
                  {" "}
                  <i className="fa fa-plus" /> Add Project Activity
                </Link>
              </div>
            </div>
          </div>

          {/* /Page Header */}
          <ProjectsFilter onSearch={onSearch}/>
          <div className="row">
            <div className="col-md-12">
              {/* <SearchBox /> */}

              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={projectTasklist}
                  className="table table-striped custom-table datatable dataTable no-footer"
                  rowKey={(record) => record.id}
                  pagination={{
                    current: paginationState.current,
                    pageSize: paginationState.pageSize,
                    total: pagination?.total,
                    onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                  }}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal show={showDeleteModal} handleClose={handleDeleteClose} onDelete={deleteUserData}  name="Delete Project Task" />
    </>
  );
};

export default ProjectTask;
