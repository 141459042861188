import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import DeleteModal from "./model/DeleteModel";
import { useDispatch, useSelector } from 'react-redux';
import { getAll, deleteData, removeErrorMsg } from '../../../redux/companySlice';
import { getCountries, getTimezones } from '../../../redux/commonSlice';
// import AddEdit from './model/AddEdit';
import Filter from "./Filter";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Companies = () => {
  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false)

  const is_rootadmin = localStorage.getItem('is_root_admin');

  const [designationDetails, setDesignationDetails] = useState(null);
  const { allData, loading, pagination, success, notfound, search } = useSelector((state) => state.company);
  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10,
  });
  useEffect(() => {
    dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
  }, [dispatch, paginationState.current, paginationState.pageSize]);
  console.log(allData, '0099')
  const userElements = allData?.map((data, index) => ({
    key: index,
    id: data.id,
    name: data.name,
    email: data.email,
    phone: data.phone,
    address: data.address,
    timezone: data?.timezone?.abbreviation,
    country: data?.country?.name,
    logo_url: data.logo_url,
    is_default: data.is_default


  }));

  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);

  const MySwal = withReactContent(Swal);

  const showAlert = (config) => {
    MySwal.fire(config);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      sorter: (a, b) => a.key.length - b.key.length,
      width: "10%",
    },

    {
      title: "Company Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      width: "20%",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to="#" className="avatar">
            <img alt="" src={record.logo_url} />
          </Link>
          {text}
        </span>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.id.length - b.id.length,
      width: "20%",
    },


    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) => a.id.length - b.id.length,
      width: "20%",
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.id.length - b.id.length,
      width: "5%",
    },


    {
      title: "Timezone",
      dataIndex: "timezone",
      sorter: (a, b) => a.timezone.length - b.timezone.length,
      width: "20%",
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: (a, b) => a.country.length - b.country.length,
      width: "20%",
    },
    {
      title: "Primary",
      dataIndex: "is_default",
      sorter: (a, b) => a.name.length - b.name.length,
      width: "20%",
      render: (text, record) => (
        <span>
          {record.is_default == 1 ? 'yes' : ""}
        </span>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Action",
      className: "text-end",
      render: (text, data) => (
        <div className="dropdown dropdown-action text-end me-30">
          <Link to={`/companies/edit/${data.id}`}

            className="dropdown-item1 m-r-10"


          >
            <i className="fa fa-pencil m-r-5" />
          </Link>

          {!data.is_default ? (
            <Link
              className="dropdown-item1 m-r-10"
              to="#"
              onClick={() => deleteModal(data)}
            >
              <i className="fa fa-trash m-r-5" />
            </Link>
          ) : (
            <Link
              className="dropdown-item1 m-r-10"
              to="#"

            >
              &nbsp;  &nbsp;  &nbsp;
            </Link>

          )}

        </div>
      ),
      sorter: (a, b) => a.length - b.length,
      width: "10%",
    },
  ];

  const haldleModel = () => {
    setOpenModel(!openModel)
  }
  const addModal = (data) => {
    setDesignationDetails(data);
    handleShow()
  }

  const deleteModal = (data) => {
    setDesignationDetails(data);
    handleDeleteShow()
  }

  const deleteDesignationData = (data) => {
    handleDeleteClose();
    dispatch(deleteData(data.id));
  }

  useEffect(() => {
    console.log('Het');
    if (success) {
      showAlert({
        title: 'Company',
        text: success,
        confirmButtonColor: '#FF902F',
        confirmButtonText: 'OK',
      });
      dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
      handleClose();
      handleDeleteClose();
    }

    if (notfound) {
      showAlert({
        title: 'Company',
        text: 'Company Not found',
        confirmButtonColor: '#FF902F',
        confirmButtonText: 'OK',
      });
      dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
      dispatch(removeErrorMsg());

      //  handleClose();
      //handleDeleteClose();
    }
  }, [success, notfound, showAlert]);


  const onSearch = (search) => {
    dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
    console.log(search, 'pp');
  }
  useEffect(() => {
    dispatch(getCountries());
    dispatch(getTimezones());
  }, [dispatch]);
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <Breadcrumbs
                maintitle="Company"
                title="Dashboard"
                subtitle="Company"
              />
              <div className="col-auto float-end ms-auto">
                <Link to="/companies/add" className="btn btn-primary">
                  <i className="fa fa-plus" /> Add Company
                </Link>

                {/* <button type="button" class="btn btn-primary" on fdprocessedid="as7mtr" onClick={() => addModal(null)}>   <i className="fa fa-plus" /> Add Client</button> */}

              </div>
            </div>
          </div>
          <Filter onSearch={onSearch} />
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0 data-table-card">

                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-stripped"
                      columns={columns}
                      rowKey={(record) => record.id}
                      dataSource={
                        userElements?.length > 0
                          ? userElements?.map((designation) => ({
                            ...designation,
                            key: designation.id,
                          }))
                          : []
                      }
                      pagination={{
                        current: paginationState.current,
                        pageSize: paginationState.pageSize,
                        total: pagination?.total,
                        onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AddEdit show={showModal} handleClose={handleClose} data={designationDetails} /> */}
      <DeleteModal show={showDeleteModal} handleClose={handleDeleteClose} data={designationDetails} onDelete={deleteDesignationData} name="Delete Company" />
    </div>
  );
};

export default Companies;
