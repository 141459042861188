import React from 'react';

const Alert = ({ type, message, onClose }) => {
  const alertTypes = {
    primary: 'alert-primary',
    secondary: 'alert-secondary',
    warning: 'alert-warning',
    danger: 'alert-danger',
    success: 'alert-success',
  };

  const icons = {
    primary: 'feather-info',
    secondary: 'feather-check-circle',
    warning: 'feather-alert-triangle',
    danger: 'feather-alert-octagon',
  };

  return (
    <div
      className={`alert ${alertTypes[type]} alert-dismissible fade show custom-alert-icon shadow-sm d-flex align-items-center`}
      role="alert"
    >
      <i className={`${icons[type]} flex-shrink-0 me-2`} />
      {message}
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={onClose}
      >
        <i className="fas fa-xmark" />
      </button>
    </div>
  );
};
export default Alert
