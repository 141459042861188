

import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { add, removeErrorMsg, update, updatequestion, addquestion } from "../../../../../redux/pemQuestionSlice";
import { getPemCategories, getPemSubCatType } from "../../../../../redux/commonSlice";

import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alert from "../../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import FormModal from '../../../../Common/FormModal'; // Import your FormModal component

const AddEdit = ({ show, handleClose, data, type }) => {

    const { loading, error, success, categories } = useSelector((state) => state.pemquestion);
    const { pemcategories, pemsubcattype } = useSelector((state) => state.common);
    const categoryOptions = pemcategories?.map(g => ({
        value: g.id,
        label: g.category_name + "  [" + g.category_code + "]",
    }));

    const typeOptions = pemsubcattype?.map(g => ({
        value: g.id,
        label: g.name,
    }));
    const dispatch = useDispatch();
    const handleModalClose = () => {
        resetMsg();
        handleClose();
    }; const resetMsg = () => {
        dispatch(removeErrorMsg());
    };



    const handleSubmit = async (values) => {
        try {
            // Initialize FormData
            const formData = new FormData();

            // Loop through the form values to append to formData
            Object.entries(values).forEach(([key, value]) => {
                if (key === 'logo' && value) {
                    formData.append(key, value);
                } else if (key !== 'logo') {
                    formData.append(key, value);
                }
            });

            // If data exists, it's an update; otherwise, it's a create operation
            const method = data ? 'put' : 'post';
            if (data) {
                formData.append('_method', method);  // Laravel requires a method for PUT
                formData.append('id', data.id);
            }

            // Handle submission based on type
            if (type === 'question') {
                if (data) {
                    await dispatch(updatequestion(formData, data.id)); // Update question
                } else {
                    await dispatch(addquestion(formData)); // Add question
                }
            } else if (type === 'category') {
                if (data) {
                    await dispatch(update(formData, data.id)); // Update category
                } else {
                    await dispatch(add(formData)); // Add category
                }
                // Reload categories after adding/updating
                dispatch(getPemCategories());
                handleModalClose();
            }

            // Close modal after submission
            // 

        } catch (error) {
            console.error('Error during submission:', error);
            // Handle error: Close modal and log the error
            handleModalClose();
        }
    };

    let title = data ? 'Edit Category' : 'Add Category';

    let fieldConfig = [{ name: "category_name", full: true, placeholder: "Category ", label: "Category", type: "text", validation: Yup.string().required("Category  is required") },
    ];

    let dialogClassName = "modal custom-modal";

    if (type == 'question') {
        dialogClassName = "modal custom-modal-three custom-modal";
        fieldConfig = [
            {
                name: "pem_question_category_id", label: "Category",
                type: "select",
                options: categoryOptions,
                validation: Yup.string().required("Category is required")

            },
            { name: "parameter", full: false, placeholder: "Parameter ", label: "Category", type: "text", validation: Yup.string().required("Parameter  is required") }
            , {
                name: "type", label: "Type",

                type: "select",
                options: typeOptions,
                validation: Yup.string().required("Type is required")

            },

            {
                name: "is_system_generate",
                label: "Is system generate",
                type: "checkbox", // Set the type to "checkbox"
                // No validation required, making the checkbox optional
            },

            {
                name: "description",
                label: "Description",
                type: "textarea",
                full: true,
                validation: Yup.string().required("Description is required")
            }

        ];
        title = data ? 'Edit Question' : 'Add Question';






    }



    useEffect(() => {
        dispatch(getPemCategories());
        dispatch(getPemSubCatType());


    }, [dispatch]);
    return (
        <>
            <Modal show={show} onHide={handleModalClose} centered dialogClassName={dialogClassName}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    <button type="button" className="btn-close" onClick={handleModalClose}>
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {/* {error && (
                        <Alert
                            type="danger"
                            message={error}
                            onClose={resetMsg}
                        />
                    )} */}
                    <FormModal
                        error={error}
                        loading={loading}
                        removeErrorMsg={removeErrorMsg}
                        data={data}
                        fieldConfig={fieldConfig}
                        onSubmit={handleSubmit}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddEdit;
