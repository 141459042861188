import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = (props) => {
  const location = useLocation();
  let addButton = null;
  addButton = (
    // <div className="row align-items-center">
      <div className="col">
        <h3 className="page-title">{props.maintitle}</h3>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/admin-dashboard">{props.title}</Link>
          </li>
          <li className="breadcrumb-item active">{props.subtitle}</li>
        </ul>
      </div>
    // </div>
  );
  
  return (
    <>
     {addButton}
    </>
  );
};

export default Breadcrumbs;
