// components/Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom'; // Used to render child routes

import Header from './Header';
import Sidebar from './Sidebar';
import OffCanvas from './OffCanvas';

const Layout = () => {
  return (
    <div>
      <header>
      <Header/>
      </header>
      
      {/* <aside> */}
        <Sidebar/>
      {/* </aside> */}

      <main>
        <Outlet />
      </main>

      <OffCanvas/>

      {/* <footer>
        <p>Footer</p>
      </footer> */}
    </div>
  );
};

export default Layout;
