import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alert from "../../helpers/AlertMessage";
import { customStyles } from "../../helpers/Constants";

import { profileuploadimg } from '../../Routes/ImagePath';

const FormModal = ({ removeErrorMsg, data, fieldConfig, onSubmit, loading, error, CustomFormComponent, disabled }) => {
    const dispatch = useDispatch();
    // console.log('Rendering CustomFormComponent:', CustomFormComponent);
    const [profileImagePreview, setProfileImagePreview] = useState(null);
    const [profileImageFile, setProfileImageFile] = useState(null);
    const [imageError, setImageError] = useState("");
    const fileInputRef = useRef(null); // Create a ref for the file input




    const validationSchema = Yup.object(
        fieldConfig.reduce((schema, field) => {
            if (field.validation) {
                schema[field.name] = field.validation;
            }
            return schema;
        }, {})
    );

    const { control, setValue, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: fieldConfig.reduce((values, field) => {
            if (field.type !== 'file') {

                values[field.name] = data ? data[field.name] || "" : "";
            }

            return values;
        }, {})
    });

    const MySwal = withReactContent(Swal);

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImageError(""); // Reset any previous error

        if (file) {
            if (file.size > 2000000) {
                setImageError("The file is too large. Maximum size is 2 MB.");
                setProfileImagePreview(null); // Reset preview
                return;
            }

            if (!["image/jpg", "image/jpeg", "image/png"].includes(file.type)) {
                setImageError("Unsupported file format. Only JPG and PNG are allowed.");
                setProfileImagePreview(null); // Reset preview
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            setProfileImageFile(file);

            // const file = event.target.files[0]; // Get the first file selected
            // setValues(prevValues => ({
            //     ...prevValues,
            //     logo: file // Update the state with the file
            // }));

        } else {
            setProfileImagePreview(null); // Reset if no file is selected
            setProfileImageFile(null);
        }
    };

    const handleModalClose = () => {
        resetMsg();
        // handleClose();
        setProfileImagePreview(null); // Reset the image preview
    };

    return (
        <>
            {error && (
                <Alert
                    type="danger"
                    onClose={resetMsg}
                    message={
                        <div>
                            {error.split(',').map((msg, index) => (
                                <div key={index}>{msg.trim()}</div>
                            ))}
                        </div>
                    }
                />
            )}



            {imageError && <Alert type="danger" message={imageError} onClose={() => setImageError("")} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    {fieldConfig.map((field) => (
                        <div className={field.type === "file" || field.full ? 'col-md-12 mb-3' : field.block ? 'col-md-3 mb-3'
                            : 'col-md-6 mb-3'} key={field.name}>
                            <label className={field.type === "file" ? 'd-none' : 'col-form-label'}>
                                {field.label} {field.validation?.tests.some(test => test.OPTIONS.name === "required") && <span className="text-danger">*</span>}
                            </label>
                            <Controller
                                name={field.name}
                                control={control}
                                render={({ field: inputField }) => (
                                    field.type === "selectmulitple" ? (


                                        <Select
                                            {...inputField}
                                            styles={customStyles}
                                            isMulti
                                            disabled={disabled || false}
                                            options={field.options} // Should be an array like [{ value: "1", label: "T001 [WEB]" }]
                                            placeholder="Select"


                                            className={`input-block mb-3 ${errors[field.name] ? "error-input" : ""}`}

                                        />

                                    )


                                        :

                                        field.type === "select" ? (
                                            <select
                                                disabled={disabled || false}
                                                className={`form-control ${errors[field.name] ? "error-input" : ""}`}
                                                {...inputField}


                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    inputField.onChange(e); // Update form state
                                                    field.onChange && field.onChange(selectedValue);

                                                    if (field.onChange && field.dependent)
                                                        setValue(field.dependent, []);

                                                    // Call the custom callback
                                                }}

                                            >
                                                <option value="">Select {field.label}</option>
                                                {field.options && field.options.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>



                                        )








                                            : field.type === "file" ? (
                                                <div className="form-upload-profile">
                                                    <h6>{field.label} {field.validation?.tests.some(test => test.OPTIONS.name === "required") && <span className="text-danger">*</span>}</h6>
                                                    <div className="profile-pic-upload">
                                                        {profileImagePreview ? (
                                                            <div className="profile-pic">
                                                                <span><img src={profileImagePreview} alt="Profile Preview" /></span>
                                                            </div>
                                                        ) : (
                                                            <div className="profile-pic">
                                                                <span><img src={profileuploadimg} alt="Img" /></span>
                                                            </div>
                                                        )}
                                                        <div className="employee-field">
                                                            <div className="mb-0">
                                                                <div className="image-upload mb-0">
                                                                    <input
                                                                        ref={fileInputRef} // Attach the ref here
                                                                        onChange={(e) => {
                                                                            inputField.onChange(e);
                                                                            handleImageChange(e);
                                                                            const fileList = e.target.files;
                                                                            if (fileList.length > 0) {
                                                                                // Set the file directly in the form state
                                                                                setValue(field.name, fileList[0]);
                                                                            }
                                                                        }}
                                                                        type="file"
                                                                        accept="image/*"
                                                                        className={`form-control ${errors[field.name] ? "error-input" : ""}`}
                                                                    />
                                                                    <div className="image-uploads">
                                                                        <h4>Upload</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="img-reset-btn">
                                                                {profileImagePreview && (
                                                                    <Link to="#" onClick={() => setProfileImagePreview(null)}>Reset</Link>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )




                                                : field.type === "checkbox" ? (
                                                    <div className="form-check">
                                                        <input
                                                            disabled={disabled || false}
                                                            defaultChecked={inputField.value}
                                                            type="checkbox"
                                                            className={`form-check-input ${errors[field.name] ? "error-input" : ""}`}
                                                            {...inputField}
                                                        />
                                                        <label className="form-check-label">{field.label}</label>
                                                    </div>
                                                )
                                                    : field.type === "date" ? (
                                                        <input
                                                            disabled={disabled || false}
                                                            className={`form-control ${errors[field.name] ? "error-input" : ""}`}
                                                            {...inputField}
                                                            placeholder={field.placeholder || ""}
                                                            type="date"  // Use the "date" type for date fields
                                                        />
                                                    )

                                                        : field.type === "textarea" ? (
                                                            <textarea
                                                                disabled={disabled || false}
                                                                className={`form-control ${errors[field.name] ? "error-input" : ""}`}
                                                                {...inputField}
                                                                placeholder={field.placeholder || ""}
                                                                rows="4"
                                                            />
                                                        )







                                                            : (
                                                                <input
                                                                    disabled={disabled || false}
                                                                    className={`form-control ${errors[field.name] ? "error-input" : ""}`}
                                                                    {...inputField}
                                                                    placeholder={field.placeholder || ""}
                                                                    type={field.type}
                                                                />
                                                            )
                                )}
                            />
                            {errors[field.name] && <span className="text-danger">{errors[field.name].message}</span>}
                        </div>
                    ))}
                </div>
                {/* Render custom form component if provided */}


                <div className="col-md-12 mb-3">


                    {CustomFormComponent ? <CustomFormComponent /> : null}
                </div>


                <div className="text-end">
                    {/* <button type="submit" className="btn btn-primary">
                              <i className="fa fa-file" />  Submit
                              </button> */}

                    {!loading ? (
                        !disabled ? <button className="btn btn-primary" type="submit" >
                            Save Changes
                        </button> : null

                    ) :

                        (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                </div>
            </form >
        </>
    );
};

export default FormModal;
