import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
  grade: null,
  teams: null,
  last_id: null,
  designation: null,
  employee: null,
  manager: null,
  hr: null,
  users: null,
  dashboardstats: null,
  softwares: null,
  loading: false,
  error: null,
  success: null,
  message: null,
  contract_employess:null,
};

const commonSlide = createSlice({
  name: 'common',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    requestSuccess: (state, action) => {
      if (action.payload.type === 'grade') {
        state.grade = action.payload.data;
      } else if (action.payload.type === 'last_id') {
        state.last_id = action.payload.data;
      } else if (action.payload.type === 'team') {
        state.teams = action.payload.data;
      } else if (action.payload.type === 'all') {
        state.employee = action.payload.data;
      }
      else if (action.payload.type === 'Manager') {
        state.manager = action.payload.data;
      } else if (action.payload.type === 'Hr') {
        state.hr = action.payload.data;
      }
      else if (action.payload.type === 'templates') {
        state.templates = action.payload.data;
      }

      else if (action.payload.type === 'timezone') {
        state.timezones = action.payload.data;
      }
      else if (action.payload.type === 'profile') {
        state.profile = action.payload.data;
      }
      else if (action.payload.type === 'country') {
        state.countries = action.payload.data;
      }
      else if (action.payload.type === 'currency') {
        state.currencies = action.payload.data;
      }
      else if (action.payload.type === 'pemcategoriescommon') {
        state.pemcategories = action.payload.data;
      }
      else if (action.payload.type === 'pemsubcattype') {
        state.pemsubcattype = action.payload.data;
      }
      else if (action.payload.type === 'software') {
        state.softwares = action.payload.data;
      }
      else if (action.payload.type === 'dashboardstats') {
        state.dashboardstats = action.payload.data;
      }
      else if (action.payload.type === 'contract-employee') {
        state.contract_employess = action.payload.data;
      }
      else {
        state.designation = action.payload.data;
      }

      state.pagination = action.payload.pagination;
      state.loading = false;
    },
    resetAll: (state) => {
      state.grade = null;
      state.teams = null;
      state.last_id = null;
      state.timezones = null;
      state.profile = null;
      state.countries = null;
      state.currencies = null;
      state.designation = null;
      state.managers = null;
      state.pemcategories = null;
      state.pemsubcattype = null;
      state.templates = null;
      state.contract_employess=null;
      state.users = null;
      state.loading = false;
      state.error = null;
      state.success = null;
      state.message = null;
    },
    requestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
  },
});

export const {
  requestStart,
  requestSuccess,
  resetAll,
  requestFailure
} = commonSlide.actions;

export default commonSlide.reducer;

export const getGrade = () => async (dispatch, getState) => {

  // const { grade } = getState();
  // console.log(grade, 'grade');
  // if (grade && grade.length > 0) {
  //   console.log('I am in');
  //   return;
  // }
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/grade?page=1&per_page=1000`);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'grade' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {

    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(requestFailure(errorMessage));
  }
};
export const GetLastReimbursementId = () => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/reimbursement-request/last-id`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'code': 'super',
      },
    });

    // Extract the last ID from the response
    const { data } = response.data; // Assuming response.data contains the data

    if (data !== null) {
      dispatch(requestSuccess({ data, type: 'last_id' }));
    } else {
      dispatch(requestFailure('No data found'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};
export const getUserProfile = () => async (dispatch) => {
  dispatch(requestStart());
  try {


    const response = await axiosInstance.get(`/helper/get/user/profile`);
    const { data, status, message, pagination } = response.data;
    console.log(response.data)
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'profile' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {

    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(requestFailure(errorMessage));
  }
};

export const getDesignations = () => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/designation?page=1&per_page=1000`);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'designation' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const getEmployee = (type = "all", reporting_to = null) => async (dispatch) => {
  dispatch(requestStart());
  //type: all, role


  try {
    let response = await axiosInstance.get(`/users?type=${type}`);
    if (reporting_to)
      response = await axiosInstance.get(`/users?type=${type}&reporting_to=${reporting_to}`);



    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, pagination, type }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const getProjectEmployee = (project_ids) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/project-employee?project_ids=${project_ids}`);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'all' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const getTeams = () => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/teams?page=1&per_page=1000`);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'team' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};


export const getTimezones = () => async (dispatch) => {
  dispatch(requestStart());
  try {


    const response = await axiosInstance.get(`/helper/get/timezones`);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'timezone' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {

    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(requestFailure(errorMessage));
  }
};


export const getCountries = () => async (dispatch) => {
  dispatch(requestStart());
  try {


    const response = await axiosInstance.get(`/helper/get/countries`);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'country' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {

    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(requestFailure(errorMessage));
  }
};



export const getPemCategories = () => async (dispatch) => {
  dispatch(requestStart());
  try {


    const response = await axiosInstance.get(`/pem/goal/categeory?page=1&per_page=1000`);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'pemcategoriescommon' }));
    } else {

      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {

    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(requestFailure(errorMessage));
  }
};

export const getPemSubCatType = () => async (dispatch) => {
  dispatch(requestStart());
  try {


    const response = await axiosInstance.get(`/helper/get/pemsubcattype`);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'pemsubcattype' }));
    } else {

      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {

    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(requestFailure(errorMessage));
  }
};


export const getCurrency = () => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/helper/get/currency`);
    const { data, status, message, pagination } = response.data;
    console.log(response.data)
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'currency' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {

    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(requestFailure(errorMessage));
  }
};

export const getSoftwares = () => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/softwares`);
    const { data, status, message } = response.data;
    console.log(response.data)
    if (status) {
      dispatch(requestSuccess({ data, type: 'software' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {

    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(requestFailure(errorMessage));
  }
};

export const getDashboardStats = () => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/dashboard/stats`);
    const { data, status, message, pagination } = response.data;
    console.log(response.data)
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'dashboardstats' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {

    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(requestFailure(errorMessage));
  }
};

export const getTemplates = () => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/pem/template?per_page=999999999`);
    const { data, status, message, pagination } = response.data;
    console.log(response.data)
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'templates' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {

    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(requestFailure(errorMessage));
  }
};

export const getContractEmployess = () => async (dispatch) => {
    dispatch(requestStart());
    try {

      const response = await axiosInstance.get(`/helper/get/contract-employees`);
      const { data, status, message, pagination } = response.data;
      if (status) {
        dispatch(requestSuccess({ data, pagination, type: 'contract-employee' }));
      } else {
        dispatch(requestFailure(message || 'Failed to fetch data'));
      }
    } catch (error) {

      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
      dispatch(requestFailure(errorMessage));
    }
  };
