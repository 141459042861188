import React, { useEffect, useState } from "react";

import { Table } from "antd";
import Breadcrumbs from "../../../Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getTimeSheetDetails, downloadTimeSheetExcel } from "../../../../redux/reportSlice";

const title = "Time Sheet Details";

const TimeSheet = () => {
  const { date, user_id, project_id } = useParams();
  console.log(date, user_id, project_id);
  const dispatch = useDispatch();
  const { timeSheetData, timeSheetPagination, loading } = useSelector(
    (state) => state.report
  );
  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(
      getTimeSheetDetails({
        page: paginationState.current,
        per_page: paginationState.pageSize,
        date,
        employee_id: user_id,
        project_id: project_id != "off" ? project_id : "",
      })
    );
  }, [date, dispatch, paginationState, project_id, user_id]);

  const downloadExcel = () => {
    dispatch(downloadTimeSheetExcel({
        date,
        employee_id: user_id,
        project_id: project_id != "off" ? project_id : "",
      })
    );
  }

  const userElements = Object.values(timeSheetData || {}).map((row, index) => ({
    ind: (paginationState.current - 1) * paginationState.pageSize + index + 1,
    key: index,
    id: row.id,
    project_name: row?.project?.project_name ?? "N/A",
    project_code: row?.project?.project_code ?? "N/A",
    manager: row?.project?.manager?.name ?? "N/A",
    date: row.date ?? "N/A",
    duration: row.duration ?? "N/A",
    task: row.task ?? "N/A",
    manager_status: row.manager_status ?? "N/A",
    manager_comment: row.manager_comment ?? "N/A",
  }));

  const columns = [
    {
      title: "#",
      dataIndex: "ind",
      className: "text-start",
      sorter: (a, b) => a.key - b.key,
      width: "10%",
      render: (text) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {text}
        </div>
      ),
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
      width: "5%",
    },
    {
      title: "Project Code",
      dataIndex: "project_code",
      width: "5%",
    },
    {
      title: "Manager",
      dataIndex: "manager",
      width: "5%",
    },
    {
      title: "Date",
      dataIndex: "date",
      width: "5%",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      render: (text) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {text}
        </div>
      ),
      width: "10%",
    },
    {
      title: "Task",
      dataIndex: "task",
      width: "40%",
      render: (text) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {text}
        </div>
      ),
    },
    {
      title: "Manager Status",
      dataIndex: "manager_status",
      width: "10%",
    },
    {
      title: "Manager Comment",
      dataIndex: "manager_comment",
      width: "10%",
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <Breadcrumbs
                maintitle={title}
                title="Dashboard"
                subtitle={title}
              />

              <div className="col-auto float-end ms-auto">
                <Link
                  to="/reports/project-time-sheet"
                  class="btn btn-success me-3"
                  on
                  fdprocessedid="as7mtr"
                >
                  <i className="fa fa-arrow-left" /> Go Back
                </Link>

                <Link to="#" className="btn btn-success me-3" onClick={(e)=>downloadExcel()}>
                  <i className="fa fa-download" /> xlsx
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  style={{ overflowX: "auto" }}
                  className="table-stripped"
                  columns={columns}
                  dataSource={
                    userElements?.length > 0
                      ? userElements?.map((designation) => ({
                          ...designation,
                          key: designation.id,
                        }))
                      : []
                  }
                  pagination={{
                    current: paginationState.current,
                    pageSize: paginationState.pageSize,
                    total: timeSheetPagination?.total,
                    onChange: (page, pageSize) =>
                      setPaginationState({ current: page, pageSize }),
                  }}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeSheet;
