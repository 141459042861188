import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; // Import your FormModal component
import { submitData } from "../../../../../../redux/pemRequestSlice";
const PrevEvaluation = ({ postdata, viewonly }) => {

    const dispatch = useDispatch();
    const [ratings, setRatings] = useState([]);
    const [aratings, setARatings] = useState([]);
    const [data, setData] = useState([]);
    const handleChange = (id, newValue) => {
        const updatedRatings = aratings.map((rating) =>
            rating.id === id ? { ...rating, value: newValue } : rating
        );

        // If the rating doesn't exist and a new value is provided, add it
        if (!updatedRatings.some((rating) => rating.id === id) && newValue) {
            const newId = id;
            updatedRatings.push({ id: newId, value: newValue });
        }

        setARatings(updatedRatings);
        //console.log(updatedRatings); // Debugging output
    };

    // Pass data to parent component on validation
    //setData(ratings?.map((rating) => rating.value));


    const projectPer = postdata?.projectPer;
    const wfhPer = postdata?.wfhPer;
    const attPer = postdata?.attPer;

    useEffect(() => {

        dispatch(submitData({ data: aratings, type: 'performSelfRating' }));
    }, [aratings]);



    useEffect(() => {

        const updatedRatings = []; const AupdatedRatings = [];
        postdata?.all_questions.map((eachQuestions) => {



            eachQuestions?.teams_question.map((eachQuestion) => {

                const answer = eachQuestion?.pem_answers || {};
                let quantityValue = answer?.self_rating || "";
                if (eachQuestion?.type == 'quantity') {
                    quantityValue = answer?.quantity || "";
                }
                let quantityValueA = answer?.appraiser_rating || "";
                AupdatedRatings.push({ id: eachQuestion.id, value: quantityValueA });
                if (eachQuestion?.is_system_generate && (eachQuestion?.parameter === "Project"
                    || eachQuestion?.parameter === "WFH"
                    || eachQuestion?.parameter === "Attendance")



                ) {
                    updatedRatings.push({ id: eachQuestion.id, value: quantityValue });
                }
                else {
                    updatedRatings.push({ id: eachQuestion.id, value: quantityValue });
                }


            });




        });

        setARatings(AupdatedRatings);
        setRatings(updatedRatings);

        dispatch(submitData({ data: aratings, type: 'performSelfRating' }));
    }, []);
    return (
        <div className="table-responsive">
            <table className="table rating-table">
                <thead>
                    <tr className="table-active">
                        <th>S. No.</th>
                        <th>Parameters</th>
                        <th >Description</th>
                        <th className="weightage">Weightage</th>
                        <th>Self Rating</th>
                        <th>Self Comments</th>
                        <th>Appraiser Rating</th>
                        <th>Appraiser Comments</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Check if allQuestions exists and is an array */}
                    {postdata?.all_questions?.length > 0 ? (
                        postdata?.all_questions?.map((eachCat, catIndex) => (
                            <React.Fragment key={`category-${catIndex}`}>
                                <tr style={{ backgroundColor: "#e9ecef" }}>


                                    <td style={{ textAlign: "left" }}>
                                        {eachCat?.category_name || "Unnamed Category"}
                                    </td>
                                    <td
                                        style={{ textAlign: "left" }}
                                    ></td>
                                    <td
                                        style={{ textAlign: "left" }}
                                    ></td>
                                    <td style={{ textAlign: "left" }}>
                                        {eachCat?.teams_question?.[0]?.category_weightage?.toFixed(2) || "0.00"}%

                                    </td>
                                    <td
                                        style={{ textAlign: "left" }}
                                    ></td>
                                    <td
                                        style={{ textAlign: "left" }}
                                    ></td>


                                </tr>
                                {eachCat?.teams_question?.map((eachQuestion, key) => {
                                    const answer = eachQuestion?.pem_answers?.[0] || {};
                                    return (
                                        <tr key={`question-${key}`}>
                                            <input type="hidden" name="answers[]" value={eachQuestion.pem_answers?.id || ''} />
                                            <td style={{ borderBottom: "1px solid lightgrey" }}>{key + 1}</td>
                                            <td style={{ borderBottom: "1px solid lightgrey", }}>{eachQuestion.parameter}</td>
                                            <td style={{ borderBottom: "1px solid lightgrey", width: "200px", whiteSpace: "break-spaces" }}>{eachQuestion.description}</td>
                                            <td style={{ borderBottom: "1px solid lightgrey" }}>
                                                {eachQuestion?.type === "quantity"
                                                    ? eachQuestion?.quantity
                                                    : `${eachQuestion?.weightage || 0} %`}
                                            </td>
                                            <td style={{ borderBottom: "1px solid lightgrey" }}>
                                                {eachQuestion ? (
                                                    (() => {
                                                        const answer = eachQuestion?.pem_answers || {};
                                                        let quantityValue = answer?.self_rating || "";
                                                        if (eachQuestion?.type == 'quantity') {
                                                            quantityValue = answer?.quantity || "";
                                                        }

                                                        let quantityValueA = answer?.appraiser_rating || "";


                                                        return (
                                                            <>

                                                                <input
                                                                    key={`pe_self_rating-${eachQuestion.id}`}
                                                                    style={{ width: "80px" }}
                                                                    name="pe_self_rating[]"
                                                                    type="number"
                                                                    disabled
                                                                    min="1"
                                                                    value={ratings.find(rating => rating.id === eachQuestion.id)?.value || quantityValue || ''}
                                                                    //onChange={(e) => handleChange(eachQuestion.id, e.target.value)}

                                                                    required
                                                                />




                                                                {/* {eachQuestion?.is_system_generate && eachQuestion?.parameter === "Project" ? (
                                                                    <input
                                                                        readOnly
                                                                        style={{ width: "80px" }}
                                                                        type="number"
                                                                        name="pe_self_rating[]"
                                                                        min="1"
                                                                        value={Math.round(projectPer ?? 0)}
                                                                    />
                                                                ) : eachQuestion?.is_system_generate && eachQuestion?.parameter === "Attendance" ? (
                                                                    <input
                                                                        readOnly
                                                                        style={{ width: "80px" }}
                                                                        type="number"
                                                                        name="pe_self_rating[]"
                                                                        min="1"
                                                                        value={Math.round(attPer ?? 0)}
                                                                    />
                                                                ) : eachQuestion?.is_system_generate && eachQuestion?.parameter === "WFH" ? (
                                                                    <input
                                                                        readOnly
                                                                        style={{ width: "80px" }}
                                                                        type="number"
                                                                        name="pe_self_rating[]"
                                                                        min="1"
                                                                        value={Math.round(wfhPer ?? 0)}
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        key={`pe_self_rating-${eachQuestion.id}`}
                                                                        style={{ width: "80px" }}
                                                                        name="pe_self_rating[]"
                                                                        type="number"
                                                                        min="1"
                                                                        value={ratings.find(rating => rating.id === eachQuestion.id)?.value || quantityValue || ''}
                                                                        onChange={(e) => handleChange(eachQuestion.id, e.target.value)}

                                                                        required
                                                                    />
                                                                )} */}
                                                            </>
                                                        );
                                                    })()
                                                ) : (
                                                    // Similar code for handling the non-'quantity' case
                                                    <> </>
                                                )}
                                            </td>



                                            <td style={{ borderBottom: "1px solid lightgrey" }}>
                                                {eachQuestion ? (
                                                    (() => {
                                                        const answer = eachQuestion?.pem_answers || {};
                                                        let quantityValue = answer?.self_comments || "";
                                                        if (eachQuestion?.type == 'quantity') {
                                                            quantityValue = answer?.quantity || "";
                                                        }

                                                        return (
                                                            <>
                                                                <textarea name="pe_self_comments[]"
                                                                    //disabled={viewonly}
                                                                    disabled
                                                                    key={`pe_self_comments-${eachQuestion.id}`}

                                                                    rows="1">

                                                                    {quantityValue ? `${quantityValue} ` : ""}

                                                                </textarea>
                                                            </>
                                                        );
                                                    })()
                                                ) : (
                                                    // Similar code for handling the non-'quantity' case
                                                    <> </>
                                                )}
                                            </td>
                                            <td style={{ borderBottom: "1px solid lightgrey" }}>
                                                {eachQuestion ? (
                                                    (() => {
                                                        const answer = eachQuestion?.pem_answers || {};
                                                        let quantityValue = answer?.self_rating || "";
                                                        if (eachQuestion?.type == 'quantity') {
                                                            quantityValue = answer?.quantity || "";
                                                        }

                                                        let quantityValueA = answer?.appraiser_rating || "";


                                                        return (
                                                            <>

                                                                <input
                                                                    key={`pe_appraiser_rating-${eachQuestion.id}`}
                                                                    style={{ width: "80px" }}
                                                                    name="pe_appraiser_rating[]"
                                                                    type="number"
                                                                    disabled={viewonly}
                                                                    min="1"
                                                                    value={aratings.find(rating => rating.id === eachQuestion.id)?.value || quantityValueA || ''}
                                                                    onChange={(e) => handleChange(eachQuestion.id, e.target.value)}


                                                                />




                                                            </>
                                                        );
                                                    })()
                                                ) : (
                                                    // Similar code for handling the non-'quantity' case
                                                    <> </>
                                                )}
                                            </td>

                                            <td style={{ borderBottom: "1px solid lightgrey" }}>
                                                {eachQuestion ? (
                                                    (() => {
                                                        const answer = eachQuestion?.pem_answers || {};
                                                        let quantityValue = answer?.appraiser_comments || "";


                                                        return (
                                                            <>
                                                                <textarea name="pe_appraiser_comments[]"
                                                                    disabled={viewonly}

                                                                    key={`pe_appraiser_comments-${eachQuestion.id}`}

                                                                    rows="1">

                                                                    {quantityValue ? `${quantityValue} ` : ""}

                                                                </textarea>
                                                            </>
                                                        );
                                                    })()
                                                ) : (
                                                    // Similar code for handling the non-'quantity' case
                                                    <> </>
                                                )}
                                            </td>



                                        </tr>
                                    );
                                })}
                            </React.Fragment>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="8" style={{ textAlign: "center" }}>
                                No data available.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default PrevEvaluation;
