import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Breadcrumbs from "../../../Breadcrumbs";
import { useDispatch, useSelector } from 'react-redux';
import { getAssetStats } from '../../../../redux/assetSlice';

const AssetStats = () => {
  const dispatch = useDispatch();
  const {
    assetStats = [],
    loading = false,
    pagination = {},
    success = null,
    search = ''
  } = useSelector((state) => state.asset || {});

  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10,
  });
  useEffect(() => {
    dispatch(getAssetStats({ page: paginationState.current, per_page: paginationState.pageSize,search  }));
  }, [dispatch, paginationState.current, paginationState.pageSize]);

  const userElements = assetStats?.map((assetstat, index) => ({
    key: index,
    id : assetstat.id,
    categoryname: assetstat.categoryname,
    total_assets:assetstat.total_assets,
    in_stock: assetstat.in_stock,
    with_employee:assetstat.with_employee,
    in_transit: assetstat.in_transit,
  }));

  const columns = [
    {
        title: "S.No",
        width: "10%",
        render: (text, record, index) => index + 1, // Calculate serial number based on row index
    },
    {
        title: "Category",
        dataIndex: "categoryname",
        width: "20%",
      },
    {
      title: "Quantity",
      dataIndex: "total_assets",
      width: "20%",
    },
    {
        title: "Un Assigned",
        dataIndex: "in_stock",
        width: "20%",
      },
      {
        title: "Assigned",
        dataIndex: "with_employee",
        width: "20%",
      },
      {
        title: "In Transit",
        dataIndex: "in_transit",
        width: "20%",
      },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
        <div className="page-header">
        <div className="row align-items-center">
          <Breadcrumbs
            maintitle="Asset"
            title="Dashboard"
            subtitle="Stats"
            modal="#add_assetcategory"
            name="AssetStats"
          />
          <div className="col-auto float-end ms-auto">
          {/* <button type="button" className="btn btn-primary" on fdprocessedid="as7mtr"   onClick={()=>addModal(null)}>   <i className="fa fa-plus" /> Add Category</button> */}

        </div>
          </div>
          </div>
          {/* <AssetCategoryFilter onSearch={onSearch}/> */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0 data-table-card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-stripped"
                      columns={columns}
                      dataSource={
                        userElements?.length > 0
                          ? userElements?.map((assetstat) => ({
                              ...assetstat,
                              key: assetstat.key,
                            }))
                          : []
                      }
                      pagination={{
                        current: paginationState.current,
                        pageSize: paginationState.pageSize,
                        total: pagination?.total,
                        onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetStats;
