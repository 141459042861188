import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
  assets: null,
  search: '',
  fields: ['name', 'code'],
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null,
  assetCategories: null,
  assetHistory:null,
  employeeAssets:null,
  employeAssetsRequests:null,
  allAssetRequests:null,
  assetStats:null,
};

const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    assetcategoryRequestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    assetcategoryUpdatePagination: (state) => {
      // state.pagination = true;
      // state.error = null;
    },
    assetcategoryRequestSuccess: (state, action) => {
      state.assetCategories = action.payload.data;
      state.pagination = action.payload.pagination;
      state.loading = false;
    },
    assetcategoryRequestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    assetcategoryAdded: (state, action) => {
      state.error = null;
      state.success = action.payload.message;
      state.message = action.payload.message;
      state.loading = false;
    },

    assetRequestStart: (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      },
      assetUpdatePagination: (state) => {
        // state.pagination = true;
        // state.error = null;
      },
      assetRequestSuccess: (state, action) => {
        state.assets = action.payload.data;
        state.pagination = action.payload.pagination;
        state.loading = false;
      },

      employeeAssetRequestSuccess: (state, action) => {
        state.employeeAssets = action.payload.data;
        state.pagination = action.payload.pagination;
        state.loading = false;
      },
      employeeAssetRequestsRequestSuccess: (state, action) => {
        state.employeAssetsRequests = action.payload.data;
        state.pagination = action.payload.pagination;
        state.loading = false;
      },
      allAssetRequestsRequestSuccess: (state, action) => {
        state.allAssetRequests = action.payload.data;
        state.pagination = action.payload.pagination;
        state.loading = false;
      },
      assetStatsRequestSuccess: (state, action) => {
        state.assetStats = action.payload.data;
        state.pagination = action.payload.pagination;
        state.loading = false;
      },
      assetRequestFailure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = null;
      },
      assetAdded: (state, action) => {
        state.error = null;
        state.success = action.payload.message;
        state.message = action.payload.message;
        state.loading = false;
      },
    removeErrorMsg: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;

    },

    assetHistorySuccessRequest: (state, action) => {
        // state.error = null;
        state.assetHistory= action.payload.data;
        // state.success = action.payload.message;
        // state.message = action.payload.message;
        // state.loading = false;
      },
  },
});

export const {
  assetcategoryRequestStart,
  assetcategoryRequestSuccess,
  assetcategoryRequestFailure,
  assetcategoryAdded,
  assetRequestStart,
  assetRequestSuccess,
  assetRequestFailure,
  assetAdded,
  removeErrorMsg,
  assetHistorySuccessRequest,
  employeeAssetRequestSuccess,
  employeeAssetRequestsRequestSuccess,
  allAssetRequestsRequestSuccess,
  assetStatsRequestSuccess
} = assetSlice.actions;

export default assetSlice.reducer;

export const getAllAssetCategories = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(assetcategoryRequestStart());
  try {

    const fields = ['name', 'code'];
    const response = await axiosInstance.get(`/assetcategories?page=${page}&per_page=${per_page}&search=${search}&${fields.map(field => `fields[]=${field}`).join('&')}`);

    // console.log(response);
    // console.log(response.data);
    const { data, status, message, pagination } = response.data;

    // console.log('data');
    if (status) {
      dispatch(assetcategoryRequestSuccess({ data, pagination }));
    } else {
      dispatch(assetcategoryRequestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(assetcategoryRequestFailure(errorMessage));
  }
};

export const addAssetCategory = (assetcategoryData) => async (dispatch) => {
  dispatch(assetcategoryRequestStart());
  try {

    const response = await axiosInstance.post(`/asset-category`, assetcategoryData);

    const { status, message } = response.data;
    if (status) {
      dispatch(assetcategoryAdded({ message }));
      // dispatch(getAllAssetCategorys({ page: 1, per_page: 10 }));
    } else {
      dispatch(assetcategoryRequestFailure(message || 'Failed to add assetcategory'));
    }
  } catch (error) {
    // dispatch(assetcategoryRequestFailure(error.message || 'Failed to add assetcategory'));
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors; // Get the validation errors
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(', '); // Flatten the error messages

      dispatch(assetcategoryRequestFailure(errorMessage || 'Failed to add assetcategory'));
    } else {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to add assetcategory';
      dispatch(assetcategoryRequestFailure(errorMessage));
    }
  }
};

export const updateAssetCategory = (assetcategoryData) => async (dispatch) => {
  dispatch(assetcategoryRequestStart());

  try {

    const response = await axiosInstance.post(`/update-asset-category`, assetcategoryData);

    const { data, status, message } = response.data;
    console.log(data); // Log the received data for debugging

    if (status) {
      dispatch(assetcategoryAdded({ message, data })); // Include data if needed
      // dispatch(getAllAssetCategorys({ page: 1, per_page: 10 }));
    } else {
      dispatch(assetcategoryRequestFailure(message || 'Failed to update assetcategory'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to update assetcategory';
    dispatch(assetcategoryRequestFailure(errorMessage));
  }
};

export const deleteAssetCategory = (id) => async (dispatch) => {
  dispatch(assetcategoryRequestStart());
  try {

    console.log(id);

    const response = await axiosInstance.delete(`/asset-category/${id}`);

    const { data, status, message } = response.data;
    if (status) {
      dispatch(assetcategoryAdded({ message, data }));
    } else {
      dispatch(assetcategoryRequestFailure(message || 'Failed to delete assetcategory'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to delete data';
    dispatch(assetcategoryRequestFailure(errorMessage));
  }
};


export const getAllAssets = ({ page, per_page, search,assetstatus="all" }) => async (dispatch) => {
    dispatch(assetRequestStart());
    try {
        let fields = [];
        if(search)
        {
             fields = ['assets.brand', 'assets.model_serial_number','assets.asset_code','users.name'];
        }
       const response = await axiosInstance.get(`/assets?page=${page}&per_page=${per_page}&search=${search}&status=${assetstatus}&${fields.map(field => `fields[]=${field}`).join('&')}`);

      // console.log(response);
      // console.log(response.data);
      const { data, status, message, pagination } = response.data;

      // console.log('data');
      if (status) {
        dispatch(assetRequestSuccess({ data, pagination }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to fetch data'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
      dispatch(assetRequestFailure(errorMessage));
    }
  };

  export const addAsset = (assetData) => async (dispatch) => {
    dispatch(assetRequestStart());
    try {


      const response = await axiosInstance.post(`/asset`, assetData);

      const { status, message } = response.data;
      if (status) {
        dispatch(assetAdded({ message }));
        // dispatch(getAllAssetCategorys({ page: 1, per_page: 10 }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to add asset'));
      }
    } catch (error) {
      // dispatch(assetcategoryRequestFailure(error.message || 'Failed to add assetcategory'));
      if (error.response && error.response.data && error.response.data.errors) {
        const validationErrors = error.response.data.errors; // Get the validation errors
        const errorMessage = Object.values(validationErrors)
          .flat()
          .join(', '); // Flatten the error messages

        dispatch(assetRequestFailure(errorMessage || 'Failed to add asset'));
      } else {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to add asset';
        dispatch(assetRequestFailure(errorMessage));
      }
    }
  };

  export const updateAsset = (assetData) => async (dispatch) => {
    dispatch(assetRequestStart());

    try {

      const response = await axiosInstance.post(`/update-asset`, assetData);

      const { data, status, message } = response.data;
      console.log(data); // Log the received data for debugging

      if (status) {
        dispatch(assetAdded({ message, data })); // Include data if needed
        // dispatch(getAllAssetCategorys({ page: 1, per_page: 10 }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to update asset'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to update asset';
      dispatch(assetRequestFailure(errorMessage));
    }
  };

  export const allotAssets = (assetData) => async (dispatch) => {
    dispatch(assetRequestStart());
    try {

      const response = await axiosInstance.post(`/assign-asset-bulk`, assetData);

      const { data, status, message } = response.data;
      console.log(data); // Log the received data for debugging

      if (status) {
        dispatch(assetAdded({ message, data })); // Include data if needed
        // dispatch(getAllAssetCategorys({ page: 1, per_page: 10 }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to assign asset'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to assign asset';
      dispatch(assetRequestFailure(errorMessage));
    }
  };

  export const UpdateAssetStatus = (assetData) => async (dispatch) => {
    dispatch(assetRequestStart());
    try {

      const response = await axiosInstance.post(`/update-asset-status`, assetData);

      const { data, status, message } = response.data;
      console.log(data); // Log the received data for debugging

      if (status) {
        dispatch(assetAdded({ message, data })); // Include data if needed
        // dispatch(getAllAssetCategorys({ page: 1, per_page: 10 }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to assign asset'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to assign asset';
      dispatch(assetRequestFailure(errorMessage));
    }
  };

  export const AddAssetMaintaince = (assetData) => async (dispatch) => {
    dispatch(assetRequestStart());
    try {

      const response = await axiosInstance.post(`/add-asset-maintaince`, assetData);

      const { data, status, message } = response.data;
      console.log(data); // Log the received data for debugging

      if (status) {
        dispatch(assetAdded({ message, data })); // Include data if needed
        // dispatch(getAllAssetCategorys({ page: 1, per_page: 10 }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to assign asset'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to assign asset';
      dispatch(assetRequestFailure(errorMessage));
    }
  };

  export const GetAssetHistory = (id) => async (dispatch) => {
    // dispatch(assetRequestStart());
    try {

      const response = await axiosInstance.get(`/asset-history/${id}`);

      const { data, status, message } = response.data;
      console.log(data); // Log the received data for debugging

      if (status) {
        dispatch(assetHistorySuccessRequest({ message, data })); // Include data if needed
        // dispatch(getAllAssetCategorys({ page: 1, per_page: 10 }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to assign asset'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to assign asset';
      dispatch(assetRequestFailure(errorMessage));
    }
  };

  export const SubmitAsset = (assetData) => async (dispatch) => {
    dispatch(assetRequestStart());
    try {

      const response = await axiosInstance.post(`/submit-asset`, assetData);

      const { data, status, message } = response.data;
      //  console.log(data); // Log the received data for debugging

      if (status) {
        dispatch(assetAdded({ message, data })); // Include data if needed
        // dispatch(getAllAssetCategorys({ page: 1, per_page: 10 }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to assign asset'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to assign asset';
      dispatch(assetRequestFailure(errorMessage));
    }
  };

  export const getAllEmplyeeAssets = ({ page, per_page, search }) => async (dispatch) => {
    dispatch(assetRequestStart());
    try {

      const fields = ['name', 'code'];
      const response = await axiosInstance.get(`/employee-assets?page=${page}&per_page=${per_page}&search=${search}&${fields.map(field => `fields[]=${field}`).join('&')}`);

      // console.log(response);
      // console.log(response.data);
      const { data, status, message, pagination } = response.data;

      // console.log('data');
      if (status) {
        dispatch(employeeAssetRequestSuccess({ data, pagination }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to fetch data'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
      dispatch(assetRequestFailure(errorMessage));
    }
  };

  export const getAllEmplyeeAssetRequests = ({ page, per_page, search }) => async (dispatch) => {
    dispatch(assetRequestStart());
    try {

      const fields = [];
      const response = await axiosInstance.get(`/employee-asset-requests?page=${page}&per_page=${per_page}&search=${search}&${fields.map(field => `fields[]=${field}`).join('&')}`);

      // console.log(response);
      // console.log(response.data);
      const { data, status, message, pagination } = response.data;

      // console.log('data');
      if (status) {
        dispatch(employeeAssetRequestsRequestSuccess({ data, pagination }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to fetch data'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
      dispatch(assetRequestFailure(errorMessage));
    }
  };

  export const AddEmployeeAssetRequest = (assetData) => async (dispatch) => {
    dispatch(assetRequestStart());
    try {

      const response = await axiosInstance.post(`/employee-asset-request`, assetData);

      const { data, status, message } = response.data;
      //  console.log(data); // Log the received data for debugging

      if (status) {
        dispatch(assetAdded({ message, data })); // Include data if needed
        // dispatch(getAllAssetCategorys({ page: 1, per_page: 10 }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to assign asset'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to assign asset';
      dispatch(assetRequestFailure(errorMessage));
    }
  };

  export const UpdateEmployeeAssetRequest = (assetData) => async (dispatch) => {
    dispatch(assetRequestStart());
    try {

      const response = await axiosInstance.post(`/update-asset-request`, assetData);

      const { data, status, message } = response.data;
      //  console.log(data); // Log the received data for debugging

      if (status) {
        dispatch(assetAdded({ message, data })); // Include data if needed
        // dispatch(getAllAssetCategorys({ page: 1, per_page: 10 }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to assign asset'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to assign asset';
      dispatch(assetRequestFailure(errorMessage));
    }
  };

  export const getAllAssetRequests = () => async (dispatch) => {
    dispatch(assetRequestStart());
    try {
      const response = await axiosInstance.get(`/all-asset-requests`);

      // console.log(response);
      // console.log(response.data);
      const { data, status, message, pagination } = response.data;

      // console.log('data');
      if (status) {
        dispatch(allAssetRequestsRequestSuccess({ data, pagination }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to fetch data'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
      dispatch(assetRequestFailure(errorMessage));
    }
  }

  export const getAssetStats = () => async (dispatch) => {
    dispatch(assetRequestStart());
    try {

      const response = await axiosInstance.get(`/asset-stats`);

      // console.log(response);
      // console.log(response.data);
      const { data, status, message, pagination } = response.data;

      // console.log('data');
      if (status) {
        dispatch(assetStatsRequestSuccess({ data, pagination }));
      } else {
        dispatch(assetRequestFailure(message || 'Failed to fetch data'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
      dispatch(assetRequestFailure(errorMessage));
    }
  }

  export const deleteAsset = (id) => async (dispatch) => {
    dispatch(assetcategoryRequestStart());
    try {

      console.log(id);

      const response = await axiosInstance.delete(`/asset/${id}`);

      const { data, status, message } = response.data;
      if (status) {
        dispatch(assetcategoryAdded({ message, data }));
      } else {
        dispatch(assetcategoryRequestFailure(message || 'Failed to delete asset'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to delete data';
      dispatch(assetcategoryRequestFailure(errorMessage));
    }
  };


