import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { add, removeErrorMsg, update } from "../../../../../redux/teamSlice";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Alert from "../../../../../helpers/AlertMessage";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getEmployee } from "../../../../../redux/commonSlice";
import Select from "react-select";

const validationSchema = Yup.object().shape({
  team: Yup.string().required("Team Code is required"),
  description: Yup.string().required("Description is required"),
  team_leader: Yup.string().required("Team Leader is required"),
  line_hr: Yup.string().required("Line Hr is required"),
  corp_hr: Yup.string().required("Corporate Hr is required"),
});

function AddEdit({ show, handleClose, data }) {
  const { loading, error } = useSelector((state) => state.team);
  const { employee } = useSelector((state) => state.common);

  console.log(data, 'data')

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      description: "",
      team_leader: "",
      line_hr: "",
      corp_hr: ""
    },
  });

  const MySwal = withReactContent(Swal);

  const showAlert = (config) => {
    MySwal.fire(config);
  };

  const resetMsg = () => {
    dispatch(removeErrorMsg());
  };

  const onSubmit = async (values) => {
    try {
      if (data) {
        values.id = data.id;
        await dispatch(update(values));
      } else {
        await dispatch(add(values));
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    dispatch(getEmployee())
    if (data) {
      reset({
        team: data?.team || "",
        description: data?.description || "",
        team_leader: employee?.find(option => option.value === data?.team_leader_id?.id)?.value || null,
        line_hr: employee?.find(option => option.value === data?.line_hr_id?.id)?.value || null,
        corp_hr: employee?.find(option => option.value === data?.corp_hr_id?.id)?.value || null,
      });
    } else {
      reset({
        team: "",
        description: "",
        team_leader: "",
        line_hr: "",
        corp_hr: "",
      });
    }
  }, [dispatch, data, reset]);

  const handleModalClose = () => {
    resetMsg();
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      centered
      dialogClassName="modal custom-modal"
    >
      <Modal.Header>
        <Modal.Title>{data ? "Edit Team" : "Add Team"}</Modal.Title>
        <button type="button" className="btn-close" onClick={handleModalClose}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert type="danger" message={error} onClose={resetMsg} />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div class="row col-md-12">
            <div class="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Team Code <span className="text-danger">*</span>
                </label>
                <Controller
                  name="team"
                  control={control}
                  render={({ field }) => (
                    <input
                      className={`form-control ${errors.name ? "error-input" : ""
                        }`}
                      type="text"
                      {...field}
                    />
                  )}
                />
                {errors.team && (
                  <span className="text-danger">{errors.team.message}</span>
                )}
              </div>
            </div>
            <div class="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Team Leader <span className="text-danger">*</span>
                </label>


                <Controller
                  name="team_leader"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={employee} // This should be an array of objects with label and value
                      placeholder="Select"
                      styles={customStyles}
                      value={employee.find(option => option.value === field.value) || null} // Set the selected option
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                      }}
                      className={` ${errors.team_leader ? "error-input" : ""}`}
                    />
                  )}
                />
                {errors.team_leader && (
                  <span className="text-danger">
                    {errors.team_leader.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div class="row col-md-12">
            <div class="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Line Hr <span className="text-danger">*</span>
                </label>
                <Controller
                  name="line_hr"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={employee} // This should be an array of objects with label and value
                      placeholder="Select"
                      styles={customStyles}
                      value={employee.find(option => option.value === field.value) || null} // Set the selected option
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                      }}
                      className={` ${errors.line_hr ? "error-input" : ""}`}
                    />
                  )}
                />
                {errors.line_hr && (
                  <span className="text-danger">{errors.line_hr.message}</span>
                )}
              </div>
            </div>
            <div class="col-md-6">
              <div className="input-block">
                <label className="col-form-label">
                  Corporate Hr <span className="text-danger">*</span>
                </label>
                <Controller
                  name="corp_hr"
                  control={control}
                  render={({ field }) => (

                    <Select
                      {...field}
                      options={employee} // This should be an array of objects with label and value
                      placeholder="Select"
                      styles={customStyles}
                      value={employee.find(option => option.value === field.value) || null} // Set the selected option
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                      }}
                      className={` ${errors.corp_hr ? "error-input" : ""}`}
                    />

                  )}
                />
                {errors.corp_hr && (
                  <span className="text-danger">{errors.corp_hr.message}</span>
                )}
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="col-md-12">
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Description <span className="text-danger">*</span>
                </label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <input
                      className={`form-control ${errors.description ? "error-input" : ""
                        }`}
                      type="text"
                      {...field}
                    />
                  )}
                />
                {errors.description && (
                  <span className="text-danger">
                    {errors.description.message}
                  </span>
                )}
              </div>
            </div>
          </div>


          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
            {!loading ? (
              <button className="btn btn-primary" type="submit">
                Save Changes
              </button>
            ) : (
              <button type="button" className="btn btn-primary" disabled>
                <i className="fas fa-spinner fa-spin me-2" />
                Processing...
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddEdit;
