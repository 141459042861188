import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
  allData: [],
  search: '',
  fields: ['partner', 'region'],
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null,
};

const projectPartnerSlice = createSlice({
  name: 'Project',
  initialState,
  reducers: {
    projectPartnerRequestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    projectPartnerRequestSuccess: (state, action) => {
      state.allData = action.payload.data;
      state.pagination = action.payload.pagination;
      state.loading = false;
    },
    projectPartnerRequestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    projectPartnerAdded: (state, action) => {
      state.error = null;
      state.success = action.payload.message;
      state.message = action.payload.message;
      state.loading = false;
    },
    removeErrorMsg: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;
    },
  },
});

export const {
  projectPartnerRequestStart,
  projectPartnerRequestSuccess,
  projectPartnerRequestFailure,
  projectPartnerAdded,
  removeErrorMsg,
} = projectPartnerSlice.actions;

export default projectPartnerSlice.reducer;

export const getAllprojectPartner = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(projectPartnerRequestStart());
  try {
    const response = await axiosInstance.get(
      `/projectPartner?page=${page}&per_page=${per_page}&search=${search}&fields[]=partner&fields[]=region`
    );
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(projectPartnerRequestSuccess({ data, pagination }));
    } else {
      dispatch(projectPartnerRequestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(projectPartnerRequestFailure(errorMessage));
  }
};

export const addprojectPartner = (projectPartnerData) => async (dispatch) => {
  dispatch(projectPartnerRequestStart());
  try {
    const response = await axiosInstance.post(
      `/projectPartner/add`,
      {
        partner: projectPartnerData.partner,
        region: projectPartnerData.region,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    const { status, message } = response.data;
    if (status) {
      dispatch(projectPartnerAdded({ message }));
    } else {
      dispatch(projectPartnerRequestFailure(message || 'Failed to add Project Partner'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to add Project Partner';
    dispatch(projectPartnerRequestFailure(errorMessage));
  }
};

export const updateprojectPartner = (projectPartnerData) => async (dispatch) => {
  dispatch(projectPartnerRequestStart());
  try {
    const response = await axiosInstance.put(
      `/projectPartner/update/${projectPartnerData.id}`,
      {
        id: projectPartnerData.id,
        partner: projectPartnerData.partner,
        region: projectPartnerData.region,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    const { status, message } = response.data;
    if (status) {
      dispatch(projectPartnerAdded({ message }));
    } else {
      dispatch(projectPartnerRequestFailure(message || 'Failed to update Project Partner'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to update Project Partner';
    dispatch(projectPartnerRequestFailure(errorMessage));
  }
};

export const deleteprojectPartner = (id) => async (dispatch) => {
  dispatch(projectPartnerRequestStart());
  try {
    const response = await axiosInstance.delete(`/projectPartner/${id}`);
    const { status, message } = response.data;
    if (status) {
      dispatch(projectPartnerAdded({ message }));
    } else {
      dispatch(projectPartnerRequestFailure(message || 'Failed to delete Project Partner'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to delete data';
    dispatch(projectPartnerRequestFailure(errorMessage));
  }
};
