import { createSlice } from '@reduxjs/toolkit';

import axiosInstance from './axiosInstance';
import { useHistory } from 'react-router-dom';
import { getActiveCompany } from './companySlice';
const initialState = {
  user: null,
  name: null,
  module_permission: null,
  is_super_admin: (localStorage.getItem('is_super_admin') && localStorage.getItem('is_super_admin') != '') ? localStorage.getItem('is_super_admin') : false,
  is_root_admin: (localStorage.getItem('is_root_admin') && localStorage.getItem('is_root_admin') != '') ? localStorage.getItem('is_root_admin') : 0,
  role: null,
  token: null,
  loading: false,
  isclient: (localStorage.getItem('code') && localStorage.getItem('code') != '' && localStorage.getItem('code') != 'super') ? true : false,
  error: null,
  isEmailSent:false,
  isPassWordReset:false,
  //companies: localStorage.getItem('companies') ? JSON.parse(localStorage.getItem('companies')) : []


};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.error = null;

    },
    loginStartClient: (state, action) => {
      state.loading = true;
      state.error = null;
      state.clientID = action.payload.clientID;
    },
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.name = action.payload.name;
      state.is_super_admin = action.payload.is_superadmin;
      state.is_root_admin = action.payload.is_root_admin;
      state.role = action.payload.role;
      state.token = action.payload.token;
      state.isclient = action.payload.code && action.payload.code != '' ? true : false;
      //state.companies = action.payload.companies || [];
      state.loading = false;
      state.clientID = null;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.clientID = null;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.loading = false;
      state.error = null;
      state.clientID = null;
      state.isclient = false;
      // state.companies = [];
      //localStorage.clear();
    },
    removeErrorMsg: (state) => {
      state.error = null;
      state.clientID = null;

    },
    forgotPasswordSuccess: (state,action) => {
        state.error = action.payload.message;
        state.isEmailSent = action.payload.status;
        state.loading = false;
    },
    resetPasswordSuccess: (state,action) => {
        state.error = action.payload.message;
        state.isPassWordReset = action.payload.status;
        state.loading = false;
    },
  },
});

export const { loginStart, loginStartClient,resetPasswordSuccess, forgotPasswordSuccess,loginSuccess, loginFailure, logout, removeErrorMsg } = authSlice.actions;

export default authSlice.reducer;

export const loginUser = (credentials) => async (dispatch) => {
  dispatch(loginStart());
  try {
    const response = await axiosInstance.post(`/login`, credentials);

    const { userDetails, token, module_permission, is_superadmin, is_rootadmin, role } = response.data.data;
    localStorage.setItem('user', JSON.stringify(userDetails));
    localStorage.setItem('token', token);
    localStorage.setItem('role', role);
    localStorage.setItem('is_root_admin', is_rootadmin);
    localStorage.setItem('is_super_admin', is_superadmin);
    // localStorage.setItem('companies', companies);
    localStorage.setItem('module_permission', JSON.stringify(module_permission));
    dispatch(loginSuccess({ user: userDetails, name: userDetails.name, is_superadmin, role, token, is_root_admin: is_rootadmin }));


  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Login failed.';
    dispatch(loginFailure(errorMessage));
  }
};


export const loginAsClient = (clientID) => async (dispatch) => {
  dispatch(loginStartClient({ clientID: clientID }));
  try {

    const response = await axiosInstance.post(`/impersonate/${clientID}`);
    const { userDetails, code, token, module_permission, is_superadmin, is_rootadmin, role } = response.data.data;
    console.log(response.data);
    localStorage.setItem('user', JSON.stringify(userDetails));
    localStorage.setItem('token', token);
    localStorage.setItem('role', role);
    localStorage.setItem('code', code);
    localStorage.setItem('is_root_admin', is_rootadmin);
    localStorage.setItem('is_super_admin', is_superadmin);

    //localStorage.setItem('companies', companies);
    localStorage.setItem('module_permission', JSON.stringify(module_permission));
    dispatch(loginSuccess({ user: userDetails, name: userDetails.name, is_superadmin, role, token, is_root_admin: is_rootadmin }));

    dispatch(getActiveCompany());
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Login as client failed.';
    dispatch(loginFailure(errorMessage));
  }
};


export const forgotPassword = (credentials) => async (dispatch) => {
    dispatch(loginStart());
    try {
      const {message,status,data} = await axiosInstance.post(`/forgot-password`, credentials);
    //   console.log(message);
    //   console.log(status);
    //   console.log(data);
      if(data.status)
      {
        dispatch(forgotPasswordSuccess({message:data.message,status:data.status}));
      }
      else
      {
        dispatch(forgotPasswordSuccess({message,status}));
      }

    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Forgot Password failed.';
      dispatch(loginFailure(errorMessage));
    }
  };

  export const resetPassword = (credentials) => async (dispatch) => {
    dispatch(loginStart());
    try {
      const {message,status,data} = await axiosInstance.post(`/reset-password`, credentials);
      if(data.status)
      {
        dispatch(resetPasswordSuccess({message:data.message,status:data.status}));
      }
      else
      {
        dispatch(resetPasswordSuccess({message,status}));
      }

    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Reset Password failed.';
      dispatch(loginFailure(errorMessage));
    }
  };

