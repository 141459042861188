import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../Breadcrumbs";
import DeleteModal from "./model/DeleteModel";
import { useDispatch, useSelector } from 'react-redux';
import { getAll, deleteData, deleteDataQuestion } from '../../../../redux/pemQuestionSlice';
import AddEdit from './model/AddEdit';
import Filter from "./Filter";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';



const Templates = () => {
  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false)
  const [dataDetails, setdataDetails] = useState(null);
  const [dataType, setdataType] = useState(null);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const { allData, loading, pagination, success, search } = useSelector((state) => state.pemquestion);
  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10,
  });
  useEffect(() => {
    dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
  }, [dispatch, paginationState.current, paginationState.pageSize]);
  //console.log(allData, '0099')
  const userElements = allData?.map((data, index) => ({
    key: index,
    id: data.id,
    rowType: 'category',
    category_name: data.category_name,
    details: 'More Detailes',
    category_code: data.category_code,

    nestedData: data.my_question?.map((question, questionIndex) => ({
      ...question,
      index: questionIndex, // Add index to each question in nestedData

    }))
  }));

  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);

  const MySwal = withReactContent(Swal);

  const showAlert = (config) => {
    MySwal.fire(config);
  };




  const columns = [
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "50px",
      className: 'text-start-td',
      render: (text, record) => {
        if (record.rowType === 'category') {
          return {
            children: <span className="font-weight-bold text-left">{record.category_name} [{record.category_code}]</span>,
            props: {
              colSpan: columns.length - 1, // Span across all columns
            },
          };
        }
        return {
          children: <span className="font-weight-bold">{record.pem_question_code} </span>,

        };
      },
    },
    {
      title: "Parameter",
      dataIndex: "parameter",
      key: "parameter",
      width: '150px',
      render: (text, record) => (record.rowType === 'category' ? { props: { colSpan: 0 } } : text),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: '300px',
      className: 'text-wrap',
      render: (text, record) => (record.rowType === 'category' ? { props: { colSpan: 0 } } : text),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
      render: (text, record) => (record.rowType === 'category' ? { props: { colSpan: 0 } } : text),
    },
    {
      title: "System Generated",
      dataIndex: "is_system_generate",
      key: "is_system_generate",
      width: "10%",
      render: (text, record) => (record.rowType === 'category' ? { props: { colSpan: 0 } } : text === 1 ? 'Yes' : 'No'),
    },


    {
      title: "Action",
      key: "action",
      // className: 'text-end-td',
      width: "5%",
      render: (text, record) => {
        if (record.rowType === 'category') {
          return {
            children: <div>
              <button className="btn btn-edit" onClick={() => addModal(record, 'category')}>
                <i className="fa fa-edit" />
              </button>
              <button className="btn btn-delete" onClick={() => deleteModal(record, 'category')}>
                <i className="fa fa-trash" />
              </button>
            </div>,

          };
        }
        return {
          children: <div>
            <button className="btn btn-edit" onClick={() => addModal(record, 'question')}>
              <i className="fa fa-edit" />
            </button>
            <button className="btn btn-delete" onClick={() => deleteModal(record, 'question')}>
              <i className="fa fa-trash" />
            </button>
          </div>,

        };
      },

    },
  ];



  const haldleModel = () => {
    setOpenModel(!openModel)
  }
  const addModal = (data, type) => {
    setdataType(type);

    console.log(data);
    setdataDetails(data);
    handleShow()
  }

  const deleteModal = (data, type) => {
    setdataDetails(data);
    setdataType(type);
    handleDeleteShow()
  }

  const deletePemData = (data) => {
    handleDeleteClose();
    if (dataType == 'category')
      dispatch(deleteData(data.id));
    else
      dispatch(deleteDataQuestion(data.id));
  }
  const handleExpand = (expanded, record) => {
    const newExpandedRowKeys = expanded
      ? [...expandedRowKeys, record.key]
      : expandedRowKeys.filter((key) => key !== record.key);
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const expandable = {
    expandedRowRender: (record) => {
      // Nested rc-table for the expanded row
      return (
        <div>
          {/* <h4>Nested Table for {record.key}</h4> */}
          <Table
            columns={columns}
            dataSource={record.nestedData}
            pagination={false}
            // showHeader={false} // Hide header in nested table
            size="small"
          />
        </div>
      );
    },
    expandedRowKeys: expandedRowKeys,
    onExpand: handleExpand,
  };

  useEffect(() => {
    // console.log('Het');
    if (success) {
      showAlert({
        title: 'PEM Question',
        text: success,
        confirmButtonColor: '#FF902F',
        confirmButtonText: 'OK',
      });
      dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
      handleClose();
      handleDeleteClose();
    }
  }, [success, showAlert]);

  useEffect(() => {
    // Set all row keys as expanded initially

    if (userElements)
      setExpandedRowKeys(userElements.map(item => item.key + 1));

  }, [allData]);
  const onSearch = (search) => {
    dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
    console.log(search, 'pp');
  }
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <Breadcrumbs
                maintitle="Template"
                title="Dashboard"
                subtitle="My Goals/Prefs"
              />

            </div>
            {/* <Filter onSearch={onSearch} /> */}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="d-flex justify-content-end mb-3">
                <span className="me-2">
                  <Link
                    to="#"
                    className="btn btn-primary btn-block w-100"
                    onClick={() => addModal(null, 'category')}
                  >
                    <i className="fa fa-plus" /> Add Category
                  </Link>
                </span>

                <span>
                  <Link
                    to="#"
                    className="btn btn-primary btn-block w-100"
                    onClick={() => addModal(null, 'question')}
                  >
                    <i className="fa fa-plus" /> Add Sub Category
                  </Link>
                </span>
              </div>

              <div className="m-b-30">
                <div className="card mb-0 data-table-card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        columns={columns}

                        dataSource={
                          userElements?.length > 0
                            ? userElements?.map((designation) => ({
                              ...designation,
                              key: designation.id,
                            }))
                            : []
                        }
                        expandable={expandable}
                        rowClassName={(record) =>
                          record.rowType === 'category' ? 'highlight-row' : ''
                        }
                        showHeader={false} // Hide header in nested table
                        pagination={{
                          current: paginationState.current,
                          pageSize: paginationState.pageSize,
                          total: pagination?.total,
                          onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                        }}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div> </div>
            </div>
          </div>
        </div>
        <AddEdit type={dataType} show={showModal} handleClose={handleClose} data={dataDetails} />
        <DeleteModal type={dataType} show={showDeleteModal} handleClose={() => setShowDeleteModal(false)} data={dataDetails} onDelete={deletePemData} name="Delete Grade" />
      </div>
    </div>
  );
};

export default Templates;
