import React from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
const DeleteModal = ({ name, show, handleClose, projectPaymentType, onDelete }) => {
  const handleModalClose = () => {
    handleClose();
};
  return (
    <>
      <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{name}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
            <div className="form-header">
                {/* <h3>{name}</h3> */}
                <p>Are you sure want to delete?</p>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="col-6">
                    <Link to="#" className="btn btn-primary continue-btn" onClick={()=>onDelete(projectPaymentType)}>
                      Delete
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link
                      to="#"
                      onClick={handleModalClose}
                      className="btn btn-primary cancel-btn"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </Modal.Body>
        </Modal>
    </>
  );
};

export default DeleteModal;
