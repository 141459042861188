import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import designationReducer from './designationSlice';
import gradeReducer from './gradeSlice';
import commonReducer from './commonSlice';
import clientReducer from './clientSlice';
import teamReducer from './teamSlice';
import userReducer from "./userSlice";
import holidayReducer from "./holidaySlice"
import companyReducer from './companySlice';
import modulesReducer from './modulesSlice';
import rolesReducer from './rolesSlice';
import leaveTypeReducer from './leaveTypeSlice';
import attendanceReducer from './attendanceSlice';
import leaveWFHRequestReducer from './leaveWFHRequestSlice';
import reimbursementReducer from './reimbursementSlice';
import projectReducer from './projectSlice';
import pemQuestionReducer from './pemQuestionSlice';
import pemTemplateReducer from './pemTemplateSlice';
import timeSheetReducer from './timeSheetSlice';
import assetReducer from "./assetSlice";
import contractReducer from "./contractSlice";
import customertReducer from "./customerSlice";
import projectTechReducer from "./projectTechSlice";
import projectBusinessAreaReducer from "./projectBusinessAreaSlice";
import projectBillabilityReducer from "./projectBillabilitySlice";
import projectPaymentTypeReducer from "./projectPaymentTypeSlice";
import projectPartnerReducer from "./projectPartnerSlice";
import projectTypeReducer from "./projectTypeSlice"
import reportReducer from "./reportSlice";
import projectTaskReducer from "./projectTaskSlice";
import pemRequestReducer from "./pemRequestSlice";
import subscriptionReducer from "./subscriptionSlice";


const store = configureStore({
  reducer: {
    auth: authReducer,
    designation: designationReducer,
    grade: gradeReducer,
    common: commonReducer,
    team: teamReducer,
    user: userReducer,
    client: clientReducer,
    holiday: holidayReducer,
    company: companyReducer,
    roles: rolesReducer,
    modules: modulesReducer,
    leaveType: leaveTypeReducer,
    attendance: attendanceReducer,
    leaveWFH: leaveWFHRequestReducer,
    reimbursement: reimbursementReducer,
    project: projectReducer,
    pemquestion: pemQuestionReducer,
    pemtemplates: pemTemplateReducer,

    asset: assetReducer,
    timeSheet: timeSheetReducer,
    contract: contractReducer,
    customer:customertReducer,
    projectTech:projectTechReducer,
    projectBusinessArea:projectBusinessAreaReducer,
    projectBillability:projectBillabilityReducer,
    projectPaymentType:projectPaymentTypeReducer,
    projectPartner:projectPartnerReducer,
    projectType:projectTypeReducer,
    report:reportReducer,
    contract: contractReducer,
    projectTask: projectTaskReducer,
    pemrequest: pemRequestReducer,
    projectTask :projectTaskReducer,
    subscription:subscriptionReducer,
  },
});

export default store;
