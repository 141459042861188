import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../Breadcrumbs";
import DeleteModal from "./model/DeleteModel";
import { useDispatch, useSelector } from 'react-redux';
import { getAllAssetCategories,deleteAssetCategory } from '../../../../redux/assetSlice';
import AddEditAssetCategory from './model/AddEditAssetCategory';
import AssetCategoryFilter from "./AssetCategoryFilter";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';



const AssetCategory = () => {
  const dispatch = useDispatch();
  const [assetcategoryDetails, setAssetCategoryDetails] = useState(null);
  const {
    assetCategories = [],
    loading = false,
    pagination = {},
    success = null,
    search = ''
  } = useSelector((state) => state.asset || {});

  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10,
  });
  useEffect(() => {
    dispatch(getAllAssetCategories({ page: paginationState.current, per_page: paginationState.pageSize,search  }));
  }, [dispatch, paginationState.current, paginationState.pageSize]);

  const userElements = assetCategories?.map((assetcategory, index) => ({
    key: index,
    id: assetcategory.id,
    name: assetcategory.name,
    code: assetcategory.code,
  }));

  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);

  const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
      width: "10%",
    },
    {
        title: "Code",
        dataIndex: "code",
        sorter: (a, b) => a.code.length - b.code.length,
        width: "20%",
      },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      width: "20%",
    },

    {
      title: "Action",
      className: "text-end",
      render: (text, assetcategory) => (
        <div className="dropdown dropdown-action text-end me-30">
          {/* <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link> */}
          {/* <div className="dropdown-menu dropdown-menu-right"> */}
            <Link
              className="dropdown-item1 m-r-10"
              to="#"
              onClick={() => addModal(assetcategory)}
            >
              <i className="fa fa-pencil m-r-5" />
            </Link>
            <Link
              className="dropdown-item1 m-r-10"
              to="#"
              // data-bs-toggle="modal"
              // data-bs-target="#delete"
              onClick={() => deleteModal(assetcategory)}
            >
              <i className="fa fa-trash m-r-5" />
            </Link>
          {/* </div> */}
        </div>
      ),
      sorter: (a, b) => a.length - b.length,
      width: "10%",
    },
  ];

//   const haldleModel = () => {
//     setOpenModel(!openModel)
//   }
  const addModal = (data)=> {
    setAssetCategoryDetails(data);
    handleShow()
  }

  const deleteModal = (data)=> {
    setAssetCategoryDetails(data);
    handleDeleteShow()
  }

  const deleteAssetCategoryData = (data)=> {
    console.log(data.id);
    handleDeleteClose();
    dispatch(deleteAssetCategory(data.id));
  }

  useEffect(() => {
    if (success) {
        showAlert({
            title: 'AssetCategory',
            text: success,
            confirmButtonColor: '#FF902F',
            confirmButtonText: 'OK',
        });
        dispatch(getAllAssetCategories({ page: paginationState.current, per_page:  paginationState.pageSize ,search}));
        handleClose();
        handleDeleteClose();
    }
}, [success,  showAlert]);


const onSearch = (search)=>{
  dispatch(getAllAssetCategories({ page: paginationState.current, per_page:  paginationState.pageSize ,search}));
  console.log(search, 'pp');
}
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
        <div className="page-header">
        <div className="row align-items-center">
          <Breadcrumbs
            maintitle="Asset Categories"
            title="Dashboard"
            subtitle="Asset Categories"
            modal="#add_assetcategory"
            name="Add  AssetCategory"
          />
          <div className="col-auto float-end ms-auto">
          <button type="button" className="btn btn-primary" on fdprocessedid="as7mtr"   onClick={()=>addModal(null)}>   <i className="fa fa-plus" /> Add Category</button>

        </div>
          </div>
          </div>
          <AssetCategoryFilter onSearch={onSearch}/>
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0 data-table-card">

                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-stripped"
                      columns={columns}
                      dataSource={
                        userElements?.length > 0
                          ? userElements?.map((assetcategory) => ({
                              ...assetcategory,
                              key: assetcategory.id,
                            }))
                          : []
                      }
                      pagination={{
                        current: paginationState.current,
                        pageSize: paginationState.pageSize,
                        total: pagination?.total,
                        onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

       <AddEditAssetCategory show={showModal} handleClose={handleClose}  assetcategory={assetcategoryDetails} />
       <DeleteModal show={showDeleteModal} handleClose={handleDeleteClose}  assetcategory={assetcategoryDetails} onDelete={deleteAssetCategoryData}  name="Delete AssetCategory" />
    </div>
  );
};

export default AssetCategory;
