import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { customStyles } from "../../../helpers/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getGrade,
  getTeams,
  getEmployee,
} from "../../../redux/commonSlice";
import { setFilter } from "../../../redux/userSlice";
import { status, workModel, employmentType } from "../../../helpers/Constants";

const SearchBox = ({ onSearch }) => {

  const { grade, teams,employee, manager } = useSelector(
    (state) => state.common
  );
  const dispatch = useDispatch();

  const teamOptions = teams?.map((g) => ({
    value: g.id,
    label: g.description + `[${g.team}]`,
  }));

  const gradeOptions = grade?.map((g) => ({
    value: g.id,
    label: g.name,
  }));

  useEffect(() => {
    dispatch(getGrade());
    dispatch(getTeams());
    dispatch(getEmployee('Manager'));
  }, [dispatch]);

  const [inputValue, setInputValue] = useState("");
  const [focused, setFocused] = useState(false);

  const handleLabelClick = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    if (inputValue === "") {
      setFocused(false);
    }
  };

  const handleInputChange = (e, type) => {
    let value = "";
    if (type === "searchText") {
       value = e.target.value;
       setInputValue(value);
       if (value !== "" && !focused) {
         setFocused(true);
       }
      // setSearchFilter((prev) => ({ ...prev, searchText: value }));
      dispatch(setFilter({ key:'searchText', value }));
    } else {
      let value = e ? e.value : "";
    // setSearchFilter((prev) => ({ ...prev, [type]: value }));
    dispatch(setFilter({ key:type, value }));
    onSearch();
    }   
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const filterResult = (e) => {
    onSearch();
  }

  return (
    <>
      <div className="filter-section filter-bottom">
        <ul>
          <li>
            <div className="form-sort value-contain">
              <Select
                options={teamOptions}
                isClearable
                placeholder="Select Team"
                styles="form-control form-control-sm"
                onChange={(e) => handleInputChange(e, "team_id")}
              />
            </div>
          </li>
          <li>
            <div className="form-sort value-contain">
              <Select
                options={manager}
                isClearable
                placeholder="Manager"
                styles={customStyles}
                onChange={(e) => handleInputChange(e, "reporting_to")}
              />
            </div>
          </li>

          <li>
            <div className="form-sort value-contain">
              <Select
                options={status}
                isClearable
                placeholder="Status"
                styles={customStyles}
                onChange={(e) => handleInputChange(e, "status")}
              />
            </div>
          </li>

          <li>
            <div className="form-sort value-contain">
              <Select
                options={gradeOptions}
                isClearable
                placeholder="Grade"
                styles={customStyles}
                onChange={(e) => handleInputChange(e, "emp_grade_id")}
              />
            </div>
          </li>

          <li>
            <div className="form-sort value-contain">
              <Select
                options={workModel}
                isClearable
                placeholder="Work Model"
                styles={customStyles}
                onChange={(e) => handleInputChange(e, "department")}
              />
            </div>
          </li>
          <li>
            <div className="form-sort value-contain">
              <Select
                options={employmentType}
                placeholder="Employment"
                styles={customStyles}
                onChange={(e) => handleInputChange(e, "employment_type")}
              />
            </div>
          </li>

          <li>
            <div className="search-set">
              <div className="search-input">
                <Link to="#" className="btn btn-searchset">
                  <i className="las la-search" />
                </Link>
                <div className="dataTables_filter">
                  <label>
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder="Search"
                      value={inputValue}
                      onFocus={handleLabelClick}
                      onBlur={handleInputBlur}
                      onChange={(e) => handleInputChange(e, "searchText")}
                      onKeyDown={handleKeyDown}
                    />
                  </label>
                </div>
              </div>
            </div>
          </li>
          <li>
            <Link to="#" className="btn btn-success" onClick={(e)=>filterResult(e)}>
              <i className="fa fa-search"  /> Search
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SearchBox;
