import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";// Replace with your action names
import { forgotPassword, removeErrorMsg, resetPassword } from '../../redux/authSlice';
import Alert from '../../helpers/AlertMessage';
import { logoWhiteVertical } from '../../Routes/ImagePath';

const ForgotPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isEmailSent, error, isPassWordReset } = useSelector((state) => state.auth);
    const [otpSent, setOtpSent] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [resetemaillocal, setEmail] = useState("");

    // Validation Schema for Email (OTP Request)
    const emailValidationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Please enter a valid email address")
            .required("Email is required"),
    });

    // Validation Schema for OTP and Password Reset
    const passwordValidationSchema = Yup.object().shape({
        otp: Yup.string().required("OTP is required"),
        email: Yup.string().nullable(),
        password: Yup.string()
            .required("Password is required")
            .min(8, "Password must be at least 8 characters"),
        password_confirmation: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref('password'), null], "Passwords must match"),
    });



    // Form Handling for Email (OTP Request)
    const {
        register: registerEmail,
        control: control,
        handleSubmit: handleSubmitEmail,
        formState: { errors: emailErrors },
    } = useForm({
        resolver: yupResolver(emailValidationSchema),
        defaultValues: {
            email: ""
        },
    });

    // Form Handling for OTP and Password Reset
    const {
        register: registerPassword,
        control: Control,
        handleSubmit: handleSubmitPassword,
        reset: reset,
        formState: { errors: passwordErrors },
    } = useForm({
        resolver: yupResolver(passwordValidationSchema),
        defaultValues: {
            resetemail: "",
            otp: "",
            password: "",
            password_confirmation: ""
        },
    });

    useEffect(() => {
        if (resetemaillocal) {
            reset({
                resetemail: resetemaillocal ? resetemaillocal : "",
            });
        } else {
            reset({
                resetemail: "",
            });
        }

    }, [resetemaillocal, reset]);

    useEffect(() => {
        if (isEmailSent) {
            setOtpSent(true);
        }
    }, [isEmailSent, setOtpSent]);

    useEffect(() => {
        if (isPassWordReset) {
            navigate('/login');
        }
    }, [isPassWordReset, navigate]);



    const handleClose = () => {
        dispatch(removeErrorMsg());
    };

    const onSubmitEmail = (values) => {
        dispatch(forgotPassword(values));
        setEmail(values.email);
    };

    const onSubmitPassword = (values) => {
        dispatch(resetPassword(values));
    };

    return (
        <div className="account-page">
            <div className="main-wrapper">
                <div className="account-content">


                    <div className="container-fluid p-0">
                        <div className="account-box row">
                            {/* Left Section */}
                            <div className="col-lg-6 account-wrapper">
                                {/* <h2 className="text-center mb-3">Welcome to</h2> */}
                                {/* <h3 className='text-center'>
                                    <img className='mb-3' src={logo2} width={300} alt="img" />
                                </h3> */}
                                {/* <p className="account-subtitle">Login to get access to our dashboard</p> */}

                                {!otpSent ? (<h2 className="account-title">Forgot Password?</h2>) : <h2 className="account-title">Reset Password?</h2>}
                                {!otpSent ? (<p className="account-subtitle">
                                    Enter your email to get a OTP
                                </p>) : <br />}
                                {isEmailSent || error ? (
                                    <Alert
                                        type={isEmailSent ? "success" : "danger"}
                                        message={error}
                                        onClose={handleClose}
                                    />
                                ) : null}

                                {/* Email Form (Visible until OTP is sent) */}
                                {!otpSent ? (
                                    <form onSubmit={handleSubmitEmail(onSubmitEmail)}>
                                        <div className="input-block">
                                            <label>Email Address</label>
                                            <Controller
                                                name="email"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        className={`form-control ${emailErrors?.email ? "error-input" : ""}`}
                                                        type="text"
                                                        {...field}

                                                        autoComplete="true"
                                                    />
                                                )}
                                            />
                                            <span className="text-danger">{emailErrors.email?.message}</span>
                                        </div>
                                        <div className="input-block text-center">
                                            <button className="btn btn-primary account-btn" type="submit">
                                                Send OTP
                                            </button>
                                        </div>
                                        <div className="account-footer">
                                            <p>
                                                Remember your password? <Link to="/">Login</Link>
                                            </p>
                                        </div>
                                    </form>
                                ) : (
                                    // OTP and Password Reset Form (Visible after OTP is sent)
                                    <form
                                        onSubmit={handleSubmitPassword(onSubmitPassword)}>
                                        <div className="input-block">
                                            <label>OTP</label>

                                            {isHidden && <Controller
                                                name="resetemail"
                                                control={Control}
                                                render={({ field }) => (
                                                    <input
                                                        className={`form-control ${passwordErrors?.resetemail ? "error-input" : ""}`}
                                                        type="text"
                                                        {...field}
                                                        autoComplete="true"
                                                    />
                                                )}
                                            />}

                                            <Controller
                                                name="otp"
                                                control={Control}
                                                render={({ field }) => (
                                                    <input
                                                        className={`form-control ${passwordErrors.otp ? "error-input" : ""}`}
                                                        type="number"
                                                        {...field}
                                                        autoComplete="false"
                                                    />
                                                )}
                                            />
                                            <span className="text-danger">{passwordErrors.otp?.message}</span>
                                        </div>

                                        <div className="input-block">
                                            <label>New Password</label>
                                            <Controller
                                                name="password"
                                                control={Control}
                                                render={({ field }) => (
                                                    <input
                                                        className={`form-control ${passwordErrors?.password ? "error-input" : ""}`}
                                                        type="password"
                                                        {...field}
                                                        autoComplete="true"
                                                    />
                                                )}
                                            />
                                            <span className="text-danger">{passwordErrors.password?.message}</span>
                                        </div>

                                        <div className="input-block">
                                            <label>Confirm Password</label>
                                            <Controller
                                                name="password_confirmation"
                                                control={Control}
                                                render={({ field }) => (
                                                    <input
                                                        className={`form-control ${passwordErrors?.password_confirmation ? "error-input" : ""}`}
                                                        type="password"
                                                        {...field}
                                                        autoComplete="true"
                                                    />
                                                )}
                                            />
                                            <span className="text-danger">{passwordErrors.password_confirmation?.message}</span>
                                        </div>

                                        <div className="input-block text-center">
                                            <button className="btn btn-primary account-btn" type="submit">
                                                Reset Password
                                            </button>
                                        </div>
                                        <div className="account-footer">
                                            <p>
                                                Remember your password? <Link to="/">Login</Link>
                                            </p>
                                        </div>
                                    </form>
                                )}

                            </div>

                            <div className="col-lg-6 account-login-image text-center">
                                <img className='mb-3' src={logoWhiteVertical} width={150} alt="img" />
                                <p className="text-center">
                                    Work Smarter, Track Better.
                                </p>
                            </div>
                        </div>
                    </div>






                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
