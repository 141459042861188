import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

const AssetFilter = ({ onSearch }) => {

  const [inputValue, setInputValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");
  const [focused, setFocused] = useState(false);

  const handleLabelClick = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    if (inputValue === "") {
      setFocused(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value !== "" && !focused) {
      setFocused(true);
    }
  };

  const search = () => {

    console.log(statusValue);

    onSearch(inputValue,statusValue);

  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      search();
    }
  };

  const statusOptions = [{
    value: 'alloted',
    label: 'Assign',
}, {
    value: 'intransit',
    label: 'In Transit',
},
{
    value: "all",
    label: 'All',
}, {
    value: 'available',
    label: 'Available',
},
{
    value: 'maintainance',
    label: 'Maintainance',
}];

  return (
    <div>
      <div className="row filter-row justify-content-end text-end">
        <div className="col-sm-4 col-md-7">
          <div
            className={
              focused || inputValue !== ""
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={inputValue}
              onFocus={handleLabelClick}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown} // Add the onKeyDown event
            />
            <label className="focus-label" onClick={handleLabelClick}>
              Search by brand, serial number , code and employee.
            </label>
          </div>
        </div>
        <div className="col-sm-4 col-md-3">
                    <div
                    >
                            <Select
                            options={statusOptions} // This should be an array of objects with label and value
                            placeholder="Select"
                            value={statusOptions?.find(option => option.value === statusValue) || null} // Set the selected option
                            onChange={(selectedOption) => {
                                setStatusValue(selectedOption.value) // Store only the value
                            }}
                    />
                </div>
        </div>
        <div className="col-sm-4 col-md-2">
          <Link to="#" className="btn btn-success" onClick={search}>
            <i className="fa fa-search" /> Search
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AssetFilter;
