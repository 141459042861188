import React, { useEffect, useState } from "react";
import { message, Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import DeleteModal from "./model/DeleteModel";
import { useDispatch, useSelector } from 'react-redux';
import { getContractEmployeeSalaries,addSalaryPayment,deleteSalaryPayment,getSalaryPayments ,generateContractEmployeeSalaries, exportSalary, getDistinctSalaryMonths, deleteSalaryDocument, getSalaryDocuments,updateContractEmployeeSalary } from '../../../redux/contractSlice';
import ContractEmployeeDateFilter from "./ContractEmployeeDateFilter";
import AssetCategoryFilter from "./ContractEmployeeDateFilter";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getContractEmployess } from '../../../redux/commonSlice';
import AddEditemployeeSalaryDocument from "./model/AddEditEmployeeSalaryDocument";
import AddEditEmployeeSalaryPayment from "./model/AddEditEmployeeSalaryPayment";
import Button from 'react-bootstrap/Button';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { format, parseISO } from 'date-fns';

const ContractEmployeeSalary = () => {
    const dispatch = useDispatch();
    const {
        contractEmployeeSalary = [],
        loading = false,
        pagination = {},
        success = null,
        message = '',
        search = '',
        salaryMonths = [],

    } = useSelector((state) => state.contract || {});

    const [employees, setEmployees] = useState([]);

    const { contract_employess } = useSelector((state) => state.common);

    const [paginationState, setPaginationState] = useState({
        current: 1,
        pageSize: 10,
    });

    const [editingRowId, setEditingRowId] = useState(null);
    const [editedRowData, setEditedRowData] = useState({});

    useEffect(() => {
        dispatch(getContractEmployeeSalaries({ page: paginationState.current, per_page: paginationState.pageSize, salary_month: selectedSalaryMonth, selectedEmployee: selectedUser }));
    }, [dispatch, paginationState.current, paginationState.pageSize]);

    useEffect(() => {
        dispatch(getContractEmployess());
        dispatch(getDistinctSalaryMonths());
    }, [dispatch]);

    const userElements = contractEmployeeSalary?.map((salary, index) => ({
        key: index,
        id: salary.id,
        contract_code: salary.contract_code,
        name: salary.name,
        per_month_cost: salary.per_month_cost,
        total_working_days: salary.total_working_days,
        number_of_days_worked: salary.number_of_days_worked,
        monthly_salary: '₹ ' + salary.monthly_salary,
        tds: salary.tds?salary.tds:0,
        net_amount_paid:salary?.net_salary ? salary.net_salary:0,
        net_salary: '₹ ' + (salary?.net_salary ? salary.net_salary?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"),
        status: 'Active',
        po_start_date: salary.po_start_date ?? "",
        po_end_date: salary.po_end_date ?? "",
        po_value: salary.po_value ?? "",
        po_type: salary.po_type ?? "",
        gst_rate: salary?.gst_rate ? '₹ ' + salary.gst_rate?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }): "₹ 0.00",
        company_rate: salary?.company_rate ? salary?.company_rate:0.00,
        salary_month: salary.salary_month ?? "",
        amount_paid:salary.salary_payments ? salary.salary_payments.reduce((sum, payment) => sum + parseFloat(payment.amount), 0):"₹ 0.00",
        payment_date:salary.salary_payments ? salary.salary_payments.map(payment => payment.payment_date).join(", "):"",
        gst_applicable:(salary?.gst_applicable === 0 ? false :true )?? false,
    }));

    const [salaryMonthsLst, setSalaryMonthsLst] = useState([]);
    const [selectedSalaryMonth, setSelectedSalaryMonth] = useState("");
    const [selectedUser, setSelectedUser] = useState("all");
    const [selectedSalaryId, setSelectedSalaryId] = useState();
    const [selectedAmountPaid, setSelectedAmountPaid] = useState(0);
    const [docId, setDocId] = useState();
    const [salaryPaymentId, setSalaryPaymentId] = useState();

    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const handlePaymentModalShow = () => setShowPaymentModal(true);
    const handlePayementModalClose = () => setShowPaymentModal(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleDeleteShow = () => setShowDeleteModal(true);
    const handleDeleteClose = () => setShowDeleteModal(false);

    const [showPaymentDeleteModal, setShowPaymentDeleteModal] = useState(false);
    const handlePaymentDeleteShow = () => setShowPaymentDeleteModal(true);
    const handlePaymentDeleteClose = () => setShowPaymentDeleteModal(false);

    useEffect(() => {
        setEmployees(contract_employess);
    }, [contract_employess, setEmployees]);

    useEffect(() => {
        setSalaryMonthsLst(salaryMonths);
    }, [salaryMonths, setSalaryMonthsLst]);

    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };
    const handleGstCheck = (value) => {

    };

    useEffect(() => {
        if (success) {
            console.log(success);
            showAlert({
                title: 'Contract',
                text: message,
                confirmButtonColor: '#FF902F',
                confirmButtonText: 'OK',
            });
            handleModalClose();
            handlePayementModalClose();
        }

    }, [success, showAlert]);

    const poOptions = [{
        value: 'Monthly',
        label: 'Monthly',
    }, {
        value: 'Lumpsum',
        label: 'Lumpsum',
    }];

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            sorter: (a, b) => a.id.length - b.id.length,
            width: "10%",
        },
        {
            title: "Emp Code",
            dataIndex: "contract_code",
            sorter: (a, b) => a.contract_code.length - b.contract_code.length,
            width: "20%",
        },
        {
            title: "Month",
            dataIndex: "salary_month",
            sorter: (a, b) => a.salary_month.length - b.salary_month.length,
            width: "10%",
            render: (salary_month) => {
                const date = new Date(salary_month + "-01"); // Convert to a full date
                return date.toLocaleString("default", { month: "long", year: "numeric" }); // Format to "April 2024"
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            fixed: 'left',
            sorter: (a, b) => a.name.length - b.name.length,
            width: "20%",
        },
        {
            title: "Status",
            dataIndex: "status",
            sorter: (a, b) => a.status.length - b.status.length,
            width: "20%",
        },
        {
            title: "Company Rate",
            dataIndex: "company_rate",
            sorter: (a, b) => a.company_rate.length - b.company_rate.length,
            width: "20%",
            render: (text, record) =>
                editingRowId === record.id ? (
                    <input
                        type="number"
                        className="form-control"
                        value={editedRowData.company_rate}
                        onChange={(e) => handleInputChange("company_rate", e.target.value)}
                    />
                ) : (
                    text ? '₹ ' + text?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }):"₹ 0.00"
            ),
        },
        {
            title: "Per Month",
            dataIndex: "per_month_cost",
            sorter: (a, b) => a.per_month_cost.length - b.per_month_cost.length,
            width: "20%",
            render: (text, record) =>
                editingRowId === record.id ? (
                    <input
                        type="number"
                        className="form-control"
                        value={editedRowData.per_month_cost}
                        onChange={(e) => handleInputChange("per_month_cost", e.target.value)}
                    />
                ) : (
                     text
                ),
        },
        {
            title: "Working Days",
            dataIndex: "total_working_days",
            sorter: (a, b) => a.total_working_days.length - b.total_working_days.length,
            width: "20%",
            render: (text, record) =>
                editingRowId === record.id ? (
                    <input
                        type="number"
                        className="form-control"
                        value={editedRowData.total_working_days}
                        onChange={(e) => handleInputChange("total_working_days", e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: "Days Worked",
            dataIndex: "number_of_days_worked",
            sorter: (a, b) => a.number_of_days_worked.length - b.number_of_days_worked.length,
            width: "20%",
            render: (text, record) =>
                editingRowId === record.id ? (
                    <input
                        type="number"
                        className="form-control"
                        value={editedRowData.number_of_days_worked}
                        onChange={(e) => handleInputChange("number_of_days_worked", e.target.value)}
                    />
                ) : (
                    text
                ),
        },
        {
            title: "Monthly Salary",
            dataIndex: "monthly_salary",
            sorter: (a, b) => a.monthly_salary.length - b.monthly_salary.length,
            width: "20%",
        },
        {
            title: "TDS(10%)",
            dataIndex: "tds",
            sorter: (a, b) => a.tds.length - b.tds.length,
            width: "20%",
            render: (text, record) =>
                editingRowId === record.id ? (
                    <input
                        type="number"
                        className="form-control"
                        value={editedRowData.tds}
                        onChange={(e) => handleInputChange("tds", e.target.value)}
                    />
                ) : (
                    '₹ ' + text?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                ),
        },
        {
            title: "Gst",
            dataIndex: "gst_rate",
            sorter: (a, b) => a.gst_rate.length - b.gst_rate.length,
            width: "20%",
        },

        {
            title: "Gst Apply",
            dataIndex: "gst_applicable",
            sorter: (a, b) => a.gst_applicable.length - b.gst_applicable.length,
            width: "20%",
            render: (text, record) =>
                editingRowId === record.id ? (
                    <div className="status-toggle">
                        <input
                            type="checkbox"
                            id={record.id}
                            className="check"
                            onChange={(e) => handleInputChange("gst_applicable", e.target.checked)}
                            checked={editedRowData.gst_applicable} // Ensure a controlled value
                        />
                        <label htmlFor={record.id} className="checktoggle">
                            checkbox
                        </label>
                    </div>
                ) : (
                    text ? "Yes" : "No"
                ),
        },
        {
            title: "Net Salary",
            dataIndex: "net_salary",
            sorter: (a, b) => a.net_salary.length - b.net_salary.length,
            width: "20%",
        },
        {
            title: "Amount Paid",
            dataIndex: "amount_paid",
            sorter: (a, b) => a.amount_paid.length - b.amount_paid.length,
            width: "20%",
        },
        {
            title: "Payment Date",
            dataIndex: "payment_date",
            sorter: (a, b) => a.payment_date.length - b.payment_date.length,
            width: "20%",

        },
        {
            title: "PO Start Date",
            dataIndex: "po_start_date",
            render: (text, record) =>
                editingRowId === record.id ? (
                    <div className="cal-icon">
                                            <DatePicker
                                                className={`form-control w-100}`}
                                                selected={editedRowData.po_start_date ? parseISO(editedRowData.po_start_date) : null}
                                                onChange={(value) => handleDateChange("po_start_date", value )}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select a date"
                                            />
                    </div>
                    // <input
                    //     type="date"
                    //     value={editedRowData.po_start_date}
                    //     onChange={(e) => handleInputChange("po_start_date", e.target.value)}
                    // />
                ) : (
                    text
                ),
            width: 150,
        },
        {
            title: "PO End Date",
            dataIndex: "po_end_date",
            render: (text, record) =>
                editingRowId === record.id ? (
                    <div className="cal-icon">
                    <DatePicker
                        className={`form-control w-100}`}
                        selected={editedRowData.po_end_date ? parseISO(editedRowData.po_end_date) : null}
                        onChange={(value) =>
                            handleDateChange("po_end_date", value)}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Select a date"
                    />
</div>
                    // <input
                    //     type="date"
                    //     value={editedRowData.po_end_date}
                    //     onChange={(e) => handleInputChange("po_end_date", e.target.value)}
                    // />
                ) : (
                    text
                ),
            width: 150,
        },
        {
            title: "PO Value",
            dataIndex: "po_value",
            render: (text, record) =>
                editingRowId === record.id ? (
                    <input
                        type="number"
                        className="form-control"
                        value={editedRowData.po_value}
                        onChange={(e) => handleInputChange("po_value", e.target.value)}
                    />
                ) : (
                    text
                ),
            width: "15%",
        },
        {
            title: "PO Type",
            dataIndex: "po_type",
            render: (text, record) =>
                editingRowId === record.id ? (
                    <Select
                        options={poOptions}
                        value={poOptions?.find(option => option.value === editedRowData.po_type ) || null}
                        onChange={(option) => handleInputChange("po_type", option.value)}
                        style={{ width: "100%" }}
                    >
                    </Select>
                ) : (
                    text
                ),
            width: "15%",
        },
        {
            title: "Action",
            className: "text-end",
            fixed: 'right',
            render: (text, salary) => (
                editingRowId === salary.id ? (
                    <span>
                        <Button type="primary" onClick={handleSaveClick} style={{ marginRight: 8 }}>
                            Save
                        </Button>
                        <Button onClick={() => setEditingRowId(null)}>Cancel</Button>
                    </span>
                ) : (
                    <div className="dropdown dropdown-action text-end me-30">
                        {/* <Button onClick={() => handleEditClick(salary)}>Edit</Button> */}
                        <Link
                            className="dropdown-item1 m-r-10"
                            to="#"
                            onClick={() => onSalaryPaymentClick(salary.id,salary.net_amount_paid)}
                        >
                            <i className="fa fa-credit-card m-r-5" />
                        </Link>
                        <Link
                            className="dropdown-item1 m-r-10"
                            to="#"
                            onClick={() => handleEditClick(salary)}
                        >
                            <i className="fa fa-pencil m-r-5" />
                        </Link>
                        <Link
                            className="dropdown-item1 m-r-10"
                            to="#"
                            onClick={() => onUploadDocClick(salary.id)}
                        >
                            <i className="fa fa-upload m-r-5" />
                        </Link>
                    </div>
                )
            ),
            width: "50%",
        },
    ];


    const onSearch = (salaryMonth, selectedEmployee) => {
        dispatch(getContractEmployeeSalaries({ page: paginationState.current, per_page: paginationState.pageSize, salary_month: salaryMonth, selectedEmployee: selectedEmployee }));
        //   console.log(search, 'pp');
    }

    const onGenerateSalaryClick = (selectedSalaryMonth) => {
        dispatch(generateContractEmployeeSalaries());
        dispatch(getDistinctSalaryMonths());
        dispatch(getContractEmployeeSalaries({ page: paginationState.current, per_page: paginationState.pageSize, salary_month: selectedSalaryMonth }));
        //   console.log(search, 'pp');
    }

    const onDownloadXlsClick = () => {
        dispatch(exportSalary(selectedSalaryMonth,selectedUser));
        //   console.log(search, 'pp');
    }

    const onUploadDocClick = (id) => {
        // console.log(id);
        setSelectedSalaryId(id);
        handleShow();
    }

    const onSalaryPaymentClick = (id,amountPaid) => {
        // console.log(id);
        setSelectedSalaryId(id);
        // console.log(amountPaid);
        setSelectedAmountPaid(amountPaid);
        handlePaymentModalShow();
    }

    const onDocDeleteClick = (id) => {
        // console.log(id);
        setDocId(id);
        handleDeleteShow();
    }

    const onPaymentDeleteClick = (id) => {
        // console.log(id);
        setSalaryPaymentId(id);
        handlePaymentDeleteShow();
    }
    const handleDocDelete = (id) => {
        dispatch(deleteSalaryDocument(docId));
        dispatch(getSalaryDocuments(selectedSalaryId));
        handleDeleteClose();
    }

    const handlePaymentDelete = () => {
        dispatch(deleteSalaryPayment(salaryPaymentId));
        dispatch(getSalaryPayments(selectedSalaryId));
        handlePaymentDeleteClose();
    }

    const handleEditClick = (record) => {
        setEditingRowId(record.id);
        setEditedRowData(record);
    };

    const handleInputChange = (field, value) => {
        setEditedRowData((prev) => ({ ...prev, [field]: value }));
    };

    const handleDateChange = (key, value) => {
        if (value) {
            const localDate = new Date(value.getTime() - value.getTimezoneOffset() * 60000); // Adjust for timezone
            const formattedDate = localDate.toISOString().split("T")[0]; // Format as "yyyy-MM-dd"
            setEditedRowData((prev) => ({
                ...prev,
                [key]: formattedDate,
            }));
        } else {
            setEditedRowData((prev) => ({
                ...prev,
                [key]: null,
            }));
        }
    };

    const handleSaveClick = async () => {
        try {

            dispatch(updateContractEmployeeSalary({ data:editedRowData,page: paginationState.current, per_page: paginationState.pageSize,salary_month: selectedSalaryMonth, selectedEmployee: selectedUser }));
            // message.success("Salary details updated successfully!");
            setEditingRowId(null);
            //dispatch(getContractEmployeeSalaries({ page: paginationState.current, per_page: paginationState.pageSize,salary_month: selectedSalaryMonth, selectedEmployee: selectedUser }));
        } catch (error) {
            console.log(error);
        }
    };

    const handleSalaryPaymentSaveClick = async (values) => {
        try {
                await dispatch(addSalaryPayment({ data:values,page: paginationState.current, per_page: paginationState.pageSize,salary_month: selectedSalaryMonth, selectedEmployee: selectedUser }));

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <Breadcrumbs
                                maintitle="Contract Salary"
                                title="Contract"
                                subtitle="Contract Employee Salries"
                                modal="#add_assetcategory"
                                name="Contractor Salary"
                            />
                            <div className="col-auto float-end ms-auto">

                                <button type="button" className="btn btn-primary" on fdprocessedid="as7mtr" onClick={() => onGenerateSalaryClick()} >   <i className="fa fa-plus" /> Generate Salary</button>
                                <button type="button" className="btn btn-success ms-2" on fdprocessedid="as7mtr" onClick={() => onDownloadXlsClick()}>   <i className="las la-file-export" /> Download .xls file</button>

                            </div>
                        </div>
                    </div>

                    <ContractEmployeeDateFilter SelectedSalaryMonth={selectedSalaryMonth} SetSelectedSalaryMonth={setSelectedSalaryMonth} setSelectedUser={setSelectedUser} onSearch={onSearch} options={salaryMonthsLst} contractEmployees={employees} />

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0 data-table-card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <Table
                                            className="table-stripped"
                                            columns={columns}
                                            dataSource={
                                                userElements?.length > 0
                                                    ? userElements?.map((assetcategory) => ({
                                                        ...assetcategory,
                                                        key: assetcategory.id,
                                                    }))
                                                    : []
                                            }
                                            pagination={{
                                                current: paginationState.current,
                                                pageSize: paginationState.pageSize,
                                                total: pagination?.total,
                                                onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                                            }}
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddEditemployeeSalaryDocument show={showModal} handleClose={handleModalClose} employeeSalaryId={selectedSalaryId} onDocDeleteClick={onDocDeleteClick} />
            <AddEditEmployeeSalaryPayment handleSalaryPaymentSaveClick={handleSalaryPaymentSaveClick} amountPaid={selectedAmountPaid} show={showPaymentModal} handleClose={handlePayementModalClose} employeeSalaryId={selectedSalaryId} onSalaryPaymentDeleteClick={onPaymentDeleteClick} />
            <DeleteModal show={showDeleteModal} handleClose={handleDeleteClose} onDeleteDocument={handleDocDelete} name="Delete Documents" />
            <DeleteModal show={showPaymentDeleteModal} handleClose={handlePaymentDeleteClose} onDeleteDocument={handlePaymentDelete} name="Delete Payment" />
        </div>
    );
};

export default ContractEmployeeSalary;
