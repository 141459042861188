import moment from "moment";

export const secondsToHMS = (seconds) => {
  if (seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} Hr`;
  } else {
    return "";
  }
}

export const currentMonthDateRange = (seconds) => {
  return {
    startDate: moment().startOf('month').format('MM/DD/YYYY'),
    endDate: moment().format('MM/DD/YYYY')
  };
}

export const getHrs = (durations, type) => {
  let totalSeconds = 0;

  if (type === 'billable') {
    if (durations.billable) {
      durations.billable.forEach(duration => {
        totalSeconds += parseInt(duration.total_duration_seconds);
      });
    }
  } else if(type === 'nonbillable') {
    if (durations.non_billable) {
      durations.non_billable.forEach(duration => {
        totalSeconds += parseInt(duration.total_duration_seconds);
      });
    }
  }else if(type === 'internal'){
    if (durations.internal) {
      durations.internal.forEach(duration => {
        totalSeconds += parseInt(duration.total_duration_seconds);
      });
    }

  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  return `${hours}:${minutes}`;
}


export const formatDate = (date, format="E MMM dd yyyy") => {
  const providedDate = moment(date); // Parse the provided date
  return providedDate.format('DD/MM/YYYY');
};

export const userFriendlyDate = (date, format="E MMM dd yyyy") => {
  const providedDate = moment(date); // Parse the provided date
  return providedDate.format(format);
};

export const myTimeZone = (dateTime) => {
  var ts = moment.utc(dateTime);
  return ts.local().format('HH:mm')
} 

