import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useDispatch, useSelector } from 'react-redux';
import { sendReminderPemRequest, removeReminderData } from "../../../../../../redux/pemRequestSlice";

const PendingReview = ({ pendingReview, pemId }) => {
    const MySwal = withReactContent(Swal);

    const dispatch = useDispatch();
    const { loadingreminder, successreminder } = useSelector((state) => state.pemrequest);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    const sendReminderModal = async (id) => {
        try {
            const jsonData = { id };
            await dispatch(sendReminderPemRequest(jsonData));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (successreminder) {
            showAlert({
                title: 'Reminder Sent',
                text: successreminder,
                confirmButtonColor: '#FF902F',
                confirmButtonText: 'OK',
            });

            dispatch(removeReminderData());
        }
    }, [successreminder, dispatch]);

    // Return null conditionally instead of conditionally calling hooks
    if (pendingReview.length === 0) {
        return null;
    }

    return (
        <div>
            <h4 className="blinking-text text-danger py-2">
                Project Pending Review (Without taking review process cannot be completed)
            </h4>
            <div className="table-responsive">
                <table className="table rating-table w-100">
                    <thead>
                        <tr className="table-active">
                            <th>Project</th>
                            <th>Manager</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingReview.map((pReview, index) => (
                            <tr key={index}>
                                <td>{pReview.project_name}</td>
                                <td>{pReview.project_manager}</td>
                            </tr>
                        ))}
                        <tr>
                            <td></td>
                            <td className="text-end-td">
                                {!loadingreminder ? (
                                    <button
                                        onClick={() => sendReminderModal(pemId)}
                                        className="btn btn-primary"
                                        id="sendReminder"
                                    >
                                        Send Reminder
                                    </button>
                                ) : (
                                    <button type="button" className="btn btn-primary" disabled>
                                        <i className="fas fa-spinner fa-spin me-2" />
                                        Processing...
                                    </button>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PendingReview;
