import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
  allData: [],
  search: '',
  fields: ['technology', 'business_area'],
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null
};

const projectTechSlice = createSlice({
    name: 'ProjectTech',
    initialState,
    reducers: {
      projectTechRequestStart: (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      },
      projectTechRequestSuccess: (state, action) => {
        state.allData = action.payload.data;
        state.pagination = action.payload.pagination;
        state.loading = false;
      },
      projectTechRequestFailure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = null;
      },
      projectTechAdded: (state, action) => {
        state.error = null;
        state.success = action.payload.message;
        state.message = action.payload.message;
        state.loading = false;
      },
      removeErrorMsg: (state) => {
        state.error = null;
        state.success = null;
        state.message = null;
      },
    },
  });

  export const {
    projectTechRequestStart,
    projectTechRequestSuccess,
    projectTechRequestFailure,
    projectTechAdded,
    removeErrorMsg
  } = projectTechSlice.actions;

  export default projectTechSlice.reducer;

  export const getAllprojectTech = ({ page, per_page, search }) => async (dispatch) => {
    dispatch(projectTechRequestStart());
    try {
      const response = await axiosInstance.get(
        `/projectTech?page=${page}&per_page=${per_page}&search=${search}&fields[]=technology&fields[]=business_area`
      );
      const { data, status, message, pagination } = response.data;
      if (status) {
        dispatch(projectTechRequestSuccess({ data, pagination }));
      } else {
        dispatch(projectTechRequestFailure(message || 'Failed to fetch data'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
      dispatch(projectTechRequestFailure(errorMessage));
    }
}

export const addProjectTech = (projectTechData) => async (dispatch) => {
    dispatch(projectTechRequestStart());
    console.log("Project Technology data being sent for adding:", projectTechData); // Logging the data

    try {
        const response = await axiosInstance.post(
            `/projectTech/add`,
            {
                technology: projectTechData.technology,  // Map fields correctly
                business_area: projectTechData.business_area,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        const { status, message } = response.data;
        if (status) {
            dispatch(projectTechAdded({ message }));
        } else {
            dispatch(projectTechRequestFailure(message || 'Failed to add projectTech'));
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors;
            const errorMessage = Object.values(validationErrors).flat().join(', ');
            dispatch(projectTechRequestFailure(errorMessage || 'Failed to add Project Technology'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to add Project Technology';
            dispatch(projectTechRequestFailure(errorMessage));
        }
    }
};

// Redux action to update projectTech data
export const updateProjectTech = (projectTechData) => async (dispatch) => {
    //console.log(projectTechData)
    dispatch(projectTechRequestStart());

    try {
        const requestData = {
            id:projectTechData.id,
            technology: projectTechData.technology,
            business_area: projectTechData.business_area,
        };

        //console.log("Sending update with data:", requestData);

        const response = await axiosInstance.put(
            `/projectTech/update/${projectTechData.id}`,
            requestData,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        console.log("Response from server:", response);

        const { status, message } = response.data;

        if (status) {
            dispatch(projectTechAdded({ message }));
        } else {
            dispatch(projectTechRequestFailure(message || 'Failed to update Project Technology'));
        }
    } catch (error) {
        // Log detailed error information
        console.error("Error updating Project Technology:", {
            message: error.message,
            response: error.response ? {
                data: error.response.data,
                status: error.response.status,
                headers: error.response.headers
            } : null,
            request: error.request || null,
            config: error.config
        });

        const errorMessage = error.response?.data?.message || error.message || 'Failed to update Project Technology';
        dispatch(projectTechRequestFailure(errorMessage));
    }
};

    export const deleteProjectTech = (id) => async (dispatch) => {
        dispatch(projectTechRequestStart());
        try {
          const response = await axiosInstance.delete(`/projectTech/${id}`);
          const { status, message } = response.data;
          if (status) {
            dispatch(projectTechAdded({ message }));
          } else {
            dispatch(projectTechRequestFailure(message || 'Failed to delete Project Technology'));
          }
        } catch (error) {
          const errorMessage = error.response?.data?.message || error.message || 'Failed to delete data';
          dispatch(projectTechRequestFailure(errorMessage));
        }


  };
