import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addprojectPartner, removeErrorMsg, updateprojectPartner } from "../../../../../redux/projectPartnerSlice";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alert from "../../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const validationSchema = Yup.object().shape({
    partner: Yup.string().required("Partner is required"),
    region: Yup.string().required("Region is required"),
});

function AddEditprojectPartner({ show, handleClose, projectPartner }) {
    //console.log(projectPartner,'projectPartner')
    const { loading, error, success } = useSelector((state) => state.projectPartner);
    const dispatch = useDispatch();
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            id:"",
            partner: "",
            region: "",
        },
    });

    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    // Handle form submission
    const onSubmit = async (values) => {
        try {
            if (projectPartner) {
                // If editing an existing projectPartner, add the projectPartner ID to the values
                values.id = projectPartner.id;
                await dispatch(updateprojectPartner(values)); // Dispatch update
            } else {
                // If adding a new projectPartner, dispatch addprojectPartner
                await dispatch(addprojectPartner(values));
            }
        } catch (error) {
            console.error(error);
        }
    };

     // Reset the form based on projectPartner data
     useEffect(() => {
        if (projectPartner) {
            reset({
                partner: projectPartner.partner || "",
                region: projectPartner.region || "",
            });
        } else {
            reset({
                partner: "",
                region: "",
            });
        }
    }, [projectPartner, reset]);

    // Handle modal close
    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{projectPartner ? 'Edit Project Partner' : 'Add Project Partner'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-block mb-3">
                    <Controller
                            name="id"
                            control={control}
                            render={({ field }) => (
                                <input name="id"
                                value={projectPartner?.id}
                                    className={`form-control ${errors.name ? "error-input" : ""}`}
                                    type="hidden"
                                    {...field}
                                />
                            )}
                        />


                        <label className="col-form-label">
                            Partner <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="partner"
                            control={control}
                            render={({ field }) => (
                                <input name="partner"
                                    className={`form-control ${errors.partner ? "error-input" : ""}`}
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        {errors.partner && <span className="text-danger">{errors.partner.message}</span>}
                    </div>
                    <div className="input-block mb-3">
                        <label className="col-form-label">
                            Region <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="region"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${errors.region ? "error-input" : ""}`}
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        {errors.region && <span className="text-danger">{errors.region.message}</span>}
                    </div>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Save Changes
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );

}

export default AddEditprojectPartner;
