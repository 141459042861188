import React, { useEffect ,useState} from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addAsset, removeErrorMsg, updateAsset, getAllAssetCategories } from "../../../../redux/assetSlice";
import { useDispatch, useSelector } from 'react-redux';
import Alert from "../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { customStyles } from "../../../../helpers/Constants";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { format, parseISO } from 'date-fns';
import { Table } from "antd";
import { Link } from "react-router-dom";




function AllotAssetTable({ assets ,setAssets}) {

    const handleDelete = (assetId) => {
        const updatedAssets = assets.filter(asset => asset.id !== assetId);
        setAssets(updatedAssets);
    };
    const columns = [
        {
            title: "Asset Type",
            dataIndex: "category",
            width: "40%",
        },
        {
            title: "Asset",
            dataIndex: "assetName",
            width: "40%",
        },
        {
            title: "Action",
            className: "text-end",
            render: (text, asset) => (
                    <Link
                    className="dropdown-item1"
                    to="#"
                    onClick={() => handleDelete(asset.id)}
                    >
              <i className="fa fa-trash m-r-5" />
            </Link>
            ),
            width: "10%",
        },
    ];


    return (
        <div className="row">
        <div className="col-12">
            <div className="card data-table-card">
                <div className="card-body">
                    <div className="table-responsive">
                    {assets?.length === 0 && (
                    <div className="no-data-message">
                        No data available
                    </div>
                     )}
                        <Table
                            className="table-stripped"
                            columns={columns}
                            dataSource={
                                assets?.length > 0
                                    ? assets?.map((asset) => ({
                                        ...asset,
                                        key: asset.id,
                                    }))
                                    : []
                                }
                                pagination={false}
                                style={{
                                    height: assets?.length > 0 ? 'auto' : '0px',
                                    visibility: assets?.length > 0 ? 'visible' : 'hidden', // Adjust width based on data
                                    transition: 'width 0.3s ease', // Optional transition for smooth effect
                                }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default AllotAssetTable;
