import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import { useDispatch, useSelector } from 'react-redux';
import { getAllAssets, GetAssetHistory, SubmitAsset,deleteAsset, getAllAssetRequests } from '../../../redux/assetSlice';
import AddEditAsset from './model/AddEditAsset';
import AssetFilter from "./AssetFilter";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AllotAsset from "./model/AllotAsset";
import AssetStatus from "./model/AssetStatusModel";
import AssetMaintenance from "./model/AddAssetMaintenance";
import AssetHistory from "./model/AssetHistory";
import SubmitAssets from "./model/SubmitAsset";
import AssetRequestModal from "./model/AssetRequestModal";
import DeleteModal from "./model/DeleteModel";

const Assets = () => {
    const dispatch = useDispatch();
    const [assetDetails, setAssetDetails] = useState(null);
    const [assignAssetBulk, setAssignAssetBulk] = useState(false);
    const [assetAllHistory, setAssetAllHistory] = useState(null);
    const [assetAllEmployeeRequests, setAssetAllEmployeeRequests] = useState(null);
    const {
        assets = [],
        loading = false,
        pagination = {},
        success = null,
        search = '',
        assetHistory = null,
        allAssetRequests = null,
    } = useSelector((state) => state.asset || {});

    const [paginationState, setPaginationState] = useState({
        current: 1,
        pageSize: 10,
    });
    useEffect(() => {
        dispatch(getAllAssets({ page: paginationState.current, per_page: paginationState.pageSize, search }));
    }, [dispatch, paginationState.current, paginationState.pageSize]);

    useEffect(() => {
        dispatch(getAllAssets({ page: paginationState.current, per_page: paginationState.pageSize, search }));
    }, [dispatch, paginationState.current, paginationState.pageSize]);

    useEffect(() => {
        setAssetAllHistory(assetHistory);
    }, [assetHistory]);

    useEffect(() => {
        setAssetAllEmployeeRequests(allAssetRequests);
    }, [allAssetRequests]);

    const userElements = assets?.map((asset, index) => ({
        key: index,
        id: asset.id,
        asset_code: asset.asset_code,
        brand: asset.brand,
        totalCost: asset.totalCost,
        model: asset.model_serial_number,
        status: asset.status,
        allotedUser: asset.allotedUser,
        owned_by: asset.owned_by,
        asset_type: asset.asset_type_id,
        purchase_date: asset.purchase_date,
        ram: asset.ram,
        processor: asset.processor,
        storage: asset.storage,
        os: asset.os,
        asset_value: asset.asset_value,
        description: asset.description,
        alloted_to: asset.alloted_to,
        asset_disbursements: asset.asset_disbursements,
    }));

    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const [showAllotAssetModal, setShowAllotAssetModal] = useState(false);
    const handleAllotAssetShow = () => setShowAllotAssetModal(true);
    const handleAllotAssetClose = () => setShowAllotAssetModal(false);

    const [showAssetStatusModal, setShowAssetStatusModal] = useState(false);
    const handleAssetStatusShow = () => setShowAssetStatusModal(true);
    const handleAssetStatusClose = () => setShowAssetStatusModal(false);


    const [showAssetMaintenanceModal, setShowAssetMaintenanceModal] = useState(false);
    const handleAssetMaintenanceShow = () => setShowAssetMaintenanceModal(true);
    const handleAssetMaintenanceClose = () => setShowAssetMaintenanceModal(false);

    const [showAssetHistoryModal, setShowAssetHistoryModal] = useState(false);
    const handleAssetHistoryShow = () => setShowAssetHistoryModal(true);
    const handleAssetHistoryClose = () => setShowAssetHistoryModal(false);

    const [showSubmitAssetModal, setShowSubmitAssetModal] = useState(false);
    const handleSubmitAssetShow = () => setShowSubmitAssetModal(true);
    const handleSubmitAssetClose = () => setShowSubmitAssetModal(false);

    const [showAssetRequestModal, setShowAssetRequestModal] = useState(false);
    const handleAssetRequestShow = () => setShowAssetRequestModal(true);
    const handleAssetRequestClose = () => setShowAssetRequestModal(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleDeleteShow = () => setShowDeleteModal(true);
    const handleDeleteClose = () => setShowDeleteModal(false);

    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    const getAllotedToName = (alloted_to) => {
        if (alloted_to?.first_name?.trim() && alloted_to?.last_name?.trim()) {
            return `${alloted_to.first_name} ${alloted_to.last_name}`;
        }
        // console.log(alloted_to?.last_name?.trim());
        // console.log(alloted_to?.name?.trim());
        return alloted_to?.name || '';
    };

    const deleteModal = (data)=> {
        setAssetDetails(data);
        handleDeleteShow()
      }

    const columns = [
        {
            title: "Asset Code",
            dataIndex: "asset_code",
            sorter: (a, b) => a.asset_code.length - b.asset_code.length,
            width: "15%",
        },
        {
            title: "Brand",
            dataIndex: "brand",
            sorter: (a, b) => a.brand.length - b.brand.length,
            width: "15%",
        },

        {
            title: "Serial Number",
            dataIndex: "model",
            sorter: (a, b) => a.model.length - b.model.length,
            width: "30%",
        },
        {
            title: "Amount",
            dataIndex: "totalCost",
            sorter: (a, b) => a.totalCost - b.totalCost,
            width: "10%",
        },

        {
            title: "Status",
            dataIndex: "status",
            sorter: (a, b) => a.status.length - b.status.length,
            width: "10%",
        },
        {
            title: "Alloted To",
            dataIndex: "alloted_to",
            sorter: (a, b) => {
                const nameA = getAllotedToName(a.alloted_to);
                const nameB = getAllotedToName(b.alloted_to);
                return nameA.length - nameB.length;
            },
            width: "20%",
            render: (alloted_to) => getAllotedToName(alloted_to),
        },

        {
            title: "Action",
            className: "text-end",
            render: (text, asset) => (
                <div className="dropdown dropdown-action text-end me-30">
                    {/* <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link> */}
                    {/* <div className="dropdown-menu dropdown-menu-right"> */}
                    <Link
                        className="dropdown-item1 m-r-10"
                        to="#"
                        onClick={() => addModal(asset)}
                    >
                        <i className="fa fa-pencil m-r-5" />
                    </Link>
                    {asset.status === "available" && (
                    <Link
                        className="dropdown-item1 m-r-10"
                        to="#"
                        onClick={() => allotAssetModal(asset, false)}
                    >
                        <i className="fa fa-user m-r-5" />
                    </Link>
                     )}

                        <Link
                            className="dropdown-item1 m-r-10"
                            to="#"
                            onClick={() => AssetStatusModal(asset)}
                        >
                            <i className="fa fa-check m-r-5" />
                        </Link>


                    <Link
                        className="dropdown-item1 m-r-10"
                        to="#"
                        onClick={() => AssetMaintenanceModal(asset)}
                    >
                        <i className="fa fa-wrench m-r-5" />
                    </Link>

                    <Link
                        className="dropdown-item1 m-r-10"
                        to="#"
                        onClick={() => AssetHistoryModal(asset)}
                    >
                        <i className="fa fa-info m-r-5" />
                    </Link>
                    {asset.asset_disbursements && asset.asset_disbursements[0]?.id && (
                    <Link
                        className="dropdown-item1 m-r-10"
                        to="#"
                        onClick={() => SubmitAssetModal(asset)}
                    >
                        <i className="fa fa-suitcase m-r-5" />
                    </Link>
                )}
                    <Link
              className="dropdown-item1 m-r-10"
              to="#"
              onClick={() => deleteModal(asset)}
            >
              <i className="fa fa-trash m-r-5" />
            </Link>
                    {/* </div> */}
                </div>
            ),
            sorter: (a, b) => a.length - b.length,
            width: "30%",
        },
    ];

    //   const haldleModel = () => {
    //     setOpenModel(!openModel)
    //   }
    const addModal = (data) => {
        setAssetDetails(data);
        handleShow()
    }

    const allotAssetModal = (asset, isbulk) => {
        setAssetDetails(asset);
        setAssignAssetBulk(isbulk);
        handleAllotAssetShow()
    }

    const AssetStatusModal = (asset) => {
        setAssetDetails(asset);
        handleAssetStatusShow()
    }

    const AssetMaintenanceModal = (asset) => {
        setAssetDetails(asset);
        handleAssetMaintenanceShow()
    }

    const AssetHistoryModal = (asset) => {
        // setAssetDetails(asset);
        dispatch(GetAssetHistory(asset.id));
        handleAssetHistoryShow()
    }

    const SubmitAssetModal = (asset) => {
        setAssetDetails(asset);
        handleSubmitAssetShow()
    }

    const ShowAssetRequestModal = () => {
        // setAssetDetails(asset);
        dispatch(getAllAssetRequests());
        handleAssetRequestShow()
    }

    useEffect(() => {
        if (success) {
            showAlert({
                title: 'Asset',
                text: success,
                confirmButtonColor: '#FF902F',
                confirmButtonText: 'OK',
            });
            dispatch(getAllAssets({ page: paginationState.current, per_page: paginationState.pageSize, search }));
            handleClose();
            handleAllotAssetClose();
            handleAssetStatusClose();
            handleAssetMaintenanceClose();
            handleAssetHistoryClose();
            handleSubmitAssetClose();
            handleAssetRequestClose();
            handleDeleteClose();
        }
    }, [success, showAlert]);


    const onSearch = (search,status) => {
        dispatch(getAllAssets({ page: paginationState.current, per_page: paginationState.pageSize, search,assetstatus:status }));
        // console.log(search, 'pp');
    }

    const deleteAssetData = (data)=> {
        console.log(data.id);
        handleDeleteClose();
        dispatch(deleteAsset(data.id));
      }
    return (
        <div>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <Breadcrumbs
                                maintitle="Assets"
                                title="Assets"
                                modal="#add_asset"
                                name="Add  Asset"
                            />
                            <div className="col-auto float-end ms-auto">
                                <button type="button" className="btn btn-primary" on fdprocessedid="as7mtr" onClick={() => addModal(null)}>   <i className="fa fa-plus" /> Add Asset</button>
                                <button type="button" className="btn btn-primary ms-2" on fdprocessedid="as7mtr" onClick={() => allotAssetModal(null, true)}>   <i className="fa fa-plus" /> Allot Asset</button>
                                <button type="button" className="btn btn-primary ms-2" on fdprocessedid="as7mtr" onClick={() => ShowAssetRequestModal()}>   <i className="fa fa-plus" /> Asset Request</button>
                            </div>
                        </div>
                    </div>
                    <AssetFilter onSearch={onSearch} />
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0 data-table-card">

                                <div className="card-body">
                                    <div className="table-responsive">
                                        <Table
                                            className="table-stripped"
                                            columns={columns}
                                            dataSource={
                                                userElements?.length > 0
                                                    ? userElements?.map((asset) => ({
                                                        ...asset,
                                                        key: asset.id,
                                                    }))
                                                    : []
                                            }
                                            pagination={{
                                                current: paginationState.current,
                                                pageSize: paginationState.pageSize,
                                                total: pagination?.total,
                                                onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                                            }}
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddEditAsset show={showModal} handleClose={handleClose} asset={assetDetails} />
            <AllotAsset show={showAllotAssetModal} handleClose={handleAllotAssetClose} asset={assetDetails} isBulk={assignAssetBulk} />
            <AssetStatus show={showAssetStatusModal} handleClose={handleAssetStatusClose} asset={assetDetails} />
            <AssetMaintenance show={showAssetMaintenanceModal} handleClose={handleAssetMaintenanceClose} asset={assetDetails} />
            <AssetHistory show={showAssetHistoryModal} handleClose={handleAssetHistoryClose} MaintenanceHistory={assetAllHistory?.maintenanceHistory} AssetHistory={assetAllHistory?.assetHistory} />
            <SubmitAssets show={showSubmitAssetModal} handleClose={handleSubmitAssetClose} assetDisbursment={assetDetails?.asset_disbursements?.find(x => x.status === "allotted")} assetId={assetDetails?.id} />
            <AssetRequestModal show={showAssetRequestModal} handleClose={handleAssetRequestClose} allAssetRequests={assetAllEmployeeRequests} loading={loading} handleOpen={handleAssetRequestShow} />
            <DeleteModal show={showDeleteModal} handleClose={handleDeleteClose}  asset={assetDetails} onDelete={deleteAssetData}  name="Delete Asset" />
        </div>
    );
};

export default Assets;
