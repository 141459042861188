import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import DeleteModal from "./model/DeleteModel";
import { useDispatch, useSelector } from 'react-redux';
import { getAll, deleteData, removeErrorMsg } from '../../../redux/clientSlice';
import { useNavigate } from 'react-router-dom';

// import AddEdit from './model/AddEdit';
import Filter from "./Filter";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { loginAsClient } from '../../../redux/authSlice';
const Clients = () => {

  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false)

  //const is_rootadmin = localStorage.getItem('is_root_admin');

  const [designationDetails, setDesignationDetails] = useState(null);
  const { is_root_admin: is_rootadmin, loading: authloading, isclient, clientID, success: authsuccess, error: autherror } = useSelector((state) => state.auth);



  const { allData, loading, pagination, success, notfound, search } = useSelector((state) => state.client);
  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10,
  });
  useEffect(() => {
    dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
  }, [dispatch, paginationState.current, paginationState.pageSize]);


  console.log(allData, '0099')
  const userElements = allData?.map((data, index) => ({
    key: index,
    id: data.id,
    name: data.name,
    sub_domain: data.sub_domain,
    database_name: data.database_name,
    company_name: data.company_name,
    phone_number: data.phone_number,
    email: data.email,
    code: data.code,
    country_name: data?.country?.name,
    logo_url: data.logo_url,
    is_default: data.is_default


  }));
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);

  const MySwal = withReactContent(Swal);

  const showAlert = (config) => {
    MySwal.fire(config);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      sorter: (a, b) => a.key.length - b.key.length,
      width: "10%",
    },

    {
      title: "Client Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      width: "20%",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to="#" className="avatar">
            <img alt="" src={record.logo_url} />
          </Link>
          {text}
        </span>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },

    // {
    //   title: "Client name",
    //   dataIndex: "name",
    //   sorter: (a, b) => a.name.length - b.name.length,
    //   width: "20%",
    // },
    {
      title: "Subdomain",
      dataIndex: "sub_domain",
      sorter: (a, b) => a.sub_domain.length - b.sub_domain.length,
      width: "10%",
    },
    {
      title: "Database",
      dataIndex: "database_name",
      sorter: (a, b) => a.database_name.length - b.database_name.length,
      width: "10%",
    },
    {
      title: "Company name",
      dataIndex: "company_name",
      sorter: (a, b) => a.company_name.length - b.company_name.length,
      width: "20%",
    },
    {
      title: "email",
      dataIndex: "email",
      sorter: (a, b) => a.id.length - b.id.length,
      width: "20%",
    },

    {
      title: "phone_number",
      dataIndex: "phone_number",
      sorter: (a, b) => a.id.length - b.id.length,
      width: "20%",
    },
    {
      title: "code",
      dataIndex: "code",
      sorter: (a, b) => a.id.length - b.id.length,
      width: "5%",
    },
    {
      title: "Country",
      dataIndex: "country_name",
      sorter: (a, b) => a.country_name.length - b.country_name.length,
      width: "20%",
    },
    {
      title: "Action",
      className: "text-end",
      render: (text, designation) => (

        <div className={`dropdown ${designation.is_default === 1 ? 'd-none' : ''} dropdown-action text-end me-30`}>



          <Link to={`/clients/edit/${designation.id}`}

            className="dropdown-item1 m-r-10"


          >
            <i className="fa fa-pencil m-r-5" />
          </Link>




          {/* { onClick={() => addModal(designation)}} */}

          {is_rootadmin == '1' && !isclient && (

            <>

              {
                !authloading && clientID != designation.id ? (
                  <Link to='#'
                    onClick={() => loginAsClientF(designation.id)}
                    className="dropdown-item1 m-r-10"
                    title="Login as client"

                  >
                    <i className="fa fa-sign-in m-r-5" />
                  </Link>
                ) : (

                  <Link to='#'

                    className="dropdown-item1 m-r-10"
                    title="Login as client"

                  >
                    <i className="fas fa-spinner fa-spin me-2" />
                    Logging in...
                  </Link>

                )

              }
              <Link
                className="dropdown-item1 m-r-10"
                to="#"
                onClick={() => deleteModal(designation)}
              >
                <i className="fa fa-trash m-r-5" />
              </Link>
            </>

          )}

        </div >
      ),
      sorter: (a, b) => a.length - b.length,
      width: "10%",
    },
  ];

  const haldleModel = () => {
    setOpenModel(!openModel)
  }
  const addModal = (data) => {
    setDesignationDetails(data);
    handleShow()
  }

  const deleteModal = (data) => {
    setDesignationDetails(data);
    handleDeleteShow()
  }

  const deleteDesignationData = (data) => {
    handleDeleteClose();
    dispatch(deleteData(data.id));
  }
  const reloadApp = () => {
    navigate('/reload'); // Temporarily navigate to a different route
    setTimeout(() => navigate('/'), 0); // Return to the original route
  };
  const loginAsClientF = (clientIDS) => {

    dispatch(loginAsClient(clientIDS));

    if (!clientID) {


      reloadApp();
      showAlert({
        title: 'Client',
        text: "Successfully logged in as client",
        confirmButtonColor: '#FF902F',
        confirmButtonText: 'OK',
      });

    }

  }

  useEffect(() => {
    console.log('Het');
    if (success) {
      showAlert({
        title: 'Client',
        text: success,
        confirmButtonColor: '#FF902F',
        confirmButtonText: 'OK',
      });
      dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
      handleClose();
      handleDeleteClose();
    }

    if (notfound) {
      showAlert({
        title: 'Client',
        text: 'Client Not found',
        confirmButtonColor: '#FF902F',
        confirmButtonText: 'OK',
      });
      dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
      dispatch(removeErrorMsg());

      //  handleClose();
      //handleDeleteClose();
    }
  }, [success, notfound, showAlert]);


  const onSearch = (search) => {
    dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
    console.log(search, 'pp');
  }
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <Breadcrumbs
                maintitle="Client"
                title="Dashboard"
                subtitle="Client"
              />
              <div className="col-auto float-end ms-auto">

                {is_rootadmin == '1' && !isclient && (
                  <Link to="/clients/add" className="btn btn-primary">
                    <i className="fa fa-plus" /> Add Client
                  </Link>
                )}

                {/* <button type="button" class="btn btn-primary" on fdprocessedid="as7mtr" onClick={() => addModal(null)}>   <i className="fa fa-plus" /> Add Client</button> */}

              </div>
            </div>
          </div>
          <Filter onSearch={onSearch} />
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0 data-table-card">

                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-stripped"
                      columns={columns}
                      rowKey={(record) => record.id}
                      dataSource={
                        userElements?.length > 0
                          ? userElements?.map((designation) => ({
                            ...designation,
                            key: designation.id,
                          }))
                          : []
                      }
                      pagination={{
                        current: paginationState.current,
                        pageSize: paginationState.pageSize,
                        total: pagination?.total,
                        onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AddEdit show={showModal} handleClose={handleClose} data={designationDetails} /> */}
      <DeleteModal show={showDeleteModal} handleClose={handleDeleteClose} data={designationDetails} onDelete={deleteDesignationData} name="Delete Client" />
    </div>
  );
};

export default Clients;
