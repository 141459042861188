import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addAsset, removeErrorMsg, updateAsset, getAllAssetCategories } from "../../../../redux/subscriptionSlice";
import { useDispatch, useSelector } from 'react-redux';
import Alert from "../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { customStyles } from "../../../../helpers/Constants";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { format, parseISO } from 'date-fns';
import { Table } from "antd";
import { Link } from "react-router-dom";




function SoftwareTableModal({ show,handleClose,softwares, setSoftwareDeatils, handleSoftwareEdit, handleSoftwareDelete }) {
    const {
        loading = false,
        error = "",
    } = useSelector((state) => state.subscription || {});

    const dispatch = useDispatch();
    const handleDelete = (software) => {
        setSoftwareDeatils(software);
        handleSoftwareDelete();
    };

    const handleEdit = (software) => {
        setSoftwareDeatils(software);
        console.log(software);
        handleSoftwareEdit();
    };

    const userElements = softwares?.map((software, index) => ({
        key: index,
        id: software.id,
        software_name: software.software_name,
        team: software.team.team,
        no_of_accounts: software.subscriptions ? software.subscriptions.length : 0,
        billing_type: software.billing_type,
        owner: software.user.name ? software.user.name : (software.user.first_name ? software.user.first_name + " " + software.user.last_name : ""),
        team_id:software.team.id,
        owner_id:software.user.id,
        cost: software?.cost || "",
        currency: software?.currency || "",
        start_date: software?.start_date || "",
        end_date: software?.end_date || "",
    }));

    const columns = [
        {
            title: "S.No",
            width: "10%",
            render: (text, record, index) => index + 1, // Calculate serial number based on row index
        },
        {
            title: "Name",
            dataIndex: "software_name",
            width: "20%",
        },
        {
            title: "Team",
            dataIndex: "team",
            width: "10%",
        },
        {
            title: "No Of Accounts",
            dataIndex: "no_of_accounts",
            width: "10%",
        },
        {
            title: "Billing Type",
            dataIndex: "billing_type",
            width: "10%",
        },
        {
            title: "Owner",
            dataIndex: "owner",
            width: "10%",
        },
        {
            title: "Action",
            className: "text-end",
            render: (text, software) => (
                <div className="dropdown dropdown-action text-end me-30">
                    <Link
                        className="dropdown-item1 m-r-10"
                        to="#"
                        onClick={() => handleEdit(software)}
                    >
                        <i className="fa fa-pencil m-r-5" />
                    </Link>
                    <Link
                        className="dropdown-item1"
                        to="#"
                        onClick={() => handleDelete(software)}
                    >
                        <i className="fa fa-trash m-r-5" />
                    </Link>
                </div>
            ),
            width: "10%",
        },
    ];

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };


    return (
        <Modal show={show} onHide={handleClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{'Software List'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}

                <div className="row">
                    <div className="col-12">
                        <div className="card data-table-card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    {softwares?.length === 0 && (
                                        <div className="no-data-message">
                                            No data available
                                        </div>
                                    )}
                                    <Table
                                        className="table-stripped"
                                        columns={columns}
                                        dataSource={
                                            softwares?.length > 0
                                                ? userElements?.map((software) => ({
                                                    ...software,
                                                    key: software.id,
                                                }))
                                                : []
                                        }
                                        pagination={false}
                                        style={{
                                            height: softwares?.length > 0 ? 'auto' : '0px',
                                            visibility: softwares?.length > 0 ? 'visible' : 'hidden', // Adjust width based on data
                                            transition: 'width 0.3s ease', // Optional transition for smooth effect
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>

    );
}

export default SoftwareTableModal;
