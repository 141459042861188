import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; // Import your FormModal component
import { submitData } from "../../../../../redux/pemRequestSlice";
const PrevEvaluation = ({ postdata }) => {

    const dispatch = useDispatch();
    const [ratings, setRatings] = useState([]);
    const [data, setData] = useState([]);
    const handleChange = (id, newValue) => {
        const updatedRatings = ratings.map((rating) =>
            rating.id === id ? { ...rating, value: newValue } : rating
        );

        // If the rating doesn't exist and a new value is provided, add it
        if (!updatedRatings.some((rating) => rating.id === id) && newValue) {
            const newId = id;
            updatedRatings.push({ id: newId, value: newValue });
        }

        setRatings(updatedRatings);
        //console.log(updatedRatings); // Debugging output
    };

    // Pass data to parent component on validation
    //setData(ratings?.map((rating) => rating.value));


    const projectPer = postdata?.projectPer;
    const wfhPer = postdata?.wfhPer;
    const attPer = postdata?.attPer;

    useEffect(() => {

        dispatch(submitData({ data: ratings, type: 'performSelfRating' }));
    }, [ratings]);



    useEffect(() => {

        const updatedRatings = [];
        postdata?.all_questions.map((eachQuestions) => {



            eachQuestions?.teams_question.map((eachQuestion) => {

                const answer = eachQuestion?.pem_answers || {};
                let quantityValue = answer?.self_rating || "";
                if (eachQuestion?.type == 'quantity') {
                    quantityValue = answer?.quantity || "";
                }


                if (eachQuestion?.is_system_generate && (eachQuestion?.parameter === "Project"
                    || eachQuestion?.parameter === "WFH"
                    || eachQuestion?.parameter === "Attendance")



                ) {

                }
                else {
                    updatedRatings.push({ id: eachQuestion.id, value: quantityValue });
                }


            });




        });


        setRatings(updatedRatings);

        // dispatch(submitData({ data: ratings, type: 'performSelfRating' }));
    }, []);
    return (
        <div className="table-responsive">
            <table className="table rating-table">
                <thead>
                    <tr className="table-active">
                        <th>S. No.</th>
                        <th>Parameters</th>
                        <th>Description</th>
                        <th className="weightage">Weightage</th>
                        <th>Self Rating</th>
                        <th>Self Comments</th>
                        {/* <th>Appraiser Rating</th>
                        <th>Appraiser Comments</th> */}
                    </tr>
                </thead>
                <tbody>
                    {/* Check if allQuestions exists and is an array */}
                    {postdata?.all_questions?.length > 0 ? (
                        postdata?.all_questions?.map((eachCat, catIndex) => (
                            <React.Fragment key={`category-${catIndex}`}>
                                <tr style={{ backgroundColor: "#e9ecef" }}>


                                    <td style={{ textAlign: "left" }}>
                                        {eachCat?.category_name || "Unnamed Category"}
                                    </td>
                                    <td
                                        style={{ textAlign: "left" }}
                                    ></td>
                                    <td
                                        style={{ textAlign: "left" }}
                                    ></td>
                                    <td style={{ textAlign: "left" }}>
                                        {eachCat?.teams_question?.[0]?.category_weightage?.toFixed(2) || "0.00"}%

                                    </td>
                                    <td
                                        style={{ textAlign: "left" }}
                                    ></td>
                                    <td
                                        style={{ textAlign: "left" }}
                                    ></td>


                                </tr>
                                {eachCat?.teams_question?.map((eachQuestion, key) => {
                                    const answer = eachQuestion?.pem_answers?.[0] || {};
                                    return (
                                        <tr key={`question-${key}`}>
                                            <input type="hidden" name="questions[]" value={eachQuestion.id} />
                                            <td style={{ borderBottom: "1px solid lightgrey" }}>{key + 1}</td>
                                            <td style={{ borderBottom: "1px solid lightgrey" }}>{eachQuestion.parameter}</td>
                                            <td style={{ borderBottom: "1px solid lightgrey" }}>{eachQuestion.description}</td>
                                            <td style={{ borderBottom: "1px solid lightgrey" }}>
                                                {eachQuestion?.type === "quantity"
                                                    ? eachQuestion?.quantity
                                                    : `${eachQuestion?.weightage || 0} %`}
                                            </td>
                                            <td style={{ borderBottom: "1px solid lightgrey" }}>
                                                {eachQuestion ? (
                                                    (() => {
                                                        const answer = eachQuestion?.pem_answers || {};
                                                        let quantityValue = answer?.self_rating || "";
                                                        if (eachQuestion?.type == 'quantity') {
                                                            quantityValue = answer?.quantity || "";
                                                        }

                                                        return (
                                                            <>
                                                                {eachQuestion?.is_system_generate && eachQuestion?.parameter === "Project" ? (
                                                                    <input
                                                                        readOnly
                                                                        style={{ width: "80px" }}
                                                                        type="number"
                                                                        name="pe_self_rating[]"
                                                                        min="1"
                                                                        value={Math.round(projectPer ?? 0)}
                                                                    />
                                                                ) : eachQuestion?.is_system_generate && eachQuestion?.parameter === "Attendance" ? (
                                                                    <input
                                                                        readOnly
                                                                        style={{ width: "80px" }}
                                                                        type="number"
                                                                        name="pe_self_rating[]"
                                                                        min="1"
                                                                        value={Math.round(attPer ?? 0)}
                                                                    />
                                                                ) : eachQuestion?.is_system_generate && eachQuestion?.parameter === "WFH" ? (
                                                                    <input
                                                                        readOnly
                                                                        style={{ width: "80px" }}
                                                                        type="number"
                                                                        name="pe_self_rating[]"
                                                                        min="1"
                                                                        value={Math.round(wfhPer ?? 0)}
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        key={`pe_self_rating-${eachQuestion.id}`}
                                                                        style={{ width: "80px" }}
                                                                        name="pe_self_rating[]"
                                                                        type="number"
                                                                        min="1"
                                                                        value={ratings.find(rating => rating.id === eachQuestion.id)?.value || quantityValue || ''}
                                                                        onChange={(e) => handleChange(eachQuestion.id, e.target.value)}

                                                                        required
                                                                    />
                                                                )}
                                                            </>
                                                        );
                                                    })()
                                                ) : (
                                                    // Similar code for handling the non-'quantity' case
                                                    <> </>
                                                )}
                                            </td>



                                            <td style={{ borderBottom: "1px solid lightgrey" }}>
                                                {eachQuestion ? (
                                                    (() => {
                                                        const answer = eachQuestion?.pem_answers || {};
                                                        let quantityValue = answer?.self_rating || "";
                                                        if (eachQuestion?.type == 'quantity') {
                                                            quantityValue = answer?.quantity || "";
                                                        }

                                                        if (eachQuestion?.is_system_generate && eachQuestion?.parameter == 'Project') {
                                                            //quantityValue = answer?.quantity || "";
                                                            quantityValue = Math.round(projectPer ?? 0)
                                                        }
                                                        if (eachQuestion?.is_system_generate && eachQuestion?.parameter == 'Attendance') {
                                                            //quantityValue = answer?.quantity || "";
                                                            quantityValue = Math.round(attPer ?? 0)
                                                        }

                                                        if (eachQuestion?.is_system_generate && eachQuestion?.parameter == 'WFH') {
                                                            //quantityValue = answer?.quantity || "";
                                                            quantityValue = Math.round(wfhPer ?? 0)
                                                        }
                                                        return (
                                                            <>
                                                                {eachQuestion?.is_system_generate && eachQuestion?.parameter === "Project" ? (
                                                                    <textarea readOnly name="pe_self_comments[]" rows="1">
                                                                        {quantityValue ? `${quantityValue} entered by user` : ""}
                                                                    </textarea>
                                                                ) : eachQuestion?.is_system_generate && eachQuestion?.parameter === "Attendance" ? (
                                                                    <textarea readOnly name="pe_self_comments[]" rows="1">
                                                                        {quantityValue ? `${quantityValue} entered by user` : ""}
                                                                    </textarea>
                                                                ) : eachQuestion?.is_system_generate && eachQuestion?.parameter === "WFH" ? (
                                                                    <textarea readOnly name="pe_self_comments[]" rows="1">
                                                                        {quantityValue ? `${quantityValue} entered by user` : ""}
                                                                    </textarea>
                                                                ) : (
                                                                    <textarea name="pe_self_comments[]"

                                                                        key={`pe_self_comments-${eachQuestion.id}`}

                                                                        rows="1"></textarea>
                                                                )}
                                                            </>
                                                        );
                                                    })()
                                                ) : (
                                                    // Similar code for handling the non-'quantity' case
                                                    <> </>
                                                )}
                                            </td>


                                        </tr>
                                    );
                                })}
                            </React.Fragment>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="8" style={{ textAlign: "center" }}>
                                No data available.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default PrevEvaluation;
