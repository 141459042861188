import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
    allData: null,
    employeerequests: null,
    search: '',
    fields: ['name'],
    pagination: null,
    loading: false,
    categories: null,
    loadingcompany: false,
    error: null,
    success: null,
    message: null,
    notfound: null,
    loadingsingle: false,
    pemformdata: {}

};

const clientSlice = createSlice({
    name: 'pemrequest',
    initialState,
    reducers: {
        requestStart: (state) => {
            state.loading = true;
            // state.loadingsingle = true;
            state.error = null;
            state.success = null;
            state.notfound = null;
        },

        requestStartReminder: (state) => {
            state.loadingreminder = true;
            // state.loadingsingle = true;
            state.errorreminder = null;
            state.successreminder = null;
            state.notfoundreminder = null;
        },
        requestSingleStart: (state) => {
            state.loading = true;
            state.loadingsingle = true;
            state.error = null;
            state.success = null;
            state.notfound = null;
        },
        requestSuccess: (state, action) => {

            if (action.payload.type === 'employee') {
                state.employeerequests = action.payload.data;
            }
            else {
                state.allData = action.payload.data;
            }
            state.pagination = action.payload.pagination;
            state.loading = false;
        },


        requestSingleRecordSuccess: (state, action) => {
            state.data = action.payload.data;
            state.loading = false;
            state.loadingsingle = false;
        },
        requestSingleRecordError: (state, action) => {
            state.notfound = true;
            state.loading = false;
            state.success = null;
            state.loadingsingle = false;
        },
        requestFailure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.success = null;


        },

        requestFailureReminder: (state, action) => {
            state.errorreminder = action.payload;
            state.loadingreminder = false;
            state.successreminder = null;


        },

        added: (state, action) => {
            state.error = null;
            state.success = action.payload.message;
            state.message = action.payload.message;
            state.loading = false;
            state.loadingsingle = false;
        },
        removeErrorMsg: (state) => {
            state.error = null;
            state.success = null;
            state.message = null;
            state.notfound = null;


        },
        added: (state, action) => {
            state.error = null;
            state.success = action.payload.message;
            state.message = action.payload.message;
            state.loading = false;
            state.loadingsingle = false;
        },
        addedReminder: (state, action) => {
            state.errorreminder = null;
            state.successreminder = action.payload.message;
            state.messagereminder = action.payload.message;
            state.loadingreminder = false;

        },

        removeData: (state, action) => {

            state.data = null;


        },

        removeReminderData: (state, action) => {
            state.errorreminder = null;
            state.successreminder = null
            state.messagereminder = null
            state.loadingreminder = false;


        },

        submitData: (state, action) => {
            // alert(action.payload.type)
            console.log(action.payload.data)
            state.pemformdata[action.payload.type] = action.payload.data;



        },

        removeSubmitData: (state, action) => {
            state.pemformdata[action.payload.type] = null;


        },


    },
});

export const {
    requestStart,
    requestSuccess,
    requestFailure,
    requestSingleStart,
    requestSingleRecordError,
    requestSingleRecordSuccess,
    added,
    removeData,
    removeReminderData,
    submitData,
    requestFailureReminder,
    requestStartReminder,
    addedReminder,
    removeSubmitData,
    removeErrorMsg
} = clientSlice.actions;

export default clientSlice.reducer;


export const getAll = ({ page, per_page, search }) => async (dispatch) => {
    dispatch(requestStart());
    try {


        const response = await axiosInstance.get(`/pem/request?page=${page}&per_page=${per_page}&search=${search}&fields[]=name
            &fields[]=name
               
              
              `);
        const { data, status, message, pagination } = response.data;

        if (status) {
            dispatch(requestSuccess({ data, pagination, type: 'all' }));
        } else {
            dispatch(requestFailure(message || 'Failed to fetch data'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
        dispatch(requestFailure(errorMessage));
    }
};
export const EmployeeProjectReview = ({ page, per_page, search }) => async (dispatch) => {
    dispatch(requestStart());
    try {


        const response = await axiosInstance.get(`/employee-project-review?page=${page}&per_page=${per_page}&search=${search}&fields[]=name
            &fields[]=name
               
              
              `);
        const { data, status, message, pagination } = response.data;

        if (status) {
            dispatch(requestSuccess({ data, pagination }));
        } else {
            dispatch(requestFailure(message || 'Failed to fetch data'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
        dispatch(requestFailure(errorMessage));
    }
};

export const updateEmployeeReview = (id, data) => async (dispatch) => {
    dispatch(requestStart());
    try {
        const response = await axiosInstance.post(`/pem-review-update/${id}`, data);
        const { status, message, data: responseData } = response.data;

        if (status) {
            dispatch(added({ message, data: responseData })); // Dispatch success action
            return { status, message }; // Return success response
        } else {
            throw new Error(message || 'Failed to update the employee review.');
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to update the employee review.';
        dispatch(requestFailure(errorMessage));
        throw new Error(errorMessage); // Throw the error for the calling function
    }
};


export const getMyRequests = ({ page, per_page, search }) => async (dispatch) => {
    dispatch(requestStart());
    try {


        const response = await axiosInstance.get(`/pem/my/request?page=${page}&per_page=${per_page}&search=${search}&fields[]=name
            &fields[]=name
               
              
              `);
        const { data, status, message, pagination } = response.data;

        if (status) {
            dispatch(requestSuccess({ data, pagination, type: 'my' }));
        } else {
            dispatch(requestFailure(message || 'Failed to fetch data'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
        dispatch(requestFailure(errorMessage));
    }
};



export const showDataview = ({ id }) => async (dispatch) => {
    dispatch(requestSingleStart());

    try {

        const response = await axiosInstance.get(`/pem/request/view/${id}`);
        const { data, status, message } = response.data;


        if (status) {
            dispatch(requestSingleRecordSuccess({ data }));
        } else {
            dispatch(requestSingleRecordError(message || 'Failed to fetch categeory'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch categeory';
        dispatch(requestSingleRecordError(errorMessage));
    }
};

export const showData = ({ id }) => async (dispatch) => {
    dispatch(requestSingleStart());

    try {

        const response = await axiosInstance.get(`/pem/request/${id}`);
        const { data, status, message } = response.data;


        if (status) {
            dispatch(requestSingleRecordSuccess({ data }));
        } else {
            dispatch(requestSingleRecordError(message || 'Failed to fetch categeory'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch categeory';
        dispatch(requestSingleRecordError(errorMessage));
    }
};

export const add = (postData) => async (dispatch) => {
    dispatch(requestStart());
    try {

        const response = await axiosInstance.post(`/pem/request`, postData);
        const { status, message } = response.data;
        if (status) {
            dispatch(added({ message }));
        } else {
            console.log('error', message);
            dispatch(requestFailure(message || 'Failed to add categeory'));
        }
    } catch (error) {

        console.log(error)
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors; // Get the validation errors
            const errorMessage = Object.values(validationErrors)
                .flat()
                .join(', '); // Flatten the error messages

            dispatch(requestFailure(errorMessage || 'Failed to add categeory'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to add company';
            dispatch(requestFailure(errorMessage));
        }
    }
};

export const update = (postData, id) => async (dispatch) => {
    dispatch(requestStart());

    try {


        const response = await axiosInstance.post(`/pem/request/${id}`, postData);

        const { data, status, message } = response.data;
        console.log(data); // Log the received data for debugging

        if (status) {
            dispatch(added({ message, data })); // Include data if needed
            // dispatch(getAllDesignations({ page: 1, per_page: 10 }));
        } else {
            dispatch(requestFailure(message || 'Failed to update categeory'));
        }
    } catch (error) {

        console.log(error)
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors; // Get the validation errors
            const errorMessage = Object.values(validationErrors)
                .flat()
                .join(', '); // Flatten the error messages

            dispatch(requestFailure(errorMessage || 'Failed to update categeory'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to update categeory';
            dispatch(requestFailure(errorMessage));
        }
    }
};

export const deleteData = (id) => async (dispatch) => {
    dispatch(requestStart());
    try {

        const response = await axiosInstance.delete(`/pem/request/${id}}`);
        const { data, status, message } = response.data;
        if (status) {
            dispatch(added({ message, data }));
        } else {
            dispatch(requestFailure(message || 'Failed to delete categeory'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to delete categeory';
        dispatch(requestFailure(errorMessage));
    }
};


export const updatePemRequest = (postData, id) => async (dispatch) => {
    dispatch(requestStart());

    try {


        const response = await axiosInstance.post(`/pem/request/update`, postData);

        const { data, status, message } = response.data;
        console.log(data); // Log the received data for debugging

        if (status) {
            dispatch(added({ message, data })); // Include data if needed
            // dispatch(getAllDesignations({ page: 1, per_page: 10 }));
        } else {
            dispatch(requestFailure(message || 'Failed to save '));
        }
    } catch (error) {

        console.log(error)
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors; // Get the validation errors
            const errorMessage = Object.values(validationErrors)
                .flat()
                .join(', '); // Flatten the error messages

            dispatch(requestFailure(errorMessage || 'Failed to save'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to update categeory';
            dispatch(requestFailure(errorMessage));
        }
    }
};


export const getEmployeeRequests = ({ page, per_page, search }) => async (dispatch) => {
    dispatch(requestStart());
    try {


        const response = await axiosInstance.get(`/pem/requestemp?page=${page}&per_page=${per_page}&search=${search}&fields[]=name
            &fields[]=name`);
        const { data, status, message, pagination } = response.data;

        if (status) {
            dispatch(requestSuccess({ data, pagination, type: 'employee' }));
        } else {
            dispatch(requestFailure(message || 'Failed to fetch data'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
        dispatch(requestFailure(errorMessage));
    }
};

export const sendReminderPemRequest = (postData) => async (dispatch) => {
    dispatch(requestStartReminder());

    try {


        const response = await axiosInstance.post(`/pem/request/reminder`, postData);

        const { data, status, message } = response.data;
        console.log(data); // Log the received data for debugging

        if (status) {
            dispatch(addedReminder({ message, data })); // Include data if needed
            // dispatch(getAllDesignations({ page: 1, per_page: 10 }));
        } else {
            dispatch(requestFailureReminder(message || 'Failed to save '));
        }
    } catch (error) {

        console.log(error)
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors; // Get the validation errors
            const errorMessage = Object.values(validationErrors)
                .flat()
                .join(', '); // Flatten the error messages

            dispatch(requestFailure(errorMessage || 'Failed to save'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to update categeory';
            dispatch(requestFailure(errorMessage));
        }
    }
};

export const showDataviewEmployee = ({ id }) => async (dispatch) => {
    dispatch(requestSingleStart());

    try {

        const response = await axiosInstance.get(`/pem/requestemp/view/${id}`);
        const { data, status, message } = response.data;


        if (status) {
            dispatch(requestSingleRecordSuccess({ data }));
        } else {
            dispatch(requestSingleRecordError(message || 'Failed to fetch categeory'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch categeory';
        dispatch(requestSingleRecordError(errorMessage));
    }
};
export const updateReviewReport = (postData, id) => async (dispatch) => {
    dispatch(requestStart());

    try {


        const response = await axiosInstance.post(`/pem/reportview/updateReview/` + id, postData);

        const { data, status, message } = response.data;
        console.log(data); // Log the received data for debugging

        if (status) {
            dispatch(added({ message, data })); // Include data if needed
            // dispatch(getAllDesignations({ page: 1, per_page: 10 }));
        } else {
            dispatch(requestFailure(message || 'Failed to save '));
        }
    } catch (error) {

        console.log(error)
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors; // Get the validation errors
            const errorMessage = Object.values(validationErrors)
                .flat()
                .join(', '); // Flatten the error messages

            dispatch(requestFailure(errorMessage || 'Failed to save'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to update categeory';
            dispatch(requestFailure(errorMessage));
        }
    }
};


export const updatePemRequestReviewed = (postData) => async (dispatch) => {
    dispatch(requestStart());

    try {


        const response = await axiosInstance.post(`/pem/request/updatereview`, postData);

        const { data, status, message } = response.data;
        console.log(data); // Log the received data for debugging

        if (status) {
            dispatch(added({ message, data })); // Include data if needed
            // dispatch(getAllDesignations({ page: 1, per_page: 10 }));
        } else {
            dispatch(requestFailure(message || 'Failed to save '));
        }
    } catch (error) {

        console.log(error)
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors; // Get the validation errors
            const errorMessage = Object.values(validationErrors)
                .flat()
                .join(', '); // Flatten the error messages

            dispatch(requestFailure(errorMessage || 'Failed to save'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to update categeory';
            dispatch(requestFailure(errorMessage));
        }
    }
};


export const showDataReport = ({ id }) => async (dispatch) => {
    dispatch(requestSingleStart());

    try {

        const response = await axiosInstance.get(`/pem/reportview/${id}`);
        const { data, status, message } = response.data;


        if (status) {
            dispatch(requestSingleRecordSuccess({ data }));
        } else {
            dispatch(requestSingleRecordError(message || 'Failed to fetch categeory'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch categeory';
        dispatch(requestSingleRecordError(errorMessage));
    }
};




