import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
  allData: [],
  search: '',
  fields: ['customer_name', 'client_code', 'region', 'industry'],
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    customerRequestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    customerRequestSuccess: (state, action) => {
      state.allData = action.payload.data;
      state.pagination = action.payload.pagination;
      state.loading = false;
    },
    customerRequestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    customerAdded: (state, action) => {
      state.error = null;
      state.success = action.payload.message;
      state.message = action.payload.message;
      state.loading = false;
    },
    removeErrorMsg: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;
    },
  },
});

export const {
  customerRequestStart,
  customerRequestSuccess,
  customerRequestFailure,
  customerAdded,
  removeErrorMsg
} = customerSlice.actions;

export default customerSlice.reducer;

// Thunks
export const getAllCustomers = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(customerRequestStart());
  try {
    const response = await axiosInstance.get(
      `/customer?page=${page}&per_page=${per_page}&search=${search}&fields[]=customer_name&fields[]=client_code&fields[]=region&fields[]=industry`
    );
    const { data, status, message, pagination } = response.data;
    if (status) {

      dispatch(customerRequestSuccess({ data, pagination }));
    } else {
      dispatch(customerRequestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(customerRequestFailure(errorMessage));
  }
};

export const addCustomer = (customerData) => async (dispatch) => {
    dispatch(customerRequestStart());
    console.log("Customer data being sent for adding:", customerData); // Logging the data

    try {
        const response = await axiosInstance.post(
            `/customer/add`,
            {
                customer_name: customerData.name,  // Map fields correctly
                client_code: customerData.code,
                region: customerData.region,
                industry: customerData.industry,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        const { status, message } = response.data;
        if (status) {
            dispatch(customerAdded({ message }));
        } else {
            dispatch(customerRequestFailure(message || 'Failed to add customer'));
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors;
            const errorMessage = Object.values(validationErrors).flat().join(', ');
            dispatch(customerRequestFailure(errorMessage || 'Failed to add customer'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to add customer';
            dispatch(customerRequestFailure(errorMessage));
        }
    }
};


// Redux action to update customer data
export const updateCustomer = (customerData) => async (dispatch) => {
    //console.log(customerData)
    dispatch(customerRequestStart());

    try {
        const requestData = {
            id:customerData.id,
            customer_name: customerData.name,
            client_code: customerData.code,
            region: customerData.region,
            industry: customerData.industry
        };

        //console.log("Sending update with data:", requestData);

        const response = await axiosInstance.put(
            `/customer/update/${customerData.id}`,
            requestData,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        console.log("Response from server:", response);

        const { status, message } = response.data;

        if (status) {
            dispatch(customerAdded({ message }));
        } else {
            dispatch(customerRequestFailure(message || 'Failed to update customer'));
        }
    } catch (error) {
        // Log detailed error information
        console.error("Error updating customer:", {
            message: error.message,
            response: error.response ? {
                data: error.response.data,
                status: error.response.status,
                headers: error.response.headers
            } : null,
            request: error.request || null,
            config: error.config
        });

        const errorMessage = error.response?.data?.message || error.message || 'Failed to update customer';
        dispatch(customerRequestFailure(errorMessage));
    }
};




export const deleteCustomer = (id) => async (dispatch) => {
  dispatch(customerRequestStart());
  try {
    const response = await axiosInstance.delete(`/customer/${id}`);
    const { status, message } = response.data;
    if (status) {
      dispatch(customerAdded({ message }));
    } else {
      dispatch(customerRequestFailure(message || 'Failed to delete customer'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to delete data';
    dispatch(customerRequestFailure(errorMessage));
  }
};
