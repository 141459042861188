import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addprojectType, removeErrorMsg, updateprojectType } from "../../../../../redux/projectTypeSlice";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alert from "../../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const validationSchema = Yup.object().shape({
    abbr: Yup.string().required("Abbr is required"),
    project_type: Yup.string().required("Project type name required"),
});

function AddEditprojectType({ show, handleClose, projectType }) {
    //console.log(projectType,'projectType')
    const { loading, error, success } = useSelector((state) => state.projectType);
    const dispatch = useDispatch();
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            id:"",
            abbr: "",
            project_type: "",
        },
    });

    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    // Handle form submission
    const onSubmit = async (values) => {
        try {
            if (projectType) {
                // If editing an existing projectType, add the projectType ID to the values
                values.id = projectType.id;
                await dispatch(updateprojectType(values)); // Dispatch update
                handleClose();

            } else {
                // If adding a new projectType, dispatch addprojectType
                await dispatch(addprojectType(values));
            }
        } catch (error) {
            console.error(error);
        }
    };

     // Reset the form based on projectType data
     useEffect(() => {
        if (projectType) {
            reset({
                abbr: projectType.abbr || "",
                project_type: projectType.project_type || "",
            });
        } else {
            reset({
                abbr: "",
                project_type: "",
            });
        }
    }, [projectType, reset]);

    // Handle modal close



    // Handle modal close
    const handleModalClose = () => {
        reset({
            abbr: "",
            project_type: "",
        }); // Reset form fields
        resetMsg(); // Clear error messages
        handleClose(); // Close the modal
    };
    return (
    <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
        <Modal.Header>
            <Modal.Title>{projectType ? 'Edit Project Type' : 'Add Project Type'}</Modal.Title>
            <button type="button" className="btn-close" onClick={handleModalClose}>
                <span aria-hidden="true">×</span>
            </button>
        </Modal.Header>
        <Modal.Body>
            {error && (
                <Alert
                    type="danger"
                    message={error}
                    onClose={resetMsg}
                />
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-block mb-3">
                    <label className="col-form-label">
                        Abbr <span className="text-danger">*</span>
                    </label>
                    <Controller
                        name="abbr"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${errors.abbr ? "error-input" : ""}`}
                                type="text"
                                {...field}
                            />
                        )}
                    />
                    {errors.abbr && <span className="text-danger">{errors.abbr.message}</span>}
                </div>
                <div className="input-block mb-3">
                    <label className="col-form-label">
                        Project Type <span className="text-danger">*</span>
                    </label>
                    <Controller
                        name="project_type"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${errors.name ? "error-input" : ""}`}
                                type="text"
                                {...field}
                            />
                        )}
                    />
                    {errors.project_type && <span className="text-danger">{errors.project_type.message}</span>}
                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    {!loading ? (
                        <button className="btn btn-primary" type="submit">
                            Save Changes
                        </button>
                    ) : (
                        <button type="button" className="btn btn-primary" disabled>
                            <i className="fas fa-spinner fa-spin me-2" />
                            Processing...
                        </button>
                    )}
                </Modal.Footer>
            </form>
        </Modal.Body>
    </Modal>


    );

}

export default AddEditprojectType;
