import React, { useEffect,useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { removeErrorMsg, UpdateEmployeeAssetRequest } from "../../../../redux/assetSlice";
import { useDispatch, useSelector } from 'react-redux';
import Alert from "../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { customStyles } from "../../../../helpers/Constants";
import Select from "react-select";
import { Table } from "antd";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';


function AssetRequestModal({ show, handleOpen,handleClose, allAssetRequests,error,loading }) {
    const dispatch = useDispatch();

    const {
        success = null,
    } = useSelector((state) => state.asset || {});

    const allAssetRequestList = allAssetRequests?.map((assetRequest, index) => ({
        id: assetRequest?.id || '',
        key: index,
        admin_reason: assetRequest.admin_reason ? assetRequest.admin_reason : 'N/A',
        status: assetRequest.status,
        comment: assetRequest.comment,
        username:(assetRequest?.user ? assetRequest.user.first_name + " " + assetRequest.user.last_name : '' )??"",
    }));

    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    useEffect(() => {
        if (success) {
            showAlert({
                title: 'Asset Request',
                text: success,
                confirmButtonColor: '#FF902F',
                confirmButtonText: 'OK',
            });
            handleOpen();
        }
    }, [success, showAlert]);

    const statusOptions = [{
        value: 'processing',
        label: 'Processing',
    }, {
        value: 'accepted',
        label: 'Accepted',
    },
    {
        value: 'rejected',
        label: 'Rejected',
    },
    ];

    const assetRequestColumns = [
        {
            title: "S.No",
            width: "10%",
            render: (text, record, index) => index + 1, // Calculate serial number based on row index
        },
        {
            title: "Employee",
            dataIndex: "username",
            sorter: (a, b) => a.username.length - b.username.length,
            width: "20%",
        },
        {
            title: "Request",
            dataIndex: "comment",
            sorter: (a, b) => a.comment.length - b.comment.length,
            width: "20%",
        },
        {
            title: "Status",
            dataIndex: "status",
            sorter: (a, b) => a.status.length - b.status.length,
            width: "30%",
            render: (status, record) => (
                <Select
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    value={statusOptions?.find(option => option.value === status) || null}
                    options={statusOptions}
                    onChange={(option) => {
                        onSubmit(record,option,'');
                        // console.log("Status changed to:", value);
                    }}
                >
                </Select>
            ),
        },
        {
            title: "Reason",
            dataIndex: "admin_reason",
            sorter: (a, b) => a.admin_reason.length - b.admin_reason.length,
            width: "20%",
            render: (reason, record) => (
                <textarea
                    rows="2" // Set an initial height with rows
                    style={{ resize: "vertical" }}
                    // onChange={(e) => {
                    //     // Handle reason change here, e.g., update record with new reason
                    //     console.log("Reason changed to:", e.target.value);
                    // }}
                    onBlur={(e) => {
                        // console.log(e.target.value);
                        // Call submit function on focus out
                        onSubmit(record,null,e.target.value); // Pass the record to submit function or handle accordingly
                    }}
                />
            ),
        },
    ];


    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const onSubmit = async (record,option,reason) => {
        try {
            if(option)
            {
                record.status=option.value;
            }

            if(reason)
            {
                    record.admin_reason=reason;
            }
            dispatch(UpdateEmployeeAssetRequest(record));
            // console.log(record);
        } catch (error) {
            console.error(error);
        }
    };


    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal custom-modal-three">
            <Modal.Header>
                <Modal.Title>{'All Requests'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <div className="row">
                    <div className="col-12">
                        <div className="card data-table-card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <Table
                                        className="table-stripped"
                                        columns={assetRequestColumns}
                                        dataSource={
                                            allAssetRequestList?.length > 0
                                                ? allAssetRequestList?.map((asset) => ({
                                                    ...asset,
                                                    key: asset.key,
                                                }))
                                                : []
                                        }
                                        pagination={false}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AssetRequestModal;
