import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
    allData: null,
    search: '',
    fields: ['name'],
    pagination: null,
    loading: false,
    categories: null,
    loadingcompany: false,
    error: null,
    success: null,
    message: null,
    notfound: null,
    loadingsingle: false,

};

const clientSlice = createSlice({
    name: 'pemtemplates',
    initialState,
    reducers: {
        requestStart: (state) => {
            state.loading = true;
            // state.loadingsingle = true;
            state.error = null;
            state.success = null;
            state.notfound = null;
        },
        requestSingleStart: (state) => {
            state.loading = true;
            state.loadingsingle = true;
            state.error = null;
            state.success = null;
            state.notfound = null;
        },
        requestSuccess: (state, action) => {
            state.allData = action.payload.data;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },


        requestSingleRecordSuccess: (state, action) => {
            state.data = action.payload.data;
            state.loading = false;
            state.loadingsingle = false;
        },
        requestSingleRecordError: (state, action) => {
            state.notfound = true;
            state.loading = false;
            state.success = null;
            state.loadingsingle = false;
        },
        requestFailure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.success = null;


        },
        added: (state, action) => {
            state.error = null;
            state.success = action.payload.message;
            state.message = action.payload.message;
            state.loading = false;
            state.loadingsingle = false;
        },
        removeErrorMsg: (state) => {
            state.error = null;
            state.success = null;
            state.message = null;
            state.notfound = null;


        },
        added: (state, action) => {
            state.error = null;
            state.success = action.payload.message;
            state.message = action.payload.message;
            state.loading = false;
            state.loadingsingle = false;
        },
        updateCategoriesDispatch: (state, action) => {

            state.categories = action.payload.categories;


        },
        removeData: (state, action) => {

            state.data = null;


        },
    },
});

export const {
    requestStart,
    requestSuccess,
    requestFailure,
    requestSingleStart,
    requestSingleRecordError,
    requestSingleRecordSuccess,
    added,
    removeData,
    updateCategoriesDispatch,
    removeErrorMsg
} = clientSlice.actions;

export default clientSlice.reducer;


export const getAll = ({ page, per_page, search }) => async (dispatch) => {
    dispatch(requestStart());
    try {


        const response = await axiosInstance.get(`/pem/template?page=${page}&per_page=${per_page}&search=${search}&fields[]=name
            &fields[]=name
               
              
              `);
        const { data, status, message, pagination } = response.data;

        if (status) {
            dispatch(requestSuccess({ data, pagination }));
        } else {
            dispatch(requestFailure(message || 'Failed to fetch data'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
        dispatch(requestFailure(errorMessage));
    }
};



export const showData = ({ id }) => async (dispatch) => {
    dispatch(requestSingleStart());

    try {

        const response = await axiosInstance.get(`/pem/template/${id}`);
        const { data, status, message } = response.data;


        if (status) {
            dispatch(requestSingleRecordSuccess({ data }));
        } else {
            dispatch(requestSingleRecordError(message || 'Failed to fetch categeory'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch categeory';
        dispatch(requestSingleRecordError(errorMessage));
    }
};

export const add = (postData) => async (dispatch) => {
    dispatch(requestStart());
    try {

        const response = await axiosInstance.post(`/pem/template`, postData);
        const { status, message } = response.data;
        if (status) {
            dispatch(added({ message }));
        } else {
            console.log('error', message);
            dispatch(requestFailure(message || 'Failed to add categeory'));
        }
    } catch (error) {

        console.log(error)
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors; // Get the validation errors
            const errorMessage = Object.values(validationErrors)
                .flat()
                .join(', '); // Flatten the error messages

            dispatch(requestFailure(errorMessage || 'Failed to add categeory'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to add company';
            dispatch(requestFailure(errorMessage));
        }
    }
};

export const update = (postData, id) => async (dispatch) => {
    dispatch(requestStart());

    try {


        const response = await axiosInstance.post(`/pem/goal/categeory/${id}`, postData);

        const { data, status, message } = response.data;
        console.log(data); // Log the received data for debugging

        if (status) {
            dispatch(added({ message, data })); // Include data if needed
            // dispatch(getAllDesignations({ page: 1, per_page: 10 }));
        } else {
            dispatch(requestFailure(message || 'Failed to update categeory'));
        }
    } catch (error) {

        console.log(error)
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors; // Get the validation errors
            const errorMessage = Object.values(validationErrors)
                .flat()
                .join(', '); // Flatten the error messages

            dispatch(requestFailure(errorMessage || 'Failed to update categeory'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to update categeory';
            dispatch(requestFailure(errorMessage));
        }
    }
};

export const deleteData = (id) => async (dispatch) => {
    dispatch(requestStart());
    try {

        const response = await axiosInstance.delete(`/pem/template/${id}}`);
        const { data, status, message } = response.data;
        if (status) {
            dispatch(added({ message, data }));
        } else {
            dispatch(requestFailure(message || 'Failed to delete categeory'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to delete categeory';
        dispatch(requestFailure(errorMessage));
    }
};



export const addquestion = (postData) => async (dispatch) => {
    dispatch(requestStart());
    try {

        const response = await axiosInstance.post(`/pem/template`, postData);
        const { status, message } = response.data;
        if (status) {
            dispatch(added({ message }));
        } else {
            console.log('error', message);
            dispatch(requestFailure(message || 'Failed to add question'));
        }
    } catch (error) {

        console.log(error)
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors; // Get the validation errors
            const errorMessage = Object.values(validationErrors)
                .flat()
                .join(', '); // Flatten the error messages

            dispatch(requestFailure(errorMessage || 'Failed to add question'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to add question';
            dispatch(requestFailure(errorMessage));
        }
    }
};

export const updatequestion = (postData, id) => async (dispatch) => {
    dispatch(requestStart());

    try {


        const response = await axiosInstance.post(`/pem/template/${id}`, postData);

        const { data, status, message } = response.data;
        console.log(data); // Log the received data for debugging

        if (status) {
            dispatch(added({ message, data })); // Include data if needed
            // dispatch(getAllDesignations({ page: 1, per_page: 10 }));
        } else {
            dispatch(requestFailure(message || 'Failed to update question'));
        }
    } catch (error) {

        console.log(error)
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors; // Get the validation errors
            const errorMessage = Object.values(validationErrors)
                .flat()
                .join(', '); // Flatten the error messages

            dispatch(requestFailure(errorMessage || 'Failed to update question'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to update question';
            dispatch(requestFailure(errorMessage));
        }
    }
};

export const deleteDataQuestion = (id) => async (dispatch) => {
    dispatch(requestStart());
    try {

        const response = await axiosInstance.delete(`/pem/template/${id}}`);
        const { data, status, message } = response.data;
        if (status) {
            dispatch(added({ message, data }));
        } else {
            dispatch(requestFailure(message || 'Failed to delete question'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to delete question';
        dispatch(requestFailure(errorMessage));
    }
};


export const getmyGoalData = (pem_id = null) => async (dispatch) => {
    dispatch(requestSingleStart());

    try {
        let url = `/pem/mygoals`;

        if (pem_id)
            url = `/pem/mygoals?pem_id=${pem_id}`;




        const response = await axiosInstance.get(url);
        const { data, status, message } = response.data;


        if (status) {
            dispatch(requestSingleRecordSuccess({ data }));
        } else {
            dispatch(requestSingleRecordError(message || 'Failed to fetch categeory'));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch categeory';
        dispatch(requestSingleRecordError(errorMessage));
    }
};

export const addmyGoalData = (postData) => async (dispatch) => {
    dispatch(requestStart());
    try {

        const response = await axiosInstance.post(`/pem/mygoals`, postData);
        const { status, message } = response.data;
        if (status) {
            dispatch(added({ message }));
        } else {
            console.log('error', message);
            dispatch(requestFailure(message || 'Failed to update goals'));
        }
    } catch (error) {

        console.log(error)
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors; // Get the validation errors
            const errorMessage = Object.values(validationErrors)
                .flat()
                .join(', '); // Flatten the error messages

            dispatch(requestFailure(errorMessage || 'Failed to goals'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to add question';
            dispatch(requestFailure(errorMessage));
        }
    }
};








