import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";
import DeleteModal from "./model/DeleteModel";
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptions,deleteSubscription,deleteSoftware } from '../../../redux/subscriptionSlice';
import AddEditSubscription from './model/AddEditSubscription';
import AddEditSoftware from "./model/AddEditSoftware";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SoftwareTableModal from "./model/SoftwareTableModal";
import { getSoftwares } from '../../../redux/commonSlice';

const Subscription = () => {
  const dispatch = useDispatch();
  const [softwareDetails, setSoftwareDetails] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const {
    subscriptions = [],
    loading = false,
    pagination = {},
    success = null,
    search = ''
  } = useSelector((state) => state.subscription || {});

  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10,
  });
  useEffect(() => {
    dispatch(getSubscriptions({ page: paginationState.current, per_page: paginationState.pageSize,search  }));
  }, [dispatch, paginationState.current, paginationState.pageSize]);

  const userElements = subscriptions?.map((subscription, index) => ({
    key: index,
    id: subscription.id,
    software_name :subscription.software.software_name,
    owned_by: subscription.user.name ? subscription.user.name : (subscription.user.first_name? subscription.user.first_name + " " +subscription.user.last_name:""),
    login_id: subscription.login_id,
    subscription_charges: subscription.subscription_charges,
    date_of_renewal: subscription.date_of_renewal,
    owned_id:subscription.owned_by,
    password:subscription.password,
    software_id:subscription.software_id
  }));

  const { softwares } = useSelector((state) => state.common);

  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [ShowSoftwareTableModal, setShowSoftwareTableModal] = useState(false);
  const handleShowSoftwareTableModal = () => setShowSoftwareTableModal(true);
  const handleCloseSoftwareTableModal = () => setShowSoftwareTableModal(false);

  const [showAddEditSubscriptionModal, setshowAddEditSubscriptionModal] = useState(false);
  const handleShowAddEditSubscriptionModal = () => setshowAddEditSubscriptionModal(true);
  const handleCloseAddEditSubscriptionModal = () => setshowAddEditSubscriptionModal(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);

  const [showSoftwareDeleteModal, setShowSoftwareDeleteModal] = useState(false);
  const handleSoftwareDeleteShow = () => setShowSoftwareDeleteModal(true);
  const handleSoftwareDeleteClose = () => setShowSoftwareDeleteModal(false);

  const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

  const columns = [
    {
        title: "Software",
        dataIndex: "software_name",
        sorter: (a, b) => a.software_name.length - b.software_name.length,
        width: "20%",
    },
    {
        title: "S No",
        dataIndex: "key",
        sorter: (a, b) => a.key.length - b.key.length,
        width: "10%",
    },
    {
      title: "Owned By",
      dataIndex: "owned_by",
      sorter: (a, b) => a.owned_by.length - b.owned_by.length,
      width: "10%",
    },
    {
        title: "Login ID",
        dataIndex: "login_id",
        sorter: (a, b) => a.login_id.length - b.login_id.length,
        width: "20%",
    },
    {
      title: "Subscription Charges",
      dataIndex: "subscription_charges",
      sorter: (a, b) => a.subscription_charges.length - b.subscription_charges.length,
      width: "20%",
    },
    {
        title: "Date Of renewal",
        dataIndex: "date_of_renewal",
        sorter: (a, b) => a.date_of_renewal.length - b.date_of_renewal.length,
        width: "20%",
    },
    {
      title: "Action",
      className: "text-end",
      render: (text, subscription) => (
        <div className="dropdown dropdown-action text-end me-30">
          {/* <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link> */}
          {/* <div className="dropdown-menu dropdown-menu-right"> */}
            <Link
              className="dropdown-item1 m-r-10"
              to="#"
              onClick={() => addSubscriptionModal(subscription)}
            >
              <i className="fa fa-pencil m-r-5" />
            </Link>
            <Link
              className="dropdown-item1 m-r-10"
              to="#"
              // data-bs-toggle="modal"
              // data-bs-target="#delete"
              onClick={() => deleteModal(subscription)}
            >
              <i className="fa fa-trash m-r-5" />
            </Link>
          {/* </div> */}
        </div>
      ),
      sorter: (a, b) => a.length - b.length,
      width: "10%",
    },
  ];

  const addModal = (data)=> {
    setSoftwareDetails(data);
    handleShow()
  }

 const handleSoftwareEdit=()=>
 {
    handleShow();
 }

 const handleSoftwareDelete = () =>
 {
    handleSoftwareDeleteShow();
 }

  const softwareListModal = ()=> {
    // setSoftwareDetails(data);
    handleShowSoftwareTableModal()
  }

  const addSubscriptionModal = (data)=> {
    console.log(data);
    setSubscriptionDetails(data);
    handleShowAddEditSubscriptionModal()
  }

  const deleteModal = (data)=> {
    setSubscriptionDetails(data);
    handleDeleteShow()
  }

  const deleteSubscriptionData = (data)=> {
    // console.log(data.id);
    handleDeleteClose();
    dispatch(deleteSubscription(data.id));
  }

  const deleteSoftwareData = (data)=> {
    // console.log(data.id);
    handleSoftwareDeleteClose();
    dispatch(deleteSoftware(data.id));
    dispatch(getSoftwares());
  }

  useEffect(() => {
    if (success) {
        showAlert({
            title: 'Subscription',
            text: success,
            confirmButtonColor: '#FF902F',
            confirmButtonText: 'OK',
        });
        dispatch(getSubscriptions({ page: paginationState.current, per_page:  paginationState.pageSize ,search}));
        handleClose();
        handleCloseAddEditSubscriptionModal();
        handleDeleteClose();
        handleSoftwareDeleteClose();
        handleCloseSoftwareTableModal();
    }
}, [success,  showAlert]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
        <div className="page-header">
        <div className="row align-items-center">
          <Breadcrumbs
            maintitle="Subscriptions"
            title="Dashboard"
            subtitle="Subscriptions"
            modal="#add_assetcategory"
            name="Manage Subscriptions"
          />
          <div className="col-auto float-end ms-auto">

          <button type="button" className="btn btn-primary me-2 mb-2" on fdprocessedid="as7mtr"   onClick={()=>softwareListModal()}> Software List</button>
          <button type="button" className="btn btn-primary me-2 mb-2" on fdprocessedid="as7mtr"   onClick={()=>addModal(null)}>   <i className="fa fa-plus" /> Add Software</button>
          <button type="button" className="btn btn-primary mb-2" on fdprocessedid="as7mtr"   onClick={()=>addSubscriptionModal(null)}>   <i className="fa fa-plus" /> Add Subscription</button>

        </div>
          </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0 data-table-card">

                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-stripped"
                      columns={columns}
                      dataSource={
                        userElements?.length > 0
                          ? userElements?.map((assetcategory) => ({
                              ...assetcategory,
                              key: assetcategory.id,
                            }))
                          : []
                      }
                      pagination={{
                        current: paginationState.current,
                        pageSize: paginationState.pageSize,
                        total: pagination?.total,
                        onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       <AddEditSubscription show={showAddEditSubscriptionModal} handleClose={handleCloseAddEditSubscriptionModal}  subscription={subscriptionDetails} />
       <AddEditSoftware show={showModal} handleClose={handleClose}  software={softwareDetails} />
       <SoftwareTableModal show={ShowSoftwareTableModal} handleClose={handleCloseSoftwareTableModal}  softwares={softwares} setSoftwareDeatils={setSoftwareDetails} handleSoftwareEdit={handleSoftwareEdit} handleSoftwareDelete={handleSoftwareDelete}/>
       <DeleteModal show={showDeleteModal} handleClose={handleDeleteClose}  data={subscriptionDetails} onDelete={deleteSubscriptionData}  name="Delete Subscription" />
       <DeleteModal show={showSoftwareDeleteModal} handleClose={handleSoftwareDeleteClose}  data={softwareDetails} onDelete={deleteSoftwareData}  name="Delete Software" />
    </div>
  );
};

export default Subscription;
