import React, { useEffect, useState, useRef } from 'react';
import * as Yup from "yup";
import Breadcrumbs from "../../../Breadcrumbs";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // Import your FormModal component
import FormModal from '../../../Common/FormModal';
import { removeData, removeErrorMsg, showDataReport, updateReviewReport } from "../../../../redux/pemRequestSlice";

import { formatDate } from "../../../../helpers/common";
import { useForm, FormProvider } from "react-hook-form";
import { Loader } from "react-feather";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Bar, Pie } from 'react-chartjs-2';
import { useReactToPrint } from "react-to-print";
const ReportForm = () => {
    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };
    const [mainTitle, setmainTitle] = useState('Performance evaluation');

    const navigate = useNavigate(); // For navigation after submission
    const dispatch = useDispatch();
    const [postdata, setpostData] = useState(null);
    const { categories, loading, loadingsingle, error, data, success, notfound } = useSelector((state) => state.pemrequest);
    const [corpHrReview, setCorpHrReview] = useState(data?.pem?.corp_hr_review || "");
    const { id } = useParams(); // Get the client ID from the URL
    useEffect(() => {

        if (id) {
            dispatch(removeData());
            // If there's an ID, fetch the existing client data for editing
            dispatch(showDataReport({ id }));
        }
    }, [id]);

    useEffect(() => {
        dispatch(removeData());

    }, []);
    const { teams } = useSelector((state) => state.common);
    const teamOptions = teams?.map(g => ({
        value: g.id,
        label: g.team + " [" + g.description + "]",
    }));

    const handleInputChange = (e) => {
        setCorpHrReview(e.target.value);
    };


    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload
        try {
            // Initialize FormData
            const formData = new FormData();

            formData.append('id', postdata?.pem_id);
            formData.append('corp_hr_review', corpHrReview);
            await dispatch(updateReviewReport(formData, postdata?.pem_id)); // Update category



            // Close modal after submission
            // 

        } catch (error) {
            console.error('Error during submission:', error);
            // Handle error: Close modal and log the error
            //  handleModalClose();
        }
    };



    useEffect(() => {

        if (data) {

            setpostData(data);
            setCorpHrReview(data.pem.corp_hr_review);

            let label = formatDate(data.pem.evaluation_from) + " to  " + formatDate(data.pem.evaluation_to);
            setmainTitle(label);
        }


    }, [data]);

    useEffect(() => {

        if (success) {
            showAlert({
                title: 'PEM Review',
                text: success,
                confirmButtonColor: '#FF902F',
                confirmButtonText: 'OK',
            });
        }

        if (error) {
            showAlert({
                title: 'PEM Review',
                icon: 'error',
                text: error,
                confirmButtonColor: '#FF902F',
                confirmButtonText: 'OK',
            });

            dispatch(removeErrorMsg());

        }
    }, [success, error]);
    const pieData = {
        labels: ["Total Working Hrs", "Total Worked Hrs"],
        datasets: [
            {
                data: [data?.pieData1 ? data.pieData1 : 0, data?.pieData2 ? data.pieData2 : 0], // Replace with actual data
                backgroundColor: ["#FF6384", "#FFCE56"],
                borderColor: ["#FF6384", "#FFCE56"],
                borderWidth: 1,
            },
        ],
    };

    const pieOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            tooltip: {
                enabled: true,
            },
        },
    };

    const barData = {
        labels: data?.perfCategory?.map(item => item.category_name),
        datasets: [
            {
                label: "Employee",
                data: data?.perfCategory?.map(item => item.avg_self_rating), // Replace with actual data
                backgroundColor: "rgba(75, 192, 192, 0.6)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
            },
            {
                label: "Manager",
                data: data?.perfCategory?.map(item => item.avg_appraiser_rating), // Replace with actual data
                backgroundColor: "rgba(255, 206, 86, 0.6)",
                borderColor: "rgba(255, 206, 86, 1)",
                borderWidth: 1,
            },
        ],
    };


    const barDataProject = {
        labels: data?.projects?.map(item => item.project_name),
        datasets: [
            {
                label: "Employee",
                data: data?.projects?.map(item => item.self_rating), // Replace with actual data
                backgroundColor: "rgba(75, 192, 192, 0.6)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
            },
            {
                label: "Manager",
                data: data?.projects?.map(item => item.appraiser_rating), // Replace with actual data
                backgroundColor: "rgba(255, 206, 86, 0.6)",
                borderColor: "rgba(255, 206, 86, 1)",
                borderWidth: 1,
            },
        ],
    };




    const barOptions = {
        responsive: true,
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
                max: 10, // Assuming the rating scale is 1-10
            },
        },
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
            tooltip: {
                enabled: true,
            },
        },
    };



    const contentRef = useRef(null);


    const reactToPrintFn = () => {
        window.print();
    };
    return (
        <>
            <div className="page-wrapper ">
                <div className="content container-fluid">
                    <div className="row align-items-center">
                        <Breadcrumbs
                            maintitle={'Performance evaluation ' + mainTitle}
                            title="Dashboard"
                            subtitle="  PEM Evaluation Report"
                        />

                        <div className="col-auto float-end ms-auto mb-3">

                            <Link onClick={reactToPrintFn} className="btn btn-primary" >   <i className="fa fa-print" /> Print</Link>&nbsp; &nbsp;
                            <Link to="/all-pem" className="btn btn-primary" on fdprocessedid="as7mtr">   <i className="fa fa-arrow-left" /> Go Back</Link>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-0">
                                            <div className="card-body">



                                                {(loadingsingle || (!postdata)) && !notfound ? (
                                                    <Loader
                                                        className="feather-loader"
                                                        data-bs-toggle="tooltip"
                                                        title="Loading..."
                                                    />
                                                ) : postdata ? (
                                                    <div className="content container-fluid" ref={contentRef}>
                                                        <div className="report-header d-flex flex-wrap align-items-center">
                                                            <div className="col-12 col-md-4 d-flex align-items-center">
                                                                <img src={data?.user?.profile_pic_url} alt="Employee" className="employee-photo mr-3" />
                                                            </div>
                                                            <div className="col-12 col-md-8 align-items-center">
                                                                <div className="row mb-3">
                                                                    <div className="col-12 col-md-12 align-items-center">
                                                                        <h1 className='text-white'>Employee Performance Review Report</h1>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-md-6 d-flex align-items-center">
                                                                        <div className="employee-details">
                                                                            <h1 className='text-white'> {postdata?.user?.first_name}  {postdata?.user?.last_name}</h1>
                                                                            <p><strong>Employee Code:</strong> {postdata?.user?.emp_id}</p>
                                                                            <p><strong>Designation:</strong>  {postdata?.user?.designation?.designation}</p>
                                                                            <p><strong>Manager: </strong> {postdata?.user?.manager?.name}</p>
                                                                            <p><strong>Line HR: </strong> {postdata?.user?.line_hr?.name}</p>
                                                                            <p><strong>Corp HR:</strong> {postdata?.user?.corphrdata?.name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-6 employee-details">
                                                                        <h3 className='text-white'>Reviewed :{mainTitle}</h3>
                                                                        <p>Overall Employee Rating: <span className="overall-rating">{postdata?.averageRating}/10</span></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="page-break"></div> {/* Forces a page break before this div */}
                                                        <div className="performance-summary">
                                                            <div className="row">
                                                                <div className="col-12 col-md-6 mb-4 fullwidthprint">
                                                                    <div className="card h-100">
                                                                        <h3 className="h3">Attendance Details from {mainTitle}</h3>
                                                                        <ul>
                                                                            <li>Total Days : {data?.allDays?.totalDays}</li>
                                                                            <li>Total Working Days : {data?.working_days}</li>
                                                                            <li>Total Present :  {data?.totalPresent}</li>
                                                                            <li>Total Leave : {data?.totalLeave}</li>
                                                                            <li>Total WFH : {data?.totalWfh}</li></ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-4 fullwidthprint">
                                                                    <div className="card h-100">
                                                                        <h4>Working Hour Performance</h4>
                                                                        <div className="chart-container">
                                                                            <div style={{ height: '300px' }}>
                                                                                <Pie data={pieData} options={pieOptions} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-12 col-md-4 mb-4">
                                                                    <div className="card h-100">
                                                                        <h4>Quarterly Performance</h4>
                                                                        <div className="chart-container">
                                                                            <div style={{ height: '300px' }}>
                                                                                <Pie data={pieData} options={pieOptions} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="page-break"></div> {/* Forces a page break before this div */}
                                                        <div className="performance-summary">
                                                            <div className="row">
                                                                <div className="col-12 col-md-6 mb-4 fullwidthprint">
                                                                    <div className="card h-100">
                                                                        <h4>Pref Evaluation</h4>
                                                                        <div className="chart-container">
                                                                            <div style={{ height: '300px' }}>
                                                                                <Bar data={barData} options={barOptions} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-4 fullwidthprint">
                                                                    <div className="card h-100">
                                                                        <h4>Projects</h4>
                                                                        <div className="chart-container">
                                                                            <div style={{ height: '300px' }}>
                                                                                <Bar data={barDataProject} options={barOptions} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> </div>
                                                        <div className="page-break"></div> {/* Forces a page break before this div */}
                                                        <div className="performance-summary">
                                                            <form onSubmit={handleSubmit}>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <h4 className="mb-1">Reviews</h4>
                                                                        <h5 className="mb-2">Corp. HR Review</h5>
                                                                        <textarea
                                                                            name="corp_hr_review"
                                                                            className="form-control mb-2"
                                                                            rows="4"
                                                                            style={{ resize: "vertical", boxSizing: "border-box" }}
                                                                            value={corpHrReview} // Bind the state value
                                                                            onChange={handleInputChange} // Update state on change
                                                                        />
                                                                        {!loading ? (
                                                                            <button className="btn btn-primary" type="submit" >
                                                                                Update Review
                                                                            </button>

                                                                        ) :

                                                                            (
                                                                                <button type="button" className="btn btn-primary" disabled>
                                                                                    <i className="fas fa-spinner fa-spin me-2" />
                                                                                    Processing...
                                                                                </button>
                                                                            )}

                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="no-data-message">
                                                        No data available
                                                    </div> // Fallback message or component
                                                )}














                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default ReportForm;
