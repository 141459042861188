import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
  allData: null,
  search: '',
  fields: ['name', 'designation'],
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null
};

const designationSlice = createSlice({
  name: 'designation',
  initialState,
  reducers: {
    designationRequestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    designationUpdatePagination: (state) => {
      // state.pagination = true;
      // state.error = null;
    },
    designationRequestSuccess: (state, action) => {
      state.allData = action.payload.data;
      state.pagination = action.payload.pagination;
      state.loading = false;
    },
    designationRequestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    designationAdded: (state, action) => {
      state.error = null;
      state.success = action.payload.message;
      state.message = action.payload.message;
      state.loading = false;
    },
    removeErrorMsg: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;

    },
  },
});

export const {
  designationRequestStart,
  designationRequestSuccess,
  designationRequestFailure,
  designationAdded,
  removeErrorMsg
} = designationSlice.actions;

export default designationSlice.reducer;

export const getAllDesignations = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(designationRequestStart());
  try {

    const response = await axiosInstance.get(`/designation?page=${page}&per_page=${per_page}&search=${search}&fields[]=name&fields[]=designation`);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(designationRequestSuccess({ data, pagination }));
    } else {
      dispatch(designationRequestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
    dispatch(designationRequestFailure(errorMessage));
  }
};

export const addDesignation = (designationData) => async (dispatch) => {
  dispatch(designationRequestStart());
  try {


    const response = await axiosInstance.post(`/designation`, designationData);

    const { status, message } = response.data;
    if (status) {
      dispatch(designationAdded({ message }));
      // dispatch(getAllDesignations({ page: 1, per_page: 10 }));
    } else {
      dispatch(designationRequestFailure(message || 'Failed to add designation'));
    }
  } catch (error) {
    // dispatch(designationRequestFailure(error.message || 'Failed to add designation'));
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors; // Get the validation errors
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(', '); // Flatten the error messages

      dispatch(designationRequestFailure(errorMessage || 'Failed to add designation'));
    } else {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to add designation';
      dispatch(designationRequestFailure(errorMessage));
    }
  }
};

export const updateDesignation = (designationData) => async (dispatch) => {
  dispatch(designationRequestStart());

  try {

    const response = await axiosInstance.put(`/designation/${designationData.id}`, designationData);

    const { data, status, message } = response.data;
    console.log(data); // Log the received data for debugging

    if (status) {
      dispatch(designationAdded({ message, data })); // Include data if needed
      // dispatch(getAllDesignations({ page: 1, per_page: 10 }));
    } else {
      dispatch(designationRequestFailure(message || 'Failed to update designation'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to update designation';
    dispatch(designationRequestFailure(errorMessage));
  }
};

export const deleteDesignation = (id) => async (dispatch) => {
  dispatch(designationRequestStart());
  try {

    const response = await axiosInstance.delete(`/designation/${id}}`);


    const { data, status, message } = response.data;
    if (status) {
      dispatch(designationAdded({ message, data }));
    } else {
      dispatch(designationRequestFailure(message || 'Failed to delete designation'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to delete data';
    dispatch(designationRequestFailure(errorMessage));
  }
};



