import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";

import { useDispatch, useSelector } from 'react-redux';
import { loginUser, removeErrorMsg } from '../../redux/authSlice';
import { logo, logoWhiteVertical } from '../../Routes/ImagePath';

import Alert from '../../helpers/AlertMessage';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be at most 20 characters")
    .required("Password is required"),
});

const Login = () => {
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const { loading, error, isEmailSent } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState(false);
  const token = useSelector((state) => state.auth.token);

  const onSubmit = (values) => {
    dispatch(loginUser(values));
  };

  useEffect(() => {
    console.log(localStorage.getItem('token'))
    if (localStorage.getItem('token')) {
      navigate('/admin-dashboard');
    }
  }, [token, navigate]);

  const [eye, seteye] = useState(true);
  const onEyeClick = () => {
    seteye(!eye);
  };

  const handleClose = () => {
    dispatch(removeErrorMsg());
  };

  return (
    <div>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container-fluid p-0">
              <div className="account-box row">
                {/* Left Section */}
                <div className="col-lg-6 account-wrapper">
                  <h2 className="text-center mb-3">Welcome to</h2>
                  <h3 className='text-center'>
                    <img className='mb-3' src={logo} width={200} alt="img" />
                  </h3>
                  <p className="account-subtitle">Login to get access to our dashboard</p>
                  <div>
                     {isEmailSent || error ? (
                  <Alert
                    type={isEmailSent ? "success" : "danger"}
                    message={error}
                    onClose={handleClose}
                  />
                ) : null}
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="input-block mb-4">
                        <label className="col-form-label">Email Address</label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${errors?.email ? "error-input" : ""
                                }`}
                              type="text"
                              defaultValue={localStorage.getItem("email")}
                              onChange={field.onChange}
                              value={field.value}
                              autoComplete="true"
                            />
                          )}
                        />

                        <span className="text-danger">
                          {" "}
                          {errors.email?.message}{" "}
                        </span>
                      </div>
                      <div className="input-block mb-4">
                        <div className="row">
                          <div className="col">
                            <label className="col-form-label">Password</label>
                          </div>
                          <div className="col-auto">
                            <Link className="text-muted" to="/forgot-password">
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                        <div style={{ position: "relative" }}>
                          <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${errors?.password ? "error-input" : ""
                                  }`}
                                type={eye ? "password" : "text"}
                                defaultValue={localStorage.getItem("password")}
                                value={field.value}
                                onChange={field.onChange}
                              />
                            )}
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "5%",
                              top: "30%",
                            }}
                            onClick={onEyeClick}
                            className={`fa-solid ${eye ? "fa-eye-slash" : "fa-eye"
                              } `}
                          />
                        </div>
                        <span className="text-danger">
                          {" "}
                          {errors.password?.message}{" "}
                        </span>
                      </div>
                      <div className="input-block text-center">
                        {
                          !loading ? (
                            <button className="btn btn-primary account-btn" type="submit">
                              Login
                            </button>
                          ) : (
                            <button type="button" className="btn btn-primary account-btn">
                              <i className="fas fa-spinner fa-spin me-2" />
                              Logging in...
                            </button>
                          )

                        }
                      </div>
                    </form>
                  </div>
                  </div>
                </div>

                <div className="col-lg-6 account-login-image text-center">
                  <img className='mb-3' src={logoWhiteVertical} width={150} alt="img" />
                  <p className="text-center">
                    Work Smarter, Track Better.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
