import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ProjectsFilter = ({ onSearch }) => {
    const [focusedClientId, setFocusedClientId] = useState(false);
    const [inputValueClientId, setInputValueClientId] = useState("");

    const handleLabelClickClientId = () => {
      setFocusedClientId(true);
    };

    const handleInputBlurClientId = () => {
      if (inputValueClientId === "") {
        setFocusedClientId(false);
      }
    };

    const handleInput = (e, type) => {
      if (type === 'search') {
        setInputValueClientId(e.target.value);
      }
    };

    const filterResult = () => {
      onSearch(inputValueClientId); // Call the onSearch callback with filter data
    };

  return (
    <>
      <div className="row filter-row">
            <div className="col-sm-9 col-md-9">
              <div
                className={
                  focusedClientId
                    ? "input-block form-focus focused"
                    : "input-block form-focus"
                }
              >
                <input
                  type="text"
                  className="form-control floating"
                  value={inputValueClientId}
                  onFocus={handleLabelClickClientId}
                  onBlur={handleInputBlurClientId}
                  onChange={(e) => handleInput(e, 'search')}
                />
                <label
                  className="focus-label"
                  onClick={handleLabelClickClientId}
                >
                  Search by project code/name employee name
                </label>
              </div>
            </div>
            <div className="col-sm-3 col-md-3">
              <Link to="#" className="btn btn-success btn-block w-100" onClick={(e)=>filterResult(e)}>
                Search
              </Link>
            </div>
          </div>
    </>
  );
}

export default ProjectsFilter;
