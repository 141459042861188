import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../Breadcrumbs";
import DeleteModal from "./model/DeleteModel";
import { useDispatch, useSelector } from 'react-redux';
import { getMyRequests, deleteData, removeErrorMsg } from '../../../../redux/pemRequestSlice';
import { useNavigate } from 'react-router-dom';
import { formatDate } from "../../../../helpers/common";
// import AddEdit from './model/AddEdit';
import Filter from "./Filter";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Templates = () => {

  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false)



  const [dataDetails, setDataDetails] = useState(null);




  const { allData, loading, pagination, success, notfound, search } = useSelector((state) => state.pemrequest);
  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10,
  });
  useEffect(() => {
    dispatch(getMyRequests({ page: paginationState.current, per_page: paginationState.pageSize, search }));
  }, [dispatch, paginationState.current, paginationState.pageSize]);

  const userElements = allData?.map((data, index) => ({
    key: index,
    id: data.id,
    name: data.Manager?.name,
    employeename: data.user ? data.user.first_name + " " + data.user.last_name : '',

    evaluation_from: formatDate(data.evaluation_from),
    evaluation_to: formatDate(data.evaluation_to),
    progress: data.progress,




  }));
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);

  const MySwal = withReactContent(Swal);

  const showAlert = (config) => {
    MySwal.fire(config);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      sorter: (a, b) => a.key.length - b.key.length,
      width: "10%",
    },

    {
      title: "Employee",
      dataIndex: "employeename",
      sorter: (a, b) => a.name.length - b.name.length,
      width: "20%",

    },

    {
      title: "Manager",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      width: "20%",

    },

    // {
    //   title: "Client name",
    //   dataIndex: "name",
    //   sorter: (a, b) => a.name.length - b.name.length,
    //   width: "20%",
    // },
    {
      title: "Last Evaluation Date",
      dataIndex: "evaluation_from",
      sorter: (a, b) => a.sub_domain.length - b.sub_domain.length,
      width: "10%",
    },
    {
      title: "Progress",
      dataIndex: "progress",
      sorter: (a, b) => a.database_name.length - b.database_name.length,
      width: "10%",
    },

    {
      title: "Action",
      className: "text-end",
      render: (text, record) => (

        <div className={`dropdown dropdown-action text-end me-30`}>

          {/* <Link
            className="dropdown-item1 m-r-10"
            to="#"
            onClick={() => deleteModal(record)}
          >
            <i className="fa fa-trash m-r-5" />
          </Link> */}


          {/* <Link to={`/templates/view/${designation.id}`}

            className="dropdown-item1 m-r-10"


          >
            <i className="fa fa-eye m-r-5" />
          </Link> */}






          {record.progress == 'initiated' || record.progress == 'forwarded-to-user-for-selfevluation' ? (
            <Link to={`/pem/request/view/${record.id}`}

              className="dropdown-item1 m-r-10"


            >
              <i className="fa fa-pencil m-r-5" />
            </Link>
          ) : (<></>)
          }



        </div >
      ),
      sorter: (a, b) => a.length - b.length,
      width: "10%",
    },
  ];

  const haldleModel = () => {
    setOpenModel(!openModel)
  }
  const addModal = (data) => {
    setDataDetails(data);
    handleShow()
  }

  const deleteModal = (data) => {
    setDataDetails(data);
    handleDeleteShow()
  }

  const deleteRecordData = (data) => {
    handleDeleteClose();
    dispatch(deleteData(data.id));
  }
  const reloadApp = () => {
    navigate('/reload'); // Temporarily navigate to a different route
    setTimeout(() => navigate('/'), 0); // Return to the original route
  };


  useEffect(() => {
    console.log('Het');
    if (success) {
      showAlert({
        title: 'PEM Request',
        text: success,
        confirmButtonColor: '#FF902F',
        confirmButtonText: 'OK',
      });
      dispatch(getMyRequests({ page: paginationState.current, per_page: paginationState.pageSize, search }));
      handleClose();
      handleDeleteClose();
    }

    if (notfound) {
      showAlert({
        title: 'PEM Request',
        text: 'PEM Request Not found',
        confirmButtonColor: '#FF902F',
        confirmButtonText: 'OK',
      });
      dispatch(getMyRequests({ page: paginationState.current, per_page: paginationState.pageSize, search }));
      dispatch(removeErrorMsg());

      //  handleClose();
      //handleDeleteClose();
    }
  }, [success, notfound, showAlert]);


  const onSearch = (search) => {
    dispatch(getMyRequests({ page: paginationState.current, per_page: paginationState.pageSize, search }));
    console.log(search, 'pp');
  }
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <Breadcrumbs
                maintitle="My PEM Requests"
                title="Dashboard"
                subtitle="PEM Request"
              />
              <div className="col-auto float-end ms-auto">


                {/* <Link to="/all-pem/add" className="btn btn-primary">
                  <i className="fa fa-plus" /> Initiate New PEM
                </Link> */}

                {/* <button type="button" class="btn btn-primary" on fdprocessedid="as7mtr" onClick={() => addModal(null)}>   <i className="fa fa-plus" /> Add Client</button> */}

              </div>
            </div>
          </div>
          <Filter onSearch={onSearch} />
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0 data-table-card">

                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-stripped"
                      columns={columns}
                      rowKey={(record) => record.id}
                      dataSource={
                        userElements?.length > 0
                          ? userElements?.map((designation) => ({
                            ...designation,
                            key: designation.id,
                          }))
                          : []
                      }
                      pagination={{
                        current: paginationState.current,
                        pageSize: paginationState.pageSize,
                        total: pagination?.total,
                        onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AddEdit show={showModal} handleClose={handleClose} data={dataDetails} /> */}
      <DeleteModal show={showDeleteModal} handleClose={handleDeleteClose} data={dataDetails} onDelete={deleteRecordData} name="Delete PEM templates" />
    </div>
  );
};

export default Templates;
