import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
  subscriptions: null,
  subscriptionDetails:null,
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null,
  softwares:null,
  softwareDetails:null,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    requestSuccess: (state, action) => {
      if(action.payload.type === 'subscriptions'){
        state.subscriptions = action.payload.data;
        state.pagination = action.payload.pagination;
      }else if(action.payload.type === 'subscriptionDetails'){
        state.subscriptionDetails = action.payload.data;
      }else if(action.payload.type === 'softwares'){
        state.softwares = action.payload.data;
      }else if(action.payload.type === 'softwareDetails'){
        state.softwareDetails = action.payload.data;
      }else if(action.payload.type === 'updated'){
        state.success = action.payload.message;
      }

      state.loading = false;

    },
    requestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    removeErrorMsg: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;

    },
    setProjectTaskSlNo:(state, action)=>{
        state.slno = action.payload.data;
        state.loading = false;
    }
  },
});

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  removeErrorMsg,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

export const getSubscriptionDetails = (id) => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.get(`subscription`+id);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, type:'softwares' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};
export const getSubscriptions = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(requestStart());
  try {
    let fields=[];
    if(search)
    {
     fields = [];
    }
    const response = await axiosInstance.get(`/subscriptions?page=${page}&per_page=${per_page}&search=${search}&${fields.map(field => `fields[]=${field}`).join('&')}`);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, type:'subscriptions' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};
export const getSoftwareDetails = (id) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/software/`+id);
    const { status, message, data } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, type:'softwareDetails' }));
    } else {
      dispatch(requestFailure(message || 'Failed to get software request'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to get software';
    dispatch(requestFailure(errorMessage));
  }
};
export const getSoftwares = () => async (dispatch) => {
    dispatch(requestStart());
    try {
      const response = await axiosInstance.get(`/softwares`);
      const { status, message, data } = response.data;
      if (status) {
        dispatch(requestSuccess({ data, type:'softwareDetails' }));
      } else {
        dispatch(requestFailure(message || 'Failed to get software request'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to get software';
      dispatch(requestFailure(errorMessage));
    }
  };
export const addEditSubscription = (newRequest) => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.post(`/subscription`, newRequest);
    const { status, message, data } = response.data;

    if (status) {
      dispatch(requestSuccess({ message, data, type:"updated" }));
    } else {
      dispatch(requestFailure(message || 'Failed to add subscription'));
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(', ');
      dispatch(requestFailure(errorMessage || 'Failed to add request'));
    } else {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to add subscription';
      dispatch(requestFailure(errorMessage));
    }
  }
};
export const addEditSoftware = (newRequest) => async (dispatch) => {
    dispatch(requestStart());
    try {

      const response = await axiosInstance.post(`/software`, newRequest);
      const { status, message, data } = response.data;

      if (status) {
        dispatch(requestSuccess({ message, data, type:"updated" }));
      } else {
        dispatch(requestFailure(message || 'Failed to add software'));
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const validationErrors = error.response.data.errors;
        const errorMessage = Object.values(validationErrors)
          .flat()
          .join(', ');
        dispatch(requestFailure(errorMessage || 'Failed to add request'));
      } else {
        const errorMessage = error.response?.data?.message || error.message || 'Failed to add software';
        dispatch(requestFailure(errorMessage));
      }
    }
  };
export const deleteSubscription = (id) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.delete(`/subscription/${id}`);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(requestSuccess({ message, data, type:"updated" }));
    } else {
      dispatch(requestFailure(message || 'Failed to delete subscription'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to delete subscription'));
  }
};
export const deleteSoftware = (id) => async (dispatch) => {
    dispatch(requestStart());
    try {
      const response = await axiosInstance.delete(`/software/${id}`);
      const { data, status, message } = response.data;
      if (status) {
        dispatch(requestSuccess({ message, data, type:"updated" }));
      } else {
        dispatch(requestFailure(message || 'Failed to delete software'));
      }
    } catch (error) {
      dispatch(requestFailure(error.message || 'Failed to delete software'));
    }
  };




