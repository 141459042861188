
import React, { useRef, useState, useEffect } from "react";
import * as Yup from "yup";
import Breadcrumbs from "../../../../Breadcrumbs";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; // Import your FormModal component
import FormModal from "../../../../Common/FormModal";
import { formatDate } from "../../../../../helpers/common";
import PrevEvaluation from "./Questions";
import Projects from "./Projects";
import Alert from "../../../../../helpers/AlertMessage";

import {
    add,
    update,
    removeErrorMsg,
    removeData,
    showData,
    showDataview,
    updatePemRequest,

} from "../../../../../redux/pemRequestSlice";
import { getTeams } from "../../../../../redux/commonSlice";
import CustomFormComponent from "./CustomFormComponent";
import { useForm, FormProvider } from "react-hook-form";
import { Loader } from "react-feather";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Form } from "antd";
const PemRequest = () => {

    const [validationerror, setValidationerror] = useState(null);
    const [alreadysubmittet, setAlreadysubmittet] = useState(false);
    const prevEvaluationSchema = Yup.object().shape({
        performSelfRating: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.string().required("ID is required"), // ID is required

                    value: Yup.number()
                        .transform((value, originalValue) => {
                            // If the value is an empty string or invalid, transform it to NaN
                            return originalValue === "" || isNaN(Number(originalValue)) ? NaN : value;
                        })
                        .required("All Parameter's Self Rating is required") // Rating is required
                        .typeError("All Parameter's Self Rating must be a valid number") // If not a number
                        .min(0, "All Parameter's  Self Rating must be at least 1") // Ensure rating is at least 1
                })

            )
        // At least one rating should exist
    });
    const prevEvaluationProjectSchema = Yup.object().shape({
        performSelfRatingProjects: Yup.array().of(
            Yup.object().shape({
                project_role: Yup.string().required("All Project's Role is required"), // Role is required
                project_self_comments: Yup.string().required("All Project's Comment is required"), // Comment is required
                project_self_rating: Yup.number()
                    .transform((value, originalValue) => {
                        // If the value is an empty string or invalid, transform it to NaN
                        return originalValue === "" || isNaN(Number(originalValue)) ? NaN : value;
                    })
                    .required("All Project's Rating is required") // Rating is required
                    .typeError("All Project's Rating must be a valid number") // If not a number
                    .min(1, "Rating must be at least 1") // Ensure rating is at least 1
            })
        )
    });


    const resetMsg = () => {
        setValidationerror(null);
        dispatch(removeErrorMsg());
    };
    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    const navigate = useNavigate(); // For navigation after submission
    const dispatch = useDispatch();
    const formRef = useRef(null); // Reference to the form element

    const [postdata, setpostData] = useState(null);
    const [mainTitle, setmainTitle] = useState('Performance evaluation');
    const { pemformdata, data, loading, loadingsingle, error, success, notfound } =
        useSelector((state) => state.pemrequest);
    const { id } = useParams(); // Get the client ID from the URL
    useEffect(() => {
        if (id) {
            // dispatch(removeData());
            // If there's an ID, fetch the existing client data for editing
            dispatch(showDataview({ id }));
        }
    }, [id]);

    useEffect(() => {
        dispatch(removeData());
    }, []);
    const { teams } = useSelector((state) => state.common);


    const teamOptions = teams?.map((g) => ({
        value: g.id,
        label: g.team + " [" + g.description + "]",
    }));
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get the data to validate from the form
        const dataToValidate = pemformdata?.performSelfRatingProjects;

        try {
            // Validate the data based on the schema before processing the form data
            await prevEvaluationProjectSchema.validate({ performSelfRatingProjects: dataToValidate }, { abortEarly: false });

            // Proceed with form submission after validation
            const formData = new FormData(formRef.current);

            // Manually handle multiple entries for `pe_self_rating[]`
            let formDataObj = {};

            // Iterate over form data to capture multiple values for the same name
            formData.forEach((value, key) => {
                if (key === "pe_self_rating[]"
                    || key === "pe_self_comments[]"

                    || key === "project_duration[]"
                    || key === "project_name[]"
                    || key === "is_billable[]"
                    || key === "project_id[]"
                    || key === "project_role[]"
                    || key === "project_self_rating[]"
                    || key === "project_self_comments[]"
                    || key === "achievement_desc[]"
                    || key === "challange_description[]"
                    || key === "questions[]"
                    || key === "project_manager_id[]"
                    || key === "project_manager[]"

                ) {
                    // Initialize as an array if not already
                    if (!formDataObj[key]) {
                        formDataObj[key] = [];
                    }
                    // Push the value into the array
                    formDataObj[key].push(value);
                } else {
                    formDataObj[key] = value;
                }
            });

            await dispatch(updatePemRequest(formDataObj));

        } catch (validationError) {
            // Handle validation errors
            if (validationError.inner) {
                // Log specific validation errors if present
                console.error("Validation Failed:", validationError.errors);
                setValidationerror(validationError);
                // alert("Please correct the errors before proceeding.");
            } else {
                // Log unexpected errors
                console.error("Unexpected Error:", validationError);
                //alert("An unexpected error occurred. Please try again.");
                setValidationerror(validationError);
            }
        }
    };


    const [activeTab, setActiveTab] = useState("basicDetails");

    // Handler to move to the next step
    // const handleNextStep = (targetId) => {


    //     setActiveTab(targetId);
    // };

    const handleNextStep = async (targetId) => {
        try {
            if (targetId === 'achievement') {
                // Perform validation only when targetId is 'achievement'
                const dataToValidate = pemformdata?.performSelfRating;

                // Validate the data based on the schema
                await prevEvaluationSchema.validate({ performSelfRating: dataToValidate }, { abortEarly: false });

                // If validation passes, proceed to set the active tab
                resetMsg();
                await setActiveTab(targetId);
            }
            else {
                resetMsg();
                await setActiveTab(targetId);
            }
        } catch (validationError) {
            // Handle validation errors
            if (validationError.inner) {
                // Log specific validation errors if present
                console.error("Validation Failed:", validationError.errors);
                setValidationerror(validationError);
                // alert("Please correct the errors before proceeding.");
            } else {
                // Log unexpected errors
                console.error("Unexpected Error:", validationError);
                //alert("An unexpected error occurred. Please try again.");
                setValidationerror(validationError);
            }
        }
    };


    // Handler to move to the previous step
    const handlePrevStep = (targetId) => {
        setActiveTab(targetId);
    };

    useEffect(() => {
        dispatch(getTeams());
    }, [dispatch]);
    useEffect(() => {
        if (data) {

            setpostData(data);

            if (data?.pem?.progress == 'initiated' || data?.pem?.progress == 'forwarded-to-user-for-selfevluation') {

            }
            else {
                setAlreadysubmittet(true);

            }
            let label = 'Performance Evaluation: ' + formatDate(data.pem.evaluation_from) + " - " + formatDate(data.pem.evaluation_to);
            setmainTitle(label);
        }
    }, [data]);

    useEffect(() => {
        if (notfound) {
            navigate("/pem/request");
        }
        if (success) {
            navigate("/pem/request");
        }
    }, [success, notfound]);
    const methods = useForm();
    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row align-items-center">
                        <Breadcrumbs
                            maintitle={mainTitle}
                            title="Dashboard"
                            subtitle="Pem Request / PEM "
                        />
                        <div className="col-auto float-end ms-auto">
                            <Link
                                to="/pem/request"
                                className="btn btn-primary"
                                on
                                fdprocessedid="as7mtr"
                            >
                                {" "}
                                <i className="fa fa-arrow-left" /> Go Back
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-0">
                                            <div className="card-body">





                                                {(loadingsingle || !postdata) && !notfound ? (
                                                    <Loader
                                                        className="feather-loader"
                                                        data-bs-toggle="tooltip"
                                                        title="Loading..."
                                                    />
                                                ) : postdata && !alreadysubmittet ? (
                                                    <div className="container-fluid">

                                                        <form ref={formRef} onSubmit={handleSubmit}>

                                                            <input type="hidden" value={postdata?.pem?.id} name="pem_id" />
                                                            <div className="tab-content">
                                                                <div
                                                                    id="basicDetails"
                                                                    className={`tab-pane fade ${activeTab === "basicDetails"
                                                                        ? "show active"
                                                                        : ""
                                                                        }`}
                                                                >
                                                                    <div className="row mb-4">
                                                                        <div className="col-md-6 mb-4">
                                                                            <table className="table mb-0">
                                                                                <thead className="thead-light">
                                                                                    <tr>
                                                                                        <th
                                                                                            colSpan="4"
                                                                                            className="table-active"
                                                                                        >
                                                                                            Employee Details
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b>Name:</b>
                                                                                        </td>
                                                                                        <td>{postdata.item?.first_name} {postdata.item?.last_name}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b>Job Title:</b>
                                                                                        </td>
                                                                                        <td>{postdata.item?.designation?.designation} </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b>Department:</b>
                                                                                        </td>
                                                                                        <td>{postdata.item?.team?.team}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>

                                                                            <table className="table mb-0">
                                                                                <thead className="thead-light">
                                                                                    <tr>
                                                                                        <th
                                                                                            colSpan="4"
                                                                                            className="table-active"
                                                                                        >
                                                                                            Manager Details
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b>Name:</b>
                                                                                        </td>
                                                                                        <td>{postdata.item?.manager?.name}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b>Job Title:</b>
                                                                                        </td>
                                                                                        <td>
                                                                                            <td>{postdata.item?.managerDesignation?.designation}</td>

                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>

                                                                            <table className="table mb-0">
                                                                                <thead className="thead-light">
                                                                                    <tr>
                                                                                        <th
                                                                                            colSpan="4"
                                                                                            className="table-active"
                                                                                        >
                                                                                            Line HR
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b>Name:</b>
                                                                                        </td>
                                                                                        <td>{postdata.item?.line_hr?.name}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b>Job Title:</b>
                                                                                        </td>

                                                                                        <td>{postdata.item?.linehrDesignation?.designation}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <div className="table-responsive">
                                                                                <table className="table rating-table w-100">
                                                                                    <thead>
                                                                                        <tr className="table-active">
                                                                                            <th>Rating</th>
                                                                                            <th>Description</th>
                                                                                            <th>Descriptors</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>9-10</td>
                                                                                            <td>Can lead and teach others</td>
                                                                                            <td>Highly Capable</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>7-8</td>
                                                                                            <td>
                                                                                                Can work independently, does not
                                                                                                need help
                                                                                            </td>
                                                                                            <td>Capable</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>5-6</td>
                                                                                            <td>
                                                                                                Understand the work, need help
                                                                                                in execution
                                                                                            </td>
                                                                                            <td>Adequate</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>3-4</td>
                                                                                            <td>
                                                                                                Learning; needs significant help
                                                                                                from others
                                                                                            </td>
                                                                                            <td>Below requirements</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>1-2</td>
                                                                                            <td>
                                                                                                Need significant improvement
                                                                                            </td>
                                                                                            <td>Unacceptable</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary next-step"
                                                                        onClick={() =>
                                                                            handleNextStep("prevEvaluation")
                                                                        }
                                                                    >
                                                                        Next
                                                                    </button>
                                                                </div>
                                                                <div
                                                                    id="prevEvaluation"
                                                                    className={`tab-pane fade ${activeTab === "prevEvaluation"
                                                                        ? "show active"
                                                                        : ""
                                                                        }`}
                                                                >
                                                                    <label className="form-header">
                                                                        Perf Evaluation
                                                                    </label>
                                                                    <PrevEvaluation

                                                                        postdata={postdata ? postdata : []} />

                                                                    {validationerror && (
                                                                        <Alert
                                                                            type="danger"
                                                                            onClose={resetMsg}
                                                                            message={
                                                                                <div>
                                                                                    {/* Check if validationerror is an object with errors */}
                                                                                    {validationerror.errors ? (
                                                                                        [...new Set(validationerror.errors)].map((msg, index) => (
                                                                                            <div key={index}>{msg.trim()}</div>
                                                                                        ))
                                                                                    ) : (
                                                                                        // If validationerror doesn't have errors, just display the raw message
                                                                                        <div>{validationerror.message || ""}</div>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                        />
                                                                    )}
                                                                    <div className="d-flex">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-secondary prev-tab"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={() =>
                                                                                handlePrevStep("basicDetails")
                                                                            }
                                                                        >
                                                                            Previous
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary next-tab"
                                                                            onClick={() =>
                                                                                handleNextStep("achievement")
                                                                            }
                                                                        >
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    id="achievement"
                                                                    className={`tab-pane fade ${activeTab === "achievement"
                                                                        ? "show active"
                                                                        : ""
                                                                        }`}
                                                                >
                                                                    <div className="row">
                                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                                            <label className="form-header">
                                                                                Achievement
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mb-4 p-2">
                                                                        <table className="rating-table achievement-table w-100">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p>Description</p>
                                                                                        <textarea
                                                                                            placeholder="Description"
                                                                                            name="achievement_desc[]"
                                                                                            rows="5"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                boxSizing: "border-box",
                                                                                                marginBottom: "10px",
                                                                                            }}
                                                                                        ></textarea>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p>Attachment</p>
                                                                                        <input
                                                                                            type="file"
                                                                                            name="achievement_attachment"
                                                                                            multiple=""
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-secondary prev-tab"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={() =>
                                                                                handlePrevStep("prevEvaluation")
                                                                            }
                                                                        >
                                                                            Previous
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary next-tab"
                                                                            onClick={() =>
                                                                                handleNextStep("challenges")
                                                                            }
                                                                        >
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    id="challenges"
                                                                    className={`tab-pane fade ${activeTab === "challenges"
                                                                        ? "show active"
                                                                        : ""
                                                                        }`}
                                                                >
                                                                    <div className="row">
                                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                                            <label className="form-header mb-0">
                                                                                Challenges
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mb-4 p-2">
                                                                        <table className="table rating-table challenges-table w-100">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td
                                                                                        style={{
                                                                                            borderBottom:
                                                                                                "1px solid lightgrey",
                                                                                        }}
                                                                                    >
                                                                                        <textarea
                                                                                            id="challengeDescription"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                boxSizing: "border-box",
                                                                                            }}
                                                                                            rows="7"
                                                                                            placeholder="Description"
                                                                                            name="challange_description[]"
                                                                                        ></textarea>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-secondary prev-tab"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={() =>
                                                                                handlePrevStep("achievement")
                                                                            }
                                                                        >
                                                                            Previous
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary next-tab"
                                                                            onClick={() => handleNextStep("project")}
                                                                        >
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    id="project"
                                                                    className={`tab-pane fade ${activeTab === "project" ? "show active" : ""
                                                                        }`}
                                                                >
                                                                    <div className="row mb-4">
                                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                                            <label className="form-header">
                                                                                Projects
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mb-4">


                                                                        <Projects

                                                                            currentProjects={postdata?.current_projects ? postdata?.current_projects : []} />

                                                                    </div>



                                                                    {error && (
                                                                        <Alert
                                                                            type="danger"
                                                                            onClose={resetMsg}
                                                                            message={
                                                                                <div>
                                                                                    {error.split(',').map((msg, index) => (
                                                                                        <div key={index}>{msg.trim()}</div>
                                                                                    ))}
                                                                                </div>
                                                                            }
                                                                        />
                                                                    )}






                                                                    {validationerror && (
                                                                        <Alert
                                                                            type="danger"
                                                                            onClose={resetMsg}
                                                                            message={
                                                                                <div>
                                                                                    {/* Check if validationerror is an object with errors */}
                                                                                    {validationerror.errors ? (
                                                                                        [...new Set(validationerror.errors)].map((msg, index) => (
                                                                                            <div key={index}>{msg.trim()}</div>
                                                                                        ))
                                                                                    ) : (
                                                                                        // If validationerror doesn't have errors, just display the raw message
                                                                                        <div>{validationerror.message || ""}</div>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                        />
                                                                    )}

                                                                    <div className="d-flex">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-secondary prev-tab"
                                                                            style={{ marginRight: "10px" }}
                                                                            onClick={() =>
                                                                                handlePrevStep("challenges")
                                                                            }
                                                                        >
                                                                            Previous
                                                                        </button>
                                                                        {!loading ? (
                                                                            <button className="btn btn-primary" type="submit" >
                                                                                Save Changes
                                                                            </button>
                                                                        ) : (
                                                                            <button type="button" className="btn btn-primary" disabled>
                                                                                <i className="fas fa-spinner fa-spin me-2" />
                                                                                Processing...
                                                                            </button>
                                                                        )}
                                                                        {/* <div
                                                                            id="loader"
                                                                            style={{ display: "none" }}
                                                                        >
                                                                            <div
                                                                                className="spinner-border"
                                                                                role="status"
                                                                            >
                                                                                <span className="sr-only">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form >
                                                    </div>
                                                ) : (
                                                    alreadysubmittet ? (
                                                        <div className="no-data-message">
                                                            You have already submitted your performance evaluation.
                                                        </div> // Fallback message or component
                                                    ) : (
                                                        <div className="no-data-message">
                                                            No data available
                                                        </div> // Fallback message or component
                                                    )


                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{" "}
            </div>
        </>
    );
};

export default PemRequest;
