import React from 'react';
const reactToPrintFn = () => {
    window.print();
};

function PrintPage() {
    return (
        <div>
            <button onClick={reactToPrintFn} className="btn btn-primary mt-3">
                Print Page
            </button>
            <div className="content-section">
                <h1>Section 1</h1>
                <p>Content for section 1...</p>
            </div>

            <div className="page-break"></div> {/* Forces a page break before this div */}

            <div className="content-section">
                <h1>Section 2</h1>
                <p>Content for section 2...</p>
            </div>

            <div className="page-break-after"></div> {/* Forces a page break after this div */}

            <div className="content-section">
                <h1>Section 3</h1>
                <p>Content for section 3...</p>
            </div>
        </div>
    );
}

export default PrintPage;
