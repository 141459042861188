import React, { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



const BarChart = ({dataa}) => {
console.log(dataa, 'bbaa')

  const  getRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const alpha = 0.6;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  const data = {
    labels: dataa?.map((item) => item.label),
    datasets: [
      {
        label: "Department Wise",
        data: dataa?.map((item) => item.y),
        backgroundColor: dataa?.map(() => getRandomColor()),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <div >
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Department Wise</h5>
        </div>
        <div className="card-body">
          <div style={{height:'300px' }}>
            <Bar data={data} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarChart;
