import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
  allData: [],
  search: '',
  fields: ['code', 'name'],
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null
};

const ProjectBusinessAreaSlice = createSlice({
    name: 'Project Business Area',
    initialState,
    reducers: {
      projectBusinessAreaRequestStart: (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      },
      projectBusinessAreaRequestSuccess: (state, action) => {
        state.allData = action.payload.data;
        state.pagination = action.payload.pagination;
        state.loading = false;
      },
      projectBusinessAreaRequestFailure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = null;
      },
      projectBusinessAreaAdded: (state, action) => {
        state.error = null;
        state.success = action.payload.message;
        state.message = action.payload.message;
        state.loading = false;
      },
      removeErrorMsg: (state) => {
        state.error = null;
        state.success = null;
        state.message = null;
      },
    },
  });

  export const {
    projectBusinessAreaRequestStart,
    projectBusinessAreaRequestSuccess,
    projectBusinessAreaRequestFailure,
    projectBusinessAreaAdded,
    removeErrorMsg
  } = ProjectBusinessAreaSlice.actions;

  export default ProjectBusinessAreaSlice.reducer;

  export const getAllprojectBusinessArea = ({ page, per_page, search }) => async (dispatch) => {
    dispatch(projectBusinessAreaRequestStart());
    try {
      const response = await axiosInstance.get(
        `/projectBusinessArea?page=${page}&per_page=${per_page}&search=${search}&fields[]=code&fields[]=name`
      );
      const { data, status, message, pagination } = response.data;
      if (status) {
        dispatch(projectBusinessAreaRequestSuccess({ data, pagination }));
      } else {
        dispatch(projectBusinessAreaRequestFailure(message || 'Failed to fetch data'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
      dispatch(projectBusinessAreaRequestFailure(errorMessage));
    }
}

export const addProjectBusinessArea = (projectBusinessAreaData) => async (dispatch) => {
    dispatch(projectBusinessAreaRequestStart());
    console.log("Project Business Area data being sent for adding:", projectBusinessAreaData); // Logging the data

    try {
        const response = await axiosInstance.post(
            `/projectBusinessArea/add`,
            {
                code: projectBusinessAreaData.code,  // Map fields correctly
                name: projectBusinessAreaData.name,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        const { status, message } = response.data;
        if (status) {
            dispatch(projectBusinessAreaAdded({ message }));
        } else {
            dispatch(projectBusinessAreaRequestFailure(message || 'Failed to add Project Business Area'));
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
            const validationErrors = error.response.data.errors;
            const errorMessage = Object.values(validationErrors).flat().join(', ');
            dispatch(projectBusinessAreaRequestFailure(errorMessage || 'Failed to add Project Business Area'));
        } else {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to add Project Business Area';
            dispatch(projectBusinessAreaRequestFailure(errorMessage));
        }
    }
};

// Redux action to update projectBusinessArea data
export const updateprojectBusinessArea = (projectBusinessAreaData) => async (dispatch) => {
    //console.log(projectBusinessAreaData)
    dispatch(projectBusinessAreaRequestStart());

    try {
        const requestData = {
            id:projectBusinessAreaData.id,
            code: projectBusinessAreaData.code,
            name: projectBusinessAreaData.name,
        };

        //console.log("Sending update with data:", requestData);

        const response = await axiosInstance.put(
            `/projectBusinessArea/update/${projectBusinessAreaData.id}`,
            requestData,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        console.log("Response from server:", response);

        const { status, message } = response.data;

        if (status) {
            dispatch(projectBusinessAreaAdded({ message }));
        } else {
            dispatch(projectBusinessAreaRequestFailure(message || 'Failed to update Project Business Area'));
        }
    } catch (error) {
        // Log detailed error information
        console.error("Error updating Project Business Area:", {
            message: error.message,
            response: error.response ? {
                data: error.response.data,
                status: error.response.status,
                headers: error.response.headers
            } : null,
            request: error.request || null,
            config: error.config
        });

        const errorMessage = error.response?.data?.message || error.message || 'Failed to update Project Business Area';
        dispatch(projectBusinessAreaRequestFailure(errorMessage));
    }
};

    export const deleteprojectBusinessArea = (id) => async (dispatch) => {
        dispatch(projectBusinessAreaRequestStart());
        try {
          const response = await axiosInstance.delete(`/projectBusinessArea/${id}`);
          const { status, message } = response.data;
          if (status) {
            dispatch(projectBusinessAreaAdded({ message }));
          } else {
            dispatch(projectBusinessAreaRequestFailure(message || 'Failed to delete Project Business Area'));
          }
        } catch (error) {
          const errorMessage = error.response?.data?.message || error.message || 'Failed to delete data';
          dispatch(projectBusinessAreaRequestFailure(errorMessage));
        }


  };
