import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"; // Import your FormModal component
import { submitData } from "../../../../../../redux/pemRequestSlice";
const ProjectForm = ({ currentProjects, viewonly }) => {
    const [projects, setProjects] = useState([]);
    const [projectssent, setProjectsSent] = useState([]);
    const [aprojectssent, setAProjectsSent] = useState([]);
    const dispatch = useDispatch();
    console.log('NEWSS');
    console.log(currentProjects);
    // Handle input changes
    const handleInputChange = (index, field, value) => {
        //alert(index + "-" + field + "-" + value);

        // Create a deep copy of the state to avoid mutating the original
        const updatedProjects = projectssent.map((project, i) =>
            i === index ? { ...project, [field]: value } : project
        );


        // Update the state with the new projects array
        setProjectsSent(updatedProjects);

        console.log(updatedProjects)
        setProjects(updatedProjects);

    };


    // useEffect(() => {

    //     const updatedData = [];
    //     currentProjects?.map((eachProject, index) => {



    //         updatedData.push({
    //             id: eachProject?.id,
    //             project_name: eachProject?.project_name,
    //             project_manager: eachProject?.project_manager,
    //             project_role: eachProject?.project_role,
    //             is_billable: eachProject?.is_billable,
    //             self_rating: eachProject?.self_rating,
    //             self_comments: eachProject?.self_comments,
    //             project_appraiser_rating: eachProject?.appraiser_rating,
    //             project_appraiser_comments: eachProject?.appraiser_comments,

    //         });




    //     });

    //     setProjects(updatedData);
    //     setProjectsSent(updatedData);

    //     // dispatch(submitData({ data: ratings, type: 'performSelfRating' }));
    // }, []);
    useEffect(() => {

        dispatch(submitData({ data: projectssent, type: 'performSelfRatingProjects' }));
    }, [projectssent]);
    useEffect(() => {
        //  setProjects(currentProjects)

        const updatedData = [];
        currentProjects?.map((eachProject, index) => {



            updatedData.push({
                id: eachProject?.id,
                project_name: eachProject?.project_name,
                project_manager: eachProject?.project_manager,
                project_role: eachProject?.project_role,
                is_billable: eachProject?.is_billable,
                self_rating: eachProject?.self_rating,
                project_duration: eachProject?.project_duration,
                self_comments: eachProject?.self_comments,
                project_appraiser_rating: eachProject?.appraiser_rating,
                project_appraiser_comments: eachProject?.appraiser_comments,

            });




        });

        setProjects(updatedData);
        setProjectsSent(updatedData);

    }, [currentProjects]);
    return (
        <div className="table-responsive">
            <table className="table rating-table project-table">
                <tbody>
                    <tr className="table-active">
                        <th>S. No.</th>
                        {!viewonly &&

                            (<th>Billable</th>)

                        }
                        <th>Project Name</th>
                        <th>Project Role</th>
                        <th>Project Duration(Hrs)</th>
                        <th>Project manager</th>
                        <th>Self Rating</th>
                        <th>Self Comments</th>
                        <th>Appraiser Rating</th>
                        <th>Appraiser Comments</th>
                    </tr>

                    {projects?.length > 0 &&
                        projects.map((eachProject, key) => (
                            <><input type="hidden" name="is_billable[]" value={eachProject?.billability_id == 1 ? 1 : 0} />
                                <input type="hidden" name="project_id[]" value={eachProject?.project_id} />
                                <input type="hidden" name="project_answer_id[]" value={eachProject?.id} />

                                <input type="hidden" name="project_manager_id[]" value={eachProject?.manager_id} />
                                <tr>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        {key + 1}
                                    </td>

                                    {!viewonly &&

                                        (<td style={{ borderBottom: "1px solid lightgrey" }}>

                                            {/* Checkbox field with the checked status and onChange handler */}
                                            <input
                                                type="checkbox"
                                                name={`is_billable[${eachProject.id}]`}
                                                value="1"
                                                checked={eachProject.is_billable === 1}
                                                onChange={(event) => handleInputChange(key, 'is_billable', event.target.checked ? 1 : 0)}
                                            />
                                        </td>)

                                    }
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        <input
                                            className="noevent"
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            type="text"
                                            placeholder="Project Name"
                                            name="project_name[]"
                                            disabled
                                            value={eachProject.project_name}
                                            onChange={(e) => handleInputChange(key, 'project_name', e.target.value)} />
                                    </td>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        <input
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            type="text"
                                            placeholder="Project Role"
                                            name="project_role[]"
                                            disabled
                                            value={eachProject.project_role}
                                        //onChange={(e) => handleInputChange(key, 'project_role', e.target.value)} 
                                        />
                                    </td>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        <input
                                            className="noevent"
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            type="text"
                                            disabled
                                            placeholder="Project Duration"
                                            name="project_duration[]"
                                            value={eachProject.project_duration}
                                        //  onChange={(e) => handleInputChange(key, 'total_duration', e.target.value)} 
                                        />
                                    </td>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        <input
                                            className="noevent"
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            type="text"
                                            placeholder="Project Manager"
                                            name="project_manager[]"
                                            disabled
                                            value={`${eachProject.project_manager}`}
                                        // onChange={(e) => handleInputChange(key, 'project_manager', e.target.value)}
                                        />
                                    </td>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        <input
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            type="number"
                                            placeholder="Self Rating"
                                            name="project_self_rating[]"
                                            min="1"
                                            disabled
                                            max="10"
                                            value={eachProject.self_rating}
                                        //onChange={(e) => handleInputChange(key, 'project_self_rating', e.target.value)} 
                                        />
                                    </td>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        <textarea
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            rows="1"
                                            disabled
                                            placeholder="Self Comments"
                                            value={eachProject.self_comments}
                                            name="project_self_comments[]"
                                        // onChange={(e) => handleInputChange(key, 'project_self_comments', e.target.value)}
                                        ></textarea>
                                    </td>

                                    <td style={{ borderBottom: '1px solid lightgrey' }}>

                                        <input
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            type="number"
                                            placeholder="Appraiser Rating"
                                            name="project_appraiser_rating[]"
                                            min="1"
                                            disabled={viewonly}
                                            max="10"
                                            value={eachProject.project_appraiser_rating || ''}
                                            onChange={(e) => handleInputChange(key, 'project_appraiser_rating', e.target.value)}
                                        />
                                    </td>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        <textarea
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            rows="1"
                                            disabled={viewonly}
                                            placeholder="Appraiser Comments"
                                            value={eachProject.project_appraiser_comments || ''}
                                            name="project_appraiser_comments[]"
                                            onChange={(e) => handleInputChange(key, 'project_appraiser_comments', e.target.value)}
                                        ></textarea>
                                    </td>
                                </tr></>

                        ))}
                </tbody></table ></div >

    );
};

export default ProjectForm;
