import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import Breadcrumbs from "../../../Breadcrumbs";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux'; // Import your FormModal component
import FormModal from '../../../Common/FormModal';
import { addmyGoalData, removeErrorMsg, removeData, showData, getmyGoalData } from "../../../../redux/pemTemplateSlice";
import { getTeams } from '../../../../redux/commonSlice';
import CustomFormComponent from './CustomFormComponent';
import { useForm, FormProvider } from "react-hook-form";
import { Loader } from "react-feather";
import Swal from 'sweetalert2';
import { formatDate } from "../../../../helpers/common";
import withReactContent from 'sweetalert2-react-content';
const TemplateForm = () => {
    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    const navigate = useNavigate(); // For navigation after submission
    const dispatch = useDispatch();
    const [postdata, setpostData] = useState(null);
    const { categories, data, loading, loadingsingle, error, success, notfound } = useSelector((state) => state.pemtemplates);


    useEffect(() => {


        dispatch(getmyGoalData());
        dispatch(removeData());

    }, [dispatch]);
    const { teams } = useSelector((state) => state.common);
    const teamOptions = postdata?.PemRequestAll?.map((g) => ({
        value: g.id,
        label: 'Performance Evaluation: ' + formatDate(g.evaluation_from) + " - " + formatDate(g.evaluation_to),
    }));

    const defaultValue = teamOptions?.find(option => option.value === postdata?.PemRequest?.id);
    // const fieldConfig = [/* Your field configurations here */];

    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    let fieldConfig = [];



    const handleSubmit = async (values) => {
        try {
            // Create JSON object for non-file fields
            const jsonData = {};





            const questionWeightages = categories
                .filter(function (element) {
                    return element.isCheckeds; // Filter categories where isCheckeds is true
                })
                .map(function (element) {
                    // Map through the selected categories and extract their questions' weightages
                    return element.teams_question.map(function (question) {

                        if (question.weightage_answer > 0)
                            return question.weightage_answer;
                        // Return the weightage of each question in the category
                    });
                })
                .flat();

            const questionids = categories
                .filter(function (element) {
                    return element.isCheckeds; // Filter categories where isCheckeds is true
                })
                .map(function (element) {
                    // Map through the selected categories and extract their questions' weightages
                    return element.teams_question.map(function (question) {

                        if (question.weightage_answer > 0)
                            return question.id; // Return the weightage of each question in the category
                    });
                })
                .flat();

            jsonData['pem_id'] = postdata.PemRequest.id

            if (questionWeightages)
                jsonData['weightage'] = questionWeightages.filter(item => item !== undefined);

            if (questionids)
                jsonData['questionid'] = questionids.filter(item => item !== undefined);


            let questionWeightagess = questionWeightages.filter(item => item !== undefined);
            if (questionWeightagess.length < 1) {
                MySwal.fire({
                    title: 'Error',
                    text: `Please update at least one goal.`,
                    icon: 'warning',
                });

                return false;
            }
            Object.entries(values).forEach(([key, value]) => {

                // Append non-file data to jsonData
                jsonData[key] = value !== undefined ? value : '';

            });

            // const teams = jsonData.teams.map(team => team.value);

            // // Prepare the JSON data for submission
            const jsonDatas = {
                ...jsonData, // Copy other form fields

            };

            console.log(jsonDatas);
            await dispatch(addmyGoalData(jsonDatas));

        } catch (error) {
            console.error(error);
        }
    };




    useEffect(() => {

        if (data) {

            setpostData(data);


        }


    }, [data]);

    const handleInputChange = (e, type) => {

        let value = e ? e.value : "";
        // setSearchFilter((prev) => ({ ...prev, [type]: value }));
        // dispatch(setFilter({ key: type, value }));
        dispatch(getmyGoalData(value));

    };

    useEffect(() => {

        if (success) {
            showAlert({
                title: 'My goalls',
                text: success,
                confirmButtonColor: '#FF902F',
                confirmButtonText: 'OK',
            });

        }
    }, [success]);
    const methods = useForm();
    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row align-items-center">
                        <Breadcrumbs
                            maintitle="Please add/update your goals."
                            title="Dashboard"
                            subtitle=" My Goals"
                        />
                        <div className="col-auto float-end ms-auto">
                            <Link to="/templates" class="btn btn-primary" on fdprocessedid="as7mtr">   <i className="fa fa-arrow-left" /> Go Back</Link>

                        </div>
                    </div>
                    {teamOptions?.length > 1 && (
                        <div className="filter-section filter-bottom">
                            <ul>
                                <li>
                                    <div className="form-sort value-contain">
                                        <Select
                                            options={teamOptions}
                                            isClearable
                                            placeholder="Select Performance evaluation "
                                            styles="form-control form-control-sm"
                                            onChange={(e) => handleInputChange(e, "id")}
                                            value={defaultValue} // Correct default value logic

                                        />
                                    </div>
                                </li>


                            </ul>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-0">
                                            <div className="card-body">
                                                {(loadingsingle || (!postdata)) && !notfound ? (
                                                    <Loader
                                                        className="feather-loader"
                                                        data-bs-toggle="tooltip"
                                                        title="Loading..."
                                                    />
                                                ) : postdata ? (
                                                    <FormProvider {...methods}>  {/* Wrap with FormProvider */}
                                                        <FormModal
                                                            error={error}

                                                            loading={loading}
                                                            removeErrorMsg={removeErrorMsg}
                                                            data={postdata}
                                                            fieldConfig={fieldConfig}
                                                            CustomFormComponent={CustomFormComponent}
                                                            onSubmit={handleSubmit}
                                                        />   </FormProvider>
                                                ) : (
                                                    <div className="no-data-message">
                                                        No data available
                                                    </div> // Fallback message or component
                                                )}


                                            </div> </div> </div> </div> </div> </div></div>

                </div> </div>


        </>
    );
};

export default TemplateForm;
