import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import Breadcrumbs from "../../../../Breadcrumbs";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // Import your FormModal component
import FormModal from '../../../../Common/FormModal';
import { add, update, removeErrorMsg, removeData, showData } from "../../../../../redux/pemTemplateSlice";
import { getTeams } from '../../../../../redux/commonSlice';
import CustomFormComponent from './CustomFormComponent';
import { useForm, FormProvider } from "react-hook-form";
import { Loader } from "react-feather";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const TemplateForm = () => {
    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    const navigate = useNavigate(); // For navigation after submission
    const dispatch = useDispatch();
    const [postdata, setpostData] = useState(null);
    const { categories, data, loading, loadingsingle, error, success, notfound } = useSelector((state) => state.pemtemplates);
    const { id } = useParams(); // Get the client ID from the URL
    useEffect(() => {

        if (id) {
            // dispatch(removeData());
            // If there's an ID, fetch the existing client data for editing
            dispatch(showData({ id }));
        }
    }, [id]);

    useEffect(() => {
        dispatch(removeData());

    }, []);
    const { teams } = useSelector((state) => state.common);
    const teamOptions = teams?.map(g => ({
        value: g.id,
        label: g.team + " [" + g.description + "]",
    }));


    // const fieldConfig = [/* Your field configurations here */];

    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    let fieldConfig = [

        { name: "name", placeholder: "Performance review", label: "Template Name", type: "text", validation: Yup.string().required("Name is required") },
        {
            name: "teams", label: "Select Team/Technology",


            type: "selectmulitple",
            options: teamOptions,
            validation: Yup.array()
                .min(1, 'At least one team must be selected')
                .required('Teams selection is required')

        },

        { name: "start_date", label: "Select Session Start", type: "date", validation: Yup.string().required("Start date is required") },
        { name: "end_date", label: "Select Session End", type: "date", validation: Yup.string().required("Start date is required") },

    ];
    // if (postdata && id) {




    //     fieldConfig = [


    //         {
    //             name: "sub_domain", placeholder: "Enter subdomain'", label: "Subdomain", type: "text", validation: Yup.string()
    //                 .required("Subdomain is required")
    //                 .max(255, "Subdomain cannot be more than 255 characters"),
    //         },

    //     ];
    // }


    const handleSubmit = async (values) => {
        try {
            // Create JSON object for non-file fields
            const jsonData = {};

            const totalWeightage = categories
                .reduce((total, category) => total + category.my_question.reduce((subTotal, question) => subTotal + (question.weightages || 0), 0), 0);


            if (totalWeightage < 100) {
                // If total weightage exceeds 100, show error message and adjust accordingly
                MySwal.fire({
                    title: 'Error',
                    text: `The total weightage of all questions cannot be below 100%.`,
                    icon: 'warning',
                });

                return false;

            }



            const category_ids = categories
                .filter(function (element) {
                    return element.isCheckeds; // Check if the element is checked
                })
                .map(function (element) {
                    return element.id; // Return the id of each checked category
                });



            const categoryWeightage = categories
                .filter(function (element) {
                    return element.isCheckeds; // Check if the element is checked
                })
                .map(function (element) {
                    return element.weightage; // Return the id of each checked category
                });

            const questionWeightages = categories
                .filter(function (element) {
                    return element.isCheckeds; // Filter categories where isCheckeds is true
                })
                .map(function (element) {
                    // Map through the selected categories and extract their questions' weightages
                    return element.my_question.map(function (question) {
                        return question.weightages; // Return the weightage of each question in the category
                    });
                })
                .flat();

            const questionids = categories
                .filter(function (element) {
                    return element.isCheckeds; // Filter categories where isCheckeds is true
                })
                .map(function (element) {
                    // Map through the selected categories and extract their questions' weightages
                    return element.my_question.map(function (question) {
                        return question.id; // Return the weightage of each question in the category
                    });
                })
                .flat();


            if (category_ids)
                jsonData['category_ids'] = category_ids;


            if (categoryWeightage)
                jsonData['categoryWeightage'] = categoryWeightage;

            if (categoryWeightage)
                jsonData['weightage'] = questionWeightages;

            if (questionids)
                jsonData['questionid'] = questionids;


            Object.entries(values).forEach(([key, value]) => {

                // Append non-file data to jsonData
                jsonData[key] = value !== undefined ? value : '';

            });

            const teams = jsonData.teams.map(team => team.value);

            // Prepare the JSON data for submission
            const jsonDatas = {
                ...jsonData, // Copy other form fields
                teams // Now teams is an array of values, e.g., [1, 2]
            };

            if (data) {
                jsonDatas._method = 'put';
                jsonDatas.id = data.id;
                await dispatch(update(jsonDatas, data.id));
            } else {
                await dispatch(add(jsonDatas));
            }
        } catch (error) {
            console.error(error);
        }
    };



    useEffect(() => {
        dispatch(getTeams());

    }, [dispatch]);
    useEffect(() => {

        if (data) {

            setpostData(data);
        }


    }, [data]);

    useEffect(() => {
        if (notfound) {
            navigate('/templates');
        }
        if (success) {
            navigate('/templates');
        }
    }, [success, notfound]);
    const methods = useForm();
    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row align-items-center">
                        <Breadcrumbs
                            maintitle="Add Template"
                            title="Dashboard"
                            subtitle=" Add Template"
                        />
                        <div className="col-auto float-end ms-auto">
                            <Link to="/templates" class="btn btn-primary" on fdprocessedid="as7mtr">   <i className="fa fa-arrow-left" /> Go Back</Link>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-0">
                                            <div className="card-body">
                                                {loadingsingle || (!postdata && id) ? (
                                                    <Loader
                                                        className="feather-loader"
                                                        data-bs-toggle="tooltip"
                                                        title="Loading..."
                                                    />
                                                ) : fieldConfig ? (
                                                    <FormProvider {...methods}>  {/* Wrap with FormProvider */}
                                                        <FormModal
                                                            error={error}
                                                            disabled={(postdata && id) ? true : false}
                                                            loading={loading}
                                                            removeErrorMsg={removeErrorMsg}
                                                            data={postdata?.template}
                                                            fieldConfig={fieldConfig}
                                                            CustomFormComponent={CustomFormComponent}
                                                            onSubmit={handleSubmit}
                                                        />   </FormProvider>
                                                ) : (
                                                    <div>No content available</div> // Fallback message or component
                                                )}


                                            </div> </div> </div> </div> </div> </div></div>

                </div> </div>


        </>
    );
};

export default TemplateForm;
