import React, { useEffect,useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { removeErrorMsg} from "../../../../../redux/contractSlice";
import { useDispatch, useSelector } from 'react-redux';
import Alert from "../../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { customStyles } from "../../../../../helpers/Constants";
import Select from "react-select";
import { Table } from "antd";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';


function ContractEmployeeSalaryHistory({ show,handleClose, salaryHistory,error,loading,employeeName }) {
    const dispatch = useDispatch();

    const salaryHistoryList = salaryHistory?.map((salary, index) => ({
        id: salary?.id || '',
        key: index,
        username: employeeName,
        current_salary_type: salary?.current_salary_type,
        current_tds: salary?.current_tds,
        current_salary: salary?.current_salary,
    }));

    const salaryColumns = [
        {
            title: "S.No",
            width: "10%",
            render: (text, record, index) => index + 1, // Calculate serial number based on row index
        },
        {
            title: "User",
            dataIndex: "username",
            sorter: (a, b) => a.username.length - b.username.length,
            width: "20%",
        },
        {
            title: "Salary Type",
            dataIndex: "current_salary_type",
            sorter: (a, b) => a.current_salary_type.length - b.current_salary_type.length,
            width: "20%",
        },

        {
            title: "TDS",
            dataIndex: "current_tds",
            sorter: (a, b) => a.current_tds.length - b.current_tds.length,
            width: "20%",
        },

        {
            title: "Salary",
            dataIndex: "current_salary",
            sorter: (a, b) => a.current_salary.length - b.current_salary.length,
            width: "20%",
        },
    ];


    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal custom-modal-three">
            <Modal.Header>
                <Modal.Title>{'Contract Employee Salary History'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <div className="row">
                    <div className="col-12">
                        <div className="card data-table-card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <Table
                                        className="table-stripped"
                                        columns={salaryColumns}
                                        dataSource={
                                            salaryHistoryList?.length > 0
                                                ? salaryHistoryList?.map((asset) => ({
                                                    ...asset,
                                                    key: asset.key,
                                                }))
                                                : []
                                        }
                                        pagination={false}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ContractEmployeeSalaryHistory;
