import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DeleteModal from "./model/DeleteModel";
import RolesPermissionsModal from "./model/RolesPermissionsModal";
import Breadcrumbs from "../../../Breadcrumbs";
import { useDispatch, useSelector } from 'react-redux';
import { getAll, deleteData, removeErrorMsg } from '../../../../redux/rolesSlice';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  getAll as getAllModules,
  getrolePermissions as getrolePermissions,
  deleteData as deleteModulesData,
  update as updateModuleToRole,
  removeErrorMsg as removeModulesErrorMsg
} from '../../../../redux/modulesSlice';
const RolesPermissions = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);
  const [dataDetails, setdataDetails] = useState(null);
  const [roleID, setroleID] = useState(null);
  const MySwal = withReactContent(Swal);

  const showAlert = (config) => {
    MySwal.fire(config);
  };
  //const [checkedModules, setCheckedModules] = useState({}); // Track checked state for each module
  const {
    allData: modulesData,
    allDatarolePermissions: rolePermissions,

    loading: modulesLoading,
    pagination: modulesPagination,
    success: modulesSuccess,
    notfound: modulesNotfound,
    search: modulesSearch
  } = useSelector((state) => state.modules);
  const { allData, error, loading, pagination, success, notfound, search } = useSelector((state) => state.roles);
  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10000,
  });
  const [modulepaginationState, setModulePaginationState] = useState({
    current: 1,
    pageSize: 10000,
  });
  const deleteModal = (data) => {
    setdataDetails(data);
    handleDeleteShow()
  }
  useEffect(() => {
    dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));

  }, [dispatch, paginationState.current, paginationState.pageSize]);

  useEffect(() => {
    dispatch(getAllModules({ page: modulepaginationState.current, per_page: modulepaginationState.pageSize, search }));


  }, [dispatch, modulepaginationState.current, modulepaginationState.pageSize]);


  useEffect(() => {


    if (roleID && roleID > 0) {

      dispatch(getrolePermissions(roleID));
    }


  }, [roleID]);

  useEffect(() => {

    if (allData && allData[0].id) {


      setroleID(allData[0].id);
    }


  }, [allData]);






  const deleteRoleData = (data) => {
    handleDeleteClose();
    dispatch(deleteData(data.id));
  }



  const handleRoleCheck = (id) => {


    setroleID(id);

  };


  const handleSubmit = async () => {
    try {
      // Handle the submission logic
      const formData = new FormData();


      permissions.forEach((perm, index) => {
        if (perm.role_id === roleID) {
          formData.append('module_ids[]', perm.module_id);

        }
      });
      await dispatch(updateModuleToRole(formData, roleID));

    } catch (error) {
      console.error(error);
    }
  };
  const handleModuleCheck = (id) => {


    handleToggle(roleID, id);
    // setCheckedModules((prevState) => ({
    //   ...prevState,
    //   [id]: !prevState[id], // Toggle checked state for the module
    // }));
  };
  // const [permissions, setPermissions] = useState(rolePermissions);

  const [permissions, setPermissions] = useState([]);



  // Handler to toggle the state of a submodule for the role
  const handleToggle = (role_id, module_id) => {
    setPermissions((prevPermissions) => {
      // Check if the permission already exists
      const exists = prevPermissions.some(
        (perm) => perm.role_id === role_id && perm.module_id === module_id
      );

      if (exists) {
        // Remove the permission if it exists
        return prevPermissions.filter(
          (perm) => !(perm.role_id === role_id && perm.module_id === module_id)
        );
      } else {
        // Add the permission if it doesn't exist
        return [...prevPermissions, { role_id, module_id }];
      }
    });
  };

  useEffect(() => {

    if (modulesSuccess) {
      showAlert({
        title: 'Permission',
        text: modulesSuccess,
        confirmButtonColor: '#FF902F',
        confirmButtonText: 'OK',
      });
      // dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
      //  handleClose();
      // handleDeleteClose();
      dispatch(removeModulesErrorMsg());

    }

    if (success) {
      showAlert({
        title: 'Permission',
        text: success,
        confirmButtonColor: '#FF902F',
        confirmButtonText: 'OK',
      });
      dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
      handleClose();
      handleDeleteClose();
      dispatch(removeErrorMsg());

    }
    if (error) {
      showAlert({
        title: 'Roles',
        icon: 'error',
        text: error,
        confirmButtonColor: '#FF902F',
        confirmButtonText: 'OK',
      });

      dispatch(removeErrorMsg());

    }


  }, [success, showAlert]);
  const addModal = (data) => {
    setdataDetails(data);
    handleShow()
  }

  useEffect(() => {
    if (rolePermissions && rolePermissions.length > 0) {
      const rolePermissionss = rolePermissions.map(item => ({
        role_id: item.pivot.role_id,
        module_id: item.pivot.module_id
      }));

      setPermissions(rolePermissionss);


    } else {
      // Initialize permissions with default values from modules data
      // const defaultPermissions = modules.data.flatMap((module) =>
      //   module.submodules.map((submodule) => ({
      //     role_id: module.role_id,
      //     module_id: submodule.id,
      //     enabled: false // Default to false or any desired initial value
      //   }))
      // );
      // setPermissions(defaultPermissions);
    }
  }, [rolePermissions]);

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            title="Dashboard"
            subtitle="Roles & Permissions"
            maintitle="Roles & Permissions" />

          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-3">
              <Link
                to="#"
                className="btn btn-primary btn-block w-100"
                onClick={() => addModal(null)}
              >
                <i className="fa fa-plus" /> Add Roles
              </Link>
              <div className="roles-menu">
                <ul>
                  {allData?.map((role, index) => (
                    <li onClick={() => handleRoleCheck(role.id)}
                      key={index} className={roleID === role.id ? "active" : ""}
                    >
                      <Link to="#">


                        {role.name}
                        <span className="role-action">
                          <span
                            className="action-circle large me-1"
                            // data-bs-toggle="modal"
                            // data-bs-target="#edit_role"
                            onClick={() => addModal(role)}
                          >
                            <i className="material-icons">edit</i>
                          </span>
                          <span
                            className="action-circle large delete-btn"
                            onClick={() => deleteModal(role)}
                          >
                            <i className="material-icons">delete</i>
                          </span>
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8 col-xl-9">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h6 className="card-title">Module Access</h6>


                {!modulesLoading ? (
                  <button onClick={handleSubmit} className="btn btn-primary" type="submit" >
                    Save Changes
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary" disabled>
                    <i className="fas fa-spinner fa-spin me-2" />
                    Processing...
                  </button>
                )}

              </div>
              <div className="m-b-30">
                <ul className="list-group notification-list">
                  {modulesData?.map((item) => (
                    <li key={item.id} className="list-group-item">
                      {item.display_name}
                      <div className="status-toggle">
                        <input
                          type="checkbox"
                          id={item.id}
                          className="check"
                          //    checked={!!checkedModules[item.id]}

                          checked={
                            permissions && permissions.find(
                              (p) =>
                                p.role_id === roleID &&
                                p.module_id === item.id
                            )
                          }
                          onChange={() => handleModuleCheck(item.id)




                          }
                        // defaultChecked={item?.defaultChecked?}
                        />
                        <label htmlFor={item.id} className="checktoggle">
                          checkbox
                        </label>
                      </div>

                      {permissions && permissions.find(
                        (p) =>
                          p.role_id === roleID &&
                          p.module_id === item.id
                      )

                        && item.submodules && item.submodules.length > 0 && (
                          <div className="m-b-30 m-t-30">
                            <ul className="list-group submodule-list">
                              {item.submodules.map((subItem) => (
                                <li key={subItem.id} className="list-group-item">
                                  {subItem.display_name}
                                  <div className="status-toggle">
                                    <input
                                      type="checkbox"
                                      id={`submodule-${subItem.id}`}
                                      className="check"
                                      checked={
                                        permissions.find(
                                          (p) =>
                                            p.role_id === roleID &&
                                            p.module_id === subItem.id
                                        )
                                      }
                                      onChange={() => handleModuleCheck(subItem.id)




                                      }
                                    // defaultChecked={subItem?.defaultChecked}
                                    />
                                    <label htmlFor={`submodule-${subItem.id}`} className="checktoggle">
                                      checkbox
                                    </label>
                                  </div>
                                </li>
                              ))}
                            </ul></div>
                        )}

                    </li>
                  ))}
                </ul>
              </div>
              {/* <div className="table-responsive">
                <table className="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <th className="text-center">Read</th>
                      <th className="text-center">Write</th>
                      <th className="text-center">Create</th>
                      <th className="text-center">Delete</th>
                      <th className="text-center">Import</th>
                      <th className="text-center">Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((categoryData, index) => (
                      <tr key={index}>
                        <td>{categoryData.category}</td>
                        {categoryData.items.map((item, itemIndex) => (
                          <td key={itemIndex} className="text-center">
                            <label className="custom_check">
                              <input type="checkbox" defaultChecked="Checked" />
                              <span className="checkmark" />
                            </label>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <RolesPermissionsModal show={showModal} handleClose={handleClose} data={dataDetails} />
        {/* <DeleteModal Name="Role" /> */}

        <DeleteModal show={showDeleteModal} handleClose={handleDeleteClose} data={dataDetails} onDelete={deleteRoleData} name="Delete Role" />
      </div>
    </>
  );
};

export default RolesPermissions;
