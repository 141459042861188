import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addprojectPaymentType, removeErrorMsg, updateprojectPaymentType } from "../../../../../redux/projectPaymentTypeSlice";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alert from "../../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const validationSchema = Yup.object().shape({
    code: Yup.string().required("Code is required"),
    name: Yup.string().required("Payment type name is required"),
});

function AddEditprojectPaymentType({ show, handleClose, projectPaymentType }) {
    //console.log(projectPaymentType,'projectPaymentType')
    const { loading, error, success } = useSelector((state) => state.projectPaymentType);
    const dispatch = useDispatch();
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            id:"",
            code: "",
            name: "",
        },
    });

    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    // Handle form submission
    const onSubmit = async (values) => {
        try {
            if (projectPaymentType) {
                // If editing an existing projectPaymentType, add the projectPaymentType ID to the values
                values.id = projectPaymentType.id;
                await dispatch(updateprojectPaymentType(values)); // Dispatch update
                handleClose();

            } else {
                // If adding a new projectPaymentType, dispatch addprojectPaymentType
                await dispatch(addprojectPaymentType(values));
            }
        } catch (error) {
            console.error(error);
        }
    };

     // Reset the form based on projectPaymentType data
     useEffect(() => {
        if (projectPaymentType) {
            reset({
                code: projectPaymentType.code || "",
                name: projectPaymentType.name || "",
            });
        } else {
            reset({
                code: "",
                name: "",
            });
        }
    }, [projectPaymentType, reset]);

    // Handle modal close



    // Handle modal close
    const handleModalClose = () => {
        reset({
            code: "",
            name: "",
        }); // Reset form fields
        resetMsg(); // Clear error messages
        handleClose(); // Close the modal
    };
    return (
    <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
        <Modal.Header>
            <Modal.Title>{projectPaymentType ? 'Edit Project Payment Type' : 'Add Project Payment Type'}</Modal.Title>
            <button type="button" className="btn-close" onClick={handleModalClose}>
                <span aria-hidden="true">×</span>
            </button>
        </Modal.Header>
        <Modal.Body>
            {error && (
                <Alert
                    type="danger"
                    message={error}
                    onClose={resetMsg}
                />
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-block mb-3">
                    <label className="col-form-label">
                        Code <span className="text-danger">*</span>
                    </label>
                    <Controller
                        name="code"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${errors.code ? "error-input" : ""}`}
                                type="text"
                                {...field}
                            />
                        )}
                    />
                    {errors.code && <span className="text-danger">{errors.code.message}</span>}
                </div>
                <div className="input-block mb-3">
                    <label className="col-form-label">
                        Name <span className="text-danger">*</span>
                    </label>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${errors.name ? "error-input" : ""}`}
                                type="text"
                                {...field}
                            />
                        )}
                    />
                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    {!loading ? (
                        <button className="btn btn-primary" type="submit">
                            Save Changes
                        </button>
                    ) : (
                        <button type="button" className="btn btn-primary" disabled>
                            <i className="fas fa-spinner fa-spin me-2" />
                            Processing...
                        </button>
                    )}
                </Modal.Footer>
            </form>
        </Modal.Body>
    </Modal>


        // <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
        //     <Modal.Header>
        //         <Modal.Title>{projectPaymentType ? 'Edit Project Payment Type' : 'Add Project Payment Type'}</Modal.Title>
        //         <button type="button" className="btn-close" onClick={handleModalClose}>
        //             <span aria-hidden="true">×</span>
        //         </button>
        //     </Modal.Header>
        //     <Modal.Body>
        //         {error && (
        //             <Alert
        //                 type="danger"
        //                 message={error}
        //                 onClose={resetMsg}
        //             />
        //         )}
        //         <form onSubmit={handleSubmit(onSubmit)}>
        //             <div className="input-block mb-3">
        //             <Controller
        //                     name="id"
        //                     control={control}
        //                     render={({ field }) => (
        //                         <input name="id"
        //                         value={projectPaymentType?.id}
        //                             className={`form-control ${errors.name ? "error-input" : ""}`}
        //                             type="hidden"
        //                             {...field}
        //                         />
        //                     )}
        //                 />


        //                 <label className="col-form-label">
        //                     Code <span className="text-danger">*</span>
        //                 </label>
        //                 <Controller
        //                     name="code"
        //                     control={control}
        //                     render={({ field }) => (
        //                         <input name="code"
        //                             className={`form-control ${errors.code ? "error-input" : ""}`}
        //                             type="text"
        //                             {...field}
        //                         />
        //                     )}
        //                 />
        //                 {errors.code && <span className="text-danger">{errors.code.message}</span>}
        //             </div>
        //             <div className="input-block mb-3">
        //                 <label className="col-form-label">
        //                     Name <span className="text-danger">*</span>
        //                 </label>
        //                 <Controller
        //                     name="name"
        //                     control={control}
        //                     render={({ field }) => (
        //                         <input
        //                             className={`form-control ${errors.name ? "error-input" : ""}`}
        //                             type="text"
        //                             {...field}
        //                         />
        //                     )}
        //                 />
        //                 {errors.code && <span className="text-danger">{errors.name.message}</span>}
        //             </div>

        //             <Modal.Footer>
        //                 <Button variant="secondary" onClick={handleModalClose}>
        //                     Close
        //                 </Button>
        //                 {!loading ? (
        //                     <button className="btn btn-primary" type="submit">
        //                         Save Changes
        //                     </button>
        //                 ) : (
        //                     <button type="button" className="btn btn-primary" disabled>
        //                         <i className="fas fa-spinner fa-spin me-2" />
        //                         Processing...
        //                     </button>
        //                 )}
        //             </Modal.Footer>
        //         </form>
        //     </Modal.Body>
        // </Modal>
    );

}

export default AddEditprojectPaymentType;
