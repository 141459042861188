import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { removeErrorMsg, UpdateAssetStatus } from "../../../../redux/assetSlice";
import { useDispatch, useSelector } from 'react-redux';
import Alert from "../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { customStyles } from "../../../../helpers/Constants";
import Select from "react-select";

const validationSchema = Yup.object().shape({
    status: Yup.string().required("Status is required"),
});



function AssetStatus({ show, handleClose, asset }) {
    const dispatch = useDispatch();
    const {
        loading = false,
        error = "",
    } = useSelector((state) => state.asset || {});

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            status: "",
        },
    });

    const statusOptions = [{
        value: 'alloted',
        label: 'Assign',
    }, {
        value: 'intransit',
        label: 'In Transit',
    },
    {
        value: 'maintainance',
        label: 'Maintainance',
    }, {
        value: 'available',
        label: 'Avaliable',
    },
    {
        value: 'broken',
        label: 'Broken',
    }];

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const onSubmit = async (values) => {
        try {
            if (asset) {
                values.id = asset.id ? asset.id : '';
                await dispatch(UpdateAssetStatus(values));
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (asset) {
            reset({
                status: asset.status,
            });
        } else {
            reset({
                status: '',
            });
        }

    }, [asset, reset]);


    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{asset ? 'Assign Asset' : 'Assign Assets'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-12">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Status :
                                </label>
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={statusOptions} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={statusOptions?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.status ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.status && (
                                    <span className="text-danger">
                                        {errors.status.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Update Status
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AssetStatus;
