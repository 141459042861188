import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../Breadcrumbs";
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmplyeeAssetRequests,getAllEmplyeeAssets } from '../../../../redux/assetSlice';
import EmployeeAssetRequest from "./model/AssetRequestModel";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const AssetRequest = () => {
  const dispatch = useDispatch();
//   const [assetcategoryDetails, setAssetCategoryDetails] = useState(null);
  const {
    employeAssetsRequests = [],
    employeeAssets=[],
    loading = false,
    pagination = {},
    success = null,
    search = ''
  } = useSelector((state) => state.asset || {});

  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(getAllEmplyeeAssets({ page: paginationState.current, per_page: paginationState.pageSize,search  }));
  }, [dispatch, paginationState.current, paginationState.pageSize]);

  useEffect(() => {
    dispatch(getAllEmplyeeAssetRequests({ page: paginationState.current, per_page: paginationState.pageSize,search  }));
  }, [dispatch, paginationState.current, paginationState.pageSize]);

  const employeeAssetRequestList = employeAssetsRequests?.map((assetRequest, index) => ({
    id: assetRequest?.id || '',
    key: index,
    admin_reason: assetRequest.admin_reason?assetRequest.admin_reason:'N/A',
    status: assetRequest.status,
    comment: assetRequest.comment,
  }));

  const employeeAssetsList = employeeAssets?.map((asset, index) => ({
    key: index,
    id: asset.category?.id || '',
    type: asset.category.name,
    asset_code: asset.asset_code,
    brand: asset.brand,
    model: asset.model_serial_number,
    date:asset.asset_disbursements && asset.asset_disbursements.length > 0
    ? asset.asset_disbursements[0].date_of_allotment
    : 'N/A'
  }));

  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);

  const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

  const columns = [
    {
        title: "S.No",
        width: "10%",
        render: (text, record, index) => index + 1, // Calculate serial number based on row index
    },
    {
        title: "Type",
        dataIndex: "type",
        sorter: (a, b) => a.type.length - b.type.length,
        width: "20%",
      },
    {
      title: "Code",
      dataIndex: "asset_code",
      sorter: (a, b) => a.asset_code.length - b.asset_code.length,
      width: "20%",
    },
    {
        title: "Brand",
        dataIndex: "brand",
        sorter: (a, b) => a.brand.length - b.brand.length,
        width: "20%",
      },
      {
        title: "Serial Number",
        dataIndex: "model",
        sorter: (a, b) => a.model.length - b.model.length,
        width: "20%",
      },
      {
        title: "Alloted Date",
        dataIndex: "date",
        sorter: (a, b) => a.date.length - b.date.length,
        width: "20%",
      },
  ];

  const assetRequestColumns = [
    {
        title: "S.No",
        width: "10%",
        render: (text, record, index) => index + 1, // Calculate serial number based on row index
    },
    {
        title: "Request",
        dataIndex: "comment",
        sorter: (a, b) => a.comment.length - b.comment.length,
        width: "20%",
      },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.length - b.status.length,
      width: "20%",
    },
    {
        title: "Reason",
        dataIndex: "admin_reason",
        sorter: (a, b) => a.admin_reason.length - b.admin_reason.length,
        width: "20%",
      },
  ];

//   const haldleModel = () => {
//     setOpenModel(!openModel)
//   }
  const addModal = (data)=> {
    // setAssetCategoryDetails(data);
    handleShow()
  }


  useEffect(() => {
    if (success) {
        showAlert({
            title: 'Asset Request',
            text: success,
            confirmButtonColor: '#FF902F',
            confirmButtonText: 'OK',
        });
        dispatch(getAllEmplyeeAssetRequests({ page: paginationState.current, per_page:  paginationState.pageSize ,search}));
        handleClose();
        handleDeleteClose();
    }
}, [success,  showAlert]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
        <div className="page-header">
        <div className="row align-items-center">
          <Breadcrumbs
            maintitle="Request"
            title="Dashboard"
            subtitle="Asset"
            modal="#add_assetcategory"
            name="Asset Request"
          />

          <div className="col-auto float-end ms-auto">
          <button type="button" className="btn btn-primary" on fdprocessedid="as7mtr"   onClick={()=>addModal(null)}>   <i className="fa fa-plus" /> Request/Upgrade Asset</button>

        </div>
          </div>
          </div>
          {/* <AssetCategoryFilter onSearch={onSearch}/> */}
          <div className="row">
            <div className="col-sm-12">
            <div className="row">
                    <div className="col-12">
                        <div className="card bg-white">
                            <div className="card-body">
                                <ul className="nav nav-tabs nav-tabs-top">
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link active"
                                            to="#top-tab1"
                                            data-bs-toggle="tab"
                                        >
                                          My Assets
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="#top-tab2" data-bs-toggle="tab">
                                            Requests
                                        </Link>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane show active" id="top-tab1">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card data-table-card">
                                                    <div className="card-body">
                                                        <div className="table-responsive">
                                                            <Table
                                                                className="table-stripped"
                                                                columns={columns}
                                                                dataSource={
                                                                    employeeAssetsList?.length > 0
                                                                        ? employeeAssetsList?.map((asset) => ({
                                                                            ...asset,
                                                                            key: asset.key,
                                                                        }))
                                                                        : []
                                                                }
                                                                pagination={{
                                                                    current: paginationState.current,
                                                                    pageSize: paginationState.pageSize,
                                                                    total: pagination?.total,
                                                                    onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                                                                  }}
                                                                  loading={loading}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="top-tab2">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card data-table-card">
                                                    <div className="card-body">
                                                        <div className="table-responsive">
                                                            <Table
                                                                className="table-stripped"
                                                                columns={assetRequestColumns}
                                                                dataSource={
                                                                    employeeAssetRequestList?.length > 0
                                                                        ? employeeAssetRequestList?.map((asset) => ({
                                                                            ...asset,
                                                                            key: asset.key,
                                                                        }))
                                                                        : []
                                                                }
                                                                pagination={{
                                                                    current: paginationState.current,
                                                                    pageSize: paginationState.pageSize,
                                                                    total: pagination?.total,
                                                                    onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                                                                  }}
                                                                  loading={loading}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>

       <EmployeeAssetRequest show={showModal} handleClose={handleClose}  />
      </div>
  );
};

export default AssetRequest;
