import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import Breadcrumbs from "../../../Breadcrumbs";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormModal from '../../../Common/FormModal'; // Import your FormModal component
import { add, update, removeData, removeErrorMsg, showData, getActiveCompany } from "../../../../redux/companySlice";
import { getCountries, getTimezones } from '../../../../redux/commonSlice';
import { Loader } from "react-feather";

const CompanyForm = () => {
    const navigate = useNavigate(); // For navigation after submission
    const dispatch = useDispatch();
    const [postdata, setpostData] = useState(null);
    const { data, loading, loadingsingle, error, success, notfound } = useSelector((state) => state.company);
    const { id } = useParams(); // Get the client ID from the URL
    useEffect(() => {
        if (id) {

            // If there's an ID, fetch the existing client data for editing
            dispatch(showData({ id }));
        }
    }, [id]);
    useEffect(() => {
        dispatch(removeData());
    }, []);

    const { countries, timezones } = useSelector((state) => state.common);
    const countryOptions = countries?.map(g => ({
        value: g.id,
        label: g.name,
    }));

    const timezoneOptions = timezones?.map(g => ({
        value: g.id,
        label: g.name,
    }));
    // const fieldConfig = [/* Your field configurations here */];

    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    let fieldConfig = [

        {
            name: "logo",
            label: "Logo Image",
            type: "file",
            // validation: Yup.mixed()
            //     .required("A profile image is required")


        },
        { name: "name", placeholder: "John Doe Inc.", label: "Company name", type: "text", validation: Yup.string().required("Company Name is required") },
        { name: "email", placeholder: "Enter email address", label: "Email", type: "email", validation: Yup.string().required("email number is required").email("Invalid email format") },
        { name: "phone", placeholder: "Enter mobile number", label: "Phone Number", type: "text", validation: Yup.string().required("Phone number is required") },

        { name: "address", placeholder: "Enter Company Address", label: "Company Address", type: "text" },
        {
            name: "country_id", label: "Country",

            type: "select",
            options: countryOptions,
            validation: Yup.string().required("Country is required")

        },
        {
            name: "timezone_id", label: "Timezone", type: "select",
            options: timezoneOptions,
            validation: Yup.string().required("Timezone is required")
        },


    ];



    const handleSubmit = async (values) => {
        try {
            // Handle the submission logic

            const formData = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                if (key === 'logo' && value && value instanceof File) {
                    formData.append(key, value);
                } else {

                    if (key != 'logo')
                        formData.append(key, value !== undefined ? value : ''); // Append empty string if value is undefined
                }
            });

            if (data) {
                formData.append('_method', 'put');
                formData.append('id', data.id);
                await dispatch(update(formData, data.id));
            } else {


                await dispatch(add(formData));
                dispatch(getActiveCompany());
            }
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        dispatch(getCountries());
        dispatch(getTimezones());
    }, [dispatch]);
    useEffect(() => {


        setpostData(data);


    }, [data]);

    useEffect(() => {


        if (notfound) {
            navigate('/companies');
        }
        if (success) {
            navigate('/companies');
        }
    }, [success, notfound]);

    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row align-items-center">
                        <Breadcrumbs
                            maintitle="Add Company"
                            title="Dashboard"
                            subtitle="Add Company"
                        />
                        <div className="col-auto float-end ms-auto">
                            <Link to="/companies" class="btn btn-primary" on fdprocessedid="as7mtr">   <i className="fa fa-arrow-left" /> Go Back</Link>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-0">
                                            <div className="card-body">
                                                {loadingsingle || (!postdata && id) ? (
                                                    <Loader
                                                        className="feather-loader"
                                                        data-bs-toggle="tooltip"
                                                        title="Loading..."
                                                    />
                                                ) : fieldConfig ? (
                                                    <FormModal
                                                        error={error}
                                                        loading={loading}
                                                        removeErrorMsg={removeErrorMsg}
                                                        data={postdata}
                                                        fieldConfig={fieldConfig}
                                                        onSubmit={handleSubmit}
                                                    />
                                                ) : (
                                                    <div>No content available</div> // Fallback message or component
                                                )}


                                            </div> </div> </div> </div> </div> </div></div>

                </div> </div>


        </>
    );
};

export default CompanyForm;
