import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import Breadcrumbs from "../../../Breadcrumbs";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // Import your FormModal component
import FormModal from '../../../Common/FormModal';
import { add, update, removeErrorMsg, removeData, showData } from "../../../../redux/clientSlice";
import { getCountries, getTimezones } from '../../../../redux/commonSlice';
import { Loader } from "react-feather";
const ClientForm = () => {

    const navigate = useNavigate(); // For navigation after submission
    const dispatch = useDispatch();
    const [postdata, setpostData] = useState(null);
    const { data, loading, loadingsingle, error, success, notfound } = useSelector((state) => state.client);
    const { id } = useParams(); // Get the client ID from the URL
    useEffect(() => {
        if (id) {
            // dispatch(removeData());
            // If there's an ID, fetch the existing client data for editing
            dispatch(showData({ id }));
        }
    }, [id]);

    useEffect(() => {
        dispatch(removeData());
    }, []);
    const { countries, timezones } = useSelector((state) => state.common);
    const countryOptions = countries?.map(g => ({
        value: g.id,
        label: g.name,
    }));

    const timezoneOptions = timezones?.map(g => ({
        value: g.id,
        label: g.name,
    }));
    // const fieldConfig = [/* Your field configurations here */];

    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    let fieldConfig = [

        {
            name: "logo",
            label: "Logo Image",
            type: "file",
            validation: Yup.mixed()
                .required("A profile image is required")


        },
        { name: "name", placeholder: "Joe Doe", label: "Name", type: "text", validation: Yup.string().required("Name is required") },
        { name: "email", placeholder: "Enter email address", label: "Email", type: "email", validation: Yup.string().required("email number is required").email("Invalid email format") },
        { name: "phone_number", placeholder: "Enter mobile number", label: "Phone Number", type: "text", validation: Yup.string().required("Phone number is required") },
        { name: "company_name", placeholder: "Enter company name", label: "Company Name", type: "text", validation: Yup.string().required("Company number is required") },
        { name: "company_address", placeholder: "Enter Company Address", label: "Company Address", type: "text" },
        { name: "encpass", placeholder: "Enter password", label: "Password", type: "text", validation: Yup.string().required("Password number is required").min(8, "Password must be at least 8 characters") },
        {
            name: "country", label: "Country",

            type: "select",
            options: countryOptions,
            validation: Yup.string().required("Country is required")

        },
        {
            name: "timezone", label: "Timezone", type: "select",
            options: timezoneOptions,
            validation: Yup.string().required("Timezone is required")
        },
        {
            name: "sub_domain", placeholder: "Enter subdomain", label: "Subdomain", type: "text", validation: Yup.string()
                .required("Subdomain is required")
                .max(255, "Subdomain cannot be more than 255 characters"),
        },
        {
            name: "database_name", placeholder: "Please Enter One String Example:-'mydatabase'", label: "Database Name", type: "text",
            validation:
                Yup.string()
                    .required("Database name is required")
                    .max(255, "Database name cannot be more than 255 characters")
                    .matches(alphanumericRegex, "Database name must be alphanumeric and cannot contain underscores or special characters"),

        },
    ];
    if (postdata && id) {




        fieldConfig = [


            {
                name: "sub_domain", placeholder: "Enter subdomain'", label: "Subdomain", type: "text", validation: Yup.string()
                    .required("Subdomain is required")
                    .max(255, "Subdomain cannot be more than 255 characters"),
            },

        ];
    }


    const handleSubmit = async (values) => {
        try {
            // Handle the submission logic
            const formData = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                if (key === 'logo' && value && value instanceof File) {
                    formData.append(key, value);
                } else {

                    if (key != 'logo')
                        formData.append(key, value !== undefined ? value : ''); // Append empty string if value is undefined
                }
            });

            if (data) {
                formData.append('_method', 'put');
                formData.append('id', data.id);
                await dispatch(update(formData, data.id));
            } else {
                await dispatch(add(formData));
            }
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        dispatch(getCountries());
        dispatch(getTimezones());
    }, [dispatch]);
    useEffect(() => {

        if (data) {
            console.log('111');
            console.log(data);
            setpostData(data);
        }


    }, [data]);

    useEffect(() => {
        if (notfound) {
            navigate('/clients');
        }
        if (success) {
            navigate('/clients');
        }
    }, [success, notfound]);

    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row align-items-center">
                        <Breadcrumbs
                            maintitle="Add Client"
                            title="Dashboard"
                            subtitle="Add Client"
                        />
                        <div className="col-auto float-end ms-auto">
                            <Link to="/clients" class="btn btn-primary" on fdprocessedid="as7mtr">   <i className="fa fa-arrow-left" /> Go Back</Link>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-0">
                                            <div className="card-body">
                                                {loadingsingle || (!postdata && id) ? (
                                                    <Loader
                                                        className="feather-loader"
                                                        data-bs-toggle="tooltip"
                                                        title="Loading..."
                                                    />
                                                ) : fieldConfig ? (
                                                    <FormModal
                                                        error={error}
                                                        loading={loading}
                                                        removeErrorMsg={removeErrorMsg}
                                                        data={postdata}
                                                        fieldConfig={fieldConfig}
                                                        onSubmit={handleSubmit}
                                                    />
                                                ) : (
                                                    <div>No content available</div> // Fallback message or component
                                                )}


                                            </div> </div> </div> </div> </div> </div></div>

                </div> </div>


        </>
    );
};

export default ClientForm;
