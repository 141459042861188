import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { useFormContext } from 'react-hook-form';
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { getAll, deleteData, deleteDataQuestion } from '../../../../redux/pemQuestionSlice';
import { updateCategoriesDispatch } from '../../../../redux/pemTemplateSlice';
// Get register from form context

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';



const CustomFormComponent = () => {
    const { register } = useFormContext();
    const dispatch = useDispatch();
    const [openModel, setOpenModel] = useState(false)
    const [dataDetails, setdataDetails] = useState(null);
    const [dataType, setdataType] = useState(null);
    const [categories, setCategories] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const { allData, loading, pagination, success, search } = useSelector((state) => state.pemquestion);
    const { data } = useSelector((state) => state.pemtemplates);
    const [paginationState, setPaginationState] = useState({
        current: 1,
        pageSize: 10,
    });
    useEffect(() => {
        dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
    }, [dispatch, paginationState.current, paginationState.pageSize]);
    //console.log(allData, '0099')




    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };




    const columns = [
        {
            title: "",
            dataIndex: "id",
            key: "id",
            width: '50px',
            className: 'text-start-td',
            render: (text, record) => {
                if (record.rowType === 'category') {
                    return {
                        children: <span className="font-weight-bold text-left">{record.category_name} [{record.category_code}]</span>,
                        props: {
                            colSpan: columns.length - 2, // Span across all columns
                        },
                    };
                }
                return {
                    children: <span className="font-weight-bold">{record.pem_question_code} </span>,

                };
            },
        },
        // {
        //     title: "Parameter",
        //     dataIndex: "parameter",
        //     key: "parameter",
        //     className: 'text-start-td',
        //     width: '100px',
        //     render: (text, record) => (record.rowType === 'category' ? { props: { colSpan: 0 } } : text),
        // },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            width: '150px',
            className: 'text-wrap',
            render: (text, record) => (record.rowType === 'category' ? { props: { colSpan: 0 } } : text),
        },

        {
            title: "Weightage",
            key: "action",
            width: '50px',
            className: 'text-start-td',
            render: (text, record) => {
                if (record.rowType === 'category') {
                    return {
                        children: <div style={{
                            position: 'absolute',

                        }}
                            key={record.id}
                            data-check={record.isChecked}
                        >



                            {record.weightage}%
                            {/* <button className="btn btn-edit" onClick={() => addModal(record, 'category')}>
                                <i className="fa fa-edit" />
                            </button>
                            <button className="btn btn-delete" onClick={() => deleteModal(record, 'category')}>
                                <i className="fa fa-trash" />
                            </button> */}
                        </div>,

                    };
                }
                return {
                    children: <div style={{
                        position: 'absolute',
                        left: '20px',
                        top: '9px'
                    }}

                    >


                        {record.type === 'weightage' ? parseFloat(record.weightage).toFixed(0) + "%" : record.quantity}


                    </div>,

                };
            },

        },




        {
            title: "Target",
            key: "action",
            width: '150px',
            className: 'text-start-td',
            render: (text, record) => {
                if (record.rowType === 'category') {
                    return {
                        children: <div


                        >


                            {/* 
                            <input
                                {...register(`category_ids[${record.id}]`)}
                                type="checkbox"
                                className="question-category_ids-input"
                                checked={record.isCheckeds || false}
                                onChange={(e) => {
                                    handleCategoryCheck(record.id, e.target.checked);

                                }}
                                style={{ width: '20px', height: '20px' }}  // Adjust size if needed
                            /> */}

                            {/* <button className="btn btn-edit" onClick={() => addModal(record, 'category')}>
                                <i className="fa fa-edit" />
                            </button>
                            <button className="btn btn-delete" onClick={() => deleteModal(record, 'category')}>
                                <i className="fa fa-trash" />
                            </button> */}
                        </div>,

                    };
                }
                return {
                    children: <div
                        style={{
                            position: 'absolute',
                            top: '6px',
                            left: '125px'
                        }}




                    >


                        <input
                            {...register(`question_weightage_answer[${record.id}]`)}
                            type="number"
                            className="question-weightage-input"
                            value={record.weightage_answer || ''}
                            disabled={record.disabled}

                            onChange={(e) => {
                                handleQuestionWeightageChange(record.pem_question_category_id, record.id, e.target.value);

                            }}
                            style={{ width: '50px' }}
                        />



                    </div>,

                };
            },

        },


    ];







    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded
            ? [...expandedRowKeys, record.key]
            : expandedRowKeys.filter((key) => key !== record.key);
        setExpandedRowKeys(newExpandedRowKeys);
    };

    const expandable = {
        expandedRowRender: (record) => {
            // Nested rc-table for the expanded row
            return (
                <div>
                    {/* <h4>Nested Table for {record.key}</h4> */}
                    <Table
                        columns={columns}
                        dataSource={record.nestedData}
                        pagination={false}
                        showHeader={false}
                        // Hide header in nested table
                        size="small"
                    />
                </div>
            );
        },
        expandedRowKeys: expandedRowKeys,
        onExpand: handleExpand,
    };


    useEffect(() => {


        if (data?.templateQuestion) {
            let disable = false;
            if (data.PemRequest.progress != 'initiated')
                disable = true;

            const updatedCategories = data.templateQuestion.map((category) => {

                const updatedQuestions = category.teams_question.map((question) => {

                    const numericValue = parseFloat(question.weightage);
                    let numericValue1 = question?.pem_answers?.self_rating ? parseFloat(question.pem_answers.self_rating).toFixed(0) : ''

                    if (question.type == 'quantity')
                        numericValue1 = question.pem_answers.quantity


                    return {
                        ...question,



                        weightage_answer: numericValue1,
                        disabled: disable,
                        readonly: true, weightages: numericValue, isChecked: true
                    };


                });
                return {
                    ...category,
                    isCheckeds: category?.teams_question.length > 0 ? true : false,
                    readonly: true,
                    weightage: category?.teams_question.length > 0 ? category?.teams_question[0].category_weightage : '', teams_question: updatedQuestions
                };


            });

            setCategories(updatedCategories);

            setExpandedRowKeys(data.templateQuestion.map((item, index) => index + 1));

        }


    }, [data]);

    useEffect(() => {
        dispatch(updateCategoriesDispatch({ categories }));
        console.log('final')
        console.log(categories)
    }, [categories]);

    const onSearch = (search) => {
        dispatch(getAll({ page: paginationState.current, per_page: paginationState.pageSize, search }));
        console.log(search, 'pp');
    }



    const handleCategoryWeightageChange = (categoryId, value) => {
        const numericValue = parseFloat(value);
        if (numericValue < 0) {
            MySwal.fire({
                title: 'Invalid Input',
                text: 'Weightage cannot be negative.',
                icon: 'error',
            });
            return;
        }


        const updatedCategories = categories.map((category) => {
            if (category.id === categoryId) {
                return { ...category, weightage: parseFloat(value) || 0 };
            }
            return category;
        });

        // Calculate the latest total weightage excluding the current category
        const otherCategoriesTotal = updatedCategories
            .filter((category) => category.id !== categoryId)
            .reduce((total, category) => total + (category.weightage || 0), 0);

        const maxAllowedWeightage = 100 - otherCategoriesTotal;
        const newWeightage = Math.min(parseFloat(value) || 0, maxAllowedWeightage);

        // Update the specific category's weightage if it exceeds the maximum allowed
        const finalCategories = updatedCategories.map((category) => {
            if (category.id === categoryId) {
                return { ...category, weightage: newWeightage };
            }
            return category;
        });

        setCategories(finalCategories);

        if (parseFloat(value) > maxAllowedWeightage) {
            MySwal.fire({
                title: 'Error',
                text: `The maximum allowable weightage for this category is ${maxAllowedWeightage}%. Adjusted automatically.`,
                icon: 'warning',
            });
        }
    };


    const handleCategoryCheck = (categoryId, isChecked) => {
        //alert(isChecked)
        const updatedCategories = categories.map((category) => {
            if (category.id === categoryId) {
                const updatedQuestions = category.my_question.map((question) => {

                    return { ...question, isChecked: isChecked ? true : false };

                    return question;
                });
                return { ...category, isCheckeds: isChecked ? true : false, my_question: updatedQuestions };
            }
            return category;
        });
        console.log(updatedCategories);
        setCategories(updatedCategories);



    }

    const handleQuestionCheck = (categoryId, questionId, isChecked) => {
        //alert(isChecked)
        const updatedCategories = categories.map((category) => {
            if (category.id === categoryId) {
                const updatedQuestions = category.my_question.map((question) => {


                    if (question.id === questionId) {
                        return { ...question, isChecked: isChecked ? true : false };

                    }

                    return question;
                });
                return { ...category, isCheckeds: isChecked ? true : true, my_question: updatedQuestions };
            }
            return category;
        });

        setCategories(updatedCategories);



    }




    const handleQuestionWeightageChange = (cateid, questionId, value) => {
        const numericValue = parseFloat(value);
        if (numericValue < 0) {
            MySwal.fire({
                title: 'Invalid Input',
                text: 'Weightage cannot be negative.',
                icon: 'error',
            });
            return;
        }

        // Update the question weightage within its respective category
        const updatedCategories = categories.map((category) => {
            if (category.id === cateid) {
                const updatedQuestions = category.teams_question.map((question) => {
                    if (question.id === questionId) {
                        return { ...question, weightage_answer: numericValue };
                    }
                    return question;
                });
                return { ...category, teams_question: updatedQuestions };
            }
            return category;
        });

        setCategories(updatedCategories);


    };




    const validateCategoryWeightage = (totalWeightage) => {
        if (totalWeightage > 100) {
            MySwal.fire({
                title: 'Error',
                text: "The total weightage of all categories cannot exceed 100%.",
                icon: 'error',
            });
            return false;
        }
        return true;
    };



    const userElements = categories?.map((category, index) => ({
        key: index,
        id: category.id,
        rowType: 'category',
        category_name: category.category_name,
        category_code: category.category_code,
        weightage: category.weightage,
        isCheckeds: category.isCheckeds,
        readonly: category.readonly || false,

        nestedData: category.teams_question?.map((question, questionIndex) => ({
            ...question,
            index: questionIndex,
        }))
    }));

    return (
        <div className="table-responsive">
            <Table
                columns={columns}

                dataSource={
                    userElements?.length > 0
                        ? userElements?.map((designation) => ({
                            ...designation,
                            key: designation.id,
                        }))
                        : []
                }
                expandable={expandable}
                rowClassName={(record) =>
                    record.rowType === 'category' ? 'highlight-row' : ''
                }
                pagination={false}
                // Hide header in nested table
                // pagination={{
                //     current: paginationState.current,
                //     pageSize: paginationState.pageSize,
                //     total: pagination?.total,
                //     onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                // }}
                loading={loading}
            />
        </div>
    );
};

export default CustomFormComponent;


