import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"; // Import your FormModal component
import { submitData } from "../../../../../redux/pemRequestSlice";
const ProjectForm = ({ currentProjects }) => {
    const [projects, setProjects] = useState([]);
    const [projectssent, setProjectsSent] = useState([]);
    const dispatch = useDispatch();
    //console.log(currentProjects);
    // Handle input changes
    const handleInputChange = (index, field, value) => {
        //alert(index + "-" + field + "-" + value);

        // Create a deep copy of the state to avoid mutating the original
        const updatedProjects = projectssent.map((project, i) =>
            i === index ? { ...project, [field]: value } : project
        );


        // Update the state with the new projects array
        setProjectsSent(updatedProjects);
    };
    useEffect(() => {
        setProjects(currentProjects)
    }, [currentProjects]);

    useEffect(() => {

        const updatedData = [];
        currentProjects?.map((eachProject, index) => {



            updatedData.push({
                id: eachProject?.project?.id,
                project_name: eachProject?.project?.project_name,
                project_role: '',
                total_duration: eachProject?.total_duration,
                project_manager: eachProject?.project?.manager?.name || '',
                project_self_rating: '',
                project_self_comments: '',

            });




        });


        setProjectsSent(updatedData);

        // dispatch(submitData({ data: ratings, type: 'performSelfRating' }));
    }, []);
    useEffect(() => {
        console.log(projectssent);
        dispatch(submitData({ data: projectssent, type: 'performSelfRatingProjects' }));
    }, [projectssent]);

    return (
        <div className="table-responsive">
            <table className="table rating-table project-table">
                <tbody>
                    <tr className="table-active">
                        <th>S. No.</th>
                        <th>Project Name</th>
                        <th>Project Role</th>
                        <th>Project Duration(Hrs)</th>
                        <th>Project manager</th>
                        <th>Self Rating</th>
                        <th>Self Comments</th>
                    </tr>

                    {projects?.length > 0 &&
                        projects.map((eachProject, key) => (
                            <><input type="hidden" name="is_billable[]" value={eachProject?.project?.billability_id == 1 ? 1 : 0} />
                                <input type="hidden" name="project_id[]" value={eachProject?.project?.id} />
                                <input type="hidden" name="project_manager_id[]" value={eachProject?.project?.manager?.id} />
                                <tr>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        {key + 1}
                                    </td>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        <input
                                            className="noevent"
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            type="text"
                                            placeholder="Project Name"
                                            name="project_name[]"
                                            value={eachProject.project.project_name}
                                            onChange={(e) => handleInputChange(key, 'project_name', e.target.value)} />
                                    </td>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        <input
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            type="text"
                                            placeholder="Project Role"
                                            name="project_role[]"
                                            onChange={(e) => handleInputChange(key, 'project_role', e.target.value)} />
                                    </td>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        <input
                                            className="noevent"
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            type="text"
                                            placeholder="Project Duration"
                                            name="project_duration[]"
                                            value={eachProject.total_duration}
                                            onChange={(e) => handleInputChange(key, 'total_duration', e.target.value)} />
                                    </td>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        <input
                                            className="noevent"
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            type="text"
                                            placeholder="Project Manager"
                                            name="project_manager[]"
                                            value={`${eachProject.project.manager?.name}`}
                                            onChange={(e) => handleInputChange(key, 'project_manager', e.target.value)} />
                                    </td>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        <input
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            type="number"
                                            placeholder="Self Rating"
                                            name="project_self_rating[]"
                                            min="1"
                                            max="10"

                                            onChange={(e) => handleInputChange(key, 'project_self_rating', e.target.value)} />
                                    </td>
                                    <td style={{ borderBottom: '1px solid lightgrey' }}>
                                        <textarea
                                            style={{
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }}
                                            rows="1"
                                            placeholder="Self Comments"
                                            name="project_self_comments[]"
                                            onChange={(e) => handleInputChange(key, 'project_self_comments', e.target.value)}
                                        ></textarea>
                                    </td>
                                </tr></>

                        ))}
                </tbody></table ></div >

    );
};

export default ProjectForm;
