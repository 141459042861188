import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
    addSalaryPayment,
    removeErrorMsg,
    getSalaryPayments,
} from "../../../../redux/contractSlice";
import Modal from "react-bootstrap/Modal";
import Alert from "../../../../helpers/AlertMessage";
import Button from "react-bootstrap/Button";
import { message, Table } from "antd";
import { Link } from "react-router-dom";
import { number } from "yup";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import { format, parseISO, isValid } from "date-fns";

// SaveEmployeeSalaryDocument, DocumentList, DeleteDocument
const AddEditEmployeeSalaryPayment = ({
    employeeSalaryId,
    show,
    handleClose,
    onSalaryPaymentDeleteClick,
    amountPaid,
    handleSalaryPaymentSaveClick,
}) => {
    // console.log(amountPaid);
    const validationSchema = Yup.object().shape({
        amount: Yup.string().required("amount is required"),
        date: Yup.string().required("date is required"),
    });

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            amount: "",
            date: "",
        },
    });

    const dispatch = useDispatch();

    const { salarypayments, loading, error } = useSelector(
        (state) => state.contract
    );

    const userElements = salarypayments?.map((doc, index) => ({
        key: index,
        id: doc.id,
        amount: doc.amount,
        date: doc.payment_date,
    }));

    useEffect(() => {
        if (amountPaid) {
            const amount_paid =
                salarypayments?.reduce(
                    (sum, payment) => sum + parseFloat(payment.amount),
                    0
                ) ?? 0;
            const amount_remaining = Number(amountPaid) - Number(amount_paid);
            reset({
                amount: amount_remaining || "",
            });
        } else {
            reset({
                amount: "",
            });
        }
    }, [salarypayments, reset]);

    useEffect(() => {
        if (amountPaid) {
            const amount_paid =
                salarypayments?.reduce(
                    (sum, payment) => sum + parseFloat(payment.amount),
                    0
                ) ?? 0;
            const amount_remaining = Number(amountPaid) - Number(amount_paid);
            reset({
                amount: amount_remaining || "",
            });
        } else {
            reset({
                amount: "",
            });
        }
    }, [amountPaid, reset]);

    useEffect(() => {
        if (employeeSalaryId && Number(employeeSalaryId) > 0) {
            dispatch(getSalaryPayments(employeeSalaryId));
            reset();
        }
    }, [employeeSalaryId, getSalaryPayments]);

    const columns = [
        {
            title: "S.No",
            width: "10%",
            render: (text, record, index) => index + 1, // Calculate serial number based on row index
        },
        {
            title: "Amount Paid",
            dataIndex: "amount",
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: "30%",
        },
        {
            title: "Date",
            dataIndex: "date",
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: "20%",
        },
        {
            title: "Action",
            className: "text-end",
            render: (text, record) => (
                <div className="dropdown dropdown-action text-end me-30">
                    <Link
                        className="dropdown-item1 m-r-10"
                        to="#"
                        // data-bs-toggle="modal"
                        // data-bs-target="#delete"
                        onClick={() => onSalaryPaymentDeleteClick(record.id)}
                    >
                        <i className="fa fa-trash m-r-5" />
                    </Link>
                </div>
            ),
            sorter: (a, b) => a.length - b.length,
            width: "40%",
        },
    ];

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    const onSubmit = async (values) => {
        try {
            values.salary_id = employeeSalaryId;
            await handleSalaryPaymentSaveClick(values);
            reset();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal
            show={show}
            onHide={handleModalClose}
            centered
            dialogClassName="modal custom-modal custom-modal-three"
        >
            <Modal.Header>
                <Modal.Title>{"Salary Payments"}</Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    onClick={handleModalClose}
                >
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert type="danger" message={error} onClose={resetMsg} />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" name="id" value={employeeSalaryId} />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="col-form-label">
                                        Amount{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <Controller
                                        name="amount"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className={`form-control ${
                                                    errors.amount
                                                        ? "error-input"
                                                        : ""
                                                }`}
                                                type="number"
                                                {...field}
                                            />
                                        )}
                                    />
                                    {errors.amount && (
                                        <span className="text-danger">
                                            {errors.amount.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <label className="col-form-label">
                                        Date :
                                    </label>
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="cal-icon">
                                                <DatePicker
                                                    className={`form-control w-100 ${
                                                        field.value
                                                            ? ""
                                                            : "error-input"
                                                    }`}
                                                    selected={
                                                        field.value &&
                                                        isValid(
                                                            parseISO(
                                                                field.value
                                                            )
                                                        )
                                                            ? parseISO(
                                                                  field.value
                                                              )
                                                            : null
                                                    }
                                                    onChange={(date) => {
                                                        // Ensure `date` is valid before formatting
                                                        field.onChange(
                                                            date
                                                                ? format(
                                                                      new Date(
                                                                          date
                                                                      ),
                                                                      "yyyy-MM-dd"
                                                                  )
                                                                : ""
                                                        );
                                                    }}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Select a date"
                                                />
                                            </div>
                                        )}
                                    />

                                    {errors.date && (
                                        <span className="text-danger">
                                            {errors.date.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <button
                                className="btn btn-primary mt-4"
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card mb-0 data-table-card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <Table
                                        className="table-stripped"
                                        columns={columns}
                                        dataSource={
                                            userElements?.length > 0
                                                ? userElements?.map(
                                                      (salaryPayment) => ({
                                                          ...salaryPayment,
                                                          key: salaryPayment.id,
                                                      })
                                                  )
                                                : []
                                        }
                                        pagination={{}}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    {!loading ? (
                        ""
                    ) : (
                        <button
                            type="button"
                            className="btn btn-primary"
                            disabled
                        >
                            <i className="fas fa-spinner fa-spin me-2" />
                            Processing...
                        </button>
                    )}
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    );
};

export default AddEditEmployeeSalaryPayment;
