import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Alert from "../../../../helpers/AlertMessage";
import { getAll } from "../../../../redux/rolesSlice";
import { updateUserRole, getSingleUserDetails, removeErrorMsg } from '../../../../redux/userSlice';
import { Loader } from "react-feather";
import Button from 'react-bootstrap/Button';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

function RoleSelection({ show, handleClose, data }) {
    const { control, handleSubmit, watch, setValue } = useForm({
        defaultValues: {
            roles: [],
            primaryRole: null,
        },
    });
    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };
    const [validationerror, setValidationerror] = useState(null);
    const dispatch = useDispatch();
    const { loading, loadingsingle, error, userDetailsSingle, success } = useSelector((state) => state.user);
    const { allData } = useSelector((state) => state.roles);

    // Create role options from the provided data
    const roleOptions = allData?.map(g => ({
        value: g.id,
        label: g.name,
    }));

    const selectedRoles = watch("roles");

    // Function to handle form submission


    const onSubmit = async (datas) => {
        setValidationerror(null)
        if (!datas.roles || datas.roles.length < 1) {

            setValidationerror('Please select atleast one role.');
            return;
        }

        if (!datas.primaryRole) {

            setValidationerror('Please select a primary role.');
            return;
        }



        try {
            datas.user_id = data.id;
            await dispatch(updateUserRole(datas));



        } catch (error) {
            console.error(error);
        }
        // alert(`Roles: ${datas.roles.join(", ")}\nPrimary Role: ${datas.primaryRole}`);
        console.log(datas);
    };

    // Function to reset error messages
    const resetMsg = () => {
        setValidationerror(null)
        dispatch(removeErrorMsg());
    };

    // Function to handle modal close
    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };
    const handleCheckboxChange = (value, checked) => {
        if (checked) {
            setValue("roles", [...watch("roles"), value]);
        } else {
            const updatedRoles = watch("roles").filter((r) => r !== value);
            setValue("roles", updatedRoles);
            if (watch("primaryRole") === value) {
                setValue("primaryRole", null);
            }
        }
    };
    const handleRadioChange = (field, value) => {
        // Set the selected primary role
        field.onChange(value);
    };


    useEffect(() => {

        if (success) {
            showAlert({
                title: 'Role Update',
                text: success,
                confirmButtonColor: '#FF902F',
                confirmButtonText: 'OK',
            });

            handleClose();

        }


    }, [success, showAlert]);
    useEffect(() => {
        setValue("roles", []);
        setValue("primaryRole", null);

        if (userDetailsSingle && userDetailsSingle?.roles?.length > 0) {


            const rolesArray = userDetailsSingle.roles.map(role => role.role_id); // Convert to [1, 2]
            console.log(rolesArray);
            setValue("roles", [...watch("roles"), ...rolesArray]); // Update the roles field
            setValue("primaryRole", data?.role_id);

        }
        else {
            if (data && data.role_id) {
                setValue("roles", [...watch("roles"), data.role_id]);
                setValue("primaryRole", data?.role_id);

            }
        }


    }, [data, userDetailsSingle]);

    useEffect(() => {


        dispatch(getAll({ page: 1, per_page: 1000, search: null }));
    }, [dispatch]);



    useEffect(() => {

        if (data?.id) {
            dispatch(getSingleUserDetails(data.id));
        }
    }, [data]);


    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>Role Setting</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>

                {validationerror && (
                    <Alert
                        type="danger"
                        onClose={resetMsg}
                        message={
                            <div>{validationerror}</div>
                        }
                    />
                )}


                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}

                {loadingsingle ? (
                    <> <Loader
                        className="feather-loader"
                        data-bs-toggle="tooltip"
                        title="Loading..."
                    /></>
                ) : (
                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {roleOptions?.map((role) => (
                                <div key={role.value} className="row">
                                    <div className="col-md-4">
                                        <Controller
                                            name="roles"
                                            control={control}
                                            render={({ field }) => (
                                                <>

                                                    <input
                                                        type="checkbox"
                                                        value={role.value}
                                                        checked={field.value.includes(role.value)}
                                                        onChange={(e) => handleCheckboxChange(role.value, e.target.checked)}
                                                    />
                                                    &nbsp;   <label>{role.label}</label>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Controller
                                            name="primaryRole"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        type="radio"
                                                        value={role.value}
                                                        disabled={!selectedRoles.includes(role.value)}

                                                        checked={field.value === role.value}
                                                        onChange={() => handleRadioChange(field, role.value)}
                                                    />
                                                    &nbsp;<label>Primary Role</label>
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            ))}
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleModalClose}>
                                    Close
                                </Button>
                                {!loading ? (
                                    <button className="btn btn-primary" type="submit">
                                        Save Changes
                                    </button>
                                ) : (
                                    <button type="button" className="btn btn-primary" disabled>
                                        <i className="fas fa-spinner fa-spin me-2" />
                                        Processing...
                                    </button>
                                )}
                            </Modal.Footer>
                        </form>
                    </>
                )}






            </Modal.Body>
        </Modal>
    );
}

export default RoleSelection;
